import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
/* import { DialogService } from 'ng6-bootstrap-modal'; */
import { PhoneVerificationBase } from '../../../../public/base-components/phone-verification-base/phone-verification-base.component';
import { UserProfileService } from '../../../../services/user-profile.service';
/* import { ApiClient } from '../../../../services/utils/api-client/api-client'; */
import { Callback } from '../../../../services/utils/cognito.service';
import { PhoneNumberPipe } from '../../../../services/utils/phone-number.pipe';
import { ResponseHandlerService } from '../../../../services/utils/response-handler.service';
import { UserManagement } from '../../../../services/utils/state-management/action-classes';
import { Utility } from '../../../../services/utils/utility';
import { VerificationService } from '../../../../services/utils/verification.service';
import { UserLoginService } from "../../../../services/user-login.service";
import { PhoneNumberFormatPipe } from '../../../../services/utils/phoneNumberFormat.pipe';
import { environment } from '../../../../../environments/environment';
import { ApiRequestService } from 'src/app/services/api-client/api-requests.service';
import { Config } from '../../../../services/utils/Config';
@Component({
  selector: 'app-edit-profile-phone-verification',
  templateUrl: './../../../../public/base-components/phone-verification-base/phone-verification-base.component.html',
  styleUrls: ['./../../../../public/base-components/phone-verification-base/phone-verification-base.component.css',  '../../../../../assets/css/mobile-region-field.css']
})

export class EditPhoneVerificationComponent extends  PhoneVerificationBase implements Callback, OnInit {
  @Input() override parent;
  @ViewChild('ngxotp') ngxOtp;
  public edit = true;
  changed: any = false;
  countryCode: any;
  brand: any;

  DIAL_CODE_LENGTH_4: number = 4;
  DIAL_CODE_LENGTH_3: number = 3;
  DIAL_CODE_LENGTH_2: number = 2;

  constructor(
    formBuilder: FormBuilder,
    translate: TranslateService,
    verificationService: VerificationService,
    responseHandler: ResponseHandlerService,
    userProfileService: UserProfileService,
    router: Router,
    userManagement: UserManagement,
    phoneNumberPipe: PhoneNumberPipe,
    private phoneNumberFormatPipe: PhoneNumberFormatPipe,
    /* private dialogService: DialogService, */
    utility: Utility,
    userService: UserLoginService
  ) {
    super(
      formBuilder,
      translate,
      verificationService,
      phoneNumberPipe,
      responseHandler,
      userManagement,
      userProfileService,
      router,
      utility,
      userService
    );

  }

  override ngOnInit() {
    super.ngOnInit();
    Utility.focusLogo();
    let phone;
    this.brand = localStorage.getItem('userBrand');
    this.countryCode = this.userProfileService.getNewCountryCode() || Config[this.brand.toUpperCase()].defaultCountryCode;
    this.dialCode = this.userProfileService.getNewDialCode();
    if(this.dialCode.length === this.DIAL_CODE_LENGTH_4){
      phone = this.userProfileService.getNewPhone().substring(this.DIAL_CODE_LENGTH_4);   
    }
    else if(this.dialCode.length === this.DIAL_CODE_LENGTH_3){
      phone = this.userProfileService.getNewPhone().substring(this.DIAL_CODE_LENGTH_3);   
    }
    else{
      phone = this.userProfileService.getNewPhone().substring(this.DIAL_CODE_LENGTH_2);
    }
    this.phoneValidation.controls["phone"].setValue(this.phoneNumberFormatPipe.transform(phone, this.countryCode));
  }

  onSuccess(result: any) {
    this.router.navigate(['/securehome']);
  }

  onVerify(dialCode) {
    this.phoneEntered = false;
    let phone = this.phoneValidation.get('phone').value;
    phone = dialCode + phone.replace(/[^0-9]*/g, "");
    this.verificationService.sendCode('phone', phone, 'update', this);
  }

  onSubmitCode() {
    this.resetOTP();
    this.errorMessage = null;
    this.successMessage = null;
    this.userProfileService.saveNumber(this.codeValidation.get('code').value, this);
  }


  callback() {
    this.isLoaderActive = false;
    this.errorMessage = null;
    this.successMessage = null;
    let user = this.userManagement.getUser();
    user.phoneActive = true;
    this.userManagement.updateUser(user);
    if (this.parent) {
      this.parent.updateCallback();
    } else {
      localStorage.setItem('phoneUpdate', 'true');
      this.successMessage = this.translate.instant("PROFILE.EDIT.POPUP.SUCCESS_MESSAGE");
    }
    this.utility.addSuccessMessage(this.successMessage);
    this.userProfileService.passUserData({user : user, isVerified: true});
    if(!this.addPhoneUnAuth){
      this.changed = false;
      this.router.navigate(['/securehome']);
    }
    

  }

  canDeactivate() {
    let objectState = JSON.parse(localStorage.getItem('state'));
    // Your profile has been successfully updated

  // HALOC-3017-
  // logout case, the form change was not being detected and was logging out the user, 
  // Now, we check if the user is not present in localstorage, then we simply logout the user and show no Prompt Box
  let email = localStorage.getItem(`CognitoIdentityServiceProvider.${environment.clientId}.LastAuthUser`);
  
   if (!email || objectState['successMessage'] === this.translate.instant('PROFILE.EDIT.POPUP.SUCCESS_MESSAGE')) {  
      return true;
    } else {
      return false;
    }
  }

  override callbackWithParam(errorObj: any) {
    this.isLoaderActive = false;
    this.errorMessage = this.responseHandler.getErrorMessageViaCode(errorObj, 'REGISTRATION.PHONE_VERIFICATION.ERROR');
    if (this.errorMessage.slice(-1) !== ".") {
      this.errorMessage += ".";
    }
    this.resetOTP();
  }


}