<div class="main">
<div class="sp-middle-element lock-base">
    <div class="col-xs-12 sm-no-padding">
      <div class="col-xs-12 sm-no-padding">
        <div class="row">
          <div class="col-xs-12 sp-back" >
            <!-- <a  (click)="goBack()">&lt;  {{ 'PROFILE.BACK' | translate }}</a> -->
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12 sm-no-padding" >
            <router-outlet></router-outlet>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
