<div>
  <div class="pull-right">
    <div class="mg-right-10">
      <div class="upload-img-nav hidden-sm hidden-xs">
        <img alt="user-img"  [src]="userObject.presignedurl" *ngIf="userObject.presignedurl" class="nav-img">
        <img alt="user-img" src="../../../../assets/images/ico-user.png" *ngIf="!userObject.presignedurl" class="nav-img-demo">
      </div>
    </div>
    <div class="mg-right-10">
      <div class="sp-red-text hidden-sm hidden-xs font-text">{{'HI_TEXT' | translate }} {{ userObject.firstName}} {{ userObject.lastName}}</div>
    </div>
    <button name="hamburger-menu" #hamburger [ngClass]="{'bar-display' : open}"  class="btn sp-menu-btn dropdown-toggle hidden-md hidden-lg" data-toggle="dropdown"
      aria-haspopup="true" aria-expanded="false" (click)='toggle(true)'>
      <div class="sp-menu-button-line"></div>
      <div class="sp-menu-button-line"></div>
      <div class="sp-menu-button-line"></div>
    </button>

    <button name="dropdown" [ngClass]="{'cross-display' : !open}" class="btn sp-menu-btn hidden-md hidden-lg" data-toggle="dropdown"
      aria-haspopup="true" aria-expanded="false" (click)='toggle(false)'>
      <img alt="hamburger-close" src="../../../../assets/images/ico-hamburger-close.png">
    </button>

    <a name="down-link" class="hidden-sm hidden-xs nav-arrow-icon" data-toggle="dropdown" (click)='toggle(true)'>
      <span>
        <i class="fa fa-chevron-down font-text"></i>
      </span>
    </a>
    <ul class="dropdown-menu sp-dropdown drop-down">
      <li class="list-i">
        <a [routerLink]="['/profile/edit']" class="sp-menu-item">{{'NAVBAR_MENU.ACCOUNT_SETTINGS_TEXT' | translate}}</a>
      </li>
      <li class="list-i">
        <a name="help-faq-link" (click)="goToLink()" class="sp-menu-item">{{'NAVBAR_MENU.HELP_TEXT' | translate}}</a>
      </li>
      <li class="list-i">
        <button name="logout-button" class="btn btn-block logout-btn" (click)="onLogout()">{{'NAVBAR_MENU.LOGOUT_BTN' | translate}}</button>
      </li>
     
    </ul>
  </div>
</div>
