<app-loader></app-loader>
<div *ngIf="whiteListedUsersOnly != null">
  <div class="container-fluid" *ngIf="!whiteListChecked && whiteListedUsersOnly">
    <div class="row">
      <app-white-list-component></app-white-list-component>
    </div>
  </div>
  <div *ngIf="whiteListChecked || !whiteListedUsersOnly">
    <div class="container-fluid" *ngIf="unwantedPage != true && !userBrand">

      <app-brand-selection></app-brand-selection>

    </div>

    <div *ngIf="unwantedPage === true || userBrand">
      <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12 no-padding">
          <ng-container *ngIf="userBrand === 'kwikset'">
            <div class="row" *ngIf="pathName !== '/home'">

              <div class="col-md-12 header no-padding">
                <div class="custom-main-container">
                  <div class="col-lg-3 col-md-3 col-sm-8 col-xs-10 padding-top-header header-alignment-left">
                    <a name="login-link" href="home/login">
                      <ng-container *ngIf="userBrand === 'kwikset'">
                        <img alt="kwikset-logo" class="site-logo" src="assets/images/kwikset-new.svg">
                      </ng-container>
                    </a>
                  </div>
  
                  <div class="col-lg-8 col-md-8 col-sm-4 col-xs-2 text-right padding-top-15">
                    <ng-container *ngIf="isVerified && userObject && userObject.phoneActive">
                      <app-nav-bar></app-nav-bar>
                    </ng-container>
                  </div>
  
                </div>
  
              </div>
  
            </div>
          </ng-container>
          <ng-container *ngIf="userBrand === 'weiser'">
            <div class="row" *ngIf="pathName !== '/home'">

              <div class="col-md-12 header no-padding">
                <div class="custom-main-container">
                  <div class="col-md-3 col-sm-4 col-xs-6 padding-top-header header-alignment-left">
                    <a name="login-link" href="home/login"> 
                      <ng-container *ngIf="userBrand === 'weiser'">
                        <img alt="weiser-logo" class="site-logo" src="assets/images/weiser.png">
                      </ng-container>
                    </a>
                  </div>

                  <div class="padding-top-15 col-sm-6 col-xs-3 hidden-md hidden-lg ">
                    <app-language></app-language>
                  </div>
  
                  <div class="col-md-7 col-sm-2 col-xs-3 text-right padding-top-15">
                    <ng-container *ngIf="isVerified && userObject && userObject.phoneActive">
                      <app-nav-bar></app-nav-bar>
                    </ng-container>
                  </div>
  
                  <div class="padding-top-15 col-md-2 hidden-sm hidden-xs padding-left-0">
                      <app-language></app-language>
                  </div>
  
                </div>
  
              </div>
  
            </div>
          </ng-container>
          <router-outlet></router-outlet>
          <div class="row footer-top-border">
            <div class="col-md-12 no-padding footer-parent">
              <footer class="page-footer font-small teal pt-4">
                <div class="col-md-12 sp-footer">
                  <div class="row sp-footer-row-1">
                    <div
                      class="col-lg-5 col-lg-offset-1 col-md-5 col-md-offset-1 col-sm-5 col-xs-6 justify-content-md-center sp-footer-padding">
                      <div class="text-left sp-footer-content">
                        <a tabIndex="67" class="footer_content anchor-padding"
                          href="{{('LINKS.' + userBrand.toUpperCase() + '.SUPPORT_CENTER') | translate}}"
                          target="_blank">{{ 'HOME.FOOTER.TITLE' | translate}}</a>
                        <br class="mobile-break">
                        <div class="mobile-space"></div>
                        <a tabIndex="68" class="footer_content anchor-padding"
                          href="{{('LINKS.' + userBrand.toUpperCase() + '.PRODUCTS') | translate}}"
                          target="_blank">{{ 'HOME.FOOTER.PRODUCTS' | translate}}</a>
                        <br class="mobile-break">
                        <div class="mobile-space"></div>
                        <a tabIndex="69" class="footer_content anchor-padding"
                          href="{{('LINKS.' + userBrand.toUpperCase() + '.APPS') | translate}}"
                          target="_blank">{{ 'HOME.FOOTER.APPS' | translate}}</a>
                      </div>

                    </div>
                    <div
                      class="col-lg-6 col-lg-offset-0 col-md-5 col-md-offset-1 col-sm-7 col-sm-offset-0 col-xs-6 sp-footer-padding padding-remove">

                      <div class="footer-content-wrapper">
                        <a tabIndex="70" class="footer_content anchor-padding-1"
                          href="{{('LINKS.' + userBrand.toUpperCase() + '.TOS') | translate}}"
                          target="_blank">{{ 'HOME.FOOTER.TERMS_OF_USE' | translate}}</a>
                        <br class="mobile-break">
                        <div class="mobile-space"></div>
                        <a tabIndex="71" class="footer_content anchor-padding"
                          href="{{('LINKS.' + userBrand.toUpperCase() + '.PP') | translate}}"
                          target="_blank">{{ 'HOME.FOOTER.PRIVACY_POLICY' | translate}}</a>
                        <br class="mobile-break">
                        <div class="mobile-space"></div>
                        <a tabIndex="72" class="footer_content anchor-padding" (keydown.enter)="showVersionInfo()" (click)="showVersionInfo()">{{
                          'HOME.FOOTER.VERSION_INFO' | translate}} </a>

                        <br class="mobile-break">
                        <div class="mobile-space"></div>
                        <a tabIndex="73" class="footer_content anchor-padding" (keydown.enter)="showWebAccessibilty()" (click)="showWebAccessibilty()">{{
                          'HOME.FOOTER.WEB_ACCESSIBILITY' | translate}}</a>
                        <br class="mobile-break">
                        <div class="mobile-space"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 sp-footer no-padding">
                  <div class="row">
                    <div
                      class="col-lg-6 col-lg-offset-6 col-md-5 col-md-offset-7 col-sm-12 col-xs-12 justify-content-md-center no-padding">
                      <div class="mobile-screen-copy-rights">
                        <p class="copy_rights">
                          {{ ('COPYRIGHT_TEXT' | translate).replace("[CURRENT_YEAR]", currentYear)}} <br
                            class="mobile-break"> {{ 'RIGHTS_RESERVED_TEXT' | translate }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </footer>
            </div>
          </div>
          <div class="sleek-fixed-footer"></div>
        </div>
        <div class="col-md-1 hidden-sm hidden-xs"></div>
        <app-timeout></app-timeout>
      </div>
    </div>
  </div>
</div>