<div>
  <div class="modal-header">
    <button type="button" class="close close-btn" (click)="close()">
      <mat-icon>clear</mat-icon>
    </button>
  </div>
  <div class="modal-body">
    <div class="options-modal">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="col-xs-12">
          <div class="row">
            <div class="col-md-12">
              <div class="options-modal">
                <h3 class="text-center header-modal">{{ 'LOGIN.VERIFICATION.VERIFY_ACCOUNT' | translate }}</h3>
                <div *ngIf="!isLoading">
                  <p *ngIf="isPhone" class="text-center selection-header">{{ 'LOGIN.VERIFICATION.VER_CODE_PHONE' |
                    translate }} <b> {{phoneLastDigits}}</b>.</p>
                  <p *ngIf="isEmail" class="text-center selection-header ellipsis"> {{
                    'LOGIN.VERIFICATION.VER_CODE_EMAIL' | translate }}
                    <b>{{this.address}}</b>.
                  </p>
                  <br />

                  <div class="otp-container">
                     <ng-otp-input #ngOtpInput (onInputChange)="handleFillEvent($event)" [config]="ngconfig"
                        id="ng-otp-input" *ngIf="showOtpComponent"></ng-otp-input>
                  </div>
                  <div class="form-group has-feedback">
                    <div class="otp-container-div">
                      <div class="text-center sp-hyperlink-div error-container">
                        {{errorMsg}}
                      </div>
                      <ng-container *ngIf="isPhone">
                        <div class="text-center sp-hyperlink-div">
                          {{ 'REGISTRATION.EMAIL_VERIFICATION.RE_SEND_SMS' | translate }}
                          <a (click)="reSendCode()" class="sp-hyperlink">{{
                            'LOGIN.VERIFICATION.LINK_RESEND_CODE' | translate }}.</a>
                        </div>
                        <div class="text-center sp-hyperlink-div">
                          {{ 'LOGIN.VERIFICATION.OR' | translate }}
                        </div>
                        <div class="text-center sp-hyperlink-div">
                          <a (click)="setMediumAsEmail()" class="sp-hyperlink">Send code</a>
                          {{ 'LOGIN.VERIFICATION.TO_MY_EMAIL' | translate }}
                        </div>
                      </ng-container>
                      <ng-container *ngIf="isEmail">
                        <div class="text-center sp-hyperlink-div">
                          {{ 'REGISTRATION.EMAIL_VERIFICATION.RE_SEND_CODE' | translate }}
                          <a (click)="reSendCode()" class="sp-hyperlink">{{ 'LOGIN.VERIFICATION.LINK_RESEND_CODE' |
                            translate }}.</a>
                        </div>
                        <div class="text-center sp-hyperlink-div">
                          {{ 'LOGIN.VERIFICATION.OR' | translate }}
                        </div>
                        <div class="text-center sp-hyperlink-div">
                          <a (click)="setMediumAsPhone()" class="sp-hyperlink">Send code</a>
                          {{ 'LOGIN.VERIFICATION.TO_MY_PHONE' | translate }}
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>
                <div *ngIf="isLoading" class="loader-body sp-middle-element loading">
                  <div class="col-sm-12">
                    <div class="loader center-block"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>