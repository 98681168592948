<div class="col-md-10 col-md-offset-1 col-lg-8 col-lg-offset-2 col-sm-6 col-sm-offset-3 col-xs-12">
  <div *ngIf="errorMessage!=null" class="alert alert-danger alert-dismissible fade in">
    <a class="close" aria-label="close" (click)="errorMessage = null">&times;</a>
    {{ errorMessage }}
  </div>
  <h3 class="heading-sm-center"> {{ 'FORGOT_PASSWORD.ENTER_SECURITY_QUESTION.TITLE' | translate }} </h3>
  <form class="form-signin" method="POST" action="#" role="form" [formGroup]="enterSecretQuestion" (ngSubmit)="onSubmitSecurityQuestion()">
    <div class="form-group has-feedback">
      <div [ngClass]="{'has-error' : !this.enterSecretQuestion.controls['secretQuestion'].invalid && this.enterSecretQuestion.controls['secretQuestion'].dirty && this.enterSecretQuestion.controls['secretAnswer'].invalid && this.enterSecretQuestion.controls['secretAnswer'].dirty, 'has-success' : this.enterSecretQuestion.valid}">
        <div>
          <select class="questions col-md-12 form-control" formControlName="secretQuestion" [(ngModel)]="question1"
            (change)="onChangeQuestion(1)">
            <option selected hidden disabled value=""> {{ 'FORGOT_PASSWORD.ENTER_SECURITY_QUESTION.LABEL_QUESTION' | translate }} </option>
            <option *ngFor="let question of this.questions" value="{{question}}">{{question}}</option>
          </select>
          <mat-form-field hideRequiredMarker class="example-full-width" appearance="fill">
            <mat-label>{{ 'FORGOT_PASSWORD.ENTER_SECURITY_QUESTION.LABEL_ANSWER' | translate}}</mat-label>
            <textarea maxlength="255" formControlName="secretAnswer" class="textarea" rows="3" matInput></textarea>
          </mat-form-field>
          <!-- <textarea rows="3" id="secretAnswer" class="form-control answer-text" maxlength="255" formControlName="secretAnswer" [placeholder]="'FORGOT_PASSWORD.ENTER_SECURITY_QUESTION.LABEL_ANSWER' | translate" ></textarea> -->
         </div>
        <p *ngIf="!this.enterSecretQuestion.controls['secretQuestion'].invalid && this.enterSecretQuestion.controls['secretQuestion'].dirty && this.enterSecretQuestion.controls['secretAnswer'].invalid && this.enterSecretQuestion.controls['secretAnswer'].dirty"
          class="help-block">{{ 'VALIDATION.SECURITY_QUESTIONS' | translate}}</p>
      </div>
    </div>
    <div class="form-group">
      <button id="submitAnswer" type="submit" class="btn btn-block sp-btn-dark h-45" [disabled]="!enterSecretQuestion.valid">
        {{ 'FORGOT_PASSWORD.ENTER_SECURITY_QUESTION.BUTTON_CONFIRM_QUESTION' | translate }}
      </button>
    </div>
  </form>
</div>
