import { Component, EventEmitter, ViewEncapsulation } from '@angular/core';
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
// import { DialogComponent, DialogService } from "ng6-bootstrap-modal";
export interface ConfirmModel {
  title: string;
  message: string;
  confirmButtonText: string;
  cancelButtonText: string;
  showCloseIcon: boolean;
}
@Component({
  selector: 'confirm',
  styleUrls: ['./confirm.css'],
  encapsulation: ViewEncapsulation.None,
  template: `<div class="{{modalCss}}">
              <div *ngIf="this.title" class="modal-header">
               <button *ngIf="this.showCloseIcon"  type="button" class="close" >&times;</button>
                <h4 class="modal-title">{{title || 'Confirm'}}</h4>
                  </div>
                    <div class="modal-body">
                    <p>{{message || 'Are you sure?'}}</p>
                  </div>
                 <div class="modal-footer">
                <button *ngIf="confirmButtonText" type="button" class="btn sp-form-button sp-green-button" (click)="confirm()">{{confirmButtonText}}</button>
               <button *ngIf="cancelButtonText" type="button" class="btn sp-form-button-cancel sp-red-button" >{{cancelButtonText}}</button>
              </div>
             </div>`
})
export class ConfirmComponent implements ConfirmModel {
  title;
  message;
  confirmButtonText;
  cancelButtonText;
  showCloseIcon;
  result:{};
  modalCss = 'modal-view';
  public event: EventEmitter<any> = new EventEmitter();
  constructor(public bsModalRef: BsModalRef,private modalService: BsModalService) {
  }
  
  confirm() {
    // we set dialog result as true on click on confirm button,
    // then we can get dialog result from caller code
   /*  this.close(); */
    this.result = true;
    this.event.emit(this.result);
    this.modalService.hide();
    this.modalCss;
  }
}
