<!-- {'sp-middle-element-account-info': (regStep | async)==0, 'sp-middle-element':(regStep | async)!=0} -->
<div class="sp-middle-element">
  <div class="col-md-12 col-sm-12 col-xs-12 no-padding sp-middle-element-direct-child">
    <div class="clearfix">
      <div class="col-md-6 col-sm-6 hidden-sm hidden-xs sp_login_left">
        <div class="col-md-12 sp_registration_img">
          <div class="gradient">
            <div>
              <div class="content-heading">{{ 'SECURE_HOME.WELCOME_TAG' | translate }}</div>
              <p class="content-banner">{{ 'BRAND_SELECTION.CONTENT_HEADING_2' | translate }}</p>
              <p class="content-banner">{{ 'BRAND_SELECTION.CONTENT_HEADING_3' | translate }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 sp_login_right">
        <div class="col-md-12 sp_grey_bg">
          <!-- <div class="row">
              <app-registeration-steps></app-registeration-steps>
          </div> -->

          <ng-container *ngIf="provider === providersConfiguration.FEDERATED">
            <div *ngIf="(regStep | async)==0" class="row sp-reg-comp">
              <app-account-info [parent]='this'></app-account-info>
            </div>
            <div *ngIf="(regStep | async)==1" class="row sp-reg-comp">
              <app-email-verification [continueType]=phone></app-email-verification>
            </div>
            <div *ngIf="(regStep | async)==2" class="row sp-reg-comp">
              <verification-continue-base [continueType]=phone></verification-continue-base>
            </div>
            <div *ngIf="(regStep | async)==3" class="row sp-reg-comp">
              <app-confirmation-screen></app-confirmation-screen>
            </div>
          </ng-container>
          <ng-container *ngIf="provider !== providersConfiguration.FEDERATED">
            <div *ngIf="(regStep | async)==0" class="row sp-reg-comp">
              <app-account-info [parent]='this'></app-account-info>
            </div>
            <div *ngIf="(regStep | async)==1" class="row sp-reg-comp">
              <app-email-verification [continueType]=email></app-email-verification>
            </div>
            <div *ngIf="(regStep | async)==2" class="row sp-reg-comp">
              <verification-continue-base [continueType]=email></verification-continue-base>
            </div>
            <div *ngIf="(regStep | async)==3" class="row sp-reg-comp">
              <app-email-verification [continueType]=phone></app-email-verification>
            </div>
            <div *ngIf="(regStep | async)==4" class="row sp-reg-comp">
              <verification-continue-base [continueType]=phone></verification-continue-base>
            </div>
            <div *ngIf="(regStep | async)==5" class="row sp-reg-comp">
              <app-security-questions></app-security-questions>
            </div>
            <div *ngIf="(regStep | async)==6" class="row sp-reg-comp">
              <app-confirmation-screen></app-confirmation-screen>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>