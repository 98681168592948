<!-- <div [ngStyle]="{'height':this.flag === true && this.langFlag === true ? '340px': '322px'}"> -->
<div class="{{modalCss}}">
    <div class="modal-header">
      <button type="button" class="close" (click)="closeDialog(false)"><mat-icon>close</mat-icon></button>
    </div>
    <div class="modal-body">
      <div class="options-modal">
        <div class="col-md-12 col-sm-12 col-xs-12 no-padding">
          <div class="col-xs-12">
            <div class="row">
              <div class="col-md-12">
                <div class="options-modal">
                  <h3 class="text-center header-modal">
                    <span class="back-icon-mobile" (click)="closeDialog(false)">
                      <i class="fa fa-chevron-left"></i>
                    </span>
                    <p class="spacing-header">
                    {{headerText}}
                    </p>
                  </h3>
                  <p class="text-center selection-header">{{headingText}}</p>
                  <br />

                  <div class="form-group has-feedback">
                    <button (click)="closeDialog(true)" class="options-modal-btn btn-block btn-filled btn-filter-box" style="text-transform:none !important;">
                      {{confirmButtonText}}
                    </button>
                    <button (click)="closeDialog(false)" class="options-modal-btn btn-block btn-empty">
                      {{cancelText}}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
<!-- </div>  -->