<div class="main">
<div class="home-body">
  <div class="sp-home-header">
    <div class="row">
      <div class="col-xs-12 home-header">
        <div class="back-div">
          <p class="back-btn" [routerLink]="'/securehome'">
            <img alt="back-icon" class="back-icon" [routerLink]="'/securehome'"
              src="../../../../assets/images/home/ico-chevron-back-outline.png">
              {{ 'REGISTRATION.TERMS.BACK_BUTTON' | translate }}
          </p>
        </div>
        <div class="row home-header scr-sml">
          <div class="col-md-6 title-header">
            <div class="breadcrumbs">
              <a aria-label="home-logo" tabindex="1" class="home-logo-back" [routerLink]="'/securehome'">
                <img alt="home-icon" src="../../../../assets/images/home/default-home.png">
              </a>
              <i style="color: black; margin-left: 10px; margin-right: 10px;" class="fa fa-chevron-right"></i>
              <b style="color: black" class="home-title" (keydown.enter)="backButton.click()" #backButton [routerLink]="'/securehome'" tabindex="2"> {{getTitle()}} </b>
              <i style="color: black; margin-left: 10px; margin-right: 10px;" class="fa fa-chevron-right"></i>
              <b>{{getSubTitle()}}</b> 
            </div>
          </div>
          <div class="col-md-3 col-md-offset-3">
            <div class="col-md-1 div-filter" (keydown.enter)="opeFiltersModal()" (click)="opeFiltersModal()">
              <img tabIndex="3" alt="menu-icon" class="img-filter" src="../../../../assets/images/ico-menu.png">
            </div>
          </div>
        </div>
        <div class="row mt-warning home-header">
          <div class="col-lg-7 col-xs-12 margin-padding">
            <disabled-phone [link]="true" [disabledText]="'HOME.ACCOUNT_DISABLED.PRE'"></disabled-phone>
          </div>
        </div>
        <div class="row home-header scr-sml">

          <div class="col-md-12 title-header">
            <h3> {{'SHARE_HOME.HEADING_TEXT' | translate}} </h3>
          </div>
        </div>
        <div class="page-label-header-small">
          <div class="">
            <h3> {{'SHARE_HOME.HEADING_TEXT' | translate}} </h3>
          </div>
          <div class="div-filter" (keydown.enter)="opeFiltersModal()" (click)="opeFiltersModal()">
            <img tabIndex="3" alt="menu-icon" class="img-filter" src="../../../../assets/images/ico-menu.png">
          </div>
        </div>

        <div *ngIf="sharedUsersList?.length == 0 && showError" class="row home-no-history">
          <div class="col-md-12 no-history">
            <p class="recordMsg"> {{recordMsg}} </p>
          </div>
        </div>
        <div class="search-results" data-infinite-scroll [infiniteScrollDistance]="2"
          [infiniteScrollThrottle]="250" [immediateCheck]="false" (scrolled)="onScroll()">
          <div *ngIf="sharedUsersList?.length > 0">
            <div *ngFor="let user of sharedUsersList;" class="row home-header">
              <div class="list-row">
                <div class="list-col-item">
                  <div class="list-row-icon" >
                    <img alt="event-img" [src]="getImageSrcXs(user)" [ngClass]=" user.preSignedURL === undefined ? 'event-img-xs' : 'event-profile-img'">
                  </div>
                  <div>
                    <p class="event-title"> <b> {{user.sharedWith}} </b> </p>
                    <p class="event-by">{{user.userAccessRightLocale}}</p>
                    <p class="event-by">{{ 'SHARE_HOME.TITLE.SHARED_BY' | translate }}: {{user.sharedByName}}</p>
                  </div>
                </div>
                <div class="list-col-item">
                  <div>
                    <div *ngIf="user.showTab == 1" class="col-md-12" >
                        <div class="event-by">{{ 'SHARE_HOME.TITLE.ACCESS' | translate }} {{ user.userAccessStatusLocale}} </div>
                        <p class="event-by"> 
                          <a tabIndex="42" aria-label="close" (keydown.enter)="onToggle(user.useraccesslevelstatus, user.userEmail)" (click)="onToggle(user.useraccesslevelstatus, user.userEmail)"> {{ 'SHARE_HOME.TITLE.CANCEL' | translate }} </a>
                        </p>
                    </div>
                    <div *ngIf="user.showTab == 2">
                      <mat-slide-toggle tabIndex="43" aria-label="toggle" class="slider round" (change)="onToggle( user.useraccesslevelstatus,user.userEmail)" checked>
                      </mat-slide-toggle>
                    </div>
                    <div *ngIf="user.showTab == 3">
                      <mat-slide-toggle tabIndex="43" aria-label="toggle" (change)="onToggle(user.useraccesslevelstatus, user.userEmail)">
                      </mat-slide-toggle>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>