import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import {
  ForgotPasswordService,
  SendVerificationCodeCallback,
} from '../../../../services/forgot-password.service';
import { ResponseHandlerService } from '../../../../services/utils/response-handler.service';

@Component({
  selector: 'app-enter-secret-questions',
  templateUrl: './enter-secret-questions.component.html',
  styleUrls: ['./enter-secret-questions.component.css'],
})
export class EnterSecretQuestionsComponent
  implements OnInit, SendVerificationCodeCallback
{
  @Output() onError = new EventEmitter<any>();

  enterSecretQuestion: FormGroup;
  questions: [string];
  errorMessage;
  question1 = '';
  phoneDisableCall = false;

  constructor(
    public translate: TranslateService,
    public formBuilder: FormBuilder,
    public responseHandler: ResponseHandlerService,
    public forgotPasswordService: ForgotPasswordService
  ) {}

  ngOnInit() {
    this.enterSecretQuestion = this.formBuilder.group({
      secretAnswer: [null, [Validators.required, Validators.minLength(3)]],
      secretQuestion: [null, [Validators.required]],
    });
    this.questions =
      this.forgotPasswordService.getForgotPasswordObject().secretQuestions;
    const secretAnswerControl =
      this.enterSecretQuestion.controls['secretAnswer'];
    secretAnswerControl.valueChanges.subscribe((value) => {
      if (value.trim() === '' || value.trim().length < 3) {
        secretAnswerControl.setErrors({
          required: true,
        });
      }
    });
  }

  onSubmitSecurityQuestion() {
    this.onError.emit({ isShowError: false, errorMessage: null });
    this.forgotPasswordService.verifySecurityQuestion(
      this.enterSecretQuestion.get('secretQuestion').value,
      this.enterSecretQuestion.get('secretAnswer').value,
      this
    );
  }

  onSuccess(res) {
    /* if (res && res.success) { */
      this.onError.emit({ isShowError: false, errorMessage: null });
      this.sendForgotPasswordCode();
    /* } */
  }
  sendForgotPasswordCode() {
    this.forgotPasswordService.callApiToSendCode('phone', this);
  }
  sendVerifiactionCodeSuccess() {}

  sendVerifiactionCodeFailure() {
    
      this.translate.get(
        'FORGOT_PASSWORD.SELECT_VERIFICATION_TYPE.RESEND_CODE.SUCCESS'
      ).subscribe((value) => {
        this.onError.emit(value);
      })
  }

  sendVerifiactionCodeErrorResponse(res) {
    res = this.responseHandler.getErrorMessage(
      'FORGOT_PASSWORD.ENTER_VERIFICATION_CODE.ERROR',
      res
    );
    let errMessg = '';
    let errorMessage = '';
    for (errMessg of res) {
      errorMessage += errMessg + ', ';
    }
    errorMessage = errorMessage.substring(0, errorMessage.length - 2);
    if (errorMessage.slice(-1) !== '.') {
      errorMessage += '.';
    }
    this.onError.emit({
      errorMessage: errorMessage,
      isShowError: this.phoneDisableCall ? false : true,
    });
  }

  onFailure(res) {
    if (res) {
      const error = res;
      console.log(error.error.code);
      this.translate
        .get(
          'FORGOT_PASSWORD.ENTER_SECURITY_QUESTION.ERROR.' +
            (error.error.code ? error.error.code : 'COMMON').toUpperCase()
        )
        .subscribe((value) => {
          this.onError.emit(value);
        });
    } else {
      this.translate
        .get('FORGOT_PASSWORD.ENTER_SECURITY_QUESTION.ERROR.COMMON')
        .subscribe((value) => {
          this.onError.emit(value);
        });
    }
  }

  //When Security question changes, answer should be erased
  onChangeQuestion(number: number) {
    this.enterSecretQuestion.get('secretAnswer').setValue('');
  }
}
