import {
  Component,
  OnInit,
  Input,
  AfterViewInit,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
// import { Observable } from "rxjs";
import { Constants } from '../../../services/utils/Constants';
import { environment } from '../../../../environments/environment';
import { UserRegistrationService } from '../../../services/user-registration.service';
import { ResponseHandlerService } from '../../../services/utils/response-handler.service';
import { spaceCheck, Utility } from '../../../services/utils/utility';
import { VerificationCallback } from '../../../services/utils/verification.service';
import { UserManagement } from '../../../services/utils/state-management/action-classes';

// ng-otp-input
import { DeviceDetectorService } from '../../../services/device-detector.service';

@Component({
  selector: 'app-email-verification',
  templateUrl: './email-verification.component.html',
  styleUrls: [
    './email-verification.component.css',
    '../../../../assets/css/ng-otp.css',
  ],
})
export class EmailVerificationComponent
  implements OnInit, VerificationCallback, AfterViewInit
{
  environment = environment;
  @Input() continueType: string;
  emailEntered = false;
  spaceCheck = spaceCheck;
  errorMessage: string;
  emailValidation: FormGroup;
  codeValidation: FormGroup;
  otp = '';
  successMessage: any;
  securityCodePaste: string;
  securityCodeEnter = [];
  securityCode: string;
  securityCondition: boolean = false;

  //ng-otp-input
  showOtpComponent = true;
  ngconfig = Utility.getNgxOtpConfigObject();

  isLoaderActive: boolean = false;
  email: any;
  emailDomain: any;
  isCodeResent: boolean = false;

  constructor(
    public formBuilder: FormBuilder,
    public translate: TranslateService,
    private registrationService: UserRegistrationService,
    public responseHandler: ResponseHandlerService,
    public userManagementService: UserManagement,
    public devicedetectorservice: DeviceDetectorService
  ) {}

  ngOnInit() {
    if (this.continueType == 'phone') {
      this.registrationService.sendVerificationCode(
        'phone',
        this.getUserData().phone,
        this
      );
    }

    if (this.continueType == 'email') {
      this.email = this.getUserData().email.split('@')[0];
      this.emailDomain = this.getUserData().email.split('@')[1];
    }

    this.emailValidation = this.formBuilder.group({
      email: [
        null,
        [
          Validators.required,
          Validators.pattern('^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$'),
        ],
      ],
    });
    this.codeValidation = this.formBuilder.group({
      code: [null, [Validators.required]],
    });
  }

  ngAfterViewInit(): void {
    Utility.focusLogo();
    if (this.devicedetectorservice.isIOSPhone()) {
      let otpInputs = document.getElementsByClassName('otp-input');
      for (var i = 0; i < otpInputs.length; i++) {
        const id = otpInputs[i].id;
        document.getElementById(id).blur();
      }
    }
  }

  onVerify() {
    this.emailEntered = false;
    this.registrationService.sendVerificationCode(
      this.continueType,
      this.emailValidation.get(this.continueType).value,
      this
    );
  }

  onOtpChange(otp) {
    this.otp = otp;
    if (this.otp.length === 6) {
      this.onSubmitCode();
    }
  }

  onSubmitCode() {
    this.isLoaderActive = true;
    this.registrationService.checkVerificationCode(
      this.continueType,
      this.securityCode,
      this,
      false
    );
    this.successMessage = null;
  }

  onSuccessSendCode(result: any) {
    this.isLoaderActive = false;
    this.emailEntered = true;
    this.errorMessage = null;
    this.codeValidation.controls['code'].reset();
    if(this.isCodeResent){
      this.isCodeResent = false;
      this.successMessage = this.translate.instant(
        'FORGOT_PASSWORD.SELECT_VERIFICATION_TYPE.RESEND_CODE.SUCCESS'
      );
    }
     
  }

  onFailure(messages: any) {
    this.resetOTP();
    if (messages && messages.data && messages.data.length > 0) {
      this.securityCondition = false;
      for (const dataElement of messages.data) {
        if (
          dataElement &&
          dataElement.code.toUpperCase() ===
            Constants.ERROR_CODES.CAPTCHA_VERIFICATION_FAILED
        ) {
          // this.errorMessage =
          // this.registrationService.resetSteps();
        }
      }
    }

    this.errorMessage = this.responseHandler.getErrorMessage(
      'REGISTRATION.EMAIL_VERIFICATION.ERROR',
      messages
    );
   /*  let errMessg = '';
    this.errorMessage = ''; */
    
   /*  for (errMessg of messages) {
      this.errorMessage += errMessg + ', ';
    }
    this.errorMessage = this.errorMessage.substring(
      0,
      this.errorMessage.length - 2
    );
    if (this.errorMessage.slice(-1) !== '.') {
      this.errorMessage += '.';
    } */
    this.isLoaderActive = false;
  }

  handleFillEvent(value: string): void {
    this.securityCodePaste = value;
    if (this.securityCodePaste.length === 6 && !this.securityCondition) {
      this.securityCode = this.securityCodePaste;
      this.securityCondition = true;
      this.onSubmitCode();
    }
  }

  resetOTP() {
    this.showOtpComponent = false;
    this.securityCodePaste = null;
    setTimeout(() => {
      this.showOtpComponent = true;
    }, 0);
  }

  onSuccess(result: any) {
    this.isLoaderActive = false;
    if (this.continueType === 'email') {
      this.registrationService.updateEmail();
      this.registrationService.rollOverRegStep();
      return;
    }
    this.registrationService.updatePhone();
    this.registrationService.rollOverRegStep();
  }

  getUserData() {
    return this.userManagementService.getUserObject();
  }
  getLastDigitsOfPhone() {
    return (this.getUserData().phone || '').slice(-2);
  }
  /**
   * Handler for resending code
   */
  resendCode() {
    this.isCodeResent = true;
    this.resetOTP();
    this.registrationService.sendVerificationCode(
      this.continueType,
      this.userManagementService.getUserObject()[this.continueType],
      this
    );
   
  }
  onChangeCode(event) {
    this.spaceCheck(event);
    // this.codeValidation.get('code').value
    if ((`${event.target.value}` || '').length === 6) {
      console.log('try again verfiying code');
      this.onSubmitCode();
    }
    // if there are 6 characters
  }
}
