import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import {
  ShareHomeService,
  shareHomeCallback,
  IShareHomeObject,
  toggleUserStatusCallback
} from "../../../services/share-home.service";
import { HomeService, IHomeObject } from "../../../services/home.service";
import { TranslateService } from "@ngx-translate/core";
/* import { DialogService } from "ng6-bootstrap-modal"; */
import { goBack } from "../../../services/utils/utility";
import { LanguageChangeService } from "../../../services/language-change.service";
import { Config } from "src/app/services/utils/Config";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ViewSharedUserFilterModalComponent } from "../view-shared-user-filter-modal/view-shared-user-filter-modal.component";
import { ViewSharedUserConfirmModalComponent } from "../view-shared-user-confirm-modal/view-shared-user-confirm-modal.component";


@Component({
  selector: "app-view-shared-users",
  templateUrl: "./view-shared-users.component.html",
  styleUrls: ["./view-shared-users.component.css"]
})
export class ViewSharedUsersComponent
  implements OnInit, shareHomeCallback, toggleUserStatusCallback {
  sharedUsersList: any = [];
  goBack = goBack;
  errorMessage;
  private homeID;
  filters: any;
  userEmail: any;
  ownersHome: IHomeObject;
  modalRef: BsModalRef;

  recordMsg = "";
  showError = false;
  USER_ACCESS_LEVELS = {
    PENDING: "Pending",
    ENABLED: "Enabled",
    DISABLED: "Disabled",
    CANCEL: "Cancel"
  }
  iconBackground = ''
  accessLevelMemeberKey = 'Member';

  //Selected Default Language
  langCode: string;
  defaultLangCode: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private modalService: BsModalService,
    public shareHomeService: ShareHomeService,
    private homeService: HomeService,
    public translate: TranslateService,
    private langService: LanguageChangeService
  ) { }

  ngOnInit() {
    this.langCode = this.langService.getCurrentLanguage();
    this.defaultLangCode = this.langService.getDefaultLanguage();
    this.recordMsg = this.translate.instant("SHARE_HOME.NOT_SHARED");
    this.filters = { setFilter: false };
    this.activatedRoute.params.subscribe(
      params => (this.homeID = params["homeID"])
    );
    this.applyFilter(this.filters);
    this.ownersHome = this.homeService.getHomeById(this.homeID);
  }



  getImageSrc(user) {
    let { preSignedURL = null } = user;
    if (preSignedURL) {
      return preSignedURL;
    } else {
      return "../../../../assets/images/home/ico-user-white.png";
    }
  }

  getImageSrcXs(user) {
    let { preSignedURL = null } = user;
    if (preSignedURL) {
      return preSignedURL;
    } else {
      return "../../../../assets/images/home/icon-default-user.png";
    }
  }

  getTitle() {
    let title: string = this.translate.instant("SHARE_HOME.PAGE_TITLE");
    if (title) {
      title = title.replace(
        "{home}",
        this.homeService.getHomeById(this.ownersHome.homeId).homeName
      );
      return title;
    }
    return '';
  }

  getSubTitle() {
    let title: string = this.translate.instant("SHARE_HOME.PAGE_SUB_TITLE");
    if (title) {
      return title;
    }
    return '';
  }

  onGetShareUserSuccess(sharedUsers: IShareHomeObject[]) {
    this.sharedUsersList = sharedUsers;
    this.sharedUsersList = this.sharedUsersList.map((home: any) => {

      let languageMappingObj = Config.ACCESS_LEVELS.find(ele => ele[this.langService.getDefaultLanguage()] === home.userAccessRights);
      home.userAccessRightLocale = languageMappingObj ? languageMappingObj[this.langService.getCurrentLanguage()] : home.userAccessRights;

      let languageMappingObjUserAccessStatuses = Config.USER_ACCESS_STATUSES.find(ele => ele[this.langService.getDefaultLanguage()] === home.useraccesslevelstatus);
      home.userAccessStatusLocale = languageMappingObjUserAccessStatuses ? languageMappingObjUserAccessStatuses[this.langService.getCurrentLanguage()] : home.useraccesslevelstatus;
      if (home.useraccesslevelstatus === this.USER_ACCESS_LEVELS.PENDING) {
        home.showTab = 1;
        home.checked = false;
      } else if (home.useraccesslevelstatus === this.USER_ACCESS_LEVELS.ENABLED) {
        home.showTab = 2;
        home.checked = false;
      } else if (home.useraccesslevelstatus === this.USER_ACCESS_LEVELS.DISABLED) {
        home.showTab = 3;
        home.checked = false;
      }
      console.log("user level access:",home.useraccesslevelstatus);
      return home
    });
    this.showError = true;
  }

  onGetShareUserFailure(messages: string) {
    if (messages) {
      let errormsg = '';
      for (const msg of messages) {
        errormsg += this.translate.instant('SHARE_HOME.ERROR.' + msg['code'].toUpperCase()) + ",";
      }
      this.errorMessage = errormsg.replace(/.$/, ".");
    }
  }

  opeFiltersModal() {
    const initialState = {
      filters: this.filters,
    }
    this.modalRef = this.modalService.show(ViewSharedUserFilterModalComponent, {
      initialState: initialState,
      class: 'view-shared-users'
    });
    this.modalRef.content.closeBtnName = 'Close';
    this.modalRef.content.event.subscribe(filterData => {
      if (filterData) {
        this.applyFilter(filterData)
      }
    });
    this.modalRef.content.eventClose.subscribe(filterData => {
      
        if (filterData) {
          console.log('cross modal', filterData);
          this.filters = filterData;

        } 
    });
    /*  this.dialogService.addDialog(ViewSharedUserFilterModalComponent, { filters: this.filters },
       { closeByClickingOutside: false }).subscribe((filterData) => {
         if (filterData) {
           this.applyFilter(filterData)
         }
       }); */
  }

  applyFilter(filters) {
    this.showError = false;
    if (filters.setFilter) {
      this.recordMsg = this.translate.instant("SHARE_HOME.NOT_SHARED_FILTER");
    } else {
      this.recordMsg = this.translate.instant("SHARE_HOME.NOT_SHARED");
    }
    let pathParams = {
      id: this.homeID,
      orderBy: "ID desc"
    };
    let filter = filters.setFilter ? this.setFilter(filters) : "";
    pathParams["filter"] = encodeURIComponent(filter);
    this.filters = filters;
    this.shareHomeService.getSharedUsersWithAttribute(pathParams, this);
  }

  setFilter(filters) {
    let { selectedUserName = [], selectedAccessLevels = [], selectedStatuses = [], selectedSharedBy = [] } = filters;
    let filter = "(";
    let index = 0;
    const removeElements = (element) => {
      if (element.indexOf(',') > -1 || element.indexOf('(') > -1 || element.indexOf(')') > -1) {
        let elements = element.split(' ');
        return elements[0];
      }
      return element;
    }
    /**
     * Modification: HALOC-1950
     * Date: 8th Feb, 2022
     * By: Arif
     * Description: In case of different types of filters, "and" operator will be added
     */
    selectedUserName.forEach(element => {
      element = removeElements(element);
      if (index > 0) {
        filter = filter + ' or ';
      }
      filter = filter + 'includes(sharedwithname, "' + element + '") == "true"'
      index++;
    });
    if (selectedUserName.length > 0) {
      filter = filter + ")";
    }
    if (selectedUserName.length > 0 && (selectedAccessLevels.length || selectedStatuses.length || selectedSharedBy.length)) {
      filter = filter + " and (";
      index = 0;
    }
    selectedAccessLevels.forEach(element => {
      element = removeElements(element[this.defaultLangCode]);
      if (index > 0) {
        filter = filter + ' or ';
      }
      element = element.toLowerCase() === 'guest' ? this.accessLevelMemeberKey : element;
      filter = filter + 'includes(useraccesslevel, "' + element + '") == "true"';
      index++;
    });
    if (selectedAccessLevels.length > 0) {
      filter = filter + ")";
    }
    if (selectedAccessLevels.length > 0 && (selectedStatuses.length || selectedSharedBy.length)) {
      filter = filter + " and (";
      index = 0;
    }
    selectedStatuses.forEach(element => {
      element = removeElements(element[this.defaultLangCode]);
      if (index > 0) {
        filter = filter + ' or ';
      }
      filter = filter + 'includes(useraccesslevelstatus, "' + element + '") == "true"'
      index++;
    });
    if (selectedStatuses.length > 0) {
      filter = filter + ")";
    }
    if (selectedStatuses.length > 0 && selectedSharedBy.length > 0) {
      filter = filter + " and (";
      index = 0;
    }
    selectedSharedBy.forEach(element => {
      element = removeElements(element);
      if (index > 0) {
        filter = filter + ' or ';
      }
      filter = filter + 'includes(sharedbyname, "' + element + '") == "true"'
      index++;
    });
    if (selectedSharedBy.length > 0) {
      filter = filter + ")";
    }
    return filter;
  }

   // onToggle(status, userEmail) {
  //   console.log("STATUES", status, userEmail);
  //   let toggledStatus = this.getToggledValue(status);
  //   if (toggledStatus) {
  //     /* this.dialogService.addDialog(ViewSharedUserConfirmModalComponent, { status: toggledStatus, confirmedStatus: false, userEmail },
  //       { closeByClickingOutside: false }).subscribe((confirmedData) => {
  //         if (confirmedData) {
  //           this.changeUserStatus(confirmedData);
  //         }
  //       }); */
  //   }
  // }

  onToggle(status, userEmail) {
    console.log("STATUSES", status, userEmail)  
    let toggledStatus = this.getToggledValue(status);
    const initialState={
      status : toggledStatus,
      confirmedData: true,
      userEmail : userEmail
    }
    if(toggledStatus){
      this.modalRef = this.modalService.show(ViewSharedUserConfirmModalComponent, {
        initialState : initialState,
        class: 'view-shared-user-confirm'
      });
        this.modalRef.content.closeBtnName = 'Close';
        this.modalRef.content.event.subscribe(confirmedData => {
          if (confirmedData) {
            this.changeUserStatus(confirmedData)
            }
          })
        }  
    }

  changeUserStatus(confirmedData) {
    let { status, confirmedStatus, userEmail } = confirmedData;
    if (confirmedStatus) {
      if (status === this.USER_ACCESS_LEVELS.PENDING) {
        this.deleteSharedAccess(userEmail);
        console.log("update status to cancel", userEmail);
      } else {
        this.toggleUserStatus(status, userEmail);
        console.log("update status to ", status, userEmail);
      }
    } else {
      this.applyFilter(this.filters);
    }
  }

  deleteSharedAccess(userEmail) {
    this.shareHomeService.deleteShare(this.homeID, userEmail, this);
  }

  toggleUserStatus(status, userEmail) {
    this.shareHomeService.toggleUserStatus(
      this.homeID,
      status,
      userEmail,
      this
    );
  }
  

  getToggledValue(status) {
    if (status === this.USER_ACCESS_LEVELS.ENABLED) {
      return this.USER_ACCESS_LEVELS.DISABLED
    } else if (status === this.USER_ACCESS_LEVELS.DISABLED) {
      return this.USER_ACCESS_LEVELS.ENABLED
    } else if (status === this.USER_ACCESS_LEVELS.PENDING) {
      return this.USER_ACCESS_LEVELS.PENDING;
    } else {
      return null;
    }
  }

  onToggleSuccess(userEmail,newStatus) {
    this.applyFilter(this.filters);
  }
  onToggleFailure(message: string) { }

  onScroll() { }

}

