    <div class="modal-header">
      <button type="button" class="close" tabIndex="0" (click)="close()">&times;</button>
    </div>
    <div class="modal-body" >
      <div class="options-modal" tabIndex="-1" #signupModal>
        <div class="col-md-12 col-sm-12 col-xs-12 no-padding">
          <div class="col-xs-12">
            <div class="row">
              <div class="col-md-12 no-padding">
                <div class="options-modal">
                  <h3 class="text-center header-modal">{{'LOGIN.CREATE_AN_ACCOUNT' | translate}}</h3>
                  <p class="text-center selection-header"> {{'LOGIN.SELECTION_INFO' |translate}}</p>
                  <div class="row option-margin">
                    <div class="col-xs-12">
                      <button role="button" tabIndex="0" (click)="onRegister()" class="btn-block use-your-email">
                        {{'LOGIN.USE_YOUR_EMAIL' | translate}} </button>
                      <ng-container *ngIf="googleFederation">
                        <button role="button" tabIndex="0" (click)="onFederatedSignUp(federatedIdentities.Google)"
                          class="options-modal-btn btn-block use-your-gmail">
                          <img class="gmail-img" src="../../../assets/images/gmail-icon.png" alt="gmail.png"
                            width="17.66px" height="18px">
                          {{'LOGIN.SIGNUP_WITH_GOOGLE' | translate}}
                        </button>
                      </ng-container>
                      <ng-container *ngIf="appleFederation">
                      <button role="button" tabIndex="0" (click)="onFederatedSignUp(federatedIdentities.Apple)"
                        class="options-modal-btn btn-block use-your-gmail">
                        <img class="apple-img" height="18px" style="float: left;" src="../../../assets/images/apple.svg"
                          width="17.66px">
                        {{'LOGIN.SIGNUP_WITH_APPLE' | translate}}
                      </button>
                    </ng-container>
                    </div>
                  </div>
                  <div class="text-center sp-hyperlink-div-modal">
                    <a tabIndex="0" (keydown.enter)="onLogin()" (click)="onLogin()" class="sp-hyperlink">{{'REGISTRATION.LOGIN' |translate}}</a>
                    {{'LOGIN.ALREADY_ACCOUNT' |translate}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>