<div class="main">
  <div class="row">
    <div class="col-md-6 hidden-sm hidden-xs no-padding image-container">
      <div class="gradient">
        <div>
          <div class="content-heading">{{ 'SECURE_HOME.WELCOME_TAG' | translate }}</div>
          <p class="content-banner">{{ 'BRAND_SELECTION.CONTENT_HEADING_2' | translate }}</p>
          <p class="content-banner">{{ 'BRAND_SELECTION.CONTENT_HEADING_3' | translate }}</p>
        </div>
      </div>
    </div>
    <div class="col-md-6 parent-right-content-container">
      <div class="right-content-container">
        <div class="col-lg-12 no-padding">
          <br>
          <div class="col-md-10 col-md-offset-1 col-lg-8 col-lg-offset-2 col-sm-6 col-sm-offset-3 col-xs-12">
            <div *ngIf="errorMessage !== null && isShowError" class="alert alert-danger alert-dismissible fade in">
              <a class="close" aria-label="close" (click)="errorMessage = null">&times;</a>
              {{ errorMessage }}
            </div>
            <!-- <div *ngIf="successMessage!=null && errorMessage == null" class="alert alert-danger alert-info alert-dismissible fade in">
              <a class="close" aria-label="close" (click)="successMessage = null">&times;</a>
              {{ successMessage }}
            </div> -->
          </div>
         
          <div *ngIf="forgotPassword?.step === 8">
            <app-enter-email (onError)="addError($event)" [parent]='this'></app-enter-email>
          </div>
          <div *ngIf="forgotPassword?.step === 8">
            <app-select-verification-type (onError)="addError($event)"></app-select-verification-type>
          </div>
          <div *ngIf="forgotPassword.step === 1">
            <app-enter-secret-questions (onError)="addError($event)"></app-enter-secret-questions>
          </div>
          <div *ngIf="forgotPassword.step === 2">
            <app-enter-verification-code (onError)="addError($event)" [parent]='this'></app-enter-verification-code>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>