<!-- Please don't format this File, Format will give extra spacing in anchors tag -->
<div class="col-md-10 col-md-offset-1 col-lg-8 col-lg-offset-2 col-sm-6 col-sm-offset-3 col-xs-12">
  <div *ngIf="successMessage!=null" class="alert alert-success alert-dismissible fade in">
    <a class="close" aria-label="close" (click)="successMessage = null">&times;</a>
    {{ successMessage }}
  </div>
  <ng-container *ngIf="!passwordView && !isVerified">
    <h2 class="phone-heading sp-form-heading">
      {{ 'LOGIN.VERIFICATION.PHONE_TITLE' |
      translate }}
    </h2>
    <ng-container *ngIf="!isLoaderActive">
      <p *ngIf="this.forgotPassword && this.choice === 'phone' && this.forgotPassword.phoneActive" class="mobile-text-align">
        {{ ('REGISTRATION.PHONE_VERIFICATION.SUB_TITLE_MSG' | translate).replace('[ENDING_IN]', '')}}
        <b class="bold-flex">{{forgotMobileLastTwoCharacters}}.</b>
      </p>
      <p *ngIf="this.forgotPassword && this.choice === 'email'" class="mobile-text-align">
        {{ ('REGISTRATION.EMAIL_VERIFICATION.SUB_TITLE' | translate).replace('[EMAIL_ADDRESS]', '' )}}
          <b class="bold-flex">
            <div class="email-truncated"> {{(this.forgotPassword.email.split('@')[0]).toLowerCase()}}</div>
            <div> @{{(this.forgotPassword.email.split('@')[1]).toLowerCase()}}</div>
            </b>
      </p>
    </ng-container>
    <ng-container *ngIf="isLoaderActive">
      <ng-container *ngIf="this.choice === 'email'">
        <p class="mobile-text-align">
          {{ ('REGISTRATION.EMAIL_VERIFICATION.WAIT_MESSAGE' | translate) }}
        </p>
        <br />
      </ng-container>

      <ng-container *ngIf="this.choice === 'phone'">
        <p class="mobile-text-align">
          {{ ('REGISTRATION.PHONE_VERIFICATION.WAIT_MESSAGE' | translate ) }}
        </p>
        <br />
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="passwordView">
    <h2 class="sp-form-heading phone-heading">
      {{ 'CHANGE_PASSWORD.LABEL_NEW_PASSWORD' | translate }}
    </h2>

    <p>
      {{ ('CHANGE_PASSWORD.ENTER_NEW_PASS' | translate)
      }}
    </p>
  </ng-container>

  <div *ngIf="!passwordView && !isVerified" class="form-group has-feedback">
    <div [hidden]="isLoaderActive">
      <div class="ngx-otp">
          <ng-otp-input #ngOtpInput (onInputChange)="handleFillEvent($event)" 
                      [config]="ngconfig" id="ng-otp-input" *ngIf="showOtpComponent"></ng-otp-input>
      </div>
      <p style="color: red; margin-top:15px;" *ngIf="otpError" class="help-block invalid-text-p">{{ errorMessage }}</p>
      <p *ngIf="false" style="margin-top: 15px;" class="mobile-text-align" class="help-block">{{ 'LOGIN.CODE.REQUIRED' |
        translate}}</p>
    </div>
  </div>
  <div *ngIf="isLoaderActive">
    <app-inner-loader></app-inner-loader>
  </div>

  <div [hidden]="isLoaderActive">
    <ng-container *ngIf="!passwordView && !isVerified && this.choice === 'phone'">
      <p  style="margin-top: 20px;" class="mobile-text-align">
       <span *ngIf="this.forgotPassword.phoneActive"> {{ 'REGISTRATION.PHONE_VERIFICATION.RE_SEND_CODE_MSG' |
        translate }} </span>
        <a tabIndex="0" *ngIf="this.forgotPassword.phoneActive" class="sp-hyperlink sp-resend" (keydown.enter)="onResendCode('phone')" (click)="onResendCode('phone')">{{ 'LOGIN.VERIFICATION.LINK_RESEND_CODE' | translate }}.</a>
      </p>
      <p class="mobile-text-align hidden-lg hidden-md">
       <span *ngIf="this.forgotPassword.phoneActive "> {{ 'LOGIN.VERIFICATION.OR' | translate }} </span>
      </p>
      <p class="mobile-text-align">
        <span *ngIf="this.forgotPassword.phoneActive" class="hidden-xs hidden-sm">{{ 'LOGIN.VERIFICATION.OR' | translate }} </span>
        <a tabIndex="0" class="sp-hyperlink sp-resend" (keydown.enter)="onResendCode('email')" (click)="onResendCode('email')">{{'LOGIN.VERIFICATION.SEND_CODE' | translate}}</a>
        {{ 'LOGIN.VERIFICATION.TO_MY_EMAIL' | translate }}
      </p>
    </ng-container>
    <ng-container *ngIf="!passwordView && !isVerified && this.choice === 'email' ">
      <p style="margin-top: 20px;" class="mobile-text-align">
       {{ 'REGISTRATION.EMAIL_VERIFICATION.RE_SEND_CODE' |
        translate }}
        <a tabIndex="0" class="sp-hyperlink sp-resend" (keydown.enter)="onResendCode('email')" (click)="onResendCode('email')">{{'LOGIN.VERIFICATION.LINK_RESEND_CODE' | translate }}.</a>
      </p>
      <p *ngIf="this.forgotPassword.phoneActive" class="mobile-text-align hidden-lg hidden-md">
        {{ 'LOGIN.VERIFICATION.OR' | translate }}
      </p>
      <p *ngIf="this.forgotPassword.phoneActive" class="mobile-text-align">
        <span class="hidden-xs hidden-sm">{{ 'LOGIN.VERIFICATION.OR' | translate }}</span>
        <a tabIndex="0" class="sp-hyperlink sp-resend" (keydown.enter)="onResendCode('phone')" (click)="onResendCode('phone')">{{'LOGIN.VERIFICATION.SEND_CODE' | translate}}</a>
        {{ 'LOGIN.VERIFICATION.TO_MY_PHONE' | translate }}
      </p>
    </ng-container>
  </div>

  <p class="text-center sp-sub-title" *ngIf='this.forgotPassword.verificationType=="email" && this.forgotPassword.phoneActive'> {{
    'FORGOT_PASSWORD.ENTER_VERIFICATION_CODE.SUB_TITLE.EMAIL' | translate }} </p>
  <form class="form-signin" method="POST" action="#" role="form" [formGroup]="enterVerificationCodeAndPassword"
    (ngSubmit)="onSubmitCode()">



    <div *ngIf="passwordView" class="form-group has-feedback">
      <div
        [ngClass]="{'has-error' : this.enterVerificationCodeAndPassword.controls['password'].invalid && this.enterVerificationCodeAndPassword.controls['password'].dirty, 'has-success' : this.enterVerificationCodeAndPassword.controls['password'].valid}">
        <mat-form-field class="full-width" appearance="fill">
          <mat-label>{{ 'LOGIN.PASSWORD.PLACEHOLDER' | translate }}</mat-label>
          <input tabIndex="48" matInput type="password" id="loginPassword" formControlName="password"
            [type]="hidePass ? 'password' : 'text'" maxlength="20">
          <mat-icon tabIndex="49" class="cursor-pointer" (click)="togglePasswordField('password')" (keydown.enter)="togglePasswordField('password')" matSuffix>{{hidePass ?
            'visibility' : 'visibility_off'}}</mat-icon>
        </mat-form-field>
        <div style="margin-top: -8px;" *ngIf="enterVerificationCodeAndPassword.get('password').value !== null"
          class="password-steps">

          <p class="password-errors"
            [ngStyle]="{'color':enterVerificationCodeAndPassword.get('password').value != null && (enterVerificationCodeAndPassword.get('password').value).length > 7 && enterVerificationCodeAndPassword.get('password').value != null && (enterVerificationCodeAndPassword.get('password').value).length < 21  ? '#41b705' : 'black'}">
            {{ 'VALIDATION.PASSWORD_STEP_1' | translate }} </p>
          <p class="password-errors"
            [ngStyle]="{'color':enterVerificationCodeAndPassword.get('password').value != null &&  (enterVerificationCodeAndPassword.get('password').value).match(upperCase) ? '#41b705' : 'black'}">
            {{ 'VALIDATION.PASSWORD_STEP_2' | translate }}</p>
          <p class="password-errors"
            [ngStyle]="{'color':enterVerificationCodeAndPassword.get('password').value != null &&  (enterVerificationCodeAndPassword.get('password').value).match(lowerCase) ? '#41b705' : 'black'}">
            {{ 'VALIDATION.PASSWORD_STEP_3' | translate }}</p>
          <p class="password-errors"
            [ngStyle]="{'color':enterVerificationCodeAndPassword.get('password').value != null && (enterVerificationCodeAndPassword.get('password').value).match(number)  ? '#41b705' : 'black'}">
            {{ 'VALIDATION.PASSWORD_STEP_4' | translate }}</p>
          <p class="password-errors"
            [ngStyle]="{'color':enterVerificationCodeAndPassword.get('password').value != null && (enterVerificationCodeAndPassword.get('password').value).match(specialCharacter)  ? '#41b705' : 'black'}">
            {{ 'VALIDATION.PASSWORD_STEP_5' | translate }}</p>
          <p style="font-size: 11px;"
            [ngStyle]="{'color':enterVerificationCodeAndPassword.get('password').value != null && (enterVerificationCodeAndPassword.get('password').value).match(space)  ? 'black' : '#41b705'}">
            {{ 'VALIDATION.PASSWORD_STEP_6' | translate }}</p>
        </div>
      </div>
    </div>
    <div *ngIf="passwordView" class="form-group has-feedback">
      <div
        [ngClass]="{'has-error' :   this.enterVerificationCodeAndPassword.controls['confirmPassword'].dirty && (this.enterVerificationCodeAndPassword.controls['confirmPassword'].invalid || this.enterVerificationCodeAndPassword.controls['confirmPassword'].value != this.enterVerificationCodeAndPassword.controls['password'].value), 'has-success' : this.enterVerificationCodeAndPassword.controls['confirmPassword'].valid && this.enterVerificationCodeAndPassword.controls['confirmPassword'].value === this.enterVerificationCodeAndPassword.controls['password'].value}">
        <mat-form-field class="full-width" appearance="fill">
          <mat-label>{{ 'REGISTRATION.SUMMARY_PASSWORD.LABEL_REPEAT_PASSWORD' | translate }}</mat-label>
          <input tabIndex="50" matInput type="password" id="loginPassword" formControlName="confirmPassword"
            [type]="hideRepeatPass ? 'password' : 'text'" maxlength="20">
          <mat-icon tabIndex="51" class="cursor-pointer" (keydown.enter)="togglePasswordField('confirmPassword')" (click)="togglePasswordField('confirmPassword')" matSuffix>{{hideRepeatPass ?
            'visibility' : 'visibility_off'}}</mat-icon>
        </mat-form-field>
        <p style="margin: 0px;"
          *ngIf="enterVerificationCodeAndPassword.get('confirmPassword').value != null && enterVerificationCodeAndPassword.get('confirmPassword').value !== enterVerificationCodeAndPassword.get('password').value"
          class="help-block">{{ 'VALIDATION.RETYPE_MATCH' | translate}}</p>
      </div>
    </div>

    <div *ngIf="passwordView" class="form-group">
      <button tabIndex="52"
        [disabled]="!enterVerificationCodeAndPassword.get('password').valid || enterVerificationCodeAndPassword.get('password').value !== enterVerificationCodeAndPassword.get('confirmPassword').value"
        id="submitCode" type="submit" class="sp-form-button btn btn-block sp-btn-dark h-45">
        {{ 'FORGOT_PASSWORD.ENTER_VERIFICATION_CODE.BUTTON_PASSWORD_UPDATE' | translate }}
      </button>
    </div>

    <div *ngIf="isVerified" style="margin-right: 30px; margin-left: 30px;">
      <h3 class="account-updated-title" style="margin-bottom: 40px;" style="font-weight: 800;"> {{
        'CHANGE_PASSWORD.ACCOUNT_UPDATED' | translate }} </h3>
      <div class="verify-tick">
        <div class="verify-tick-img"></div>
        <p class="verify-tick-p">{{ 'REGISTRATION.EMAIL_VERIFICATION.COMPLETED' | translate }} </p>

      </div>
      <button style="margin-top: 40px;" (click)="goToLogin()"
        class="btn btn-block sp-form-button sign-up-continue-btn verify-button sp-btn-dark" [disabled]="false">
        {{ 'REGISTRATION.COLLECT_INFO.BUTTON_SUBMIT_FORM' | translate }}
      </button>
    </div>

  </form>
</div>