import { Component, Input, OnInit } from '@angular/core';
import { Config } from 'src/app/services/utils/Config';
import { UserManagement } from 'src/app/services/utils/state-management/action-classes';
import { LanguageChangeService } from '../../services/language-change.service';

@Component({
  selector: 'app-battery',
  templateUrl: './battery.component.html',
  styleUrls: ['./battery.component.css']
})
export class BatteryComponent implements OnInit {
  @Input() batteryStatus: string;
  viewType;
  batteryStatusLocale: string;

  constructor(private languageService: LanguageChangeService, private userManagement: UserManagement) {
    this.viewType = this.userManagement.getUserView();
  }

  ngOnInit() {}

  getImageSrc() {
    let src = "../../../assets/images/battery/battery_too_low_to_operate.png";
    switch (this.batteryStatus) {
      case Config.BATTERY.NORMAL: {
        let langObj = Config.BATTERY_STATUS_LIST.find(obj => obj[this.languageService.getDefaultLanguage()] === this.batteryStatus);
        this.batteryStatusLocale = langObj ? langObj[this.languageService.getCurrentLanguage()] : this.batteryStatus;
        src = "../../../assets/images/battery/battery-full.png";
        break;
      }
      case Config.BATTERY.LOW: {
        let langObj = Config.BATTERY_STATUS_LIST.find(obj => obj[this.languageService.getDefaultLanguage()] === this.batteryStatus);
        this.batteryStatusLocale = langObj ? langObj[this.languageService.getCurrentLanguage()] : this.batteryStatus;
        src = "../../../assets/images/battery/low-battery.png";
        break;
      }
      case Config.BATTERY.VERY_LOW: {
        let langObj = Config.BATTERY_STATUS_LIST.find(obj => obj[this.languageService.getDefaultLanguage()] === this.batteryStatus);
        this.batteryStatusLocale = langObj ? langObj[this.languageService.getCurrentLanguage()] : this.batteryStatus;
        src = "../../../assets/images/battery/very-low-battery.png";
        break;
      }
      case Config.BATTERY.TOO_LOW: {
        let langObj = Config.BATTERY_STATUS_LIST.find(obj => obj[this.languageService.getDefaultLanguage()] === this.batteryStatus);
        this.batteryStatusLocale = langObj ? langObj[this.languageService.getCurrentLanguage()] : this.batteryStatus;
        src = "../../../assets/images/battery/too-low-to-operate.png";
        break;
      }
    }
    return src;
  }

  changeView(view: string) {
    this.viewType = view;
    this.userManagement.storeUserViewType(view);
  }

}
