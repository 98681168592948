import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { FederatedManagementService } from '../../../services/federated-management.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Config } from 'src/app/services/utils/Config';

export interface SignupOptionsModal { }
@Component({
  selector: 'app-signup-options-modal',
  templateUrl: './signup-options-modal.component.html',
  styleUrls: ['./signup-options-modal.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class SignupOptionsModalComponent implements SignupOptionsModal, AfterViewInit {
  @ViewChild('signupModal') signupModal: ElementRef;
  outputEvent: EventEmitter<any> = new EventEmitter();
  public event: EventEmitter<any> = new EventEmitter();

  federatedIdentities = Config.FEDERATED_IDENTITIES;
  googleFederation: boolean;
  appleFederation: boolean;
  result: {};
  invalidEmail : boolean;
  requireEmail : boolean;

  constructor(
    public bsModalRef: BsModalRef,
    public router: Router,
    private federatedService: FederatedManagementService
  ) { }

  
  /**
   * @description prevent to focus background input and focus to modal input
   * @returns void 
   */
  focusInput(): void {
    this.signupModal.nativeElement.focus();
  }

  ngOnInit(): void {
    let configObject = JSON.parse(localStorage.getItem('config') || '');
    this.googleFederation = configObject && configObject.Feature.GoogleFederation;
    this.appleFederation = configObject && configObject.Feature.AppleFederation;
  }

  
  /**
   * @description this is angular hook responisble when page fully load 
   * @returns void
   */
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.focusInput();
    }, 0);
  }

  onLogin() {
    this.close();
    this.router.navigate(['/home/login']);
  }

  onRegister() {
    this.close();
    localStorage.setItem('PROVIDER', 'SIMPLE');
    this.router.navigate(['/home/register']);
  }

  onFederatedSignUp(identityProvider: string) {
    this.close();
    const url = this.federatedService.getFederatedLoginUrl(identityProvider);
    localStorage.setItem('PROVIDER', Config.PROVIDERS.FEDERATED);
    localStorage.setItem('FLOW', 'SIGNUP');
    window.open(url, "_self");
  }

  close() {
    this.result = { invalidEmail: true, requireEmail: true };
    this.event.emit(this.result);
    this.bsModalRef.hide();
  }
}
