import { NgRedux } from '@angular-redux/store';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ApiRequestService } from './api-client/api-requests.service';
import apiConfig from './api-client/api-config';
import { IAppState } from './utils/state-management/store';
import { getLanguageCode, Utility } from './utils/utility';
import { LoaderService } from './loader.service';
import { Constants } from './utils/Constants';
import { ResponseHandlerService } from './utils/response-handler.service';

@Injectable({
  providedIn: 'root'
})
export class FederatedManagementService {
  
  constructor(private http: HttpClient,
    // private utility: Utility,
    private responseHandler: ResponseHandlerService,
    private loaderService: LoaderService,
    private ngRedux: NgRedux<IAppState>,
    private apiRequest: ApiRequestService) { }


    getFederatedLoginUrl(federatedIdentity: string){
      return `${environment.fedratedLoginParams.BASEURL}oauth2/authorize?identity_provider=${federatedIdentity}&redirect_uri=${environment.fedratedLoginParams.redirectUri}&response_type=${environment.fedratedLoginParams.responseType}&client_id=${environment.fedratedLoginParams.clientId}&scope=${environment.fedratedLoginParams.scope}`;
  
    }
  
    getFederatedLogoutUrl(): string {
      return `${environment.fedratedLoginParams.BASEURL}logout?client_id=${environment.fedratedLoginParams.clientId}&logout_uri=${environment.fedratedLoginParams.redirectLogoutUri}`;
    }

  getGoogleOAuthTokens(code: string) {
    // this.utility.addLoader(new Date());
    this.loaderService.fireLoaderValue(true);
    const formData = new URLSearchParams();
    formData.append('client_id', environment.fedratedLoginParams.clientId);
    formData.append('grant_type', environment.fedratedLoginParams.grantType);
    formData.append('code', code);
    formData.append('redirect_uri', environment.fedratedLoginParams.redirectUri);

    const headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization', environment.fedratedLoginParams.authorization);

    let result = this.http.post(`${environment.fedratedLoginParams.BASEURL}oauth2/token`, formData.toString(), { headers }).pipe(
      map(response => {
          this.loaderService.fireLoaderValue(false);
          return response;
        })
      );
      this.loaderService.fireLoaderValue(false);
      return result;
  }


  registerFederatedUser(user: any, callback: any): void{
    let language = this.ngRedux.getState().preferredLanguage;

    const body = {
      phonenumber: user['phone'],
      firstname: user['firstName'].trim(),
      lastname: user['lastName'].trim(),
      preferredlanguage: getLanguageCode(language),
      brandname: this.ngRedux.getState().userBrand
    };

    this.apiRequest.callApi(Constants.API_METHODS.POST, apiConfig.signUpFederated.uri, body).subscribe({
      next: (res) => {
          console.log(res);
        callback(null, res);
      },
      error: (err) => {
        callback(err, null);
      }
    });

    /* this.apiClient.makeCall(null, 'post', apiConfig.signUpFederated, body, callback); */


  }

  getGoogleUser(accessToken: string) {

    const headers = new HttpHeaders()
      .set('Authorization', `Bearer ${accessToken}`);

    return this.http.get(`${environment.fedratedLoginParams.BASEURL}oauth2/userInfo`, { headers }).pipe(
      map(response => {
        return response;
      })
    );

  }

  saveGoogleTokens(userEmail: string, sessionTokens: any){

    // this.user = this.userManagementService.parseUserObjectAndDispatch(user);
    localStorage.setItem(`CognitoIdentityServiceProvider.${environment.clientId}.LastAuthUser`, userEmail);
    localStorage.setItem(`CognitoIdentityServiceProvider.${environment.clientId}.${userEmail}.clockDrift`, '0');
    localStorage.setItem(`CognitoIdentityServiceProvider.${environment.clientId}.${userEmail}.accessToken`, sessionTokens.access_token);
    localStorage.setItem(`CognitoIdentityServiceProvider.${environment.clientId}.${userEmail}.refreshToken`, sessionTokens.refresh_token);
    localStorage.setItem(`CognitoIdentityServiceProvider.${environment.clientId}.${userEmail}.idToken`, sessionTokens.id_token);

  }

  saveGoogleTokenSessionStorage(userEmail: string, sessionTokens: any){

    sessionStorage.setItem(`GoogleEmail`, userEmail);
    sessionStorage.setItem(`CognitoIdentityServiceProvider.${environment.clientId}.LastAuthUser`, userEmail);
    sessionStorage.setItem(`CognitoIdentityServiceProvider.${environment.clientId}.${userEmail}.clockDrift`, '0');
    sessionStorage.setItem(`CognitoIdentityServiceProvider.${environment.clientId}.${userEmail}.accessToken`, sessionTokens.access_token);
    sessionStorage.setItem(`CognitoIdentityServiceProvider.${environment.clientId}.${userEmail}.refreshToken`, sessionTokens.refresh_token);
    sessionStorage.setItem(`CognitoIdentityServiceProvider.${environment.clientId}.${userEmail}.idToken`, sessionTokens.id_token);

  }

  removeGoogleTokensSessionStorage(userEmail: string){

    sessionStorage.removeItem(`GoogleEmail`);
    sessionStorage.removeItem(`CognitoIdentityServiceProvider.${environment.clientId}.LastAuthUser`);
    sessionStorage.removeItem(`CognitoIdentityServiceProvider.${environment.clientId}.${userEmail}.clockDrift`);
    sessionStorage.removeItem(`CognitoIdentityServiceProvider.${environment.clientId}.${userEmail}.accessToken`);
    sessionStorage.removeItem(`CognitoIdentityServiceProvider.${environment.clientId}.${userEmail}.refreshToken`);
    sessionStorage.removeItem(`CognitoIdentityServiceProvider.${environment.clientId}.${userEmail}.idToken`);

  }

  getGoogleTokenSessionStorage(userEmail: string){

    let sessionTokens : any = {};
    
    sessionTokens.access_token = sessionStorage.getItem(`CognitoIdentityServiceProvider.${environment.clientId}.${userEmail}.accessToken`);
    sessionTokens.refresh_token = sessionStorage.getItem(`CognitoIdentityServiceProvider.${environment.clientId}.${userEmail}.refreshToken`);
    sessionTokens.id_token = sessionStorage.getItem(`CognitoIdentityServiceProvider.${environment.clientId}.${userEmail}.idToken`);
    return sessionTokens;
  }


  checkIfUserExist(callback: any){
    const googleEmail = sessionStorage.getItem('GoogleEmail');
    const tokens = this.getGoogleTokenSessionStorage(googleEmail);
    const headers = new HttpHeaders()
      .set('authorization', `Bearer ${tokens.id_token}`);
    // We are making an temporary call to check if user already exist or not.
    // this.apiClient.makeTemporarySessionApiCall(null, 'get', apiConfig.me, null, callback, true);

    this.apiRequest.callApi(Constants.API_METHODS.GET, apiConfig.me.uri, null, true, null, {headers}).subscribe({
      next: (res) => {
        callback(res, null);
      },
      error: (err) => {
        let error = this.responseHandler.parseErrorResponseV2(err);
        callback(null, error);
      }
    })

  }

}
