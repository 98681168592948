import { Component, OnInit } from '@angular/core';
import {
  LockService,
  ILockHistory,
  LockHistoryCallback,
} from '../../../services/lock.service';
import { ActivatedRoute, Router } from '@angular/router';
import { goBack } from '../../../services/utils/utility';
import { Config } from '../../../services/utils/Config';
import * as moment from 'moment';
// import {DialogService } from "ng6-bootstrap-modal";
import { LockHistoryFilterModalComponent } from '../lock-history-filter-modal/lock-history-filter-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { LanguageChangeService } from '../../../services/language-change.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Constants } from 'src/app/services/utils/Constants';

@Component({
  selector: 'app-lock-history',
  templateUrl: './lock-history.component.html',
  styleUrls: ['./lock-history.component.css'],
})
export class LockHistoryComponent implements OnInit, LockHistoryCallback {
  pageSize = Config.HISTORY_ON_ONE_PAGE;
  defaultPageNo = Config.DEFAULT_PAGE_NO;
  pageNo = this.defaultPageNo;
  goBack = goBack;
  errorMessage;
  private lockId;
  homeHistory;
  eventHistory = [];
  issueHistory = [];
  activities = Config.HISTORY_CHECKLISTS;
  sources = Config.SOURCES_CHECKLISTS;
  disableCall = false;
  fromDate;
  toDate;
  monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  monthNamesMobile = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  filters: any = { setFilter: false };
  noRecordMsg = '';
  noRecordMsgFiltered = this.translate.instant('LOCKS.HISTORY.NO_RECORDS_FILTER');
  showError = false;
  STATUS_UPDATE = 'Status Update';
  currentScrollPosition: number = 0;
  modalRef: BsModalRef;
  //Selected Default Language
  langCode: string;
  defaultLangCode: string;

  constructor(
    private lockService: LockService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    // private dialogService: DialogService,
    private route: ActivatedRoute,
    public translate: TranslateService,
    private langService: LanguageChangeService,
    private modalService: BsModalService
  ) { }

  getDateOnInit() {
    this.fromDate = Date.now() - 2 * 30 * 24 * 60 * 60 * 1000;
    this.toDate = Date.now();
    var offset = new Date().getTimezoneOffset() * 60 * -1;
    this.toDate = this.toDate + offset;
    this.toDate = this.toDate + 2 * 30 * 24 * 60 * 60 * 1000;
  }

  ngOnInit() {
    this.langCode = this.langService.getCurrentLanguage();
    this.defaultLangCode = this.langService.getDefaultLanguage();
    this.route.queryParams.subscribe((params) => {
      if (params['filters']?.setFilter === true) {
        this.noRecordMsg = this.translate.instant(
          'LOCKS.HISTORY.NO_RECORDS_FILTER'
        );
      } else {
        this.noRecordMsg = this.translate.instant('LOCKS.HISTORY.NO_RECORDS');
      }
    });
    this.getDateOnInit();
    this.activatedRoute.params.subscribe(
      (params) => (this.lockId = params['lockID'])
    );
    this.activatedRoute.queryParams.subscribe(({ filters }) => {
      if (filters) this.filters = JSON.parse(filters);
      else this.filters = { setFilter: false };
      this.currentScrollPosition = 0;
      this.applyFilter(this.filters);
    });
  }

  getLockName() {
    let title = this.lockService.getLockName(this.lockId);
    return title;
  }

  getHomeName() {
    let title = this.lockService.getHomeName(this.lockId);
    return title;
  }

  getImageSrc(event) {
    if (event === '') {
      return '../../../../assets/images/history/svg/Connection Error.svg';
    }
    return (
      '../../../../assets/images/history/svg/' +
      event.replace(/\//g, '-') +
      '.svg'
    );
  }

  getPageTitle() {
    return this.translate.instant('HOME_LOCK.HISTORY');
  }

  callback(lockHistory: ILockHistory[], issueHistory: ILockHistory[], total) {
    if (this.pageNo === this.defaultPageNo) {
      this.eventHistory = [];
      this.issueHistory = [];
    }
    this.sortEventHistoryObject(lockHistory, 'eventHistory');
    if (issueHistory.length) {
      this.issueHistory = this.setIssueHistoryList(issueHistory);
    }
    this.showError = true;
  }

  setIssueHistoryList(issueHistory: ILockHistory[]) {
    return issueHistory.map((event) => {
      const eventDateTime: any = moment(event.time);
      const eventDate = eventDateTime.format('YYYY-MM-DD');
      const lockCurrentDate = moment()
        .utcOffset(event.lockOffsetInMinutes)
        .format('YYYY-MM-DD');
      let showTime = eventDateTime.format('hh:mm a');
      if (lockCurrentDate === eventDate) {
        let day = this.translate.instant('LOCKS.HISTORY.TODAY');
        event['day'] = event.eventType === this.STATUS_UPDATE ? '' : day; //day;
        event['dayMobile'] =
          event.eventType === this.STATUS_UPDATE ? '' : day; //day;
        event['showTime'] =
          event.eventType === this.STATUS_UPDATE ? '' : showTime; //showTime;
      } else {
        let eventMonth: any;
        let eventMonthShort: any;
        let eventDay: any;
        if(this.langService.getCurrentLanguage() === Config.LANGUAGES.FRENCH){
          eventMonth = eventDateTime.locale(Config.LANGUAGES.FRENCH).format('MMMM');
          eventMonthShort = eventDateTime.locale(Config.LANGUAGES.FRENCH).format('MMM');
          eventDay = eventDateTime.locale(Config.LANGUAGES.FRENCH).format('D');
         }
        else{
        eventMonth = eventDateTime.locale(Config.LANGUAGES.ENGLISH).format('MMMM');
        eventMonthShort = eventDateTime.locale(Config.LANGUAGES.ENGLISH).format('MMM');
        eventDay = eventDateTime.locale(Config.LANGUAGES.ENGLISH).format('D');
        }
        let day = `${eventMonth}, ${eventDay}`;
        let dayMobile = `${eventMonthShort}, ${eventDay}`;
        event['day'] = event.eventType === this.STATUS_UPDATE ? '' : day; //day;
        event['dayMobile'] =
          event.eventType === this.STATUS_UPDATE ? '' : dayMobile; //dayMobile;
        event['showTime'] =
          event.eventType === this.STATUS_UPDATE ? '' : showTime; //showTime;
        }
        return event;
      });
  }

  sortEventHistoryObject(lockHistory: ILockHistory[], type) {
    let sortedHistory = lockHistory.reduce(
      (acc, event) => {
        const updateAccumulator = (title, acc) => {
          let index = acc.data.findIndex((obj) => title === obj.historyTitle);
          if (index === -1) {
            let newObject = {
              historyTitle: title,
              history: [event],
            };
            acc.data.push(newObject);
          } else {
            acc.data[index].history.push(event);
          }
          return acc;
        };
        const eventDateTime: any = moment(event.time);
        const eventDate = eventDateTime.format('YYYY-MM-DD');
        const lockCurrentDate = moment()
          .utcOffset(event.lockOffsetInMinutes)
          .format('YYYY-MM-DD');
        let showTime = eventDateTime.format('hh:mm a');
        if (lockCurrentDate === eventDate) {
          let day = this.translate.instant('LOCKS.HISTORY.TODAY');
          event['day'] = event.eventType === this.STATUS_UPDATE ? '' : day; //day;
          event['dayMobile'] = event.eventType === this.STATUS_UPDATE ? '' : day; //day;
          event['showTime'] = event.eventType === this.STATUS_UPDATE ? '' : showTime; //showTime;
          updateAccumulator(day, acc);
        } else {
          let eventMonth: any;
          let eventMonthShort: any;
          let eventDay: any;
          if(this.langService.getCurrentLanguage() === Config.LANGUAGES.FRENCH){
            eventMonth = eventDateTime.locale(Config.LANGUAGES.FRENCH).format('MMMM');
            eventMonthShort = eventDateTime.locale(Config.LANGUAGES.FRENCH).format('MMM');
            eventDay = eventDateTime.locale(Config.LANGUAGES.FRENCH).format('D');
           } else{
           eventMonth = eventDateTime.locale(Config.LANGUAGES.ENGLISH).format('MMMM');
           eventMonthShort = eventDateTime.locale(Config.LANGUAGES.ENGLISH).format('MMM');
           eventDay = eventDateTime.locale(Config.LANGUAGES.ENGLISH).format('D');
           }
          let day = `${eventMonth}, ${eventDay}`;
          let dayMobile = `${eventMonthShort}, ${eventDay}`;
          event['day'] = event.eventType === this.STATUS_UPDATE ? '' : day; //day;
          event['dayMobile'] =
            event.eventType === this.STATUS_UPDATE ? '' : dayMobile; //dayMobile;
          event['showTime'] =
            event.eventType === this.STATUS_UPDATE ? '' : showTime; //showTime;
            updateAccumulator(`${eventMonth}`, acc);
          }
          return acc;
      },
      {
        data: this[`${type}`],
      }
    );
    this[`${type}`] = this.sortTodayEvents(sortedHistory.data);
    this.disableCall = false;
  }

  sortTodayEvents(events) {
    let sortedEvents = events.reduce(
      (acc, obj) => {
        if (
          obj.historyTitle ===
          this.translate.instant('LOCKS.HISTORY.TODAY')
        ) {
          acc.todayChunk.push(obj);
        } else {
          acc.otherChunk.push(obj);
        }
        return acc;
      },
      {
        todayChunk: [],
        otherChunk: [],
      }
    );

    let response = [...sortedEvents.todayChunk, ...sortedEvents.otherChunk];
    return response;
  }

  onScroll(event) {
    if (this.currentScrollPosition + 250 < event.currentScrollPosition) {
      if (!this.disableCall) {
        this.disableCall = true;
        this.pageNo = this.pageNo + 1;
        this.applyFilter(this.filters);
      }
      this.currentScrollPosition = event.currentScrollPosition;
    }
  }

  opeFiltersModal() {
    const initialState = {
      lockID: this.lockId,
      activities: this.activities,
      sources: this.sources,
      filters: this.filters
    }

    console.log(initialState);

    this.modalRef = this.modalService.show(LockHistoryFilterModalComponent, {
      initialState: initialState,
      class: 'lock-history-filter',
      ...Constants.MODAL_CONFIG
    });
    this.modalRef.content.closeBtnName = 'Close';

    this.modalRef.content.event.subscribe(filterData => {
      if (filterData) {
        console.log('filtered Data', filterData);
        this.pageNo = this.defaultPageNo;
        this.eventHistory = [];
        this.issueHistory = [];
        this.filters = filterData;
        this.filters.timestamp = Date.now();
        const queryParams = { filters: JSON.stringify(this.filters) };
        this.router.navigate([], {
          relativeTo: this.activatedRoute,
          queryParams: queryParams,
        });
      }
    });
    this.modalRef.content.eventClose.subscribe(filterData => {
      if (filterData) { 
          console.log('cross modal', filterData);
          this.pageNo = this.defaultPageNo;
          this.filters = filterData;
          this.filters.timestamp = Date.now();
      }
    });
    // this.dialogServic
    //   .addDialog(
    //     LockHistoryFilterModalComponent,
    //     {
    //       lockID: this.lockId,
    //       activities: this.activities,
    //       sources: this.sources,
    //       filters: this.filters,
    //     },
    //     { closeByClickingOutside: false }
    //   )
    //   .subscribe((filterData) => {
    //     if (filterData) {
    //       console.log('filtered Data', filterData);
    //       this.pageNo = this.defaultPageNo;
    //       this.eventHistory = [];
    //       this.issueHistory = [];
    //       this.filters = filterData;
    //       this.filters.timestamp = Date.now();
    //       const queryParams = { filters: JSON.stringify(this.filters) };
    //       this.router.navigate([], {
    //         relativeTo: this.activatedRoute,
    //         queryParams: queryParams,
    //       });
    //     }
    //   });
  }

  applyFilter(filters) {
    this.showError = false;
    if (filters.setFilter) {
      this.noRecordMsg = this.noRecordMsgFiltered;
    }
    this.updateDateFilter(filters['fromDate'], filters['toDate']);
    let pathParams = {
      id: this.lockId,
      pageNo: this.pageNo,
      pageSize: this.pageSize,
      sortOrder: '',
      orderBy: 'ID desc',
      fromDate: isNaN(this.fromDate) ? '' : this.fromDate,
      toDate: this.toDate,
    };
    let filter = filters.setFilter ? this.setFilter(filters) : '';
    pathParams['filter'] = encodeURIComponent(filter);
    this.lockService.getLockHistoryWithParams(pathParams, this);
  }

  setFilter(filters) {
    let {
      selectedActivities = [],
      selectedLocks = [],
      selectedSource = [],
    } = filters;
    let filter = '(';
    let index = 0;
    const removeElements = (element) => {
      if (
        element.indexOf(',') > -1 ||
        element.indexOf('(') > -1 ||
        element.indexOf(')') > -1
      ) {
        let elements = element.split(' ');
        return elements[0];
      }
      return element;
    };
    selectedActivities.forEach((element) => {
      element = removeElements(element[this.defaultLangCode]);
      if (index > 0) {
        filter = filter + ' or ';
      }
      filter = filter + '(event =="' + element + '")';
      index++;
    });

    if (selectedActivities.length > 0) {
      filter = filter + ')';
    }

    if (selectedActivities.length > 0 && selectedSource.length > 0) {
      filter = filter + ' and (';
      index = 0;
    }
    selectedSource.forEach((element) => {
      element = element.trim();
      const unknownSourceStr = this.langService.getCurrentLanguage() === Config.LANGUAGES.ENGLISH ? Config.STATUS_UPDATE['en-us'].toLowerCase() : Config.STATUS_UPDATE['fr'].toLowerCase();;
      if (unknownSourceStr.includes(element.toLowerCase())) {
        element = removeElements(element);
        element = 'Unknown Source';
        filter =
          filter +
          'includes(user ,"' +
          element +
          '") == "true" or includes(eventtype ,"' +
          element +
          '") == "true"';
      } else {
        const itemsArr = element.split(' ');
        let itemIndex = 0;
        itemsArr.forEach((item) => {
          if (itemIndex > 0) {
            filter = filter + ' or ';
          }
          filter =
            filter +
            'includes(user ,"' +
            item +
            '") == "true" or includes(eventtype ,"' +
            item +
            '") == "true"';
          itemIndex++;
        });
      }
      index++;
    });
    filter = filter + ')';
    return filter;
  }

  updateDateFilter(fromDate, toDate) {
    if (fromDate && toDate) {
      if (typeof fromDate === 'string') fromDate = new Date(fromDate); // In case if date came from query params
      if (typeof toDate === 'string') toDate = new Date(toDate);
      /**
       * Modification: HALOC-1959
       * As dates are received as javasctipt date object and it is expected
       * to be the range of date, So I'm setting todate time as last minute of selected date
       * and doing timeset offset to send dates in utc format.
       */
      const {
        DATE_START_TIME: { HOUR: S_HOUR, MIN: S_MIN, SEC: S_SEC },
        DATE_END_TIME: { HOUR, MIN, SEC },
      } = Config;
      fromDate = {
        year: fromDate.getFullYear(),
        month: fromDate.getMonth(),
        day: fromDate.getDate(),
      };
      toDate = {
        year: toDate.getFullYear(),
        month: toDate.getMonth(),
        day: toDate.getDate(),
      };
      this.fromDate = Date.UTC(
        fromDate.year,
        fromDate.month,
        fromDate.day,
        S_HOUR,
        S_MIN,
        S_SEC
      );
      this.toDate = Date.UTC(
        toDate.year,
        toDate.month,
        toDate.day,
        HOUR,
        MIN,
        SEC
      );
    } else {
      this.getDateOnInit();
    }
  }

  formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }
}
