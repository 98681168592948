import { NgRedux } from '@angular-redux/store';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UPDATE_USER_BRAND } from '../../services/utils/state-management/actions';
import { IAppState } from '../../services/utils/state-management/store';
import { WebAccessibiltyComponent } from "../webaccessibilty/web-accessibilty.component";
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-brand-selection',
  templateUrl: './brand-selection.component.html',
  styleUrls: ['./brand-selection.component.css']
})
export class BrandSelectionComponent implements OnInit, AfterViewInit {

  @ViewChild('brandfocus') brandFocus: ElementRef;

  currentYear = new Date().getFullYear();

  // we will inject the related modal in future "private dialogService: DialogService"
  constructor(
    private ngRedux: NgRedux<IAppState>,
    private translate: TranslateService,
    private modalService: BsModalService
  ) { }

  /**
  * This method initializes the component after the view has been fully initialized
  */
  ngAfterViewInit() {
    this.brandFocus.nativeElement.focus();
  }

  ngOnInit() { }

  select(name: string) {
    if (name == 'kwikset') {
      // document.getElementById("onetrust-style").innerHTML = '#onetrust-consent-sdk #onetrust-pc-sdk button {background-color: #000000 !important; border-color: #000000 !important; font-weight: bold;} #onetrust-consent-sdk #onetrust-pc-sdk #close-pc-btn-handler {background-color: #FFFFFF !important; border-color: #FFFFFF !important; } #onetrust-pc-sdk .pc-logo { background-image: url("../../../assets/images/kwikset-brand.svg") !important; margin-left:-7px !important; } #onetrust-consent-sdk #onetrust-banner-sdk { background-color: #D90114 !important; } #onetrust-consent-sdk #onetrust-policy-title, #onetrust-consent-sdk #onetrust-policy-text, #onetrust-consent-sdk #onetrust-policy-text *:not(.onetrust-vendors-list-handler) {color: white !important;} #onetrust-consent-sdk #onetrust-accept-btn-handler, #onetrust-banner-sdk #onetrust-reject-all-handler { background-color: white !important; border-color: white !important; color: black !important; } #onetrust-consent-sdk #onetrust-pc-btn-handler { background-color: #D90114 !important; border-color: #D90114 !important; color: white !important; }';
    

      localStorage.setItem('theme', 'theme-dark');

    }
    else if (name == 'weiser') {
      // document.getElementById("onetrust-style").innerHTML = '#onetrust-consent-sdk #onetrust-pc-sdk button {background-color: #000000 !important; border-color: #000000 !important; font-weight: bold;} #onetrust-consent-sdk #onetrust-pc-sdk #close-pc-btn-handler {background-color: #FFFFFF !important; border-color: #FFFFFF !important; } #onetrust-pc-sdk .pc-logo { background-image: url("../../../assets/images/weiser-new.svg") !important; margin-left:-7px !important; } #onetrust-consent-sdk #onetrust-banner-sdk { background-color: #E7BF3C !important; } #onetrust-consent-sdk #onetrust-policy-title, #onetrust-consent-sdk #onetrust-policy-text, #onetrust-consent-sdk #onetrust-policy-text *:not(.onetrust-vendors-list-handler) {color: black !important;} #onetrust-consent-sdk #onetrust-accept-btn-handler, #onetrust-banner-sdk #onetrust-reject-all-handler { background-color: #011F37 !important; border-color: #011F37 !important; color: #ffffff !important; } #onetrust-consent-sdk #onetrust-pc-btn-handler { background-color: #E7BF3C !important; border-color: #E7BF3C !important; color: black !important; }';
  
      localStorage.setItem('theme', 'theme-light')
    }
    // To Open Initial Register Popup for the firstime.
    localStorage.setItem('ISREGISTERPOPUP', '1');

    // function to set a given theme/color-scheme
    function setTheme(themeName: any) {
      localStorage.setItem('theme', themeName);
      document.documentElement.className = themeName;
    }
    // function to toggle between light and dark theme
    // function toggleTheme() {
    if (localStorage.getItem('theme') === 'theme-dark') {
      setTheme('theme-light');
    } else {
      setTheme('theme-dark');
    }
    // }
    // Immediately invoked function to set the theme on initial load
    (function () {
      if (localStorage.getItem('theme') === 'theme-dark') {
        setTheme('theme-dark');
      } else {
        setTheme('theme-light');
      }
    })();

    this.ngRedux.dispatch({
      type: UPDATE_USER_BRAND,
      brand: name
    });


  }
  showWebAccessibilty() {
    this.modalService.show(WebAccessibiltyComponent, { class: 'web-access' });
  }

}
