import { Component, OnInit, Renderer2 } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
/* import { DialogService } from "ng6-bootstrap-modal"; */
import { environment } from "../../../../environments/environment";
import { ConfirmComponent } from "../../../helpers/modal/confirm.component";
import { HomeCallback, HomeService, IHomeObject } from "../../../services/home.service";
import { ShareHomeService, toggleUserStatusCallback } from "../../../services/share-home.service";
import { UserManagement } from "../../../services/utils/state-management/action-classes";
import { Utility } from "../../../services/utils/utility";
import { HomeSelectionModalComponent } from '../home-selection-modal/home-selection-modal.component';
import { AcceptRejectHomeModalComponent } from '../accept-reject-home-modal/accept-reject-home-modal.component';
import { NgRedux } from "@angular-redux/store";
import { IAppState } from "../../../services/utils/state-management/store";
import { LoaderService } from "src/app/services/loader.service";
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Constants } from "src/app/services/utils/Constants";

@Component({
  selector: "app-home-base",
  templateUrl: "./home-base.component.html",
  styleUrls: ["./home-base.component.css"]
})
export class HomeBaseComponent implements OnInit, HomeCallback, toggleUserStatusCallback {
  environment = environment;
  homeList: IHomeObject[];
  homeObject: IHomeObject[];
  modalRef: BsModalRef;
  brand;
  viewType;
  selectedHome;
  selectedHomeName;
  successMessage: string = '';
  sharedHomeConfirmationStatus = {
    ACCEPT: 'accept',
    REJECT: 'reject'
  }
  isOwner = 'owner';
  isGuest = 'guest';
  isAdmin = 'admin';

  AllHomeId = 'All';
  isShowed = false;

  adminText = '';
  guestText = '';

  constructor(
    private homeService: HomeService,
    public translate: TranslateService,
    public shareHome: ShareHomeService,
    private userManagement: UserManagement,
    private utility: Utility,
    private modalService: BsModalService,
    /* private dialogService: DialogService, */
    private renderer: Renderer2,
    private loaderService: LoaderService,
    private ngRedux: NgRedux<IAppState>
  ) { }

  ngOnInit() {
    this.translate.use(this.ngRedux.getState().preferredLanguage)
    this.selectedHome = this.userManagement.getUserHome();
    this.homeService.getHome(this);
    this.successMessage = this.utility.getSuccessMessage();
    this.brand = this.userManagement.getUserBrand();
    this.viewType = this.userManagement.getUserView();
    this.loaderService.fireLoaderValue(true);
    setTimeout(() => {
      this.isShowed = true;
    }, 1500);

    this.adminText = this.translate.instant('HOME.ADMIN_SHARE_TEXT');
    this.guestText = this.translate.instant('HOME.GUEST_SHARE_TEXT');
  }

  callback(home) {
    this.homeObject = home;
    if (this.selectedHome) {
      if (this.selectedHome === this.AllHomeId) {
        this.homeList = home;
        this.translate.get("HOME.MODAL.TITLE").subscribe(value => this.selectedHomeName = value);
      } else {
        this.homeList = home.filter(x => x.homeId === this.selectedHome);
        if (this.homeList.length > 0) {
          this.selectedHomeName = this.homeList[0].homeName;
        }
        else {
          this.homeList = home;
          this.translate.get("HOME.MODAL.TITLE").subscribe(value => this.selectedHomeName = value);
          this.userManagement.storeSelectedHome(this.AllHomeId);
        }
      }
    } else {
      this.homeList = home;
      this.translate.get("HOME.MODAL.TITLE").subscribe(value => this.selectedHomeName = value);
    }
  }

  openHomeModal() {
    this.modalRef = this.modalService.show(HomeSelectionModalComponent, { ...Constants.MODAL_CONFIG, class: 'home-selection' });
    setTimeout(() => {
      this.renderer.addClass(document.body, 'modal-open');
     }, 100);
    this.modalRef.content.closeBtnName = 'Close';

    
    this.modalRef.content.event.subscribe(userHome => {
      if (userHome && userHome != this.AllHomeId) {
              this.homeList = this.homeObject.filter(x => x.homeId === userHome);
              this.selectedHomeName = this.homeList[0].homeName;
            } else if (userHome) {
              this.homeList = this.homeObject;
              this.selectedHome = this.AllHomeId;
              this.translate.get("HOME.MODAL.TITLE").subscribe(value => this.selectedHomeName = value);;
            }
    })
    // this.dialogService.addDialog(HomeSelectionModalComponent, {},
    //   { closeByClickingOutside: true }).subscribe((userHome) => {
    //     if (userHome && userHome != this.AllHomeId) {
    //       this.homeList = this.homeObject.filter(x => x.homeId === userHome);
    //       this.selectedHomeName = this.homeList[0].homeName;
    //     } else if (userHome) {
    //       this.homeList = this.homeObject;
    //       this.selectedHome = this.AllHomeId;
    //       this.translate.get("HOME.MODAL.TITLE").subscribe(value => this.selectedHomeName = value);;
    //     }
    //   });
  }

  changeView(view: string) {
    this.viewType = view;
    this.userManagement.storeUserViewType(view);
  }

  onToggleSuccess(userEmail: string, oldStatus: string) {
    this.homeService.getHome(this);
  }

  onToggleFailure(message: string) {
    this.homeService.getHome(this);
  }

  accept(id: string) {
    const user = this.userManagement.getUser();
    this.shareHome.toggleUserStatus(id, "Enabled", user.email, this);
  }

  reject(id: string) {
    const user = this.userManagement.getUser();
    this.shareHome.deleteShare(id, user.email, this);
  }

  delete(id: string, name: string) {
    this.showPopup(
      this.translate.get('HOME.DELETE.WARNING_POP_UP_HEADING')['value'],
      (this.translate.get('HOME.DELETE.WARNING_POP_UP_MESSAGE')['value'] || "").replace('{id}', name),
      this.translate.get('HOME.DELETE.DELETE_BUTTON')['value'],
      this.translate.get('HOME.DELETE.CANCEL_BUTTON')['value'],
      id
    );
  }

  showPopup(title, message, okButton, canceBUtton, id) {
    /*  const disposable = this.dialogService.addDialog(ConfirmComponent, {
       title: title,
       message: message,
       confirmButtonText: okButton,
       cancelButtonText: canceBUtton,
       showCloseIcon: true
     }).subscribe((isConfirmed) => {
       if (isConfirmed === true) {
         this.reject(id);
       }
       if (disposable) {
         disposable.unsubscribe();
       }
       this.dialogService.removeAll();
     }); */
  }

  viewInvitation(sharedBy, accessRights, homeName, homeId) {
    /*  this.dialogService.addDialog(AcceptRejectHomeModalComponent, { sharedBy, accessRights, homeName },
       { closeByClickingOutside: false }).subscribe((result) => {
         if (result) {
           if (result === this.sharedHomeConfirmationStatus.ACCEPT) {
             this.accept(homeId);
           } else if (result === this.sharedHomeConfirmationStatus.REJECT) {
             this.reject(homeId);
           }
         }
       }); */

       const initialState = {
        sharedBy, 
        accessRights, 
        homeName
       }
       this.modalRef = this.modalService.show(AcceptRejectHomeModalComponent, { 
        initialState : initialState,
        class: 'accept-reject-home-modal'
         });
        this.modalRef.content.closeBtnName = 'Close';
        this.modalRef.content.event.subscribe((result) => {
            if (result && result === this.sharedHomeConfirmationStatus.ACCEPT) {
              this.accept(homeId);
            } else if (result === this.sharedHomeConfirmationStatus.REJECT) {
              this.reject(homeId);
            }
        });
  }

}
