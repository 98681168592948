<div class="language-dropdown">
<div [(ngModel)]="language" (selectionChange)="onLanguageChange(language)" class="sp-red-text mg-right-10 font-text" >{{language}}</div>
  <a name="down-link" class="nav-arrow-icon" data-toggle="dropdown" >
    <span>
      <i class="fa fa-chevron-down font-text"></i>  
    </span>
  </a>
  <ul class="dropdown-menu sp-dropdown drop-down">
    <li *ngFor="let lang of translate.getLangs()" class="list-i">
      <a class="sp-menu-item" (click)="onLangClick(lang)"> {{lang}}</a>
    </li>
  </ul>
</div>
  
