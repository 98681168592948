<div class="hidden-xs hidden-sm col-md-12 col-sm-12 col-xs-12 breadcrumbs no-padding">
  <a [routerLink]="'/securehome'">
    <img src="./../../../../assets/images/home/ico-default-home-gray.png" alt="home.png">
  </a>
  <i style="color: #7F7F7F; margin-left: 10px; margin-right: 10px;" class="fa fa-chevron-right"></i>
  <span [routerLink]="'/profile/edit'" style="color: #7F7F7F; font-family: 'Gotham-Bold'; cursor: pointer;" tabIndex="42">{{ 'EDIT_PROFILE.ACCOUNT_SETTINGS' | translate }}</span>
  <i style="color: #7F7F7F; margin-left: 10px; margin-right: 10px;" class="fa fa-chevron-right"></i>
  <span style="font-family: 'Gotham-Bold';"> <b>{{ 'PROFILE.CHANGE_SECURITY_QUESTIONS.TITLE' | translate }}</b> </span>
</div>
<div class="row sm-no-margin hidden-md hidden-lg">
  <div class="col-xs-12 sp-back">
    <a [routerLink]="'/profile/edit'">  <i class="fa fa-chevron-left"></i> {{ 'PROFILE.BACK' | translate }}</a>
  </div>
</div>
<div class="sp-body clearfix padding-none">
  <div class="row mt-warning">
    <div class="col-lg-10 col-xs-12 margin-no-padding">
      <disabled-phone [link]="true" [disabledText]="'HOME.ACCOUNT_DISABLED.PRE'"></disabled-phone>
    </div>
  </div>
  <div class="row">
    <div *ngIf="genericMessage" class="alert alert-danger alert-dismissible fade in">
      <a class="close" aria-label="close" (click)="genericMessage = null">&times;</a>
      {{ genericMessage }}
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12 no-padding">
      <h2 class="heading-sm-center"> {{ 'PROFILE.CHANGE_SECURITY_QUESTIONS.TITLE' | translate }} </h2>
      <p class="text-left">{{'PROFILE.CHANGE_SECURITY_QUESTIONS.INSTRUCTIONS' | translate}}</p>
    </div>
  </div>

  <br />
  <form class="form-signin col-md-6 col-sm-12 no-padding" [formGroup]="securityQuestions" (ngSubmit)="onSubmit()">
    <div class="form-group has-feedback">
      <div [ngClass]="{'has-error' : checkError(1), 'has-success' : checkSuccess(1)}">
        <div>
          <select tabIndex="60" (change)="clearField('answer1')" class="questions col-md-12 form-control" formControlName="question1" [(ngModel)]="question1">
            <option selected hidden disabled value=""> {{ 'REGISTRATION.SECURITY_QUESTIONS.LABEL_QUESTION_1' | translate
              }} </option>
            <option *ngFor="let question of getQuestionList(0)" value="{{question}}">{{question}}</option>
          </select>
          <mat-form-field hideRequiredMarker class="example-full-width" appearance="fill">
            <mat-label>{{ 'REGISTRATION.SECURITY_QUESTIONS.LABEL_ANSWER_1' | translate }}</mat-label>
            <textarea tabIndex="61" maxlength="255" formControlName="answer1" class="textarea" rows="2" matInput></textarea>
          </mat-form-field>
        </div>
        <p *ngIf="checkError(1) && this.securityQuestions.controls['answer1'].value.trim().length < 3" class="help-block">
          {{ 'VALIDATION.SECURITY_QUESTIONS' | translate}}</p>
        <p *ngIf="checkSameAnswer(1)" class="help-block">{{ 'VALIDATION.SAME_ANSWER' | translate}}</p>
      </div>
    </div>

    <div class="form-group has-feedback">
      <div [ngClass]="{'has-error' : checkError(2), 'has-success' :  checkSuccess(2)}">
        <div>
          <select tabIndex="62" (change)="clearField('answer2')" class="questions col-md-12 form-control" formControlName="question2" [(ngModel)]="question2">
            <option selected disabled hidden value=""> {{ 'REGISTRATION.SECURITY_QUESTIONS.LABEL_QUESTION_2' | translate
              }} </option>
            <option *ngFor="let question of getQuestionList(1)" value="{{question}}">{{question}}</option>
          </select>
          <mat-form-field hideRequiredMarker class="example-full-width" appearance="fill">
            <mat-label>{{ 'REGISTRATION.SECURITY_QUESTIONS.LABEL_ANSWER_2' | translate }}</mat-label>
            <textarea tabIndex="63" formControlName="answer2" class="textarea" maxlength="255" rows="2" matInput></textarea>
          </mat-form-field>
        </div>
        <p *ngIf="checkError(2) && this.securityQuestions.controls['answer2'].value.trim().length < 3" class="help-block">
          {{ 'VALIDATION.SECURITY_QUESTIONS' | translate}}</p>
        <p *ngIf="checkSameAnswer(2)" class="help-block">{{ 'VALIDATION.SAME_ANSWER' | translate}}</p>
      </div>
    </div>

    <div class="form-group has-feedback">
      <div [ngClass]="{'has-error' : checkError(3), 'has-success' :  checkSuccess(3)}">
        <div>
          <select tabIndex="64" (change)="clearField('answer3')" class="questions col-md-12 form-control" formControlName="question3" [(ngModel)]="question3">
            <option selected hidden disabled value=""> {{ 'REGISTRATION.SECURITY_QUESTIONS.LABEL_QUESTION_3' | translate
              }} </option>
            <option *ngFor="let question of getQuestionList(2)" value="{{question}}">{{question}}</option>
          </select>
          <mat-form-field hideRequiredMarker class="example-full-width" appearance="fill">
            <mat-label>{{ 'REGISTRATION.SECURITY_QUESTIONS.LABEL_ANSWER_3' | translate }}</mat-label>
            <textarea tabIndex="65" formControlName="answer3" class="textarea" maxlength="255" rows="2" matInput></textarea>
          </mat-form-field>
        </div>
        <p *ngIf="checkError(3) && this.securityQuestions.controls['answer3'].value.trim().length < 3" class="help-block">
          {{ 'VALIDATION.SECURITY_QUESTIONS' | translate}}</p>
        <p *ngIf="checkSameAnswer(3)" class="help-block">{{ 'VALIDATION.SAME_ANSWER' | translate}}</p>
      </div>
    </div>

    <br />
    <div class="col-lg-6 col-md-6 col-sm-12 no-padding">
      <div class="form-group">
        <button tabIndex="66" id="submitQuestions" type="submit" class="btn btn-block sp-btn-dark h-45"
          [disabled]="!securityQuestions.valid || checkError(1) || checkError(2) || checkError(3)">
          {{ 'PROFILE.CHANGE_SECURITY_QUESTIONS.BUTTON_SUBMIT' | translate }}
        </button>
      </div>
    </div>
  </form>
</div>