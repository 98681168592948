import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'phoneNumber' })
export class PhoneNumberPipe implements PipeTransform {
  transform(number: string): string {
    if (!number) {
      return '';
    }
    number = String(number);
    number = number.replace(/[^0-9]*/g, '');

    if (number.length > 10) {
      number = number.substring(number.length - 10, number.length);
    }

    let formattedNumber = number;

    let area = number.substring(0, 3);
    let front = number.substring(3, 6);
    let end = number.substring(6, 10);

    if (front) {
      formattedNumber = area + '-' + front;
    }
    if (end) {
      formattedNumber += '-' + end;
    }

    return formattedNumber;
  }
}
