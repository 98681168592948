import { NgRedux } from '@angular-redux/store';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ADD_SUCCESS_MESSAGE } from './utils/state-management/actions';
import { IAppState } from './utils/state-management/store';
import { UserLoginService } from './user-login.service';
import { ApiRequestService } from './api-client/api-requests.service';
import { Constants } from './utils/Constants';
import apiConfig from './api-client/api-config';
import { ResponseHandlerService } from './utils/response-handler.service';


export interface ChangePasswordCallback {
  onPasswordMissMatch(): void;
  onSuccess(): void;
  onFailure(message: string): void;
}

@Injectable()
export class ChangePasswordService {
  constructor(
    public apiClient: ApiRequestService,
    private ngRedux: NgRedux<IAppState>,
    private userService: UserLoginService,
    private translate: TranslateService,
    private responseHandler: ResponseHandlerService
  ) { }

  changePassword(existingPassword: string, newPassword: string, confirmNewPassword: string, callback): void {
    if (newPassword === confirmNewPassword) {

      const body = {
        password: existingPassword,
        newpassword: newPassword
      };

      this.apiClient.callApi(Constants.API_METHODS.PATCH, apiConfig.changePassword.uri, body).subscribe({
        next: (res: any) => {
          if(res){
            this.userService.globalLogout({
              onFailure: callback.onFailure, onSuccess: () => {
                this.ngRedux.dispatch({
                  type: ADD_SUCCESS_MESSAGE,
                  msg: this.translate.instant("CHANGE_PASSWORD.PASSWORD_CHANGE_SUCCESS_MESSAGE")
                });
                callback.onSuccess();
              }
            });
          }
        },
        error: (err) => {
         let error = this.responseHandler.parseErrorResponseV2(err);
         callback.onFailure(error);
        },
      })

      /* this.apiClient.makeCall(null, 'patch', apiConfig.changePassword, body, (err, res) => {

        if (res && res.success) {
          this.userService.globalLogout({
            onFailure: callback.onFailure, onSuccess: () => {
              this.ngRedux.dispatch({
                type: ADD_SUCCESS_MESSAGE,
                msg: this.translate.get("CHANGE_PASSWORD.PASSWORD_CHANGE_SUCCESS_MESSAGE")["value"]
              });
              callback.onSuccess();
            }
          });
        } else {
          callback.onFailure(res.data[0].code || '', null);
        }
      }); */
      
    } else {
      callback.onPasswordMissMatch();
    }
  }
}
