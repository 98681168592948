<div class="{{modalCss}}">
  <div class="modal-header">
    <button type="button" class="close close-btn" (click)="closeDialogue(lastTab)"><mat-icon>clear</mat-icon></button>
  </div>

  <div class="modal-body">
    <div class="options-modal">
      <div class="col-md-12 col-sm-12 col-xs-12 modal-content-div">
        <div class="col-xs-12">
          <div class="row">
            <div class="col-md-12 main-head">
              <div class="options-modal" *ngIf="currentTab==0">
                <h3 class="text-center header-modal">{{ 'HOME.HISTORY.FILTERS.FILTERS_BY_TEXT' | translate }}</h3>
                <div class="form-group has-feedback">
                  <ng-container>
                    <a (click)="resetFilterAndCloseDialog()" class="reset-btn-link sp-hyperlink">{{
                      'HOME.HISTORY.FILTERS.RESET_TEXT' | translate }}</a>
                  </ng-container>


                  <div class="container filter-main">
                    <div class="filter-main-row filter-main-row-top" (click)="changeTab(2, false)">
                      <div>
                        <img class="row-icon"
                          src="../../../../assets/images/home/ico-date-and-time-schedule-date-range.png">
                        <b>{{ 'HOME.HISTORY.FILTERS.DATE_RANGE_TEXT' | translate }}</b>
                      </div>
                      <div>
                        <p class="filter-text">
                          <ng-container *ngIf="selectedMoments[0] && selectedMoments[1]">
                            {{selectedMoments[0] | date:'dd/MM/yyyy' }} - {{selectedMoments[1] |
                            date:'dd/MM/yyyy' }}
                          </ng-container>
                        </p>
                        <img src="../../../../assets/images/home/ico-chevron-forward-outline.png">
                      </div>
                    </div>
                    <div class="filter-main-row" (click)="changeTab(3, false)">
                      <div>
                        <img class="row-icon" src="../../../../assets/images/home/ico-user.png">
                        <b>{{ 'HOME.HISTORY.FILTERS.USER_TYPE_TEXT' | translate }}</b>
                      </div>
                      <div>
                        <p class="filter-text"> {{selectedSources[0]}} </p>
                        <img src="../../../../assets/images/home/ico-chevron-forward-outline.png">
                      </div>
                    </div>
                    <div class="filter-main-row filter-main-row-bottom" (click)="changeTab(4, false)">
                      <div>
                        <img class="row-icon" src="../../../../assets/images/home/ico-activity.png">
                        <b>{{ 'HOME.HISTORY.FILTERS.ACTIVITY_TEXT' | translate }}</b>
                      </div>
                      <div>
                        <p class="filter-text"> <span *ngFor="let s of selectedActivities">{{s[langCode]}}&nbsp;</span>
                        </p>
                        <img src="../../../../assets/images/home/ico-chevron-forward-outline.png">
                      </div>
                    </div>
                    <button (click)="closeDialog()"
                      [disabled]="selectedSources.length === 0 && selectedActivities.length === 0 && !selectedMoments[0] &&  !selectedMoments[1]"
                      class="options-modal-btn btn-block btn-filled btn-filter-box">
                      <b>{{ 'HOME.HISTORY.FILTERS.SEE_RESULTS_TEXT' | translate }}</b>
                    </button>
                  </div>
                </div>
              </div>
              <div class="options-modal" *ngIf="currentTab==2">
                <img class="back-btn" (click)="changeTab(0, false, true, 2)"
                  src="../../../../assets/images/home/ico-chevron-back-outline.png">
                <h3 class="text-center header-modal">{{ 'HOME.HISTORY.FILTERS.SELECT_DATE_RANGE_TEXT' | translate }}
                </h3>
                <div class="form-group has-feedback date-picker-main-container">
                  <div class="date-picker-container">
                    <owl-date-time-inline [max]="toDate" [min]="minDate" [(ngModel)]="selectedMoments"
                      [selectMode]="'range'"></owl-date-time-inline>
                  </div>
                  <button (click)="changeTab(0, true, false,1)" [disabled]="!selectedMoments[0] || !selectedMoments[1]"
                    class="options-modal-btn btn-block btn-filled-date-picker btn-filter-box date-picker-btn">
                    <b>{{ 'HOME.HISTORY.FILTERS.APPLY_TEXT' | translate }}</b>
                  </button>
                </div>
              </div>
              <div class="options-modal" *ngIf="currentTab==3">
                <img class="back-btn" (click)="changeTab(0, false, true, 3)"
                  src="../../../../assets/images/home/ico-chevron-back-outline.png">
                <h3 class="text-center header-modal">{{ 'HOME.HISTORY.FILTERS.USER_TYPE_TEXT' | translate }}</h3>
                <div class="form-group has-feedback">
                  <div class="input-icons">
                    <i class="fa fa-search icon"></i>
                    <input class="input-field" type="text"
                      placeholder="{{ 'HOME.HISTORY.FILTERS.SEARCH_FOR_KEYWORDS_PLACEHOLDER' | translate }}"
                      (keyup)="addSourceFilter($event)" [(ngModel)]="selectedSources[0]">
                  </div>
                  <button (click)="changeTab(0, true, false, 3)"
                    [disabled]="selectedSources.length === 0 || selectedSources[0].trim().length === 0"
                    class="options-modal-btn btn-block btn-filled-date-picker btn-filter-box date-picker-btn">
                    <b>{{ 'HOME.HISTORY.FILTERS.APPLY_TEXT' | translate }}</b>
                  </button>
                </div>
              </div>
              <div class="options-modal" *ngIf="currentTab==4">
                <img class="back-btn" (click)="changeTab(0, false, true, 4)"
                  src="../../../../assets/images/home/ico-chevron-back-outline.png">
                <h3 class="text-center header-modal">{{ 'HOME.HISTORY.FILTERS.ACTIVITY_TEXT' | translate }}</h3>
                <div class="form-group has-feedback">
                  <div id="" class="checkbox-container-main">
                    <div *ngFor="let activity of activities;">
                      <div class="checkbox-container">
                        <div class="panel-bottom">
                          <div class="div-80"> <label class="checkbox-label">{{activity[langCode]}}</label> </div>
                          <div class="div-20">
                            <div class="container-checkbox">
                              <div class="round">
                                <input type="checkbox" value="{{activity[defaultLangCode]}}"
                                  id="{{activity[defaultLangCode]}}" (change)="addActivityFilter(activity)"
                                  [checked]="isActivityChecked(activity)" />
                                <label for="{{activity[defaultLangCode]}}"></label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <button (click)="changeTab(0, true, false, 4)" [disabled]="selectedActivities.length === 0"
                    class="options-modal-btn btn-block btn-filled-date-picker btn-filter-box date-picker-btn">
                    <b>{{ 'HOME.HISTORY.FILTERS.APPLY_TEXT' | translate }}</b>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>