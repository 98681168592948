
<br/>
<div class="sp-lock-header hidden-xs hidden-sm">
  <div class="col-lg-8 col-lg-offset-2">
    <div class="breadcrumbs ml-5px">
      <a name="route-back"  [routerLink]="'/securehome'" tabindex="1">
        <img alt="home-icon" src="../../../../assets/images/home/ico-default-home-gray.png">
      </a>
      <i style="color: black; margin-left: 10px; margin-right: 10px;" class="fa fa-chevron-right"></i>
     <span style="color: black; cursor: pointer" (keydown.enter)="backButton.click()" #backButton [routerLink]="'/securehome'" tabindex="2">{{getHomeName()}}</span> 
      <i style="color: black; margin-left: 10px; margin-right: 10px;" class="fa fa-chevron-right"></i>
      {{getLockName()}}
    </div>
    
  </div>
</div>

<div class="sp-middle-element visible-xs visible-sm">
    <div class="col-xs-12 col-sm-10 col-sm-offset-1 sm-no-padding">
      <div class="row">
        <div class="col-xs-12 sp-back">
          <a name="route-back" (keydown.enter)="backButton.click()" #backButton [routerLink]="'/securehome'"><i class="fa fa-chevron-left"></i>  {{ 'PROFILE.BACK' | translate }}</a>
        </div>
      </div>
    </div>
</div>

<div class="sp-body sp-body-device-detail clearfix">

  <div class="row mt-warning">
    <div class="col-lg-8 col-lg-offset-2 col-xs-12 margin-no-padding">
      <disabled-phone [link]="true" [disabledText]="'HOME.ACCOUNT_DISABLED.PRE'"></disabled-phone>
    </div>
  </div>

  <div *ngIf="errorMessage!=null" class="alert alert-danger alert-dismissible fade in">
    <a class="close" aria-label="close" (click)="errorMessage = null">&times;</a>
    {{ errorMessage }}
  </div>

  <div class="col-sm-12">
    <div *ngIf='details'>
      <div class="clearfix sp-lock-device-info">
        <div class="col-lg-8 col-lg-offset-2 col-sm-10 col-sm-offset-1">
          <h2>{{ 'LOCKS.TITLE' | translate }}</h2>
        </div>
      </div>
      <div class="clearfix sp-lock-device-detail main-container">
        <div class="col-lg-4 col-lg-offset-2 col-sm-10 col-sm-offset-1 detail-div">
          <div class="clearfix">
            <p class="lock-detail-key col-xs-5">{{ 'LOCKS.DETAIL.KEY.DOOR_STATUS' | translate }}</p>
            <p *ngIf= "!details.lockState && !checkUpdateLastStatus(details.lastUpdateStatus)" class="lock-detail-value col-xs-7">{{ 'LOCKS.NO_CONNECTION' | translate}}</p>
            <p *ngIf= "!details.lockState && checkUpdateLastStatus(details.lastUpdateStatus)" class="lock-detail-value col-xs-7">{{details.doorStatusLocale}}</p>
          </div>
          <div class="clearfix">
            <p class="lock-detail-key col-xs-5">{{ 'LOCKS.DETAIL.KEY.BATTERY_STATUS' | translate }}</p>
            <p *ngIf= "!details.lockState && !checkUpdateLastStatus(details.lastUpdateStatus)" class="lock-detail-value col-xs-7">{{ 'LOCKS.NO_CONNECTION' | translate}}</p>
            <p *ngIf= "!details.lockState && checkUpdateLastStatus(details.lastUpdateStatus)" class="lock-detail-value col-xs-7">{{details.batteryStatusLocale}}</p>
          </div>
          <div class="clearfix">
            <p class="lock-detail-key col-xs-5">{{ 'LOCKS.DETAIL.KEY.LOCK_TEMP_STATE' | translate }}</p>
            <p class="lock-detail-value col-xs-7">{{details.lockTamperState}}</p>
          </div>
          <div class="clearfix">
            <p class="lock-detail-key col-xs-5">{{ 'LOCKS.DETAIL.KEY.MODEL' | translate }}</p>
            <p class="lock-detail-value col-xs-7">{{details.modelNumber}}:{{details.hardwareVariant}}</p>
          </div>
          <div class="clearfix">
            <p class="lock-detail-key col-xs-5">{{ 'LOCKS.DETAIL.KEY.SKU' | translate }}</p>
            <p class="lock-detail-value col-xs-7">{{details.sku}}</p>
          </div>
          <div class="clearfix">
            <p class="lock-detail-key col-xs-5">{{ 'LOCKS.DETAIL.KEY.SERIAL_NUMBER' | translate }}</p>
            <p class="lock-detail-value col-xs-7">{{details.serialNumber}}</p>
          </div>
          <div class="clearfix">
            <p class="lock-detail-key col-xs-5">{{ 'LOCKS.DETAIL.KEY.FIRMWARE_VERSION' | translate }}</p>
            <p class="lock-detail-value col-xs-7">{{details.firmwareBundleVersion}}</p>
          </div>
          <div class="clearfix">
            <p class="lock-detail-key col-xs-5">{{ 'LOCKS.DETAIL.KEY.PRODUCT_FAMILY' | translate }}</p>
            <p class="lock-detail-value col-xs-7">{{details.productFamilyLocale}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-container *ngIf='ble'>
      <div class="row">
        <div class="col-lg-8 col-lg-offset-2 col-md-10">
          <p class="ml-10px ble-text"> {{ 'LOCKS.DETAIL.BLE' | translate }}</p>
        </div>
      </div>
  </ng-container>
</div>
