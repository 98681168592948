import { Injectable } from "@angular/core";
import { Logger } from "../logger";
import { NgRedux } from "@angular-redux/store";
import { IAppState } from "./store";
import { IUser } from "./types";
import { UPDATE_USER_OBJECT, UPDATE_USER_BRAND, USER_VIEW_TYPE, USER_MODAL_VIEW_TYPE, USER_SELECTED_HOME } from "./actions";
import { getUILanguage } from "../utility";

/**
 * This is a general class for getting and setting user object from redux.
 */
@Injectable()
export class UserManagement {
  constructor(private logger: Logger, private ngRedux: NgRedux<IAppState>) {}

  getUser(): IUser {
    return this.ngRedux.getState().userObject;
  }

  storeUserViewType(viewType: String): any {
    this.ngRedux.dispatch({ type: USER_VIEW_TYPE, userView: viewType });
  }

  storeUserModalViewType(modalViewType: String): any {
    this.ngRedux.dispatch({ type: USER_MODAL_VIEW_TYPE, userModalView: modalViewType });
  }

  storeSelectedHome(selectedHome: String): any {
    this.ngRedux.dispatch({ type: USER_SELECTED_HOME, userSelectedHome: selectedHome });
  }

  getUserObject(): any {
    return this.ngRedux.getState().user
  }

  updateUser(userObject: IUser): void {
    this.ngRedux.dispatch({ type: UPDATE_USER_OBJECT, userObject: userObject });
  }

  removeUser(): void {
    this.ngRedux.dispatch({ type: UPDATE_USER_OBJECT, userObject: null });
  }

  removeUserPhoneNumber(): void {
    const user = this.ngRedux.getState().userObject;
    user.phoneNumber = null;
    this.ngRedux.dispatch({ type: UPDATE_USER_OBJECT, userObject: user });
  }


  //This method must be called after calling /me api to set user object in redux
  parseUserObjectAndDispatch(rawUser: any): IUser {
    let user: IUser = {
      firstName: rawUser["firstname"],
      lastName: rawUser["lastname"],
      email: rawUser["email"],
      accountEnabled:rawUser["accountenabled"] && (rawUser["accountenabled"] === true || rawUser["accountenabled"] === "true") ? true : false,  
      phoneActive: rawUser["phoneactive"],
      phoneNumber: rawUser["phonenumber"],
      mfaEnabled: rawUser['mfaenabled'],
      preferredLanguage: getUILanguage(rawUser["preferredlanguage"]),
      presignedurl: rawUser['presignedurl'],
      profilePicture: rawUser['profilepicture'],
      provider: rawUser['provider']
    };

    this.updateUser(user);

    this.ngRedux.dispatch({ type: UPDATE_USER_BRAND, brand: rawUser["brandname"]});
    return user;
  }

  getUserBrand() {
    return this.ngRedux.getState().userBrand;
  }

  getUserView() {
    return this.ngRedux.getState().userView;
  }

  getUserModalView() {
    return this.ngRedux.getState().userModalView;
  }

  getUserHome() {
    return this.ngRedux.getState().userSelectedHome;
  }
}
