import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CanDeactivateGuard } from './guards/deactivate/CanDeactivateGuard';
import { LoginComponent } from './public/auth/login/login.component';
import { HomeComponent } from './public/home/home.component';
import { LandingPageComponent } from './public/home/landing-page/landing-page.component';
import { RegisterComponent } from './public/register/registration.component';
import { HomeGuard } from './guards/home.guard';
import { SecureHomeComponent } from './secure/landing/securehome.component';
import { ForgotPasswordComponent } from './public/auth/forgot-password/forgot-password.component';
import { LoginGuard } from './guards/login.guard';
import { HomeHistoryComponent } from './secure/home/home-history/home-history.component';
import { LockHistoryComponent } from './secure/locks/lock-history/lock-history.component';
import { LockBaseComponent } from './secure/locks/lock-base/lock-base.component';
import { LockDetailsComponent } from './secure/locks/lock-details/lock-details.component';
import { ViewSharedUsersComponent } from './secure/home/view-shared-users/view-shared-users.component';
import { ProfileComponent } from './secure/profile/profile.component';
import { EditProfileComponent } from './secure/profile/edit-profile/edit-profile.component';
import { EditPhoneVerificationComponent } from './secure/profile/edit-profile/edit-phone/edit-phone-verification.component';
import { ChangeSecurityQuestionsComponent } from './secure/profile/edit-profile/change-security-questions/change-security-questions.component';
import { ToggleEnableDisableAccountComponent } from './secure/profile/toggle-enable-disable-account/toggle-enable-disable-account.component';
import { DeleteAccountComponent } from './secure/profile/delete-account/delete-account.component';
import { ChangePasswordComponent } from './public/auth/change-password/change-password.component';
import { RedirectFederationAuthComponent } from './public/auth/redirect-federation-auth/redirect-federation-auth.component';
import { ScanWebUrlComponent } from './public/scanweburl/scan-web-url.component';
// import { ScanWebUrlComponent } from './public/scanweburl/scan-web-url.component';

const shareHome: Routes = [
  {
    path: "viewSharedUsers/:homeID",
    component: ViewSharedUsersComponent,
    canActivate: [LoginGuard],
    children: [],
  },
];

const homeHistory: Routes = [
  {
    path: "homeHistory/:homeID",
    component: HomeHistoryComponent,
    canActivate: [LoginGuard],
    children: [],
  },
];

const locks: Routes = [
  {
    path: "lock",
    component: LockBaseComponent,
    canActivate: [LoginGuard],
    children: [
      { path: "details/:lockID", component: LockDetailsComponent },
      { path: "history/:lockID", component: LockHistoryComponent },
    ],
  },
];

const homeRoutes: Routes = [
  {
    path: "",
    redirectTo: "home",
    pathMatch: "full",
  },
  {
    path: "home",
    component: HomeComponent,
    children: [
      {
        path: "",
        component: LandingPageComponent,
      },
      {
        path: "login",
        component: LoginComponent,
        canDeactivate: [CanDeactivateGuard],
      },
      {
        path: "register",
        component: RegisterComponent,
        canActivate: [HomeGuard],
        canDeactivate: [CanDeactivateGuard],
      },
    ],
  },
];

const profile: Routes = [
  {
    path: "profile",
    component: ProfileComponent,
    canActivate: [LoginGuard],
    children: [
      {
        path: "",
        component: ProfileComponent,
        canActivate: [LoginGuard],
      },
      {
        path: "edit",
        component: EditProfileComponent,
        canActivate: [LoginGuard],
        canDeactivate: [CanDeactivateGuard],
        children: [
          {
            path: "editPhone",
            component: EditPhoneVerificationComponent,
            canActivate: [LoginGuard],
            canDeactivate: [CanDeactivateGuard]
          },
        ],
      },
      {
        path: "changeSecurityQuestions",
        component: ChangeSecurityQuestionsComponent,
        canActivate: [LoginGuard],
      },
      {
        path: "toggleEnableDisableAccount",
        component: ToggleEnableDisableAccountComponent,
        canActivate: [LoginGuard],
      },
      {
        path: "deleteAccount",
        component: DeleteAccountComponent,
        canActivate: [LoginGuard],
      },
      {
        path: "changePassword",
        component: ChangePasswordComponent,
        canActivate: [LoginGuard],
        canDeactivate: [CanDeactivateGuard]
      },
    ],
  },
];

const secureHomeRoutes: Routes = [
  {
    path: "securehome",
    component: SecureHomeComponent,
    canActivate: [LoginGuard],
    children: [],
  },
];

const scanweburl: Routes = [
  {
    path: "scanweburl",
    component: ScanWebUrlComponent,
    children: [],
  },
];

/* const kwiksetApp: Routes = [
  {
    path: "kwiksetApp",
    component: CommingSoonComponent,
    // canActivate: [LoginGuard],
    children: [],
  },
]; */

/* const weiserApp: Routes = [
  {
    path: "weiserApp",
    component: CommingSoonComponent,
    // canActivate: [LoginGuard],
    children: [],
  },
]; */

const forgotPassword: Routes = [
  {
    path: "forgotPassword",
    component: ForgotPasswordComponent,
    canDeactivate: [CanDeactivateGuard],
  },
];




/* const unwantedPage: Routes = [
  {
    path: "404",
    component: UnwantedPageComponent,
  },
]; */

const RedirectGooglePage: Routes = [
  {
    path: "redirect",
    component: RedirectFederationAuthComponent
  },
];

const routes: Routes = [
  {
    path: "",
    children: [
      ...scanweburl,
      ...homeRoutes,
      ...secureHomeRoutes,
      ...profile,
      ...forgotPassword,
      ...shareHome,
      ...locks,
      ...homeHistory,
      // ...unwantedPage,
      // ...kwiksetApp,
      // ...weiserApp,
      ...RedirectGooglePage,
      {
        path: "",
        component: HomeComponent,
      }
    ],
  },
  {
    path: "**",
    redirectTo: "404",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
