<div *ngIf="formname" class="row" [formGroup]="formname">
  <div class="col-xs-5 col-sm-3 col-md-4 col-lg-4 col-xl-3 pl-0">
    <div class="form-group has-feedback">
      <mat-form-field appearance="fill" class="region-width">
        <mat-label>{{'PHONE_FIELD.REGION_TEXT' | translate}}</mat-label>
        <mat-select tabIndex="14" name="selectedCountryCode" [(ngModel)]="selectedCountryCode" (selectionChange)="onChangeCountry()"
          [ngModelOptions]="{standalone: true}">
          <mat-select-trigger>
            <img width="20" src="{{selectedCountry?.flag}}" />
            <span class="dialcode-text">{{selectedCountry?.dialCode}}</span>
          </mat-select-trigger>
          <mat-option *ngFor="let r of countryList" [value]="r.countryCode">
            <img width="20" src="{{r?.flag}}" /> {{r?.countryName}} <span class="dropdown-text-color">{{r?.dialCode}}</span>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="col-xs-7 col-sm-9 col-md-8 col-lg-8 col-xl-9 no-padding pr-0">
    <div class="form-group has-feedback">
      <div [ngClass]="{'has-error' : (!validPhoneNumber || formname.get('phone').invalid) && formname.get('phone').untouched, 'has-success' : formname.get('phone').valid}">
        <mat-form-field hideRequiredMarker class="full-width" appearance="fill">
          <mat-label>{{'PHONE_FIELD.MOBILE_TEXT' | translate}}</mat-label> 
          <input tabIndex="15" matInput type="tel"  #phone id="phone"  (keypress)="isNumberKey($event)"
            [value]="formname.get('phone').value | phoneNumberFormat : selectedCountryCode" formControlName="phone"
            [maxlength]="maxlength" (ngModelChange)="hasError()" (keydown)="onInput($event)" />
        </mat-form-field>
      </div>
    </div>
  </div>
</div>
<p *ngIf="(formname.get('phone').invalid || validPhoneNumber) && (formname.controls['phone'].dirty)"
  class="help-block">{{
  this.invalidNumberMessage | 
  translate}}
</p>