<div class="modal-header">
  <button type="button" class="close" (click)="closeDialog()">
    <mat-icon>clear</mat-icon>
  </button>
</div>
<div class="modal-body">
  <div class="options-modal">
    <div class="col-md-12 col-sm-12 col-xs-12">
      <div class="col-xs-12">
        <div class="row">
          <div class="col-md-12">
            <div class="options-modal">
              <h3 class="text-center header-modal">{{ 'PROFILE.PROFILE_PICTURE.TITLE' | translate }}</h3>
              <br />
              <div class="rotate-option">
                <mat-icon (click)="rotateLeft()">rotate_left</mat-icon>
              </div>
              <div class="row">
                <div style="height: 250px; width: 100%;" class="col-sm-12">
                  <image-cropper [imageChangedEvent]="imageChangedEvent"  [canvasRotation]="canvasRotation"
                  [transform]="transform" width="100%" height="100%" crop="true"
                    [maintainAspectRatio]="true" [cropperMinWidth]="200" [cropperMinHeight]="200" [aspectRatio]="1 / 1"
                    scale="1" (imageCropped)="imageCropped($event)" class="img-center img-size"></image-cropper>
                  <!-- <img  [src]="imageSrc" class="upload-img" > -->
                </div>

              </div>

              <div class="row">
                <div class="col-sm-12">
                  <button (click)="confirmUpload()" class="options-modal-btn btn-block btn-filled btn-filter-box">
                    {{ 'PROFILE.PROFILE_PICTURE.UPLOAD_BUTTON' | translate }}
                  </button>
                  <button (click)="closeDialog()" class="options-modal-btn btn-block btn-empty">
                    {{ 'PROFILE.PROFILE_PICTURE.CANCEL_BUTTON' | translate }}
                  </button>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>