import { AfterViewInit, Component, OnInit, Renderer2 } from "@angular/core";
import { FormBuilder, FormGroup, FormControl, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { ResponseHandlerService } from "../../../services/utils/response-handler.service";
import { Router, NavigationEnd } from "@angular/router";
import { UserProfileService } from "../../../services/user-profile.service";
import { CommonService } from "../../../services/common.service";
import { Callback } from "../../../services/utils/cognito.service";
import { IConfiguration, IUser } from "../../../services/utils/state-management/types";
import { UserManagement } from "../../../services/utils/state-management/action-classes";
/* import { DialogService } from "ng6-bootstrap-modal"; */
import { isNumberKey, Utility } from "../../../services/utils/utility";
import { AsYouType } from 'libphonenumber-js';
const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();
import { DeleteVerificationModalComponent } from '../delete-verification-modal/delete-verification-modal.component';
import { VerificationCodeModalComponent } from '../../../public/auth/verification-code-modal/verification-code-modal.component';
import { HomeService } from "../../../services/home.service";
import { LockService } from "../../../services/lock.service";
import { UPDATE_USER_OBJECT } from "../../../services/utils/state-management/actions";
import { NgRedux } from "@angular-redux/store";
import { IAppState } from "../../../services/utils/state-management/store";
import { ImageCropDialogComponent } from "../image-crop-dialog/image-crop-dialog.component";
import { PhoneNumberFormatPipe } from "../../../services/utils/phoneNumberFormat.pipe";
import { environment } from "../../../../environments/environment";
import { LanguageChangeCallback, LanguageChangeService } from "../../../services/language-change.service";
import { ComponentCanDeactivate } from "src/app/guards/deactivate/ComponentCanDeactivate";
import { Config } from "src/app/services/utils/Config";
import { ApiRequestService } from "src/app/services/api-client/api-requests.service";
import { Constants } from "src/app/services/utils/Constants";
import apiConfig from "src/app/services/api-client/api-config";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";

@Component({
  selector: "app-edit-profile",
  templateUrl: "./edit-profile.component.html",
  styleUrls: ["./edit-profile.component.css", "../../../../assets/css/mobile-region-field.css"]
})
export class EditProfileComponent extends ComponentCanDeactivate implements OnInit, Callback, LanguageChangeCallback {

  preSignedData: Object;
  fileToUpload: File | null = null;
  errorMessage: string;
  successMessage: string;
  phone: boolean;
  userValidation: FormGroup;
  userObject: IUser;
  isNumberKey = isNumberKey;
  initialFlag: boolean = true;
  countryCodesObj: any;
  countriesList: any;
  invalidNumberMessage: string;
  validPhoneNumber: boolean = false;
  phoneInput: any;
  iti: any;
  initialCountryCode: string;
  dialCode: string;
  selectedCountryCode: any;
  maxlength: any;
  asYouType = new AsYouType();
  CODE_TYPES: Object
  MEDIUM_TYPES: Object
  accountEnabled = false;
  isEditPhone: Boolean;
  activateBreadCrumb: Boolean = false;
  imageSrc: any;
  profilePicture = "";
  user: any;
  isImageType = false;
  isImageSize = false;
  imgResultBeforeCompress: string;
  imgResultAfterCompress: string;
  blobObject: any;
  compressedFileObject: Object;
  imageSizeInMb: any = Config.USER.IMAGE_SIZE;
  imageSizeInBytes: any = Config.USER.IMAGE_SIZE * 1024 * 1024; // converting into bytes
  allowedFormats: any = Config.USER.ALLOWED_FORMATS;
  changed = false;
  onSaveButton: boolean = false;
  configuration: IConfiguration;

  constructor(
    public formBuilder: FormBuilder,
    public translate: TranslateService,
    public responseHandler: ResponseHandlerService,
    public router: Router,
    public ngRedux: NgRedux<IAppState>,
    public userProfileService: UserProfileService,
    public userManagement: UserManagement,
    private phoneNumberFormatPipe: PhoneNumberFormatPipe,
    private utility: Utility,
    private modalService: BsModalService,
    private renderer: Renderer2,
    private homeService: HomeService,
    private lockService: LockService,
    private commonService: CommonService,
    private apiClient: ApiRequestService,
    private languageService: LanguageChangeService,
  ) {
    super();
    this.CODE_TYPES = { DELETE: 'deletion' };
    this.MEDIUM_TYPES = { PHONE: 'phone', EMAIL: "email" };
  }

  ngOnInit() {
    this.configuration = JSON.parse(localStorage.getItem('config'));
    console.log(this.configuration);
    // removing this check added for alert popup modal activation, reseting the state here
    localStorage.removeItem('phoneUpdated');
    this.activateBreadCrumb = false;
    this.commonService.getPresignedUrl(this);
    this.isEditPhone = window && window.location.href.endsWith('editPhone') ? true : false;
    Utility.focusLogo();
    this.router.events.subscribe(
      (event) => {
        if (event instanceof NavigationEnd) {
          let url = event.url;
          if (url.toString().indexOf("editPhone") > 0) {
            this.phone = true;
          } else {
            this.phone = false;
          }
        }
      });
    this.userValidation = this.formBuilder.group({
      firstName: [null, [Validators.required,Validators.minLength(2), this.noWhitespaceValidator]],
      lastName: [null, [Validators.required,Validators.minLength(2), this.noWhitespaceValidator]],
      email: [null, [Validators.email]],
      phone: ['',Validators.required],
      mfaEnabled: [],
      accountEnabled: []

    });
    this.updateForm();
  }

  canDeactivate() {
    // this is added because of child component exiting parent component, and checks for alert popup state here
    let phoneUpdated = localStorage.getItem('phoneUpdate');
    if (this.userValidation.value.phone == '') {
      this.userValidation.markAsDirty();
    }
    // HALOC-3017-
    // logout case, the form change was not being detected and was logging out the user, 
    // Now, we check if the user is not present in localstorage, then we simply logout the user and show no Prompt Box
    let email = localStorage.getItem(`CognitoIdentityServiceProvider.${environment.clientId}.LastAuthUser`);

    if (!email || this.userValidation.pristine || this.onSaveButton || phoneUpdated) {
      return !this.changed
    } else {
      return this.changed;
    }
  }

  noWhitespaceValidator(control: FormControl) {
    const value = new String(control.value);
    const valueLength = value.length;
    const trimLenght = (control.value || '').replace(/\s+/g, '').length;
    var isValid;
    var isWhitespace = (control.value || '').trim().length === 0;
    var isEqualChar = valueLength === trimLenght ? true : false;
    if (trimLenght >= 2 && trimLenght <= 30) {
      isValid = !isWhitespace;
    } else {
      isValid = !isWhitespace && isEqualChar;
    }
    return isValid ? null : { 'whitespace': true };
  }

  getUser() {

    this.apiClient
      .callApi(Constants.API_METHODS.GET, apiConfig.me.uri, null)
      .subscribe({
        next: (res) => {
          if (res && res.data && res.data.length > 0) {
            const profilepicture = {};
            this.user = this.userManagement.parseUserObjectAndDispatch(res.data[0]);
            profilepicture["presignedurl"] = res.data[0].presignedurl;
            this.userProfileService.saveData(profilepicture, this);
            this.updateForm();
          }
        },
        error: (err) => {
          let data = this.responseHandler.parseErrorResponseV2(err);
          return data;
        },
      });
  }

  public selectedCountry(event) {
    this.dialCode = event.dialCode;
    this.maxlength = event.phoneMaxLength;
    this.selectedCountryCode = event.selectedCountryCode
  }

  goToAccountSetting() {
    this.router.navigate(["/profile/edit"]);
    this.ngOnInit()
  }

  updateForm() {
    this.userObject = this.userManagement.getUser();
    if (this.userObject) {
      this.userValidation.controls["firstName"].setValue(
        this.userObject.firstName
      );
      this.userValidation.controls["lastName"].setValue(
        this.userObject.lastName
      );
      this.userValidation.controls["email"].setValue(
        this.userObject.email
      );
      this.userValidation.controls["mfaEnabled"].setValue(
        this.userObject.mfaEnabled
      );
      this.userValidation.controls['accountEnabled'].setValue(
        !this.userObject.accountEnabled
      );

      if (this.userObject.presignedurl) {
        this.imageSrc = this.userObject.presignedurl
      }
      if (this.userObject.phoneNumber) {
        const phone = phoneUtil.parseAndKeepRawInput(this.userObject.phoneNumber);
        const dialCode = '+' + phone.getCountryCode();
        this.selectedCountryCode = phoneUtil.getRegionCodeForNumber(phone);
        let inputNumber = this.userObject.phoneNumber.substring(dialCode.length);
        this.userValidation.get('phone').setValue(this.phoneNumberFormatPipe.transform(inputNumber, this.selectedCountryCode));
      } else {
        this.userValidation.get('phone').setValue('');
      }
    }
  }

  onToggle(event) {
    const body = {
      mfaEnabled: event.checked
    };

    this.apiClient.callApi(Constants.API_METHODS.PATCH, apiConfig.updateUser.uri, body).subscribe({
      next: (res) => {
        this.userObject.mfaEnabled = event.checked;
        this.ngRedux.dispatch({ type: UPDATE_USER_OBJECT, userObject: this.userObject });
      },
      error: (err) => {
        let error = this.responseHandler.parseErrorResponseV2(err);
        return error;
      }
    });

    /* this.apiClient.makeCall(null, 'patch', apiConfig.updateUser, body, (err, res) => {
      this.userObject.mfaEnabled = event.checked;
      this.ngRedux.dispatch({ type: UPDATE_USER_OBJECT, userObject: this.userObject });
    }) */
  }


  onSave() {
    // HALOC-3078: Setting User Preferred Language After Login Success
    this.errorMessage = '';
    this.successMessage = '';
    const data = {};
    let phone = this.userValidation.get("phone").value ? this.userValidation.get("phone").value : "";
    phone = this.dialCode + phone.replace(/[^0-9]*/g, "");
    let user = this.userManagement.getUser();
    let body = {
      enabled: (!this.userValidation.get('accountEnabled').value).toString()
    }
    if (this.userValidation.invalid) {
      return;
    }
    if (
      this.userValidation.get("firstName").value &&
      this.userObject.firstName !== this.userValidation.get("firstName").value.trim()
    ) {
      data["firstname"] = this.userValidation.get("firstName").value.trim();
    }
    if (
      this.userValidation.get("lastName").value &&
      this.userObject.lastName !== this.userValidation.get("lastName").value.trim()
    ) {
      data["lastname"] = this.userValidation.get("lastName").value.trim();
    }

    if (this.profilePicture) {
      data["profilePicture"] = this.profilePicture;
    }

    if (
      !this.userValidation.get("phone").value ||
      this.userObject.phoneNumber === phone
    ) {
      this.onSaveButton = true;
      this.userProfileService.saveData(data, this);
      this.userProfileService.update();

    } else {
      // if (this.validPhoneNumber) {
      data["phonenumber"] = phone;
      data["dialcode"] = this.dialCode;
      data["countrycode"] = this.selectedCountryCode;
      this.userProfileService.saveData(data, this);
      this.initialFlag = true;
      this.phone = true;
      this.activateBreadCrumb = true;
      this.router.navigate(["/profile/edit/editPhone"]);
      // }
    }


    this.apiClient.callApi(Constants.API_METHODS.PATCH, apiConfig.toggleEnableDisableAccount.uri, body).subscribe({
      next: (res) => {
        if (res) {
          user.accountEnabled = !this.userValidation.get('accountEnabled').value;
          this.userManagement.updateUser(user);
        }
      },
      error: (err) => {
        let error = this.responseHandler.parseErrorResponseV2(err);
        return error;
      }
    })
  }

  callback() {

    this.apiClient
      .callApi(Constants.API_METHODS.GET, apiConfig.me.uri, null)
      .subscribe({
        next: (res) => {
          if (res && res.data && res.data.length > 0) {
            const profilepicture = {};
            this.user = this.userManagement.parseUserObjectAndDispatch(res.data[0]);
            profilepicture["presignedurl"] = res.data[0].presignedurl;
            this.userProfileService.saveData(profilepicture, this);
            this.successMessage = this.translate.instant("PROFILE.EDIT.POPUP.SUCCESS_MESSAGE");
            this.utility.addSuccessMessage(this.successMessage);
            this.router.navigate(['/securehome']);
          }
        },
        error: (err) => {
          let data = this.responseHandler.parseErrorResponseV2(err);
          return data;
        },
      });

  }

  callbackWithParam(messages: any) {
    this.successMessage = '';
    messages = this.getErrorMessage("PROFILE.EDIT.ERROR", messages);
    let errMessg = "";
    this.errorMessage = "";
    for (errMessg of messages) {
      this.errorMessage += errMessg + ", ";
    }
    this.errorMessage = this.errorMessage.substring(
      0,
      this.errorMessage.length - 2
    );
    if (this.errorMessage.slice(-1) !== ".") {
      this.errorMessage += ".";
    }
  }

  getErrorMessage(componentMessagePath: string, parsedResponse: any) {
    let msg = [];

    if (parsedResponse && parsedResponse.data) {
      const error = parsedResponse.data;
      let dataElement;

      if (error !== null && error.length > 0) {
        for (dataElement of error) {
          if (dataElement) {
            msg.push(
              this.translate.get(
                componentMessagePath +
                "." +
                dataElement.code.toUpperCase() +
                "." +
                dataElement.target.toUpperCase()
              )["value"]
            );
          }
        }
      }
    } else {
      msg.push(this.translate.instant(componentMessagePath + ".COMMON"));
    }
    return msg;
  }

  updateCallback() {
    this.phone = false;
    this.callback();

  }

  // Dialog for Delete confirmation
  confirmDeleteAccountVerification() {
    const homes = this.homeService.getHomesCounter();
    const locks = this.lockService.getLocksCounter();

    const modalOpts = { ...Constants.MODAL_CONFIG, initialState: { homes, locks }, class: 'delete-verification' };

    const modalRef: BsModalRef = this.modalService.show(DeleteVerificationModalComponent, modalOpts);
    setTimeout(() => {
      this.renderer.addClass(document.body, 'modal-open');
    }, 100);

    modalRef.content.outputEvent.subscribe(res => {
      if (res) {
        let data = {
          email: this.userObject.email,
          phoneNumber: this.userObject.phoneNumber,
          medium: Config.MEDIUM_TYPES.PHONE,
          codeType: Config.CODE_TYPES.DELETE,
          initialState: true,
          errorMsg: '',
          successMsg: '',
          continueDisabled: false,
          isPhone: true,
          isEmail: false
        }
        setTimeout(() => {
          this.generateCodeAndVerify(data);
        }, 100);
      } else {
        this.ngOnInit();
      }
    });
  }

  // Dialog to Verify user deletion
  generateCodeAndVerify(data: Object) {

    const modalOpts = { ...Constants.MODAL_CONFIG, initialState: data, class: 'verification-code' };
    const modalRef: BsModalRef = this.modalService.show(VerificationCodeModalComponent, modalOpts);
    modalRef.content.outputEvent.subscribe(res => {
      if (res && res.delete) {
        let medium = res['medium'];
        let code = res['code'];
        let params = { medium, code };
      }
    });


  }


  fileInput($event) {
    this.fileToUpload = $event.target.files;
    const file = this.fileToUpload[0];
    let imageType = file.name.split('.')[1];

    if (!this.utility.isFileInAllowedFormats(imageType.toLowerCase(), this.allowedFormats)) {
      this.isImageType = true;
      $event.target.value = '';
      return;
    } else if (!this.utility.isFileSizeAllowed(file.size, this.imageSizeInBytes)) {
      this.isImageSize = true;
      $event.target.value = '';
      return;
    } else {
      this.isImageType = false;
      this.isImageSize = false;
    }

    // Crop Image Dialog Open Here
    /*  this.dialogService.addDialog(ImageCropDialogComponent, { imageChangedEvent: $event },
       { closeByClickingOutside: false }).subscribe((result) => {
         if (result.success) {
           this.utility.compressFile(result.file, file.name).then(res => {
             const { compressedFile, imageSrc } = res;
             this.imageSrc = imageSrc;
             this.compressedFileObject = compressedFile;
             this.upload();
             $event.target.value = '';
           });
         }
         else {
           $event.target.value = '';
         }
       }); */

    const modalOpts = { ...Constants.MODAL_CONFIG, initialState: { imageChangedEvent: $event }, class: 'image-crop' };
    const modalRef: BsModalRef = this.modalService.show(ImageCropDialogComponent, modalOpts);
    modalRef.content.outputEvent.subscribe(result => {
      if (result.file != null ) {
        this.utility.compressFile(result.file, file.name).then(res => {
          const { compressedFile, imageSrc } = res;
          this.imageSrc = imageSrc;
          this.compressedFileObject = compressedFile;
          this.upload();
          $event.target.value = '';
        });
      }
      else {
        $event.target.value = '';
      }
    });
  }

  upload() {
    this.profilePicture = this.preSignedData['imagename'];
    this.userProfileService.uploadFileToPreSignedURL(this.preSignedData, this.compressedFileObject);
  }

  preSignedCallBack(data) {
    this.preSignedData = data;
  }


  isNotNullOrEmpty(value) {
    if (value == '' || value == null) {
      return true;
    } else {
      return false
    }
  }

  onSuccessSendCode() {
  }

  onFailure() {
    return;
  }


}
