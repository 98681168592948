<div class="main">
  <div class="sp-middle-element">
    <div class="col-xs-12 sm-no-padding">
      <div class="col-xs-12 sm-no-padding">
        <div class="row sm-no-margin">
          <div class="col-xs-12 sp-back">
            <a [routerLink]="'/securehome'">  <i class="fa fa-chevron-left"></i> {{ 'PROFILE.BACK' | translate }}</a>
          </div>
        </div>
        <div class="row sm-no-margin">
          <div class="col-sm-2 col-md-2 sm-no-padding">
          </div>
          <div class="col-xs-12 col-sm-8 col-md-8 sm-no-padding">
            <router-outlet></router-outlet>
          </div>
          <div class="col-sm-2 col-md-2 sm-no-padding">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>