import { Component, EventEmitter, OnInit, ViewEncapsulation } from '@angular/core';
import { Utility } from '../../../services/utils/utility';
import { ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { BsModalRef } from 'ngx-bootstrap/modal';

export interface ImageCropDialog {
  imageChangedEvent: any
}

@Component({
  selector: 'app-image-crop-dialog',
  templateUrl: './image-crop-dialog.component.html',
  styleUrls: ['./image-crop-dialog.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ImageCropDialogComponent implements ImageCropDialog, OnInit {

  imageChangedEvent: any;
  croppedImage: any;
  imageSrc;
  canvasRotation = 0;
  rotation = 0;
  transform: ImageTransform = {};

  outputEvent: EventEmitter<any> = new EventEmitter();

  constructor(
    private utility: Utility, public bsModalRef: BsModalRef) {

  }

  ngOnInit(): void {
    console.log(this.imageChangedEvent);
    const imgFiles = this.imageChangedEvent.target.files;
    var reader = this.utility.getFileReader(imgFiles);
    reader.onload = (_event) => {
      this.imageSrc = `${reader.result}`; 
    }

   }


  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    console.log(this.croppedImage);
  }

  rotateLeft() {
    // this.imageCropper.rotateLeft();
    this.canvasRotation--;
    this.flipAfterRotate();

}

  closeDialog(): void {
      this.imageChangedEvent = null;
      this.croppedImage = null;
      this.outputEvent.emit({file: this.croppedImage});
      this.bsModalRef.hide();
  }

  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
        ...this.transform,
        flipH: flippedV,
        flipV: flippedH
    };
}

  confirmUpload(): void{
    this.outputEvent.emit({file: this.croppedImage});
    this.bsModalRef.hide();
  }
}
