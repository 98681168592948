<div>
  <div [ngStyle]="{'height': this.user && this.accountEnabled ? '520px' : '450px' }">
    <div class="modal-header">
      <button type="button" class="close close-btn" (click)="close()"><mat-icon>clear</mat-icon></button>
    </div>
    <div class="modal-body">
      <div class="options-modal">
        <div class="col-md-12 col-sm-12 col-xs-12">
          <div class="col-xs-12">
            <div class="row">
              <div class="col-md-12">
                <div class="options-modal">
                  <h3 class="text-center header-modal">
                  <span class="back-icon-mobile" (click)="close()">
                    <i class="fa fa-chevron-left"></i>
                  </span>
                 <p class="spacing-header">
                  {{ 'PROFILE.DELETE_ACCOUNT.WARNING_DELETE_MSG' | translate }}
                 </p> 
                </h3>
                  <p class="text-center selection-header">{{ ('PROFILE.DELETE_ACCOUNT.WARNING_DELETE_MSG_IMPACT' | translate).replace('[brandName]', brandName)}}</p>
                  <div class="form-group has-feedback center-flex">
                    <div class="center-div">
                    <div class="row row-item">
                      <div class="block col-item">
                        <img width="27" height="27" src="../../../../assets/images/approve-icon.png" />
                      </div>
                      <div class="block">{{ 'PROFILE.DELETE_ACCOUNT.PERMANENT_DELETE' | translate }}</div>
                    </div>
                    <div class="row row-item">
                      <div class="block col-item"><img width="27" height="27"
                          src="../../../../assets/images/approve-icon.png" /></div>
                      <div class="block">{{ ('PROFILE.DELETE_ACCOUNT.LOOSE_ACCESS_HOMES' | translate).replace('[homes]', homes)}}</div>
                    </div>
                    <div class="row row-item">
                      <div class="block col-item"><img width="27" height="27"
                          src="../../../../assets/images/approve-icon.png" /></div>
                      <div class="block"> {{ ('PROFILE.DELETE_ACCOUNT.LOOSE_ACCESS_LOCKS' | translate).replace('[locks]', locks)}}</div>
                    </div>
                  </div>
                  </div>
                  <div *ngIf="this.user && this.accountEnabled" class="row disable-text">
                    {{ 'PROFILE.DELETE_ACCOUNT.DISABLE_PHONE_ACCESS' | translate }} <span (click)="onTogglePhoneAccess({checked: true})" style="font-weight: 800; cursor: pointer;   text-decoration: underline;"> {{ 'PROFILE.DELETE_ACCOUNT.WARNING_MESSAGE.DISABLE_LINK' | translate }} </span>
                  </div>
                  <div class="form-group has-feedback">
                    <button (click)="confirmDelete()" class="options-modal-btn btn-block btn-filled btn-filter-box">
                      {{ 'PROFILE.DELETE_ACCOUNT.DELETE_ACCOUNT_CONFIRM' | translate }}
                    </button>
                    <button (click)="close()" class="options-modal-btn btn-block btn-empty">
                      {{ 'PROFILE.DELETE_ACCOUNT.CANCEL_BUTTON' | translate }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>