import { Component, EventEmitter, ViewEncapsulation } from '@angular/core';
// import { DialogComponent, DialogService } from "ng6-bootstrap-modal";
import { Router } from "@angular/router";
import { TResetModal } from '../../../services/utils/state-management/types';
import { RESET_MODAL_SOFT, RESET_MODAL_HARD } from '../../../services/utils/state-management/actions';
import { TranslateService } from '@ngx-translate/core';
import { LanguageChangeService } from '../../../services/language-change.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DateTimeAdapter } from '@danielmoncada/angular-datetime-picker';
import { Config } from 'src/app/services/utils/Config';

export interface LockHistoryOptionsModal {
  lockID: string
  activities: Object
  sources: Object,
  filters: Object
}
@Component({
  selector: 'app-lock-history-filter-modal',
  templateUrl: './lock-history-filter-modal.component.html',
  styleUrls: ['./lock-history-filter-modal.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class LockHistoryFilterModalComponent implements LockHistoryOptionsModal {
  lockID: string;
  currentTab = 0;
  homeLocks = [];
  selectedActivities = [];
  filters;
  activities = [];
  sources = [];
  selectedSources = [];
  modalCss = 'modal-view';
  selectedMoments = [];
  filterTypes = {
    DATE_RANGE: 'daterange',
    USER_TYPE: 'usertype',
    ACTIVITY: 'activity'
  }
  fromDate;
  toDate;
  minDate;
  maxDate;
  errorMsg = "Enter or choose any value before applying the filter"
  errorDisplay = "";
  private resetType: TResetModal = RESET_MODAL_SOFT;
  result: {};
  lastTab: number;
  tabs = {
    RANGE_PICKER: 2,
    USER_TYPE: 3,
    ACTIVITIES: 4
  }

  //Selected Language
  event: EventEmitter<any> = new EventEmitter();
  eventClose: EventEmitter<any> = new EventEmitter();

  langCode: string;
  defaultLangCode: string;
  holdLastActivity = [];
  holdSelectedMoments = [];
  holdSelectedSources = [];

  constructor(public router: Router,
    public bsModalRef: BsModalRef,
    private translate: TranslateService,
    private dateAdapter: DateTimeAdapter<any>,
    private langService: LanguageChangeService) {
    let date = new Date();
    this.toDate = date; //.toISOString().split("T")[0];
    date = new Date(Date.now() - (2 * 30 * 24 * 60 * 60 * 1000));
    this.fromDate = date; ///.toISOString().split("T")[0];
    this.minDate = this.fromDate;
    this.maxDate = this.toDate + (12 * 60 * 60 * 1000); // add 12 hours 
  }

  ngOnInit() {
    if (this.langService.getCurrentLanguage() === Config.LANGUAGES.FRENCH) {
      this.dateAdapter.setLocale(Config.LANGUAGES.FRENCH);
    } else {
      this.dateAdapter.setLocale(Config.LANGUAGES.ENGLISH);
    }
    this.langCode = this.langService.getCurrentLanguage();
    this.defaultLangCode = this.langService.getDefaultLanguage();

    document.getElementsByTagName('body')[0].style.overflow = 'hidden'
    this.homeLocks = null;
    this.selectedSources = this.filters.selectedSource ? this.filters.selectedSource : [];
    this.selectedActivities = this.filters.selectedActivities ? this.filters.selectedActivities : [];
    this.selectedMoments = [this.filters.fromDate, this.filters.toDate];

    if (this.selectedSources.length || this.selectedActivities.length || (this.filters.fromDate && this.filters.toDate)) {
      this.resetType = RESET_MODAL_HARD;
    }
  }

  private preSelectedValue(IsbackButton: boolean, activeTabId: number) {
    switch (activeTabId) {
      case 2:
        if (IsbackButton)
          this.selectedMoments = [...this.holdSelectedMoments];
        else
          this.holdSelectedMoments = [...this.selectedMoments];
        break;
      case 3:
        if (IsbackButton)
          this.selectedSources = [...this.holdSelectedSources];
        else
          this.holdSelectedSources = [...this.selectedSources];
        break;
      case 4:
        if (IsbackButton)
          this.selectedActivities = [...this.holdLastActivity];
        else
          this.holdLastActivity = [...this.selectedActivities];
        break;

    }

  }
  changeTab(tabId, filterApplied, IsbackButton = false, activeTabId = 1) {
    this.errorDisplay = "";
    this.preSelectedValue(IsbackButton, tabId); // initial state hold of lock, date picker, usertype, activities
    if (tabId === this.tabs.RANGE_PICKER) {
      this.lastTab = this.tabs.RANGE_PICKER;
      this.modalCss = 'modal-view-date-picker';
    } else if (tabId === this.tabs.USER_TYPE) {
      this.lastTab = this.tabs.USER_TYPE;
      this.modalCss = 'modal-view-input'
    } else if (tabId === this.tabs.ACTIVITIES) {
      this.lastTab = this.tabs.ACTIVITIES;
      this.modalCss = 'modal-view-list'
    } else {
      if (!filterApplied) {
        this.emptyFilters();
      }
      this.modalCss = 'modal-view';
      // hold value for back button click resign to values
      this.preSelectedValue(IsbackButton, activeTabId);
    }
    this.currentTab = tabId
  }

  emptyFilters() {
    if (this.lastTab === this.tabs.RANGE_PICKER) {
      this.selectedMoments = [];
    } else if (this.lastTab === this.tabs.USER_TYPE) {
      this.selectedSources = [];
    } else if (this.lastTab === this.tabs.ACTIVITIES) {
      this.selectedActivities = [];
    }
  }


  addActivityFilter(activity) {
    this.errorDisplay = "";
    let index = this.selectedActivities.findIndex(name => activity[this.defaultLangCode] === name[this.defaultLangCode]);
    if (index > -1) {
      this.selectedActivities.splice(index, 1);
    } else {
      this.selectedActivities.push(activity);
    }
  }

  addSourceFilter(event) {
    this.errorDisplay = "";
    if (event.target.value) {
      this.selectedSources = [event.target.value];
    } else {
      this.selectedSources = [];
    }
  }

  closeDialogue(selecteTab) {

    if (selecteTab === this.tabs.ACTIVITIES || selecteTab === this.tabs.USER_TYPE || selecteTab === this.tabs.RANGE_PICKER) {
      this.preSelectedValue(true, selecteTab);
    }
    let setFilter = false;
    if (this.selectedSources.length || this.selectedActivities.length) {
      setFilter = true;
    }
    const [fromDate, toDate] = this.selectedMoments;
    this.result = { setFilter, selectedSource: this.selectedSources, selectedActivities: this.selectedActivities, fromDate, toDate }
    this.eventClose.emit(this.result);
    this.bsModalRef.hide();
    document.getElementsByTagName('body')[0].style.overflowY = 'scroll'
  }


  closeDialog() {
    let setFilter = false;
    if (this.selectedSources.length || this.selectedActivities.length) {
      setFilter = true;
    }
    const [fromDate, toDate] = this.selectedMoments;
    this.result = { setFilter, selectedSource: this.selectedSources, selectedActivities: this.selectedActivities, fromDate, toDate }
    this.event.emit(this.result);
    this.bsModalRef.hide();
    document.getElementsByTagName('body')[0].style.overflowY = 'scroll'
  }

  resetFilterAndCloseDialog() {
    this.result = { setFilter: false };
    this.event.emit(this.result);
    this.bsModalRef.hide();
    document.getElementsByTagName('body')[0].style.overflowY = 'scroll'
  }

  checkFilterApplied(type) {
    if (type === this.filterTypes.USER_TYPE && this.selectedSources.length) {
      return true;
    } else if (type === this.filterTypes.ACTIVITY && this.selectedActivities.length) {
      return true;
    } else if (type === this.filterTypes.DATE_RANGE) {
      const [fromDate, toDate] = this.selectedMoments;
      if (fromDate && toDate) {
        return true;
      }
    }
    this.errorDisplay = "error-msg-display";
    return false;
  }

  closeModal() {
    document.getElementsByTagName('body')[0].style.overflowY = 'scroll'
    //  this.close();
  }

  isActivityChecked(activity) {
    let isChecked = this.selectedActivities.find(ele => ele[this.defaultLangCode] === activity[this.defaultLangCode]);
    return isChecked;
  }

}
