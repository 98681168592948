<div class="col-md-10 col-md-offset-1 col-lg-8 col-lg-offset-2 col-sm-12 col-xs-12 sp-verf-block main">
  <div *ngIf="errorMessage!=null" class="alert alert-danger alert-dismissible fade in">
    <a class="close" aria-label="close" (click)="errorMessage = null">&times;</a>
    {{ errorMessage }}
  </div>
  <h3 class="heading-sm-center"> {{ 'REGISTRATION.SECURITY_QUESTIONS.TITLE' | translate }} </h3>
  <form class="form-signin" method="POST" action="#" role="form" [formGroup]="securityQuestions" (ngSubmit)="onSubmit()">
    <div class="form-group has-feedback">
      <div [ngClass]="{'has-error' : checkError(1), 'has-success' : checkSuccess(1)}">
        <div>
          <mat-select class="questions col-md-12"
          formControlName="question1" [(ngModel)]="question1" (selectionChange)="onChangeQuestion(1)">
            <mat-option selected hidden disabled value=""> {{ 'REGISTRATION.SECURITY_QUESTIONS.LABEL_QUESTION_1' | translate }} </mat-option>
            <mat-option *ngFor="let question of getQuestionList(0)" value="{{question}}">{{question}}</mat-option>
          </mat-select>

          <mat-form-field hideRequiredMarker class="example-full-width" appearance="fill">
            <mat-label>{{ 'REGISTRATION.SECURITY_QUESTIONS.LABEL_ANSWER_1' | translate }}</mat-label>
            <textarea maxlength="255"  [disabled]='this.securityQuestions.controls["question1"].invalid' formControlName="answer1" class="textarea" rows="2" matInput></textarea>
          </mat-form-field>
        </div>
        <p *ngIf="checkError(1) && this.securityQuestions.controls['answer1'].value.trim().length < 3"
          class="help-block">{{ 'VALIDATION.SECURITY_QUESTIONS' | translate}}</p>
        <p *ngIf="checkSameAnswer(1)" class="help-block">{{ 'VALIDATION.SAME_ANSWER' | translate}}</p>
      </div>
    </div>

    <div class="form-group has-feedback">
      <div [ngClass]="{'has-error' : checkError(2), 'has-success' :  checkSuccess(2)}">
        <div>
          <mat-select class="questions col-md-12" formControlName="question2" [(ngModel)]="question2" (selectionChange)="onChangeQuestion(2)">
            <mat-option selected disabled hidden value=""> {{ 'REGISTRATION.SECURITY_QUESTIONS.LABEL_QUESTION_2' | translate }} </mat-option>
            <mat-option *ngFor="let question of getQuestionList(1)" value="{{question}}">{{question}}</mat-option>
          </mat-select>

          <mat-form-field hideRequiredMarker class="example-full-width" appearance="fill">
            <mat-label>{{ 'REGISTRATION.SECURITY_QUESTIONS.LABEL_ANSWER_2' | translate }}</mat-label>
            <textarea formControlName="answer2" class="textarea" maxlength="255" rows="2" matInput></textarea>
          </mat-form-field>
        </div>
        <p *ngIf="checkError(2) && this.securityQuestions.controls['answer2'].value.trim().length < 3"
          class="help-block">{{ 'VALIDATION.SECURITY_QUESTIONS' | translate}}</p>
        <p *ngIf="checkSameAnswer(2)" class="help-block">{{ 'VALIDATION.SAME_ANSWER' | translate}}</p>
      </div>
    </div>

    <div class="form-group has-feedback">
      <div [ngClass]="{'has-error' : checkError(3), 'has-success' :  checkSuccess(3)}">
        <div>
          <mat-select class="questions col-md-12" formControlName="question3" [(ngModel)]="question3" (selectionChange)="onChangeQuestion(3)">
            <mat-option selected hidden disabled value=""> {{ 'REGISTRATION.SECURITY_QUESTIONS.LABEL_QUESTION_3' | translate }} </mat-option>
            <mat-option *ngFor="let question of getQuestionList(2)" value="{{question}}">{{question}}</mat-option>
          </mat-select>

          <mat-form-field hideRequiredMarker class="example-full-width" appearance="fill">
            <mat-label>{{ 'REGISTRATION.SECURITY_QUESTIONS.LABEL_ANSWER_3' | translate }}</mat-label>
            <textarea formControlName="answer3" class="textarea" maxlength="255" rows="2" matInput></textarea>
          </mat-form-field>
        </div>
        <p *ngIf="checkError(3) && this.securityQuestions.controls['answer3'].value.trim().length < 3"
          class="help-block">{{ 'VALIDATION.SECURITY_QUESTIONS' | translate}}</p>
        <p *ngIf="checkSameAnswer(3)" class="help-block">{{ 'VALIDATION.SAME_ANSWER' | translate}}</p>
      </div>
    </div>

    <div class="col-lg-5 no-padding form-group submit-button">
      <button id="submitQuestions" type="submit" class="btn btn-block sp-btn-dark h-45" [disabled]="!securityQuestions.valid || checkError(1) || checkError(2) || checkError(3)">
        {{ 'REGISTRATION.SECURITY_QUESTIONS.BUTTON_SUBMIT' | translate }}
      </button>
    </div>
  </form>
</div>
