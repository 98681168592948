import { Component, OnInit, Input } from '@angular/core';
import { RegisterComponent } from '../registration.component';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  UserRegistrationService,
  RegistrationCallback,
} from '../../../services/user-registration.service';
import { NgRedux } from '@angular-redux/store';
import { IAppState } from '../../../services/utils/state-management/store';
import { SecurityQuestionsBaseComponent } from '../../base-components/security-questions-base/security-questions-base.component';
import { UserManagement } from '../../../services/utils/state-management/action-classes';
import { ADD_SUCCESS_MESSAGE } from '../../../services/utils/state-management/actions';

@Component({
  selector: 'app-security-questions',
  templateUrl:
    '../../base-components/security-questions-base/security-questions-base.component.html',
  styleUrls: [
    '../../base-components/security-questions-base/security-questions-base.component.css',
  ],
})
export class SecurityQuestionsComponent
  extends SecurityQuestionsBaseComponent
  implements RegistrationCallback, OnInit
{
  override errorMessage: string;
  override securityQuestions: FormGroup;

  override QuestionList = [];

  constructor(
    public override formBuilder: FormBuilder,
    public override translate: TranslateService,
    public override ngRedux: NgRedux<IAppState>,
    private registrationService: UserRegistrationService,
    private userManagementService: UserManagement
  ) {
    super(formBuilder, translate, ngRedux);
  }

  registrationCallback(message: string, result: any): void {
    if (result) {
      this.ngRedux.dispatch({
        type: ADD_SUCCESS_MESSAGE,
        msg: this.translate
          .instant('REGISTRATION.SUCCESS_MESSAGE'),
      });
      this.registrationService.rollOverRegStep();
      this.errorMessage = '';
    } else {
      const msg = this.translate.instant(
        'REGISTRATION.SUMMARY_PASSWORD.ERROR.COMMON'
      );
      this.errorMessage = msg;
    }
  }

  onSubmit() {
    this.registrationService.updateSecurityQuestions(
      this.securityQuestions.get('question1').value,
      this.securityQuestions.get('answer1').value,
      this.securityQuestions.get('question2').value,
      this.securityQuestions.get('answer2').value,
      this.securityQuestions.get('question3').value,
      this.securityQuestions.get('answer3').value
    );
    this.registrationService.register(
      this.userManagementService.getUserObject().password,
      this
    );
  }

  getSubtitle() {
    return this.translate.get('REGISTRATION.SECURITY_QUESTIONS.SUB_TITLE')[
      'value'
    ];
  }
}
