<div class="modal-header">
  <div>
    <button type="button" class="close close-btn" (click)="closeDialog(false)">
      <mat-icon>clear</mat-icon>
    </button>
  </div>
</div>
<div class="modal-body">
  <div class="title">
    <span class="back-icon-mobile" (click)="closeDialog(false)">
      <i class="fa fa-chevron-left"></i>
    </span>
    {{ 'SHARE_HOME.TITLE.INVITATION' | translate }}
  </div>
  <div class="options-modal">
    <div class="col-md-12 col-sm-12 col-xs-12">
      <div class="col-xs-12">
        <div class="row">
          <div class="col-md-12">
            <div class="options-modal">
              <p style="position: relative; top: 10px;" class="text-center selection-header">{{sharedBy}} {{
                (('HOME.SHARE.MSG' | translate).replace('[AccessLevel]', accessRights.toLowerCase() === 'admin' ?
                adminText : guestText )).replace('[Home]', homeName ) }}</p>
              <br />

              <div class="form-group has-feedback">
                <button (click)="closeDialog('accept')" class="options-modal-btn btn-block btn-filled btn-filter-box">
                  {{ 'HOME.SHARE.ACCEPT' | translate }}
                </button>
                <button (click)="closeDialog('reject')" class="options-modal-btn btn-block btn-empty">
                  {{ 'HOME.SHARE.REJECT' | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>