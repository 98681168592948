    <div class="modal-header">
  <button type="button" (click)="bsModalRef.hide()" class="close">&times;</button>
    </div>
    <div class="modal-body">
      <div class="sp-middle-element">
        <div class="col-md-12 col-lg-12 col-sm-8">
          <div class="col-md-12">
            <div class="row">
              <div class="row">
                <h3 class="col-md-offset-2">
                  {{ 'VERSION_INFO_MODAL.WEB_VERSION_INFO' | translate}}
                </h3>
                <div class="col-md-3 col-sm-3 col-md-offset-2 col-sm-offset-2 version-detail">
                  {{ 'VERSION_INFO_MODAL.VERSION' | translate}}
                </div>
                <div class="col-md-7 col-sm-7 version-detail version-value">
                  {{ 'VERSION_INFO_MODAL.WEB_VERSION' | translate }}
                </div>
              </div>

              <div class="row">
                <div style="font-size: 13px;" class="col-md-3 col-sm-3 col-md-offset-2 col-sm-offset-2 version-detail">
                  {{ 'VERSION_INFO_MODAL.DATE' | translate}}
                </div>
                <div style="font-size: 13px;" class="col-md-7 col-sm-7 version-detail date-detail">
                  {{'VERSION_INFO_MODAL.WEB_DATE' | translate}}
                </div>
              </div>

              <!-- <div class="row">
                <div class="col-md-3 col-md-offset-2">
                  Description:
                </div>
                <div class="col-md-7">
                   {{webDescription}}
                </div>
              </div> -->
              <div class="row col-md-offset-2">
              </div>
            </div>
            <div class="row">
              <div class="row">
                <h3 class="col-md-offset-2 apimargin"> 
                  {{ 'VERSION_INFO_MODAL.API_VERSION_INFO' | translate}}
                </h3>
                <div class="col-md-3 col-sm-3 col-md-offset-2 col-sm-offset-2 version-detail">
                  {{ 'VERSION_INFO_MODAL.VERSION' | translate}}
                </div>
                <div class="col-md-7 col-sm-7 version-detail version-value">
                  {{'VERSION_INFO_MODAL.API_VERSION' | translate}}
                </div>
              </div>

              <div class="row">
                <div style="font-size: 13px;" class="ccol-md-3 col-sm-3 col-md-offset-2 col-sm-offset-2 version-detail">
                  {{ 'VERSION_INFO_MODAL.DATE' | translate}}
                </div>
                <div style="font-size: 13px;" class="col-md-7 col-sm-7 version-detail date-detail">
                  {{'VERSION_INFO_MODAL.API_DATE' | translate}}
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
</div>