import { Injectable } from '@angular/core';
import { isEqual } from 'lodash';
import { NgRedux } from '@angular-redux/store';
import { IAppState } from './state-management/store';
import {
  ADD_ERROR_MESSAGE,
  ADD_SUCCESS_MESSAGE,
} from './state-management/actions';
// import { DialogService } from "ng6-bootstrap-modal";
// import { LoaderEntryComponent } from "../../helpers/loader-entry-comp/loader-entry-comp.component";
import { Config } from './Config';
import EULA_eng from '../../../assets/leagalDocuments/EULA/eng';
import privacy_eng from '../../../assets/leagalDocuments/privacyPolicy/eng';
import terms_eng from '../../../assets/leagalDocuments/termsAndConditions/eng';
import terms_fr from '../../../assets/leagalDocuments/termsAndConditions/fr';
import EULA_fr from '../../../assets/leagalDocuments/EULA/fr';
import privacy_fr from '../../../assets/leagalDocuments/privacyPolicy/fr';
import { NgxImageCompressService } from 'ngx-image-compress';

@Injectable()
export class Utility {
  private loader = 0;
  private loaderTime: any = [];

  private loaderObject: any;
  private dialogObject: any = {};
  imgResultBeforeCompress: any;
  imgResultAfterCompress: any;
  imageSrc: any;
  blobObject: Blob;
  compressedFileObject: File;
  constructor(
    public ngRedux: NgRedux<IAppState>,
    private imageCompress: NgxImageCompressService
  ) {
    this.setDialogObject();
  }

  static compareJson(obj: {}, obj2: {}) {
    return isEqual(obj, obj2);
  }
  static focusLogo() {
    window.scrollTo(0, 0);
  }

  getFileReader(files: any) {
    var reader = new FileReader();
    reader.readAsDataURL(files[0]);

    return reader;
  }

  getErrorMessage() {
    let msg = this.ngRedux.getState().errorMessage;
    this.ngRedux.dispatch({
      type: ADD_ERROR_MESSAGE,
      msg: null,
    });
    return msg;
  }

  sort(arr, key, type) {
    switch(type) {
      case 'date':
        arr.sort((a,b) => (a[key] > b[key]) ? 1 : ((b[key] > a[key]) ? -1 : 0))
      break;
      case 'text':
        arr.sort((a, b) => a[key].toLowerCase().localeCompare(b[key].toLowerCase()));
    }
  }

  getSuccessMessage() {
    let msg = this.ngRedux.getState().successMessage;
    this.ngRedux.dispatch({
      type: ADD_SUCCESS_MESSAGE,
      msg: null,
    });
    return msg;
  }

  addSuccessMessage(msg: any) {
    this.ngRedux.dispatch({
      type: ADD_SUCCESS_MESSAGE,
      msg: msg,
    });
  }

  removeLoader(time: any) {
    const i = this.loaderTime.indexOf(time);
    if (i < 0) {
      return;
    }
    this.loaderTime.splice(i, 1);
    this.loader--;
    setTimeout(() => {
      if (this.loader <= 0) {
        this.loader = 0;
        // this.dialogService.removeAll();
        this.loaderObject = null;
      }
    }, 0);
  }

  addLoader(time: any) {
    this.loaderTime.push(time);
    try {
      this.loader++;
      setTimeout(() => {
        if (this.loader > 0 && !this.loaderObject) {
          // this.loaderObject = this.dialogService.addDialog(LoaderEntryComponent, {}).subscribe();
        }
      }, 0);
    } catch (err) {}
  }

  removeAllLoader() {
    setTimeout(() => {
      this.loaderObject = null;
      this.loaderTime = [];
      this.loader = 0;
      // this.dialogService.removeAll();
    }, 0);
  }

  getDialogElement(element: string) {
    let lang = this.ngRedux.getState().preferredLanguage;
    return (
      this.dialogObject[element + '_' + lang] ||
      this.dialogObject['EULA_English']
    );
  }

  private setDialogObject() {
    this.dialogObject = {};
    this.dialogObject['EULA_English'] = EULA_eng;
    this.dialogObject['terms_English'] = terms_eng;
    this.dialogObject['privacy_English'] = privacy_eng;
    this.dialogObject['EULA_French'] = EULA_fr;
    this.dialogObject['terms_French'] = terms_fr;
    this.dialogObject['privacy_French'] = privacy_fr;
  }

  isFileInAllowedFormats(imageType: any, allowedFormats: any) {
    if (allowedFormats.includes(imageType)) {
      return true;
    } else {
      return false;
    }
  }

  isFileSizeAllowed(imageSize: number, imageSizeInBytes: number) {
    if (imageSize <= imageSizeInBytes) {
      return true;
    } else {
      return false;
    }
  }

  dataURItoBlob(dataURI: any) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);

    // create a view into the buffer
    var ia = new Uint8Array(ab);

    // set the bytes of the buffer to the correct values
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    var blob = new Blob([ab], { type: mimeString });
    return blob;
  }

  async compressFile(image: any, name: any) {
    let orientation = 1;
    this.imgResultBeforeCompress = image;
    console.warn('Size in bytes was:', this.imageCompress.byteCount(image));

    let imageByteCount = this.imageCompress.byteCount(image);
    let uploadedImageDefaultBytecount = Config.USER.UPLOADED_IMAGE_DEFAULT_BYTECOUNT * 1024 * 1024;
    let imageRatio = imageByteCount > uploadedImageDefaultBytecount ? 10 : 90;
    
    return this.imageCompress
      .compressFile(image, orientation, imageRatio, 50) // image quality.
      .then((result) => {
        this.imgResultAfterCompress = result;
        this.blobObject = this.dataURItoBlob(result);
        this.compressedFileObject = new File([this.blobObject], name);
        console.warn(
          'Size in bytes is now:',
          this.imageCompress.byteCount(result)
        );
        return { imageSrc: result, compressedFile: this.compressedFileObject };
      });
  }

  static getNgxOtpConfigObject() {
    const configObj = {
      allowNumbersOnly: true,
      length: 6,
      isPasswordInput: false,
      disableAutoFocus: false,
      placeholder: '',
      inputStyles: {
        width: '35px',
        height: '35px',
        border: '1px solid #000',
        'font-size': '13px',
        color: '#000',
        'font-weight': 'bold',
      },
    };

    return configObj;
  }

  makeFormDataCall(preSignedURLData: any, file) {
    let { fields } = preSignedURLData;
    var form_data = new FormData();
    let fieldsKeys = Object.keys(fields);
    for (const field of fieldsKeys) {
      form_data.append(field, fields[field]);
    }
    form_data.append('file', file);
    return form_data;
  }
}

export function isNumberKey(evt: any) {
  evt = evt ? evt : window.event;
  const charCode = evt.which ? evt.which : evt.keyCode;
  if ((charCode > 31 && charCode < 48) || charCode > 57) {
    return false;
  }
  return true;
}

export function isNumber(evt: any) {
  evt = evt ? evt : window.event;
  const charCode = evt.which ? evt.which : evt.keyCode;
  const key = evt.key;
  event.preventDefault ? event.preventDefault() : (event.returnValue = false);

  const target = evt.target;
  let start = target.selectionStart;
  let end = target.selectionEnd;

  let value = target.value;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    return false;
  }
  start =
    target.selectionStart < target.selectionEnd
      ? target.selectionStart
      : target.selectionEnd;
  end =
    target.selectionStart > target.selectionEnd
      ? target.selectionStart
      : target.selectionEnd;
  if (start !== end) {
    value = value.slice(0, start) + value.slice(end);
    end = start;
  }
  // value = this.phoneNumberPipe.transform(value);
  const length = value.length;
  if (length + 1 > 12) {
    target.value = value;
    target.setSelectionRange(start, end);
    return true;
  }
  value = value.slice(0, start) + key + value.slice(start);
  // value = this.phoneNumberPipe.transform(value);
  /* if (evt.shiftKey || evt.code && (evt.code.toString().indexOf("Shift") >= 0 || evt.code.toString().indexOf("Arrow") >= 0)) {
    return;
  } */
  target.value = value;
  start++;
  end++;
  if (start === 4 || start === 8) {
    start++;
    end++;
  }
  if (length > value.length - 1) {
    start++;
    end++;
  }
  target.setSelectionRange(start, end);
  return true;
}

export function spaceCheck(evt: any) {
  evt = evt ? evt : window.event;

  const charCode = evt.which ? evt.which : evt.keyCode;

  if (charCode === 32) {
    return false;
  }
  return true;
}

export function goBack() {
  // this.location.back();
}

export function getUILanguage(langCode: string): string {
  let lang = Config.LANGUAGES[langCode] || Config.LANGUAGES[getLanguageCode(langCode)];
  return lang || Config.LANGUAGES['en-us'];
}

export function getLanguageCode(lang: string): string {
  let code = Config.LANGUAGES[lang.toUpperCase()];
  return code || Config.LANGUAGES.ENGLISH;
}