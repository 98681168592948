export const UPDATE_USER_OBJECT = 'UPDATE_USER_OBJECT';
export const LOGOUT = 'LOGOUT';
export const UPDATE_REGISTRAION_USER = 'UPDATE_REGISTRAION_USER';
export const RESET_REGISTRATION = 'RESET_REGISTRATION';
export const UPDATE_VERIFICATION = 'UPDATE_VERIFICATION';
export const UPDATE_REG_STEP = 'UPDATE_REG_STEP';
export const UPDATE_TWO_STEP = 'UPDATE_TWO_STEP';
export const UPDATE_PROFILE_DATA = 'UPDATE_PROFILE_DATA';
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';
export const UPDATE_FORGOT_PASSWORD_FLOW = 'UPDATE_FORGOT_PASSWORD_FLOW';
export const UPDATE_CORRELAION_ID = 'UPDATE_CORRELAION_ID';
export const UPDATE_HOMES = 'UPDATE_HOMES';
export const UPDATE_USER_BRAND = 'UPDATE_USER_BRAND';
export const UPDATE_LOCKS = 'UPDATE_LOCKS';
export const ADD_ERROR_MESSAGE = 'ADD_ERROR_MESSAGE';
export const ADD_SUCCESS_MESSAGE = 'ADD_SUCCESS_MESSAGE';
export const LAST_ACTIVITY_TIME = 'LAST_ACTIVITY_TIME';
export const WHITE_LIST_CHECKED = 'WHITE_LIST_CHECKED';
export const USER_VIEW_TYPE = 'USER_VIEW_TYPE';
export const USER_MODAL_VIEW_TYPE = 'USER_MODAL_VIEW_TYPE';
export const USER_SELECTED_HOME = 'USER_SELECTED_HOME';
export const RESET_MODAL_SOFT = 'soft';
export const RESET_MODAL_HARD = 'hard';
