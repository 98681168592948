import { Component, EventEmitter, ViewEncapsulation } from '@angular/core';
// import { DialogComponent, DialogService } from "ng6-bootstrap-modal";
import { Router } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Config } from 'src/app/services/utils/Config';

export interface ViewSharedUserConfirmOptionsModal {
  status: any;
  confirmedStatus: boolean;
  userEmail: any;
}
@Component({
  selector: 'app-view-shared-user-confirm-modal',
  templateUrl: './view-shared-user-confirm-modal.component.html',
  styleUrls: ['./view-shared-user-confirm-modal.component.css'],
  encapsulation:ViewEncapsulation.None
})
export class ViewSharedUserConfirmModalComponent implements ViewSharedUserConfirmOptionsModal {
  status;
  confirmedStatus: boolean;
  flag: boolean;
  langFlag: boolean;
  modalCss = 'modal-view';
  userEmail: any;
  headerText = "";
  headingText = "";
  result: {};
  confirmButtonText = "";
  cancelText = "";
  USER_ACCESS_LEVELS = {
    PENDING: "Pending",
    ENABLED: "Enabled",
    DISABLED: "Disabled"
  }
  public event: EventEmitter<any> = new EventEmitter(); 
  constructor(
    private modalService: BsModalService,
    public router: Router,
    private translate: TranslateService,
    public bsModalRef: BsModalRef) {
  }
  
  ngOnInit() {
    this.setModalData();    
  }

  setModalData() {
    if (this.status === this.USER_ACCESS_LEVELS.PENDING) {
      this.headerText = this.translate.instant('SHARE_HOME.POPUP.TITLE.PENDING');
      this.headingText = this.translate.instant('SHARE_HOME.POPUP.MSG.PENDING');
      this.confirmButtonText = this.translate.instant('SHARE_HOME.POPUP.CONFIRM_BUTTON.PENDING');
      this.cancelText = this.translate.instant('SHARE_HOME.POPUP.CANCEL.PENDING');
      if(Config.LANGUAGES.FRENCH){
        this.langFlag = true;
      }
      this.flag = true;
      this.modalCss = 'modal-view';
    } else if (this.status === this.USER_ACCESS_LEVELS.ENABLED) {
      this.headerText = this.translate.instant('SHARE_HOME.POPUP.TITLE.DISABLED');
      this.headingText = this.translate.instant('SHARE_HOME.POPUP.MSG.DISABLED');
      this.confirmButtonText = this.translate.instant('SHARE_HOME.POPUP.CONFIRM_BUTTON.DISABLED');
      this.cancelText = this.translate.instant('SHARE_HOME.POPUP.CANCEL.DISABLED');
      this.modalCss = 'modal-view';
    } else if (this.status === this.USER_ACCESS_LEVELS.DISABLED) {
      this.headerText = this.translate.instant('SHARE_HOME.POPUP.TITLE.ENABLED');
      this.headingText = this.translate.instant('SHARE_HOME.POPUP.MSG.ENABLED');
      this.confirmButtonText = this.translate.instant('SHARE_HOME.POPUP.CONFIRM_BUTTON.ENABLED');
      this.cancelText = this.translate.instant('SHARE_HOME.POPUP.CANCEL.ENABLED');
      this.modalCss = 'modal-view';
    }
  }

  closeDialog(confirmedStatus) {
    this.result = { confirmedStatus, status: this.status, userEmail: this.userEmail };
    console.log(this.result,"Result");
    this.event.emit(this.result);
    this.modalService.hide();
  }
}
