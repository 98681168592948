import { Component, OnInit, Input } from "@angular/core";
import {
  LockService,
  LockCallback,
  ILockObject
} from "../../../services/lock.service";
import { TranslateService } from "@ngx-translate/core";
import { HomeService } from "../../../services/home.service";
import { LanguageChangeService } from "../../../services/language-change.service";
import { Config } from "src/app/services/utils/Config";

@Component({
  selector: "app-home-locks",
  templateUrl: "./home-locks.component.html",
  styleUrls: ["./home-locks.component.css"]
})
export class HomeLocksComponent implements OnInit, LockCallback {
  @Input() homeID: string;
  @Input() member: boolean;
  @Input() viewType: string;
  homeLocks: ILockObject[];
  homeName: string;
  bleAdvertisement = '1';
  batteryLevel = 20;
  lockState = 'jammed';
  isLoaderActive = false;
  
  constructor(
    private lockService: LockService,
    private langService: LanguageChangeService,
    private homeService: HomeService
  ) {}

  ngOnInit() {
    this.homeLocks = null;
    this.lockService.getLocksByHomeId(this.homeID, this);
    this.homeName = this.homeService.getHomeById(this.homeID).homeName;
  }

  callback(lock: ILockObject[]) {
    if (lock == null) {
      this.homeLocks = [];
    } else {
      this.homeLocks = lock;
    }
  }

  convertDateFormat(date) {
    
    date = new Date(date * 1000);
    date = date.toUTCString();
    date = new Date(date);

    var d = new Date(date),
        month = '' + (d.getMonth()),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = month;
    if (day.length < 2) day = '0'+ day;
    
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    return `${months[month]} ${day}, ${year}`;
  }

  convertFirstLetter(lockState) {
    lockState = lockState.charAt(0).toUpperCase() + lockState.slice(1);
    lockState = Config.HISTORY_CHECKLISTS.find(ele => ele[this.langService.getDefaultLanguage()] === lockState);
    return lockState ? lockState[this.langService.getCurrentLanguage()] : '';
  }

  checkUpdateLastStatus(lastUpdateStatus) {
    let currentDate = Math.floor(Date.now() / 1000);
    let lockStatus = currentDate - lastUpdateStatus;
    return lockStatus > Config.UPDATE_STATUS_SECONDS ? false : true; 
  }
  
}
