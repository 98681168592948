import { Component, OnInit } from '@angular/core';
import { DomSanitizer} from '@angular/platform-browser';
import { Router } from '@angular/router';

// import '../scanweburl/index.html';

@Component({
  selector: 'app-scanweburl',
  templateUrl: './scan-web-url.component.html'
})
export class ScanWebUrlComponent implements OnInit {

  content: any
  constructor(
    public router: Router,
    private sanitizer: DomSanitizer
  ) { 
  }


  ngOnInit(): void {

    // fetch('/scanweburl/index.html').then(res => res.text()).then(data => {
    //   console.log(data);
    //   this.content = this.sanitizer.bypassSecurityTrustHtml(data);
    // })
    // this.router.navigate(['scanweburl/index.html'])
    console.log(window.location);
    window.location.href = `${window.location.origin}/scanweburl/index.html`;
    // window.open('../scanweburl/index.html');
    // const template = `<!DOCTYPE html>
    // <html>
    // <head>
    //   <script>
    //     // Function to redirect based on the user agent
    //     function redirectToAppStore() {
    //       var userAgent = navigator.userAgent || navigator.vendor || window.opera;
          
    //       if (/android/i.test(userAgent)) {
    //         // Android redirection
    //         window.location.href = "https://play.google.com/store/apps/details?id=com.kwikset.blewifi&hl=en&gl=US";
    //       } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    //         // iOS redirection
    //         window.location.href = "https://apps.apple.com/us/app/kwikset/id1465996742";
    //       } else {
    //         // Fallback redirection if the user agent is unknown
    //         window.location.href = "https://play.google.com/store/apps/details?id=com.kwikset.blewifi&hl=en&gl=US";
    //       }
    //     }
        
    //     // Call the redirection function when the page loads
    //     window.onload = redirectToAppStore;
    //   </script>
    //   <title>Redirecting...</title>
    // </head>
    // <body>
    // </body>
    // </html>
    // `

    // this.router.navigate([template]);
    // this.redirectToAppStore();
  }

    //   // Function to redirect based on the user agent
    // redirectToAppStore() {
    //     var userAgent = navigator.userAgent || navigator.vendor;
        
    //     if (/android/i.test(userAgent)) {
    //       // Android redirection
    //       window.location.href = "https://play.google.com/store/apps/details?id=com.kwikset.blewifi&hl=en&gl=US";
    //     } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MediaStream) {
    //       // iOS redirection
    //       window.location.href = "https://apps.apple.com/us/app/kwikset/id1465996742";
    //     } else {
    //       // Fallback redirection if the user agent is unknown
    //       window.location.href = "https://play.google.com/store/apps/details?id=com.kwikset.blewifi&hl=en&gl=US";
    //     }
    //   }
      
      // Call the redirection function when the page loads;

}
