import { NgRedux } from '@angular-redux/store';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import apiConfig from 'src/app/services/api-client/api-config';
import { ApiRequestService } from 'src/app/services/api-client/api-requests.service';
import { Config } from 'src/app/services/utils/Config';
import { Constants } from 'src/app/services/utils/Constants';
import { ADD_ERROR_MESSAGE } from 'src/app/services/utils/state-management/actions';
import { IAppState } from 'src/app/services/utils/state-management/store';
import { DataShareService } from '../../../services/data-share.service';
import { FederatedManagementService } from '../../../services/federated-management.service';
import { UserLoginService } from '../../../services/user-login.service';
import { UserManagement } from '../../../services/utils/state-management/action-classes';
import { IUser } from '../../../services/utils/state-management/types';

@Component({
  selector: 'app-redirect-federation-login',
  templateUrl: './redirect-federation-auth.component.html',
  styleUrls: ['./redirect-federation-auth.component.css']
})
export class RedirectFederationAuthComponent implements OnInit {

  code: string = "";
  sessionTokens: any;
  user: IUser;
  flowType: string;
  isCallInvoked = false;
  step = Config.PROVIDERS.FEDERATED;
  errorMessage = null;

  constructor(private route: ActivatedRoute,
    public googleservice: FederatedManagementService,
    public userManagementService: UserManagement,
    public userService: UserLoginService,
    public apiService: ApiRequestService,
    private dataservice: DataShareService,
    private ngRedux: NgRedux<IAppState>,
    private translate: TranslateService,
    private router: Router) { }

  ngOnInit() {
      this.flowType = localStorage.getItem('FLOW') || '';
      this.code = this.route.snapshot.queryParams['code'];
      if(this.route.snapshot.queryParams['error_description']){
        this.dataservice.fireValue({error: true});
      }
      this.getToken(this.code);
      
  }


  getToken(code) {
    this.isCallInvoked = true;
    this.googleservice.getGoogleOAuthTokens(code).subscribe((response) => {

      this.sessionTokens = response;
      this.googleservice.getGoogleUser(this.sessionTokens.access_token).subscribe((response) => {

        const googleUserDetails: any = response;
        console.log(this.flowType);

        if (this.flowType === 'SIGNUP') {
          this.googleservice.saveGoogleTokenSessionStorage(googleUserDetails.email, this.sessionTokens);
          this.user = this.userManagementService.getUserObject();
          this.user.email = googleUserDetails.email;
          localStorage.setItem('PROVIDER', 'FEDERATED');
          localStorage.setItem('FLOW', 'SIGNUP');
          this.router.navigate(["/home/register"]);
        } else {
          this.googleservice.saveGoogleTokens(googleUserDetails.email, this.sessionTokens);
          this.fetchUser(googleUserDetails.email, this.sessionTokens);
        }
      }, (err) => {

        
      });
    }, (err)=>{

      this.userManagementService.removeUser();
      this.router.navigate(['/home/login']);

    });
  }

  fetchUser(email, sessionTokens): void {
    this.isCallInvoked = true;
    /* this.apiService.makeCall(null, 'get', apiConfig.me, null, (err, meResponse) => {
      this.isCallInvoked = false;
      if (meResponse && meResponse.success && meResponse.data && meResponse.data.length > 0) {

        this.user = this.userManagementService.parseUserObjectAndDispatch(meResponse.data[0]);
        if (this.user.phoneActive) {
          this.router.navigate(['/securehome']);
        } else {
          
          this.userManagementService.removeUserPhoneNumber();
          this.router.navigate(['/home/login'], {queryParams : {step: this.step}});
        }
        
      }
      else {
        if (meResponse && meResponse['type'] === errorCodes.RESOURCE_NOT_FOUND) {
          //user not found, proceed to signup
          this.userManagementService.removeUser();
          this.userService.logout();
          this.googleservice.saveGoogleTokenSessionStorage(email, sessionTokens);
          localStorage.setItem('PROVIDER', 'GOOGLE');
          localStorage.setItem('FLOW', 'SIGNUP');
          this.router.navigate(['/home/register']);
        }
        else {
          this.router.navigate(['/home']);
        }
      }
    }); */


    this.apiService.callApi(Constants.API_METHODS.GET, apiConfig.me.uri, null, false).subscribe({
      next: (meResponse) => {
        this.isCallInvoked = false;
        if (meResponse && meResponse.data && meResponse.data.length > 0) {

          this.user = this.userManagementService.parseUserObjectAndDispatch(meResponse.data[0]);
          if (this.user.phoneActive) {
            this.router.navigate(['/securehome']);
          } else {
            
            this.userManagementService.removeUserPhoneNumber();
            this.router.navigate(['/home/login'], {queryParams : {step: this.step}});
          }
          
        }
        else {
          if (meResponse && meResponse['type'] === Constants.ERROR_CODES.RESOURCE_NOT_FOUND) {
            //user not found, proceed to signup
            this.userManagementService.removeUser();
            this.userService.logout();
            this.googleservice.saveGoogleTokenSessionStorage(email, sessionTokens);
            localStorage.setItem('PROVIDER', 'FEDERATED');
            localStorage.setItem('FLOW', 'SIGNUP');
            this.router.navigate(['/home/register']);
          }
          else {
            this.router.navigate(['/home']);
          }
        }
      },
      error: (err) => {
        this.translate.get('LOGIN.ERROR.USER_NOT_FOUND').subscribe(value => this.errorMessage = value);
        this.ngRedux.dispatch({
          type: ADD_ERROR_MESSAGE,
          msg: this.errorMessage 
        });
        this.isCallInvoked = false;
        this.router.navigate(['/home/login'])
        
      }
    })
  }
}
