import { Component, EventEmitter, ViewEncapsulation } from '@angular/core';
//import { DialogComponent, DialogService } from "ng6-bootstrap-modal";
import { Router } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { TranslateService } from '@ngx-translate/core';

export interface AcceptRejectHomeOptionsModal {
  accessRights: string;
  homeName: string;
  sharedBy: string;
}
@Component({
  selector: 'app-accept-reject-home-modal-modal',
  templateUrl: './accept-reject-home-modal.component.html',
  styleUrls: ['./accept-reject-home-modal.component.css'],
  encapsulation:ViewEncapsulation.None
})
export class AcceptRejectHomeModalComponent implements AcceptRejectHomeOptionsModal {
  status: string;
  accessRights: string;
  homeName: string;
  sharedBy: string;
  result: {};
  adminText = '';
  guestText = '';

  public event: EventEmitter<any> = new EventEmitter(); 
  constructor(
    private modalService: BsModalService, 
    public router: Router, 
    private translate: TranslateService,
    public bsModalRef: BsModalRef) 
    { }

  ngOnInit() {
    const strLength = 20;
    this.sharedBy = this.sharedBy.length > strLength ? `${this.sharedBy.substring(0, strLength)}...` : this.sharedBy;
    this.homeName = this.homeName.length > strLength ? `${this.homeName.substring(0, strLength)}...` : this.homeName;

    this.translate.get('HOME.ADMIN_SHARE_TEXT').subscribe(value => this.adminText = value);
    this.translate.get('HOME.GUEST_SHARE_TEXT').subscribe(value => this.guestText = value);
  }


  closeDialog(confirmedStatus) {
    this.result = confirmedStatus;
    console.log(this.result,"Result");
    this.event.emit(this.result);
    this.modalService.hide();
  }
}
