<div class="verify-continue" style="padding-top: 25px;">
   <div class="row">
    <div *ngIf="errorMessage!=null" class="alert alert-danger alert-dismissible fade in">
      <a class="close" aria-label="close" (click)="errorMessage = null">&times;</a>
      {{ errorMessage }}
    </div>
  <p 
  class="verify-continue-title"> {{ (continueType === "email" ? 'REGISTRATION.EMAIL_VERIFICATION.TITLE' : 'REGISTRATION.PHONE_VERIFICATION.TITLE') | translate }} </p>
  <div 
   class="verify-tick">
      <div class="verify-tick-img"></div>
      <!-- <img src="../../../../assets/images/tick-icon.png" /> -->
      <p class="verify-tick-p">{{ 'REGISTRATION.EMAIL_VERIFICATION.COMPLETED' | translate }} </p>
  </div>
  <!-- <br /> -->
  <!-- <p class="verify-tick-login-mobile-only">{{ 'REGISTRATION.EMAIL_VERIFICATION.COMPLETED' | translate }} </p> -->
  <br />
  <div class="row">
    <div class="col-lg-7 no-padding mb-10px">
      <button 
       (click)="onContinue()" class="btn btn-block sp-btn-dark h-45" [disabled]="false">
          {{ 'REGISTRATION.COLLECT_INFO.BUTTON_SUBMIT_FORM' | translate }}
      </button>
    </div>
  </div>
   </div>
  </div>
  