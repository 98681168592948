import { Component, OnInit } from "@angular/core";
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { TranslateService } from "@ngx-translate/core";
import { ConfirmComponent } from "../../helpers/modal/confirm.component";
import { UserLoginService } from "../../services/user-login.service";
import { Router } from "@angular/router";
import { LoggedInCallback } from "../../services/utils/cognito.service";
import { environment } from "../../../environments/environment";
import { Subscription } from "rxjs";

@Component({
  selector: "app-timeout",
  template: ""
})
export class TimeoutComponent implements OnInit, LoggedInCallback {
  modalRef: BsModalRef;
  subscription: Subscription;
  constructor(
    public translate: TranslateService,
    private modalService: BsModalService,
    private router: Router,
    private userLoginService: UserLoginService
  ) { }

  // Set timeout variables.
  private startingTime = environment.totalDelayTimeOut -
  environment.delayWarningTime; // Display warning in x Mins.
  private endWarningTime = environment.delayWarningTime; // Warning has been shown, give the user y minute to interact
  private logoutUrl = "/home"; // URL to logout page.
  private warningTimer: any;
  private timeoutTimer: any;
  private popup: any;
  private isModalActive = false;

  ngOnInit() {
    document.addEventListener("touchstart", (event) => {
      if (!this.isModalActive) {
        this.ResetTimeOutTimer();
      }
    });
    document.addEventListener("wheel", () => {
      if (!this.isModalActive) {
        this.ResetTimeOutTimer();
      }
    });
    document.addEventListener("keypress", () => {
      if (!this.isModalActive) {
        this.ResetTimeOutTimer();
      }
    });
    document.addEventListener("mouseup", () => {
      if (!this.isModalActive) {
        this.ResetTimeOutTimer();
      }
    });
    document.addEventListener("mousedown", () => {
      if (!this.isModalActive) {
        this.ResetTimeOutTimer();
      }
    });
    document.addEventListener("mousemove", () => {
      if (!this.isModalActive) {
        this.ResetTimeOutTimer();
      }
    });
    document.addEventListener("scroll", () => {
      if (!this.isModalActive) {
        this.ResetTimeOutTimer();
      }
    });
    this.ResetTimeOutTimer();
  }

  // ngOnDestroy() {
  //   this.subscription.unsubscribe();
  // }

  // Start warning timer.
  StartWarningTimer() {
    clearTimeout(this.warningTimer);
    this.warningTimer = setTimeout(() => {
      this.IdleWarning();
      this.isModalActive = true;
    }, this.startingTime);
  }

  // Reset timers.
  ResetTimeOutTimer() {
    this.userLoginService.UpadteLastActivity();
    this.userLoginService.isAuthenticated(this);
  }

  // Show idle timeout warning dialog.
  IdleWarning() {
    clearTimeout(this.warningTimer);
    this.timeoutTimer = setTimeout(() => {
      this.IdleTimeout();
    }, this.endWarningTime);


    const translateKeys = [
      "TIMEOUT_LOGOUT.POP_UP_TITLE",
      "TIMEOUT_LOGOUT.WARNING_POP_UP_MESSAGE",
      "TIMEOUT_LOGOUT.POP_UP_ACTIVE_BUTTON"
    ];

    this.translate.get(translateKeys).subscribe((value)=>{
      this.showPopup(
        value['TIMEOUT_LOGOUT.POP_UP_TITLE'],
        value['TIMEOUT_LOGOUT.WARNING_POP_UP_MESSAGE'],
        value['TIMEOUT_LOGOUT.POP_UP_ACTIVE_BUTTON'],
        null
      );
    })

  }

  IdleTimeout() {
    if (this.popup) {
      this.popup = null;
      this.modalService.hide();
    }
    this.userLoginService.logout();
    this.router.navigate([this.logoutUrl]).then(()=>{
      location.reload();
    });

   /*  if (this.popup) {
      this.popup.unsubscribe();
      this.popup = null;
      this.dialogService.removeAll();
    }
    this.userLoginService.logout();
    this.router.navigate([this.logoutUrl]).then(()=>{
      location.reload();
    }); */
  }
  showPopup(title: any, message: any, okButton: any, canceBUtton: any) {
     this.isModalActive = true;
     let context = this;
     const initialState={
      title: title,
      message: message,
      confirmButtonText: okButton,
      cancelButtonText: "",
      showCloseIcon: false
    }    
    this.popup = this.modalService.show(ConfirmComponent, {
        initialState : initialState,   
        class: 'confirm.component'
      });
      this.popup.content.closeBtnName = 'Close';
      this.popup.content.event.subscribe(() => {
        if (this.popup) {
          this.isModalActive = false;
          this.popup.unsubscribe();
          this.popup = null;
          this.modalService.hide();
        }
        context.ResetTimeOutTimer();
      });

    /*this.popup = this.dialogService
      .addDialog(ConfirmComponent, {
        title: title,
        message: message,
        confirmButtonText: okButton,
        cancelButtonText: "",
        showCloseIcon: false
      })
      .subscribe(isConfirmed => {
        if (this.popup) {
          this.isModalActive = false;
          this.popup.unsubscribe();
          this.popup = null;
          this.dialogService.removeAll();
        }
        context.ResetTimeOutTimer();
      }); */
  }

  isLoggedIn(err: any, login: any) {
    clearTimeout(this.timeoutTimer);
    clearTimeout(this.warningTimer);
    if (this.popup) {
      this.popup.unsubscribe();
      this.popup = null;
      this.modalService.hide();
      // this.dialogService.removeAll();
    }
    if (login) {
      this.StartWarningTimer();
    }
  }
}
