import { NgRedux } from '@angular-redux/store';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import apiConfig from './api-client/api-config';
import { ApiRequestService } from './api-client/api-requests.service';
import { ResponseHandler } from './api-client/response-handler';
import { HomeService } from './home.service';
import { Constants } from './utils/Constants';
import { Logger } from './utils/logger';
import { ResponseHandlerService } from './utils/response-handler.service';
import { IAppState } from './utils/state-management/store';

export interface shareHomeCallback {
  onGetShareUserSuccess(sharedUsers: IShareHomeObject[]): void;
  onGetShareUserFailure(message: string): void;
}

export interface toggleUserStatusCallback {
  onToggleSuccess(userEmail: string, oldStatus: string): void;
  onToggleFailure(message: string): void;
}

export interface IShareHomeObject {
  sharedWith: string;
  userAccessRights: string;
  useraccesslevelstatus: string;
  userEmail: string;
  sharedByName: string;
  preSignedURL: string;
}

@Injectable()
export class ShareHomeService {
  constructor(
    private ngRedux: NgRedux<IAppState>,
    public apiService: ApiRequestService,
    private logger: Logger,
    private responseHandler: ResponseHandlerService,
    private translate: TranslateService,
    private homeService: HomeService,
  ) { }

  toggleUserStatus(homeID: string, status: string, userEmail: string, callback: toggleUserStatusCallback) {

    let pathParams = {
      id: homeID,
      email: userEmail
    }

    let body = {
      userhomestate: status
    }

    this.apiService.callApi(Constants.API_METHODS.PATCH, apiConfig.toggleSharedUserStatus.uri, body, true, pathParams)
    .subscribe({
      next: (response)=>{
        if (response) {
          callback.onToggleSuccess(userEmail, status);
        }
        else {
          callback.onToggleFailure(response.data)
        };
      },
      error: (err) => {
        callback.onToggleFailure(err)
      },
    });
    
    
    
    // (err, response) => {
    //   if (err) {
    //     callback.onToggleFailure(err)
    //   }
    //   else if (response && response.success) {
    //     callback.onToggleSuccess(userEmail, status);
    //   }
    //   else {
    //     callback.onToggleFailure(response.data)
    //   }
    // }) 

    /* this.apiService.makeCall(pathParams, 'patch', apiConfig.toggleSharedUserStatus, body, (err, response) => {
      if (err) {
        callback.onToggleFailure(err)
      }
      else if (response && response.success) {
        callback.onToggleSuccess(userEmail, status);
      }
      else {
        callback.onToggleFailure(response.data)
      }
    }) */
  }



  getSharedUsersWithAttribute(data: any, callback: shareHomeCallback) {

    let pathParams = data;

    

    this.apiService.callApi(Constants.API_METHODS.GET, apiConfig.getSharedUsersWithParemeter.uri, null, true, pathParams)
      .subscribe({
        next: (response: any) => {
          if (response && response.data) {
            let sharedUsers: IShareHomeObject[] = [];
            response.data.forEach(element => {

              let eachUser: IShareHomeObject = {
                sharedWith: element['sharedwithname'],
                userAccessRights: element['useraccesslevel'].toLowerCase() === 'member' ? this.translate.instant('SHARE_HOME.TITLE.GUEST') : element['useraccesslevel'],
                useraccesslevelstatus: element['useraccesslevelstatus'],
                userEmail: element['email'],
                sharedByName: element['sharedbyname'],
                preSignedURL: element['presignedurl']
              }
              sharedUsers.push(eachUser);
            });
            const d = response.data[0] || {};
            this.homeService.updateHomeName(d.homename, data.id);
            callback.onGetShareUserSuccess(sharedUsers);
          } else {
            this.homeService.updateHomeName(null, data.id);
            callback.onGetShareUserFailure(response ? response.data : {})
          }
        },
        error: (err) => {
          callback.onGetShareUserFailure(err);
        },
      });
  }

  deleteShare(homeID: string, userEmail: string, callback: toggleUserStatusCallback) {

    let pathParams = {
      id: homeID,
      email: userEmail
    };

    this.apiService.callApi(Constants.API_METHODS.DELETE, apiConfig.deleteSharedUserStatus.uri, {}, true,  pathParams, null)
    .subscribe({
      next: (response : any)=>{
          callback.onToggleSuccess(userEmail, "Deleted"); 
        },
      error: (err) => {
        callback.onToggleFailure(err)
      },
    });

    /*  this.apiService.makeCall(pathParams, 'delete', apiConfig.deleteSharedUserStatus, null, (err, response) => {
       if (err) {
         callback.onToggleFailure(err);
       } else if (response && response.success) {
         callback.onToggleSuccess(userEmail, "Deleted");
       } else {
         callback.onToggleFailure(response ? response.data : null);
       }
     }); */
  }

}
