import { Component, EventEmitter, ViewEncapsulation } from '@angular/core';
import { Router } from "@angular/router";
import { BsModalRef } from 'ngx-bootstrap/modal';
import apiConfig from 'src/app/services/api-client/api-config';
import { ApiRequestService } from 'src/app/services/api-client/api-requests.service';
import { Constants } from 'src/app/services/utils/Constants';
import { UserManagement } from '../../../services/utils/state-management/action-classes';


export interface DeleteVerificationModal {
  homes: string;
  locks: string;
}
@Component({
  selector: 'app-delete-verification-modal',
  templateUrl: './delete-verification-modal.component.html',
  styleUrls: ['./delete-verification-modal.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class DeleteVerificationModalComponent implements DeleteVerificationModal {
  homes: string;
  locks: string;
  brandName: any;
  accountEnabled: boolean;
  user = {};
  outputEvent: EventEmitter<any> = new EventEmitter();

  constructor(
    public bsModalRef: BsModalRef,
    public router: Router,
    public apiClient: ApiRequestService,
    public userManagement: UserManagement) {
    this.brandName = localStorage.getItem('userBrand');
    this.brandName = this.brandName.charAt(0).toUpperCase() + this.brandName.slice(1);
    this.user = this.userManagement.getUser();
    this.accountEnabled = this.user['accountEnabled'] ? true : false; 
  }

  confirmDelete() {
    this.outputEvent.emit(true);
    this.bsModalRef.hide();
  }

  close(){
    this.bsModalRef.hide();
  }

  onTogglePhoneAccess($event){
    let user = this.userManagement.getUser();
    let body = {
      enabled: (!$event.checked).toString()
    }
    this.apiClient.callApi(Constants.API_METHODS.PATCH, apiConfig.toggleEnableDisableAccount.uri, body).subscribe((res) => {
        user.accountEnabled = !$event.checked;
        this.accountEnabled = !$event.checked;
        this.userManagement.updateUser(user);
        this.outputEvent.emit(false);
        this.bsModalRef.hide();
        this.close();
    }, (err) =>{});
  /*   this.apiClient.makeCall(null, 'patch', apiConfig.toggleEnableDisableAccount, body, (err, res) => {
      if (res && res.success) {
        user.accountEnabled = !$event.checked;
        this.accountEnabled = !$event.checked;
        this.userManagement.updateUser(user);
        this.result = false;
        this.close();
      }
      else { }
    }); */

  }
}
