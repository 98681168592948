import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AsYouType } from 'libphonenumber-js';
import { isNumberKey } from "../../services/utils/utility";
import { TranslateService } from '@ngx-translate/core';
import { UserProfileService } from '../../services/user-profile.service';
import { PhoneNumberFormatPipe } from '../../services/utils/phoneNumberFormat.pipe';
import { Config } from 'src/app/services/utils/Config';

@Component({
  selector: 'phone-field',
  templateUrl: './phone-field.component.html',
  styleUrls: ['./phone-field.component.css'],
  providers: [PhoneNumberFormatPipe]
})
export class PhoneFieldComponent implements OnInit {

  @Input() formname: FormGroup;
  @Input() selectedCountryCode: any;
  @Output() selectedCountryEvent = new EventEmitter<any>();

  isNumberKey = isNumberKey;

  flagAssetsDir = Config.FLAG_ASSET_DIR;
  countryList = [];
  countryCodesObj: any;
  PhoneBinding: string = '';
  brand: any;
  WEISER = 'weiser';
  CANADA = 'CA';
  USA = 'US';
  selectedCountry: any;

  asYouType = new AsYouType();
  maxlength: number;
  validPhoneNumber: boolean = false;

  invalidNumberMessage: string;

  @ViewChild('phone') phoneInput: any;

  constructor(
    private translate: TranslateService,
    public userProfileService: UserProfileService) { }

  ngOnInit() {
    this.userProfileService.getCountryCode(this);
    this.brand = localStorage.getItem('userBrand') || this.WEISER;
    this.selectedCountryCode = this.selectedCountryCode ? this.selectedCountryCode : Config[this.brand.toUpperCase()].defaultCountryCode;
  }

  /* Invoked when country dropdown value changed   */
  onChangeCountry() {
    this.selectedCountry = this.countryList.find(o => o.countryCode === this.selectedCountryCode);
    this.maxlength = parseInt(Config.PHONEFORMAT[this.selectedCountryCode].FORMATTEDLENGTH);
    this.asYouType = new AsYouType(this.selectedCountryCode);
    this.formname.get('phone').setValue('');
    this.getInvalidMessage();
    this.selectedCountryEvent.emit({ phoneMaxLength: this.maxlength, dialCode: this.selectedCountry.dialCode, selectedCountryCode: this.selectedCountryCode });
  }

  /* List All the Countries From Backend and create the Object for default selected country */
  countryCodesCallback(obj) {
    this.countryCodesObj = obj.countryCodes;
    const countryNames = Object.keys(this.countryCodesObj);
    countryNames.forEach((i) => {
      const countryObj = {
        countryName: this.countryCodesObj[i].name,
        dialCode: this.countryCodesObj[i].code,
        length: this.countryCodesObj[i].length,
        countryCode: i,
        flag: `${this.flagAssetsDir}${i.toLowerCase()}.svg`
      };
      this.countryList.push(countryObj);
    });
    this.selectedCountry = this.countryList.find(o => o.countryCode === this.selectedCountryCode);
    this.maxlength = parseInt(Config.PHONEFORMAT[this.selectedCountryCode].FORMATTEDLENGTH);
    this.asYouType = new AsYouType(this.selectedCountryCode);
    this.getInvalidMessage();
    this.selectedCountryEvent.emit({ phoneMaxLength: this.maxlength, dialCode: this.selectedCountry.dialCode, selectedCountryCode: this.selectedCountryCode })

  }

  /* Shows The Invalid message on UI */
  getInvalidMessage() {
    if(this.countryCodesObj){
      this.countryCodesObj[this.selectedCountryCode].length
      let validationPhone: string = this.translate.instant("VALIDATION.PHONE");
      validationPhone = validationPhone.replace(
        "{length}", this.countryCodesObj[this.selectedCountryCode].length
      );
      this.invalidNumberMessage = validationPhone;
    }
   
  }

  public hasError() {
    this.formname.controls['phone'].setErrors(null);
    const phoneInput = this.formname.get('phone').value;
    if ((/[a-zA-Z]/).test(phoneInput)) {
      this.formname.get('phone').setErrors({ 'incorrect': true });
      this.getInvalidMessage();
      return;
    }
    if (phoneInput.length > 0) {
      const isStringContainsPlusSign = /\+/.test(phoneInput);
      if (isStringContainsPlusSign) {
        this.validPhoneNumber = true;
        this.formname.get('phone').setErrors({ 'incorrect': true });
        this.getInvalidMessage();
      }
      else {
        let inputLength = phoneInput.replace(/[^A-Z0-9]/ig, "").length;
        if (this.countryCodesObj) {
          if (inputLength === this.countryCodesObj[this.selectedCountryCode].length) {
            this.validPhoneNumber = false;
          } else {
            this.validPhoneNumber = true;
            this.formname.get('phone').setErrors({ 'incorrect': true });
            this.getInvalidMessage();
          }
        } else {
          this.validPhoneNumber = true;
        }
      }
    } else {
      this.validPhoneNumber = false;
      this.formname.get('phone').setErrors({ 'incorrect': true })
      this.getInvalidMessage();
    }
  }

  /* Cursor Position Update */
  onInput(event): void {
    let startPosition = event.target.selectionStart;
    // console.log(startPosition);
    if (event.key === 'Backspace') {
      if (startPosition >= 6) {
        setTimeout(() => {
          this.phoneInput.nativeElement.focus();
          this.phoneInput.nativeElement.setSelectionRange(startPosition - 1, startPosition - 1);
        }, 100)
      }
    }

  }

}
