import { Injectable } from '@angular/core';
import { Constants } from '../utils/Constants';
import { Logger } from '../utils/logger';
import { LoggingLevel } from '../utils/logging-level';

@Injectable()
export class ResponseHandler {
  constructor(private logger: Logger) {}

  parseSuccessResponse(res: any) {
    let response: any = { type: null, success: null, data: null, total: 0 };
    this.logger.log(LoggingLevel.INFO, 'parseAPIResponse', JSON.stringify(res));

    if (res && res.data) {
      if (res.data) {
        var body = JSON.parse(res._body);
        var resStatus = res.status;
        if (resStatus >= 200 && resStatus < 300) {
          response.success = true;

          if (body.data) {
            response.data = body.data;
          }
          if (body.total) {
            response.total = body.total;
          }
          if (body.issues) {
            response['issues'] = body.issues;
          }
        } else if (resStatus >= 400 && resStatus < 500) {
          response.success = false;
          if (body.error) {
            response.data = this.parseErrorResponse(body.error);
            response.type = body.error.code;
          }
        } else {
          response.success = false;
        }
      } else {
        if (res.status && res.status === 204) {
          //in case of "success" with NO-Content (without body) e.g: DELETE
          response.success = true;
        } else {
          response.success = false;
        }
      }
    } else {
      response.success = false;
    }
    return response;
  }

  parseErrorResponse(errResponse: any) {
    var errorResponses = [];
    if (errResponse && errResponse != null && errResponse !== null) {
      if (errResponse.code === Constants.ERROR_CODES.BAD_ARGUMENT) {
        let errorDetails = errResponse.details;
        for (var errorDetail of errorDetails) {
          var errorResponse = {
            code: errorDetail['code'],
            target: errorDetail['target'],
          };
          errorResponses.push(errorResponse);
        }
      } else {
        var errorResponse = {
          code: errResponse.code,
          target: errResponse.target,
        };
        errorResponses.push(errorResponse);
      }
    } else {
      return null;
    }
    return errorResponses;
  }
}
