import { NgRedux } from '@angular-redux/store';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject, Observable } from 'rxjs';
import { PhoneVerificationBase } from '../public/base-components/phone-verification-base/phone-verification-base.component';
import { UserLoginService } from './user-login.service';
import { RegistrationUser } from './user-registration.service';
import { ApiRequestService } from './api-client/api-requests.service';
import apiConfig from './api-client/api-config';
import { Callback } from './utils/cognito.service';
import { ResponseHandlerService } from './utils/response-handler.service';
import { UserManagement } from './utils/state-management/action-classes';
import {
  ADD_SUCCESS_MESSAGE,
  UPDATE_PROFILE_DATA,
} from './utils/state-management/actions';
import { IAppState } from './utils/state-management/store';
import { VerificationService } from './utils/verification.service';
import { Constants } from './utils/Constants';
import { Utility } from './utils/utility';

export interface ToggleEnableDisableAndDeleteAccountCallback {
  onFailure(): void;
  onSuccess(): void;
}

@Injectable()
export class UserProfileService {
  constructor(
    private apiClient: ApiRequestService,
    private ngRedux: NgRedux<IAppState>,
    private responseHandler: ResponseHandlerService,
    private verificationService: VerificationService,
    public userManagement: UserManagement,
    private translate: TranslateService,
    private userService: UserLoginService,
    public utility: Utility
  ) { }
  private callback: Callback;
  private subject: Subject<PhoneVerificationBase> = new Subject();

  toggleEnableDisable(
    status,
    callback: ToggleEnableDisableAndDeleteAccountCallback
  ) {
    let user = this.userManagement.getUser();
    let body = {
      enabled: status + '',
    };
    this.apiClient
      .callApi(
        Constants.API_METHODS.PATCH,
        apiConfig.toggleEnableDisableAccount.uri,
        body,
        false
      )
      .subscribe({
        next: (res) => {
          user.accountEnabled = status;
          this.userManagement.updateUser(user);
          callback.onSuccess();
        },
        error: (err) => {
          callback.onFailure();
        },
      });
    // this.apiClient.makeCall(
    //   null,
    //   'patch',
    //   apiConfig.toggleEnableDisableAccount,
    //   body,
    //   (err, res) => {
    //     if (res && res.success) {
    //       user.accountEnabled = status;
    //       this.userManagement.updateUser(user);
    //       callback.onSuccess();
    //     } else {
    //       callback.onFailure();
    //     }
    //   }
    // );
  }

  deleteAccount(callback: ToggleEnableDisableAndDeleteAccountCallback) {
    this.apiClient
      .callApi(
        Constants.API_METHODS.DELETE,
        apiConfig.deleteAccount.uri,
        null,
        false
      )
      .subscribe({
        next: (res) => {
          this.userService.logout();
          this.ngRedux.dispatch({
            type: ADD_SUCCESS_MESSAGE,
            msg: this.translate.get('PROFILE.DELETE_ACCOUNT.SUCCESS_MESSAGE')[
              'value'
            ],
          });
          callback.onSuccess();
        },
        error: (err) => {
          callback.onFailure();
        },
      });
    // this.apiClient.makeCall(
    //   null,
    //   'delete',
    //   apiConfig.deleteAccount,
    //   null,
    //   (err, res) => {
    //     if (res && res.success) {
    //       this.userService.logout();
    //       this.ngRedux.dispatch({
    //         type: ADD_SUCCESS_MESSAGE,
    //         msg: this.translate.get('PROFILE.DELETE_ACCOUNT.SUCCESS_MESSAGE')[
    //           'value'
    //         ],
    //       });
    //       callback.onSuccess();
    //     } else {
    //       callback.onFailure();
    //     }
    //   }
    // );
  }

  deleteUserAccount(
    params,
    callback: ToggleEnableDisableAndDeleteAccountCallback
  ) {
    this.apiClient
      .callApi(
        Constants.API_METHODS.DELETE,
        apiConfig.deleteAccount.uri,
        params,
        false
      )
      .subscribe({
        next: (res) => {
          this.userService.logout();
          this.ngRedux.dispatch({
            type: ADD_SUCCESS_MESSAGE,
            msg: this.translate.instant('PROFILE.DELETE_ACCOUNT.SUCCESS_MESSAGE')
          });
          callback.onSuccess();
        },
        error: (err) => {
          callback.onFailure();
        },
      });
    // this.apiClient.makeCall(
    //   null,
    //   'delete',
    //   apiConfig.deleteAccount,
    //   params,
    //   (err, res) => {
    //     if (res && res.success) {
    //       this.userService.logout();
    //       this.ngRedux.dispatch({
    //         type: ADD_SUCCESS_MESSAGE,
    //         msg: this.translate.get('PROFILE.DELETE_ACCOUNT.SUCCESS_MESSAGE')[
    //           'value'
    //         ],
    //       });
    //       callback.onSuccess();
    //     } else {
    //       callback.onFailure();
    //     }
    //   },
    //   false
    // );
  }

  getUser() {
    return this.ngRedux.getState().user
      ? this.ngRedux.getState().user
      : new RegistrationUser();
  }

  getNewPhone(): string {
    if (
      this.ngRedux.getState().updateProfileData &&
      this.ngRedux.getState().updateProfileData['phonenumber']
    ) {
      return this.ngRedux.getState().updateProfileData['phonenumber'];
    }
    return '';
  }

  getNewDialCode(): string {
    if (
      this.ngRedux.getState().updateProfileData &&
      this.ngRedux.getState().updateProfileData['dialcode']
    ) {
      return this.ngRedux.getState().updateProfileData['dialcode'];
    }
    return '';
  }

  getNewCountryCode(): string {
    if (
      this.ngRedux.getState().updateProfileData &&
      this.ngRedux.getState().updateProfileData['countrycode']
    ) {
      return this.ngRedux.getState().updateProfileData['countrycode'];
    }
    return '';
  }

  saveData(data: {}, callback: Callback) {
    this.callback = callback;
    this.ngRedux.dispatch({
      type: UPDATE_PROFILE_DATA,
      object: data,
    });
  }

  passUserData(data) {
    this.subject.next(data);
  }

  getUserData(): Observable<any> {
    return this.subject.asObservable();
  }

  saveNumber(code: number, callback: Callback) {
    this.callback = callback;

    const verify = this.ngRedux.getState().verify;
    const data = this.ngRedux.getState().updateProfileData
      ? this.ngRedux.getState().updateProfileData
      : {};
    let userPhone = this.userManagement.getUser().phoneNumber;
    if (userPhone === verify['phone'].address) {
      delete data['phonenumber'];
      delete data['code'];
      delete data['codetype'];
      this.ngRedux.dispatch({
        type: UPDATE_PROFILE_DATA,
        object: data,
      });
      return this.update(false);
    }
    data['phonenumber'] = verify['phone'].address;
    data['code'] = code;
    data['codetype'] = 'update';
    this.ngRedux.dispatch({
      type: UPDATE_PROFILE_DATA,
      object: data,
    });

    this.update(false);
  }

  updateSecurityQuestions(
    question1: string,
    answer1: string,
    question2: string,
    answer2: string,
    question3: string,
    answer3: string,
    callback: Callback
  ) {
    const data = {};
    data['secretquestion1'] = question1;
    data['secretanswer1'] = answer1;
    data['secretquestion2'] = question2;
    data['secretanswer2'] = answer2;
    data['secretquestion3'] = question3;
    data['secretanswer3'] = answer3;
    this.ngRedux.dispatch({
      type: UPDATE_PROFILE_DATA,
      object: data,
    });

    this.callback = callback;
    this.update();
  }

  update(isGlobalLoader = true): void {
    const body = this.ngRedux.getState().updateProfileData;
    if (Object.keys(body).length <= 0) {
      this.callback.callback();
      return;
    }
    // call update api
    this.apiClient
      .callApi(
        Constants.API_METHODS.PATCH,
        apiConfig.updateUser.uri,
        body,
        isGlobalLoader
      )
      .subscribe({
        next: (res) => {
          this.callback.callback();
          let data = this.ngRedux.getState().updateProfileData;

          let user = this.userManagement.getUser();

          user.firstName = data['firstname']
            ? data['firstname']
            : user.firstName;
          user.lastName = data['lastname'] ? data['lastname'] : user.lastName;
          user.phoneNumber = data['phonenumber']
            ? data['phonenumber']
            : user.phoneNumber;
          user.profilePicture = data['profilepicture']
            ? data['profilepicture']
            : user.profilePicture;

          this.userManagement.updateUser(user);

          data = {};
          this.ngRedux.dispatch({
            type: UPDATE_PROFILE_DATA,
            object: data,
          });
        },
        error: (err) => {
          let error = this.responseHandler.parseErrorResponseV2(err);
          this.callback.callbackWithParam(error);
        },
      });
    // this.apiClient.makeCall(
    //   null,
    //   'patch',
    //   apiConfig.updateUser,
    //   body,
    //   (err, res) => {
    //     if (err) {
    //       this.callback.callbackWithParam(err);
    //     } else {
    //       if (res && res.success) {
    //         this.callback.callback();
    //         let data = this.ngRedux.getState().updateProfileData;

    //         let user = this.userManagement.getUser();

    //         user.firstName = data['firstname']
    //           ? data['firstname']
    //           : user.firstName;
    //         user.lastName = data['lastname'] ? data['lastname'] : user.lastName;
    //         user.phoneNumber = data['phonenumber']
    //           ? data['phonenumber']
    //           : user.phoneNumber;
    //         user.profilePicture = data['profilepicture']
    //           ? data['profilepicture']
    //           : user.profilePicture;

    //         this.userManagement.updateUser(user);

    //         data = {};
    //         this.ngRedux.dispatch({
    //           type: UPDATE_PROFILE_DATA,
    //           object: data,
    //         });
    //       } else {
    //         this.callback.callbackWithParam(res);
    //       }
    //     }
    //   },
    //   isGlobalLoader
    // );
  }

  getSecurityQuestions(callback) {
    this.apiClient
      .callApi(
        Constants.API_METHODS.GET,
        apiConfig.getSecreteQuestion.uri,
        null,
        false
      )
      .subscribe({
        next: (res) => {
          const data = res.data[0];
          callback.questionsAndAnswers({
            secretQuestion1: data.secretquestion1,
            secretQuestion2: data.secretquestion2,
            secretQuestion3: data.secretquestion3,
            secretAnswer1: data.secretanswer1,
            secretAnswer2: data.secretanswer2,
            secretAnswer3: data.secretanswer3,
          });
        },
        error: (err) => {
          this.callback.callbackWithParam(err);
        },
      });
    // this.apiClient.makeCall(
    //   null,
    //   'get',
    //   apiConfig.getSecreteQuestion,
    //   null,
    //   (err, res) => {
    //     if (res && res.success) {
    //       const data = res.data[0];
    //       callback.questionsAndAnswers({
    //         secretQuestion1: data.secretquestion1,
    //         secretQuestion2: data.secretquestion2,
    //         secretQuestion3: data.secretquestion3,
    //         secretAnswer1: data.secretanswer1,
    //         secretAnswer2: data.secretanswer2,
    //         secretAnswer3: data.secretanswer3,
    //       });
    //     } else {
    //       this.callback.callbackWithParam(res);
    //     }
    //   }
    // );
  }

  getCountryCode(callback) {
    this.apiClient
      .callApi(
        Constants.API_METHODS.GET,
        apiConfig.getCountryCode.uri,
        null,
        false
      )
      .subscribe({
        next: (res) => {
          const data = res.data[0];
          callback.countryCodesCallback({
            countryCodes: data,
          });
        },
        error: (err) => {
          let error = this.responseHandler.parseErrorResponseV2(err);
          this.callback.callbackWithParam(error);
        },
      });
  }

  uploadFileToPreSignedURL(preSignedUrlData, file) {
    let formData = this.utility.makeFormDataCall(preSignedUrlData, file);
    this.apiClient.callApi(
      Constants.API_METHODS.PRESIGNED, preSignedUrlData.url, formData
    ).subscribe({
      next: (res) => {
        console.log(res);
      },
      error: (err) => {
        console.log(err);
      }
    });
  }
}
