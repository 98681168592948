import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { WhiteListService } from '../../services/white-list.service';

@Component({
  selector: 'app-white-list-component',
  templateUrl: './white-list.component.html',
  styleUrls: ['./white-list.component.css']
})
export class WhiteListComponent implements OnInit {

  whiteList: FormGroup;
  errorMessage: string;

  constructor(
    public formBuilder: FormBuilder,
    private whiteListService: WhiteListService,
    private translate: TranslateService,
    private router: Router
  ) { }

  ngOnInit() {
    this.whiteList = this.formBuilder.group({
      email: [null, [Validators.required, Validators.pattern("^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$")]],
      code: [null, [Validators.required]],
    });

  }

  onSubmit() {
    let email = this.whiteList.get('email').value;
    let code = this.whiteList.get('code').value;
    this.whiteListService.checkEmail(email, code, this);
  }

  onSuccess() {
    this.router.navigate(['/home']);
    sessionStorage.setItem('whiteListed', "true");
  }
  onFailure() {
    this.translate.get('WHITELIST.ERROR').subscribe(value => this.errorMessage = value);
  }

  setTitle() {
    this.translate.get('WHITELIST.TAB_TITLE').subscribe(value => document.title = value);
  }

}
