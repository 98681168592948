<div *ngIf="(viewType == null || viewType == 'List')">
  <img *ngIf="this.batteryStatus" [src]="getImageSrc()" alt="batter-status.png" class="battery-list" title='{{batteryStatusLocale}}'>
  <div *ngIf="!this.batteryStatus">
    N/A
  </div>
</div>
<div *ngIf="(viewType == null || viewType == 'Grid')">
  <img *ngIf="this.batteryStatus" [src]="getImageSrc()" alt="batter-status.png" class="battery-grid" title='{{batteryStatusLocale}}'>
  <div *ngIf="!this.batteryStatus">
    N/A
  </div>
</div>

<!-- <div>
  <img *ngIf="this.batteryStatus" [src]="getImageSrc()" alt="batter-status.png" class="battery" title='{{batteryStatusLocale}}'>
  <div *ngIf="!this.batteryStatus">
    N/A
  </div>
</div> -->