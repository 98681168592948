import { Component, OnInit, OnDestroy } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { UserProfileService, ToggleEnableDisableAndDeleteAccountCallback } from "../../../services/user-profile.service";
import { UserManagement } from "../../../services/utils/state-management/action-classes";
import { ConfirmComponent } from "../../../helpers/modal/confirm.component";
/* import { DialogService } from "ng6-bootstrap-modal"; */
import { Utility } from "../../../services/utils/utility";

@Component({
  selector: "app-toggle-enable-disable-account",
  templateUrl: "./toggle-enable-disable-account.component.html",
  styleUrls: ["./toggle-enable-disable-account.component.css"]
})
export class ToggleEnableDisableAccountComponent implements OnInit, ToggleEnableDisableAndDeleteAccountCallback, OnDestroy {

  buttonText: string;
  accountEnabled: boolean;
  errorMessage: string;
  successMessage: string;
  disposable;
  
  constructor(
    public translate: TranslateService,
    public userProfileService: UserProfileService,
    public userManagement: UserManagement,
    /* private dialogService: DialogService, */
  ) { }

  ngOnInit() {
    Utility.focusLogo();
    this.updateStatusOnUI();
  }

  ngOnDestroy(): void {
    if(this.disposable){
      this.disposable.unsubscribe();
      this.disposable = null;
      /* this.dialogService.removeAll(); */
    }
  }

  updateStatusOnUI() {
    this.accountEnabled = this.userManagement.getUser().accountEnabled;
  }

  onSubmit() {
    // let disposable;
    if (this.accountEnabled) {
      /* this.disposable = this.dialogService.addDialog(ConfirmComponent, {
        title: this.translate.get("PROFILE.TOGGLE_ENABLE_DISABLE_ACCOUNT.WARNING_POP_UP_HEADING")["value"],
        message: this.translate.get("PROFILE.TOGGLE_ENABLE_DISABLE_ACCOUNT.WARNING_POP_UP_MESSAGE")["value"],
        confirmButtonText: this.translate.get("PROFILE.TOGGLE_ENABLE_DISABLE_ACCOUNT.WARNING_POP_UP_CONFIRM_BUTTON")["value"],
        cancelButtonText: this.translate.get("PROFILE.TOGGLE_ENABLE_DISABLE_ACCOUNT.CANCEL_BUTTON")["value"],
        showCloseIcon: true
      }).subscribe((isConfirmed) => {
        if (isConfirmed === true) {
          this.userProfileService.toggleEnableDisable(!this.accountEnabled, this);
        }
        if(this.disposable) {
          this.disposable.unsubscribe();
          this.disposable = null;
          this.dialogService.removeAll();
        }
        this.disposable = null;
      }); */
    } else {
      this.userProfileService.toggleEnableDisable(!this.accountEnabled, this);
    }
  }

  onSuccess() {
    this.updateStatusOnUI();
    if (this.accountEnabled) {
      this.successMessage = this.translate.get("PROFILE.TOGGLE_ENABLE_DISABLE_ACCOUNT.ENABLE_MESSAGE")["value"];
      this.errorMessage = null;
    } else {
      this.errorMessage = this.translate.get("PROFILE.TOGGLE_ENABLE_DISABLE_ACCOUNT.DISABLE_MESSAGE")["value"];
      this.successMessage = null;
    }
  }

  onFailure() {
    this.errorMessage = this.translate.get("PROFILE.TOGGLE_ENABLE_DISABLE_ACCOUNT.ERROR.COMMON")["value"];
    this.successMessage = null;
  }

}
