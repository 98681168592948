import { Component, OnInit, ViewChild} from "@angular/core";
import { LoggedInCallback } from "../../services/utils/cognito.service";
import { Router } from "@angular/router";
import { UserLoginService } from "../../services/user-login.service";
import { LoggingLevel } from '../../services/utils/logging-level';
import { Logger } from "../../services/utils/logger";
import { TranslateService } from "@ngx-translate/core";
import { UserManagement } from "../../services/utils/state-management/action-classes";
import { FederatedManagementService } from "../../services/federated-management.service";
import { Utility } from "../../services/utils/utility";
import { DataShareService } from "../../services/data-share.service";
import { Config } from "src/app/services/utils/Config";
import { IAppState } from "src/app/services/utils/state-management/store";
import { NgRedux } from "@angular-redux/store";
import { LoaderService } from "src/app/services/loader.service";

@Component({
  selector: "app-nav-bar",
  templateUrl: "./nav-bar.component.html",
  styleUrls: ["./nav-bar.component.css"]
})
export class NavBarComponent implements OnInit, LoggedInCallback {
  userObject: any;

  language = null;
  lang;
  userFirstName: string;
  userLastName: string;
  profilePicture: string;
  kwiksetLink = Config.KWIKSET.HELP_FAQ;
  weiserLink = Config.WEISER.HELP_FAQ;
  userBrand;
  open: boolean = false;
  @ViewChild('hamburger') hamburger;

  constructor(
    public router: Router,
    public userService: UserLoginService,
    public federationervice: FederatedManagementService,
    private logger: Logger,
    public translate: TranslateService,
    public userManagement: UserManagement,
    public utility: Utility,
    private dataservice: DataShareService,
    private ngRedux: NgRedux<IAppState>,
    private loaderService: LoaderService
  ) {
    document.addEventListener('click', this.offClickHandler.bind(this));
  }

  ngOnInit() {

    this.ngRedux.select('userObject').subscribe((value) => {
      this.userObject = value;     
    });
    this.userBrand = this.userManagement.getUserBrand().toUpperCase();
    this.logger.log(
      LoggingLevel.INFO,
      "SecureHomeComponent: ",
      "Checking if the user is authenticated. If not, then redirect to the home"
    );
    this.userService.isAuthenticated(this);
  }
  onLogout() {
    this.dataservice.fireValue({logoutCalled: true});
    this.utility.addLoader(new Date());
    this.userService.logout();
    let configObject = JSON.parse(localStorage.getItem('config') || '');
    const googleFederation = configObject && configObject.Feature.GoogleFederation;
    const appleFederation = configObject && configObject.Feature.AppleFederation
    if(googleFederation || appleFederation){
      const url = this.federationervice.getFederatedLogoutUrl();
      window.location.href = url;
    }
    else{
      this.router.navigate(["/home/login"]).then(()=>{
        setTimeout(() => {
          this.utility.removeAllLoader();
        }, 2000);
      });
    }
  }
  goToLink(){
    // picks the brand name from user data - and reads the value from config file
    let helpLink = this.userBrand === Config.USER_BRANDS.WEISER ? this.translate.instant('NAVBAR_MENU.WEISER_HELP_FAQ_LINK') : this.translate.instant('NAVBAR_MENU.KWIKSET_HELP_FAQ_LINK');
    window.open(helpLink, "_blank");
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (!isLoggedIn) {
      this.router.navigate(["/home"]);
    } else {
      let user = this.userManagement.getUser();
      /* let preferredLang = user.preferredLanguage;
      if (preferredLang && preferredLang != null) {
        this.translate.use(preferredLang);
      } */

      /* this.language = preferredLang;
      this.lang = preferredLang; */
      this.userFirstName = user.firstName;
      this.userLastName = user.lastName;
      this.profilePicture = user.presignedurl;
    }
  }

  offClickHandler(event:any) {
    if (!this.hamburger.nativeElement.contains(event.target)) {
      this.open = false;    
    }
  }

  toggle(x) {
    this.open = x;
  }
}
