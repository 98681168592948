import { NgRedux } from '@angular-redux/store';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageChangeCallback, LanguageChangeService } from "../../services/language-change.service";
import { UserLoginService } from "../../services/user-login.service";
import { LoggedInCallback } from "../../services/utils/cognito.service";
import { Logger } from "../../services/utils/logger";
import { LoggingLevel } from '../../services/utils/logging-level';
import { CHANGE_LANGUAGE } from '../../services/utils/state-management/actions';
import { IAppState } from '../../services/utils/state-management/store';

@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.scss']
})
export class LanguageComponent implements OnInit, LanguageChangeCallback, LoggedInCallback {

  language: string = '';
  lang;
  languageMsg;
  selectedlang:string;

  constructor(
    public translate: TranslateService,
    private logger: Logger,
    public userService: UserLoginService,
    public languageService: LanguageChangeService,
    private ngRedux: NgRedux<IAppState>
  ) { }

  ngOnInit() {
    if (this.ngRedux.getState().preferredLanguage && this.ngRedux.getState().preferredLanguage != '') {
      this.language = this.ngRedux.getState().preferredLanguage;
      this.translate.use(this.ngRedux.getState().preferredLanguage)
    }

  }

  onLangClick(lang:string) {
    this.selectedlang = lang;
    this.onLanguageChange(this.selectedlang);
  }

  onLanguageChange(lang) {
    this.translate.use(lang)
    this.language = lang;
    this.ngRedux.dispatch({
      type: CHANGE_LANGUAGE,
      preferredLanguage: lang
    });
    this.userService.isAuthenticated(this);
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    this.logger.log(LoggingLevel.INFO, "AppComponent: the user is authenticated:", isLoggedIn.toString());
    this.languageService.updateUserLanguage(isLoggedIn, this.language, this)

  }

  onSuccessSendCode() {
    location.reload();
    return;
  }

  onFailure() {
    return;
  }

}

