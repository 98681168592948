import { NgRedux } from '@angular-redux/store';
import { Injectable } from '@angular/core';
import { unlink } from 'fs';
import apiConfig from './api-client/api-config';
import { ApiRequestService } from './api-client/api-requests.service';
import { LanguageChangeService } from './language-change.service';
import { LoaderService } from './loader.service';
import { Config } from './utils/Config';
import { Constants } from './utils/Constants';
import { UPDATE_HOMES } from './utils/state-management/actions';
import { IAppState } from './utils/state-management/store';
import { Utility } from './utils/utility';

export interface HomeCallback {
  callback(home: IHomeObject[]);
}

export interface IHomeObject {
  homeState: string;
  ownerName: string;
  accessRights: string;
  homeId: string;
  homeName: string;
  email: string;
  sharedBy: string;
  isShared: boolean;
  homePic: string;
}
export interface IHomeHistory {
  eventType: string;
  eventLocaleName: string;
  eventTypeLocale: string;
  event: string;
  eventDescription: string;
  lockName: string;
  user: string;
  time: Date;
  lockOffsetInMinutes?: number;
  day?: string;
  dayMobile?: string;
  showTime?: string;
  history?: Array<any>;
  historyTitle?: string;

}
export interface HomeHistoryCallback {
  callback(home: IHomeHistory[], issues: IHomeHistory[], totalData: number);
}

@Injectable()
export class HomeService {

  home_name = "homeName";

  constructor(
    private ngRedux: NgRedux<IAppState>,
    public apiService: ApiRequestService,
    private loaderService: LoaderService,
    public langService: LanguageChangeService,
    public utility: Utility
  ) {}

  private homes: IHomeObject[];

  getHome(callback: HomeCallback) {
    const pathParams = {
      pageSize: Config.HOMES_TOP,
    };
    this.homes = this.ngRedux.getState().homes;
    const homeUri = apiConfig.getHome.uri;
    if (this.homes != null && this.homes.length > 0) {
      callback.callback(this.homes);
      this.apiService
        .callApi(Constants.API_METHODS.GET, homeUri, null, true, pathParams)
        .subscribe({
          next: (res: any) => {
            this.homesCallback(null, res, callback);
          },
          error: (err) => {
            this.homesCallback(err.error, null, callback);
          },
        });
      /* this.apiService.makeSilentCall(pathParams, 'get', apiConfig.getHome, null, (err, res) => {
        this.homesCallback(err, res, callback);
      }); */
    } else {
      this.apiService
        .callApi(Constants.API_METHODS.GET, homeUri, null, true, pathParams)
        .subscribe({
          next: (res: any) => {
            this.homesCallback(null, res, callback);
          },
          error: (err) => {
            this.homesCallback(err.error, null, callback);
          },
        });
    }
  }

  homesCallback(err, res, callback) {
    if (res && res.data && res.data.length >= 0) {
      const home: IHomeObject[] = [];
      res.data.forEach((element) => {
        let h: IHomeObject = {
          homePic: element['homepic'],
          homeState: element['useraccesslevelstatus'],
          ownerName: element['ownername'],
          accessRights:
            element['useraccesslevel'].toLowerCase() === 'member'
              ? 'Guest'
              : element['useraccesslevel'],
          homeId: element['homeid'],
          homeName: element['homename'],
          email: element['email'],
          sharedBy: '',
          isShared: false,
        };
        if (element['sharedbyname']) {
          h.sharedBy = element['sharedbyname'];
        }
        if (element['isshared']) {
          h.isShared = element['isshared'];
        }
        home.push(h);
        this.utility.sort(home,this.home_name,"text");
      });
      if (!Utility.compareJson(home, this.homes)) {
        this.ngRedux.dispatch({
          type: UPDATE_HOMES,
          homes: home,
        });
        callback.callback(home);
      } else if (res.data.length === 0) {
        callback.callback(home);
      }
      return;
    } else {
      callback.callback([]);
    }
  }

  getOwnersHome(): IHomeObject {
    let allHomes = this.ngRedux.getState().homes;
    let ownersHomeIndex = allHomes.findIndex(
      (home) => home.accessRights && home.accessRights.toLowerCase() === 'owner'
    );

    let ownersHome: IHomeObject = null;

    if (ownersHomeIndex !== -1) {
      ownersHome = allHomes[ownersHomeIndex];
    }

    return ownersHome;
  }

  getHomeById(id: string): IHomeObject {
    let allHomes = this.ngRedux.getState().homes;
    let ownersHomeIndex = allHomes.findIndex((home) => home.homeId === id);

    let ownersHome: IHomeObject = null;

    if (ownersHomeIndex !== -1) {
      ownersHome = allHomes[ownersHomeIndex];
    }

    return ownersHome;
  }

  getHomeHistory(homeId: string, callback: HomeHistoryCallback) {
    const pathParams = {
      id: homeId,
      pageSize: Config.HISTORY_ON_ONE_PAGE,
      orderBy: '',
      sortOrder: 'desc',
    };
    const UNKNOWN_SOURCE = 'Unknown Source';
    const STATUS_UPDATE = 'Status Update';

    this.apiService
      .callApi(
        Constants.API_METHODS.GET,
        apiConfig.getHomeHistory.uri,
        null,
        true
      )
      .subscribe({
        next: (res) => {
          if (res && res.data && res.data.length > 0) {
            const homes: IHomeHistory[] = [];
            const homeIssues: IHomeHistory[] = [];
            const events = res.data;
            const issues = res.issues ? res.issues : [];

            events.forEach((element) => {
              let languageMappingChecklist = Config.HISTORY_CHECKLISTS.find(
                (ele) =>
                  ele[this.langService.getDefaultLanguage()] === element.event
              );
              let languageMappingSourceList = Config.SOURCES_CHECKLISTS.find(
                (ele) =>
                  ele[this.langService.getDefaultLanguage()] ===
                  element.eventtype
              );
              const eventTypeLocale = languageMappingSourceList
                ? languageMappingSourceList[
                    this.langService.getCurrentLanguage()
                  ]
                : element.eventtype;
              const history: IHomeHistory = {
                eventLocaleName: languageMappingChecklist
                  ? languageMappingChecklist[
                      this.langService.getCurrentLanguage()
                    ]
                  : element.event,
                event: element['event'] ? element['event'] : '',
                eventTypeLocale: eventTypeLocale,
                eventDescription: element['eventtype']
                  ? element['eventtype'] === UNKNOWN_SOURCE
                    ? STATUS_UPDATE
                    : eventTypeLocale
                  : '',
                user: element['user'] ? element['user'] : '',
                lockName: element['devicename'] ? element['devicename'] : '',
                time: new Date(element['timestamp'] * 1000),
                eventType: element['eventtype']
                  ? element['eventtype'] === UNKNOWN_SOURCE
                    ? STATUS_UPDATE
                    : eventTypeLocale
                  : '',
              };
              homes.push(history);
            });

            issues.forEach((element) => {
              let languageMappingObj = Config.HISTORY_CHECKLISTS.find(
                (ele) =>
                  ele[this.langService.getDefaultLanguage()] === element.event
              );
              let languageMappingSourceList = Config.SOURCES_CHECKLISTS.find(
                (ele) =>
                  ele[this.langService.getDefaultLanguage()] ===
                  element.eventtype
              );
              const eventTypeLocale = languageMappingSourceList
                ? languageMappingSourceList[
                    this.langService.getCurrentLanguage()
                  ]
                : element.eventtype;
              const history: IHomeHistory = {
                eventLocaleName: languageMappingObj
                  ? languageMappingObj[this.langService.getCurrentLanguage()]
                  : element.event,
                event: element['event'] ? element['event'] : '',
                eventTypeLocale: eventTypeLocale,
                eventDescription: element['eventtype']
                  ? element['eventtype'] === UNKNOWN_SOURCE
                    ? STATUS_UPDATE
                    : eventTypeLocale
                  : '',
                user: element['user'] ? element['user'] : '',
                lockName: element['devicename'] ? element['devicename'] : '',
                time: new Date(element['timestamp'] * 1000),
                eventType: element['eventtype']
                  ? element['eventtype'] === UNKNOWN_SOURCE
                    ? STATUS_UPDATE
                    : eventTypeLocale
                  : '',
              };
              homeIssues.push(history);
            });
            callback.callback(homes, homeIssues, res.total);
            return;
          }
          this.updateHomeName(null, homeId);
          callback.callback([], [], -1);
        },
        error: (err) => {
          this.updateHomeName(null, homeId);
          callback.callback([], [], -1);
        },
      });
  }

  public updateHomeName(name, id, lockId?) {
    if (!name) {
      this.getHome({ callback: (homes) => {} });
      return;
    }
    if (lockId) {
      id = this.getHomeIdFromLockId(lockId);
    }
    const allHomes = this.ngRedux.getState().homes;
    const index = allHomes.findIndex((home) => home.homeId === id);

    if (index >= 0 && allHomes[index].homeName !== name) {
      allHomes[index].homeName = name;
      this.ngRedux.dispatch({
        type: UPDATE_HOMES,
        homes: allHomes,
      });
    }
  }

  getHomeIdFromLockId(lockId: string) {
    const locks = this.ngRedux.getState().locks;
    for (const item in locks) {
      if (locks.hasOwnProperty(item)) {
        for (const lock of locks[item]) {
          if (lock['deviceID'] === lockId) {
            return this.getHomeById(item).homeId;
          }
        }
      }
    }
  }

  getHomeHistoryWithParams(pathParams: any, callback: HomeHistoryCallback) {
    this.apiService
      .callApi(
        Constants.API_METHODS.GET,
        apiConfig.getHomeHistoryWithParams.uri,
        null,
        true,
        pathParams
      )
      .subscribe({
        next: (res) => {
          if (
            (res && res.data && res.data.length > 0) ||
            (res.issues && res.issues.length)
          ) {
            const homes: IHomeHistory[] = [];
            const homeIssues: IHomeHistory[] = [];
            const events = res.data;
            const issues = res.issues ? res.issues : [];
            const UNKNOWN_SOURCE = 'Unknown Source';
            const STATUS_UPDATE = Config.STATUS_UPDATE[this.langService.getCurrentLanguage()];
            const localOffsetInSeconds = new Date().getTimezoneOffset() * 60;
            events.forEach((element) => {
              let languageMappingObj = Config.HISTORY_CHECKLISTS.find((ele) => ele[this.langService.getDefaultLanguage()] === element.event);
              let languageMappingSourceList = Config.SOURCES_CHECKLISTS.find((ele) => ele[this.langService.getDefaultLanguage()] === element.eventtype);
              const eventTypeLocale = languageMappingSourceList
                ? languageMappingSourceList[
                    this.langService.getCurrentLanguage()
                  ]
                : element.eventtype;
              const history: IHomeHistory = {
                eventLocaleName: languageMappingObj
                  ? languageMappingObj[this.langService.getCurrentLanguage()]
                  : element.event,
                event: element['event'] ? element['event'] : '',
                eventTypeLocale: eventTypeLocale,
                eventDescription: element['eventtype']
                  ? element['eventtype'] === UNKNOWN_SOURCE
                    ? STATUS_UPDATE
                    : eventTypeLocale
                  : '',
                user: element['user']
                  ? element['user']
                  : element['eventtype']
                  ? element['eventtype'] === UNKNOWN_SOURCE
                    ? STATUS_UPDATE
                    : eventTypeLocale
                  : '',
                lockName: element['devicename'] ? element['devicename'] : '',
                time: new Date(
                  (element['timestamp'] + localOffsetInSeconds) * 1000
                ),
                lockOffsetInMinutes: this.getLockOffsetInMinutes(
                  element['timezone']
                ),
                eventType: element['eventtype']
                  ? element['eventtype'] === UNKNOWN_SOURCE
                    ? STATUS_UPDATE
                    : eventTypeLocale
                  : '',
              };
              homes.push(history);
            });

            issues.forEach((element) => {
              let languageMappingObj = Config.HISTORY_CHECKLISTS.find(
                (ele) =>
                  ele[this.langService.getDefaultLanguage()] === element.event
              );
              let languageMappingSourceList = Config.SOURCES_CHECKLISTS.find(
                (ele) =>
                  ele[this.langService.getDefaultLanguage()] ===
                  element.eventtype
              );
              const eventTypeLocale = languageMappingSourceList
                ? languageMappingSourceList[
                    this.langService.getCurrentLanguage()
                  ]
                : element.eventtype;
              const history: IHomeHistory = {
                eventLocaleName: languageMappingObj
                  ? languageMappingObj[this.langService.getCurrentLanguage()]
                  : element.event,
                event: element['event'] ? element['event'] : '',
                eventTypeLocale: eventTypeLocale,
                eventDescription: element['eventtype']
                  ? element['eventtype'] === UNKNOWN_SOURCE
                    ? STATUS_UPDATE
                    : eventTypeLocale
                  : '',
                user: element['user']
                  ? element['user']
                  : element['eventtype']
                  ? element['eventtype'] === UNKNOWN_SOURCE
                    ? STATUS_UPDATE
                    : eventTypeLocale
                  : '',
                lockName: element['devicename'] ? element['devicename'] : '',
                time: new Date(
                  (element['timestamp'] + localOffsetInSeconds) * 1000
                ),
                lockOffsetInMinutes: this.getLockOffsetInMinutes(
                  element['timezone']
                ),
                eventType: element['eventtype']
                  ? element['eventtype'] === UNKNOWN_SOURCE
                    ? STATUS_UPDATE
                    : eventTypeLocale
                  : '',
              };
              homeIssues.push(history);
            });
            callback.callback(homes, homeIssues, res.total);
            return;
          }
          this.updateHomeName(null, pathParams.id);
          callback.callback([], [], -1);
        },
        error: (err) => {
          this.updateHomeName(null, pathParams.id);
          callback.callback([], [], -1);
        },
      });
  }

  getHomesCounter() {
    const homes = this.ngRedux.getState().homes;
    return `${homes.length}`;
  }

  getLockOffsetInMinutes(timezoneStr: string) {
    let eventOffsetInSeconds = 0;
    if (timezoneStr.includes('GMT')) {
      timezoneStr = timezoneStr.replace('GMT', '');
      let colonIndex = timezoneStr.indexOf(':');
      let hours = parseInt(timezoneStr.slice(0, colonIndex));
      let mins = parseInt(timezoneStr.slice(colonIndex + 1));

      eventOffsetInSeconds = hours * 60 + mins;
    }
    return eventOffsetInSeconds;
  }
}
