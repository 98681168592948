import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { NgRedux } from '@angular-redux/store';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';
import {
  UserRegistrationService,
  RegistrationCallback,
} from '../../../services/user-registration.service';
import { UserManagement } from '../../../services/utils/state-management/action-classes';
import { IAppState } from '../../../services/utils/state-management/store';
import { ADD_SUCCESS_MESSAGE } from '../../../services/utils/state-management/actions';
import { Constants } from '../../../services/utils/Constants';
import { ApiRequestService } from '../../../services/api-client/api-requests.service';
import { IUser } from '../../../services/utils/state-management/types';
import apiConfig from '../../../services/api-client/api-config';
import { FederatedManagementService } from '../../../services/federated-management.service';
import { Utility } from '../../../services/utils/utility';
import { ResponseHandlerService } from 'src/app/services/utils/response-handler.service';
import { Config } from 'src/app/services/utils/Config';

@Component({
  selector: 'app-confirmation-screen',
  templateUrl: './confirmation-screen.component.html',
  styleUrls: ['./confirmation-screen.component.css'],
})
export class ConfirmationScreenComponent
  implements RegistrationCallback, OnInit, AfterViewInit
{
  environment = environment;
  errorMessage: string;
  user: IUser;
  brandName;
  @Input() continueType: string;
  step = 'login';
  provider = '';
  constructor(
    public translate: TranslateService,
    private registrationService: UserRegistrationService,
    public userManagementService: UserManagement,
    public apiService: ApiRequestService,
    public ngRedux: NgRedux<IAppState>,
    private googleservice: FederatedManagementService,
    private responseHandler: ResponseHandlerService,
    public router: Router
  ) {}

  ngOnInit() {
    this.brandName = localStorage.getItem('userBrand');
    this.provider = localStorage.getItem('PROVIDER') || '';
  }

  ngAfterViewInit() {
    Utility.focusLogo();
  }

  onContinue() {
    // this.registrationService.rollOverRegStep();
    // call the cloud enpoint to create a user when continueType === phone.
    if (this.provider === Config.PROVIDERS.FEDERATED) {
      // LOGIN TO GOOGLE AND REGISTER USER
      if (this.continueType === 'phone') {
        const googleEmail = sessionStorage.getItem('GoogleEmail');
        // set value once phone is verified, and flow is on last step to avoid alert popup for Email and Google users
        localStorage.setItem('PhoneVerified', 'true');
        const userSessionTokens =
          this.googleservice.getGoogleTokenSessionStorage(googleEmail);
        console.log(userSessionTokens);
        this.googleservice.saveGoogleTokens(googleEmail, userSessionTokens);
        this.googleservice.removeGoogleTokensSessionStorage(googleEmail);
        const userDetails = this.userManagementService.getUserObject();
        console.log(userDetails);
        this.googleservice.registerFederatedUser(userDetails, (err, res) => {
          if (err) {
            console.log(err);
          } else {
            console.log(res);
            this.fetchUser();
          }
        });
      }
    } else {
      Utility.focusLogo();
      if (this.continueType === 'phone') {
        this.registrationService.rollOverRegStep();
        // set value once phone is verified, and flow is on last step to avoid alert popup for Email and Google users
        // localStorage.setItem('PhoneVerified', 'true');
        // this.registrationService.register(this.userManagementService.getUserObject().password, this);
        return;
      }
      this.registrationService.rollOverRegStep();
    }
  }

  onLogin() {
    // this.registrationService.register(this.userManagementService.getUserObject().password, this);
    console.log('log this user');
    this.apiService
      .callApi(Constants.API_METHODS.GET, apiConfig.me.uri, null, false)
      .subscribe((res, err) => {
        if (res && res.data && res.data.length > 0) {
          this.user = this.userManagementService.parseUserObjectAndDispatch(
            res.data[0]
          );
          this.ngRedux.dispatch({
            type: ADD_SUCCESS_MESSAGE,
            msg: this.translate.instant('REGISTRATION.SUCCESS_MESSAGE'),
          });
          this.router.navigate(['/securehome']);
          this.step = '';
        } else {
          if (res && res['type'] === Constants.ERROR_CODES.RESOURCE_NOT_FOUND) {
            //user not found, proceed to signup
            this.userManagementService.removeUser();
            this.router.navigate(['/home/register']);
          } else {
            this.router.navigate(['/home/login']);
          }
        }
      });
    // this.apiService.makeCall(null, 'get', apiConfig.me, null, (err, res) => {
    //   if (res && res.success && res.data && res.data.length > 0) {
    //     this.user = this.userManagementService.parseUserObjectAndDispatch(
    //       res.data[0]
    //     );
    //     this.router.navigate(['/securehome']);
    //     this.step = '';
    //   } else {
    //     if (res && res['type'] === Constants.ERROR_CODES.RESOURCE_NOT_FOUND) {
    //       //user not found, proceed to signup
    //       this.userManagementService.removeUser();
    //       this.router.navigate(['/home/register']);
    //     } else {
    //       this.router.navigate(['/home/login']);
    //     }
    //   }
    // });

    this.apiService
      .callApi(Constants.API_METHODS.GET, apiConfig.me.uri, null)
      .subscribe({
        next: (res) => {
          console.log(res, JSON.stringify(res));
        },
        error: (err) => {
          let data = this.responseHandler.parseErrorResponseV2(err);
        },
      });
  }

  registrationCallback(message: string, result: any): void {
    if (result) {
      if (result.success) {
        this.ngRedux.dispatch({
          type: ADD_SUCCESS_MESSAGE,
          msg: this.translate.instant('REGISTRATION.SUCCESS_MESSAGE'),
        });
        this.registrationService.rollOverRegStep();
        this.errorMessage = '';
        // this.router.navigate(['/home']);
      } else {
        const errorMessages = this.getErrorMessage(
          'REGISTRATION.SUMMARY_PASSWORD.ERROR',
          result
        );
        let errMessg = '';
        this.errorMessage = '';
        for (errMessg of errorMessages) {
          this.errorMessage += errMessg + ', ';
        }
        this.errorMessage = this.errorMessage.substring(
          0,
          this.errorMessage.length - 2
        );
        if (this.errorMessage.slice(-1) !== '.') {
          this.errorMessage += '.';
        }
      }
    } else {
      const msg = this.translate.get(
        'REGISTRATION.SUMMARY_PASSWORD.ERROR.COMMON'
      );
      this.errorMessage = msg['value'];
    }
  }

  getErrorMessage(componentMessagePath: string, parsedResponse: any) {
    let msg = [];

    if (parsedResponse && parsedResponse.data) {
      const error = parsedResponse.data;
      let dataElement;

      if (error !== null && error.length > 0) {
        for (dataElement of error) {
          if (dataElement) {
            if (
              dataElement.target &&
              dataElement.target.toLowerCase() === 'usersignup'
            ) {
              msg.push(
                this.translate.get(
                  componentMessagePath + '.' + dataElement.code.toUpperCase()
                )['value']
              );
            } else {
              msg.push(
                this.translate.get(
                  componentMessagePath +
                    '.' +
                    dataElement.code.toUpperCase() +
                    '.' +
                    dataElement.target.toUpperCase()
                )['value']
              );
            }
          }
        }
      }
    } else {
      msg.push(this.translate.get(componentMessagePath + '.COMMON')['value']);
    }
    return msg;
  }

  fetchUser(): void {
    this.apiService
      .callApi(Constants.API_METHODS.GET, apiConfig.me.uri, null, false)
      .subscribe((meResponse, err) => {
        if (
          meResponse &&
          meResponse.data &&
          meResponse.data.length > 0
        ) {
          this.user = this.userManagementService.parseUserObjectAndDispatch(
            meResponse.data[0]
          );
          this.router.navigate(['/securehome']).then(() => {
            location.reload();
          });
        } else {
          if (
            meResponse &&
            meResponse['type'] === Constants.ERROR_CODES.RESOURCE_NOT_FOUND
          ) {
            //user not found, proceed to signup
            this.userManagementService.removeUser();
            console.log('HERE');
            localStorage.setItem('PROVIDER', 'FEDERATED');
            localStorage.setItem('FLOW', 'SIGNUP');
            this.router.navigate(['/home/register']);
          } else {
            this.router.navigate(['/home']);
          }
        }
      });
    // this.apiService.makeCall(
    //   null,
    //   'get',
    //   apiConfig.me,
    //   null,
    //   (err, meResponse) => {
    //     if (
    //       meResponse &&
    //       meResponse.success &&
    //       meResponse.data &&
    //       meResponse.data.length > 0
    //     ) {
    //       this.user = this.userManagementService.parseUserObjectAndDispatch(
    //         meResponse.data[0]
    //       );
    //       this.router.navigate(['/securehome']).then(() => {
    //         location.reload();
    //       });
    //     } else {
    //       if (
    //         meResponse &&
    //         meResponse['type'] === Constants.ERROR_CODES.RESOURCE_NOT_FOUND
    //       ) {
    //         //user not found, proceed to signup
    //         this.userManagementService.removeUser();
    //         console.log('HERE');
    //         localStorage.setItem('PROVIDER', 'GOOGLE');
    //         localStorage.setItem('FLOW', 'SIGNUP');
    //         this.router.navigate(['/home/register']);
    //       } else {
    //         this.router.navigate(['/home']);
    //       }
    //     }
    //   }
    // );
  }
}
