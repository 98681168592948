const EULA_eng = `<p>
    <strong>
        End-User Agreement
        <br/>
    </strong>
    <br/>
    <br/>
</p>
<p>
    <a name="_GoBack"></a>
    <em>Last Updated: July 1, 2016 (App ver. 1.0)</em>
    <br/>
    <br/>
    <strong>TERMS OF SERVICE</strong>
    <br/>
    <br/>
    BY USING THIS SPECTRUM MOBILE APP (THE “APP”), YOU ACCEPT THESE TERMS OF
    USE (“TERMS”). IF YOU DO NOT AGREE WITH THESE TERMS, YOU SHOULD IMMEDIATELY
    UNINSTALL THIS APP AND DISCONTINUE ITS USE. THESE TERMS FORM A BINDING
    LEGAL CONTRACT BETWEEN YOU AND SPECTRUM BRAND HOLDINGS, INC. (“SPECTRUM”).
    <br/>
    <br/>
    <strong>Age Restriction</strong>
    <br/>
    Only individuals thirteen (13) years of age or older may use the App. Users
    ages 13-18 should obtain parental approval to use the App and accept these
    Terms.
    <br/>
    <br/>
    <strong>Additional Terms and Policies; Changes/Updates</strong>
    <br/>
    These Terms incorporate and supplement the Apple, Inc. (“Apple”) Terms and
Conditions (available at    <u>http://www.apple.com/legal/itunes/us/terms.html#service</u>), including
    without limitation the Licensed App End User Agreement therein (“Apple
    Terms”). In addition, these Terms also incorporate and supplement the
    following Spectrum policies and terms of Spectrum, which are reproduced at
    the end of these Terms. In the event of any conflict, these Terms will
control but only to the extent applicable to use of the App.<em><strong>We may update these Terms, or the accompanying </strong></em><em><u><strong>Online Terms of Service</strong></u></em><em><strong> and/or </strong></em>    <em><u><strong>Privacy Policy</strong></u></em>
    <em>
        <strong>
            at any time; provided that in the event of any update, we will
            notify users of the change and require confirmation of acceptance
            of the update. If you do not agree to the update, you will cease to
            have access to the App, and you will not be bound by the update.
        </strong>
    </em>
</p>
<ul>
    <li>
        <p>
            Spectrum Online Terms of Service
        </p>
    </li>
    <li>
        <p>
            Spectrum Privacy Policy
        </p>
    </li>
</ul>
<p>
    <strong>End-User License</strong>
    <br/>
    Subject to these Terms, Spectrum grants you a personal, non-exclusive,
    non-transferable, limited and revocable license to use the App for personal
    use only on an Apple iPhone, iPad, or iPod Touch (“iOS Device”) owned or
    controlled by you as permitted by the Usage Rules contained in the Apple
    Terms and in accordance with these Terms (“User License”). Any use of the
    App in any other manner, including, without limitation, resale, transfer,
    modification or distribution of the App or text, pictures, music, barcodes,
    video, data, hyperlinks, displays, and other content associated with the
    App (“Content”) is prohibited. These Terms also govern any updates to, or
    supplements or replacements for, this App unless separate terms accompany
    such updates, supplements or replacements, in which case the separate terms
    will apply. The license is effective until terminated by you or Spectrum,
    with or without written notice. Your rights hereunder terminate
    automatically without notice from Spectrum if you fail to comply with any
    Terms or terms of the User License. Upon any termination of the User
    License, you shall cease all use of the App and destroy all copies, full or
    partial, of the App.
</p>
<p>
    <strong>User Information</strong>
    <br/>
    Neither downloading nor using the App requires creation of an account or
    submission of any personally identifiable information. Nor does the App
    need or gather any personally identifiable information. You may, however,
    elect to enable the “Address Book” feature to facilitate sharing of
    lock-control capability with others who are in your address book. The
    choice to use this feature is voluntary. If you enable the feature, the App
    will gain access to information in the address book in your iOS device to
    enable sending/sharing of information with the people you choose. Such
    information will be used to facilitate any sharing of lock-control
    abilities you elect to initiate and not for any other purpose.
</p>
<p>
    <br/>
    By using the App, you consent to the transmission of information to
    Spectrum, including its agents and third-party partners, and consent to
    Spectrum, including its agents and third-party partners, receiving,
    collecting, storing, processing, transmitting, and using any information
    you submit or transmit for App functionality and for the purposes disclosed
    in our <u>Privacy Policy</u>, subject to the limitations on use of Address
    Book information listed above.
</p>
<p>
    You further agree that Spectrum, its service providers and/or others
    involved in creating or providing the App may collect and use technical
    data and related information—including but not limited to technical
    information about your device, system and App software, and
    peripherals—that is gathered periodically to facilitate the provision of
    software updates, product support, and other services to you (if any)
    related to the App. Spectrum, its service providers, and/or others involved
    in creating or providing the App may use this information, as long as it is
    in a form that does not personally identify you, to improve the App or to
    provide services or technologies to you.
    <br/>
    <br/>
    <strong>Your Responsibility for Your Mobile Device</strong>
</p>
<p>
    You are solely responsible for (a) the confidentiality and security of User
    Information sent from or stored on your iOS Device by the App, (b) taking
    precautionary steps to protect User Information stored on your iOS Device,
    including without limitation password-protecting the iOS Device and
    employing Apple’s remote-wipe feature, and (c) all activities undertaken
    via your device whether authorized by you or not. This includes any and all
    unauthorized use of the App by anyone with access to your device. You agree
    to immediately notify Spectrum of any suspected unauthorized actions
    associated with the App or any other breach of security. Spectrum shall not
    be responsible for any losses arising from loss or theft of data or
    property due to unauthorized or fraudulent transactions related your
    device.
</p>
<p>
    <strong>Prohibited Uses</strong>
    <br/>
    Use of the App is limited to the contemplated functionality. The App shall
    not be used in any way that does any of the following:
    <br/>
    <br/>
</p>
<ul>
    <li>
        <p>
            Harasses, abuses, stalks, threatens, defames, or otherwise
            infringes or violates the rights of any other party (including but
            not limited to rights of publicity or other proprietary rights).
        </p>
    </li>
    <li>
        <p>
            Is unlawful, fraudulent, or deceptive.
        </p>
    </li>
    <li>
        <p>
            Uses technology or other means to access unauthorized content or
            non-public spaces, including but not limited to use of “bots,”
            “spiders,” or “crawlers.”
        </p>
    </li>
    <li>
        <p>
            Attempts to (a) introduce viruses or any other harmful computer
            code, files or programs that interrupt, destroy or limit the
            functionality of any computer software or hardware or
            telecommunications equipment, (b) damage, disable, overburden, or
            impair Spectrum servers or networks, or (c) gain unauthorized
            access to a Spectrum computer network or user accounts.
        </p>
    </li>
    <li>
        <p>
            Encourages conduct that would constitute a criminal offense, or
            that gives rise to civil liability.
        </p>
    </li>
    <li>
        <p>
            Violates these Terms in any manner.
        </p>
    </li>
    <li>
        <p>
            Fails to comply with applicable third-party terms and conditions or
            other third-party policies (collectively “Acceptable Use”).
        </p>
    </li>
</ul>
<p>
    Spectrum reserves the right, in its sole discretion, to terminate any User
    License, remove Content, assist law enforcement in the prosecution of
    criminal liability, or assert a civil or criminal legal action with respect
    to Content or use of the App, that Spectrum reasonably believes is or might
    be in violation of these Terms. Any failure or delay in taking such actions
    does not constitute a waiver of its rights to enforce these Terms.
    <br/>
    <br/>
</p>
<p>
    <strong>Indemnification</strong>
    <br/>
    You agree to indemnify and hold harmless Spectrum, including its agents,
    affiliated companies, employees, contractors, directors, and officers, and
    anyone involved in creating or providing the App, from all claims or causes
    of action, liabilities, damages, costs, fines, penalties, and expenses
    (including attorneys’ fees) occurring from or related to the use or misuse
    of the App, violation of these Terms, or violations of any rights of a
    third party, or any allegation thereof. Spectrum reserves the right to
    assume the exclusive defense and control of any matter otherwise subject to
    indemnification by you, in which event you will cooperate in asserting any
    available defenses.
</p>
<p>
    <br/>
    <strong>No Warranties</strong>
    <br/>
    SPECTRUM IS PROVIDING THE APP AND CONTENT “AS IS” AND “AS AVAILABLE”
    WITHOUT ANY WARRANTIES OR REPRESENTATIONS, EXPRESSED OR IMPLIED. YOU USE
    THE APP AT YOUR OWN RISK. TO THE FULLEST EXTENT ALLOWABLE UNDER APPLICABLE
    LAW, SPECTRUM DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS OR IMPLIED,
    INCLUDING ANY WARRANTIES THAT THE APP IS MERCHANTABLE, RELIABLE, ACCURATE,
    FIT FOR A PARTICULAR PURPOSE OR NEED, NON-INFRINGING OR FREE OF DEFECTS OR
    ABLE TO OPERATE ON AN UNINTERRUPTED BASIS, OR THAT THE USE OF THE APP BY
    THE USER IS IN COMPLIANCE WITH LAWS, OR THAT INFORMATION TRANSMITTED IN
    CONNECTION WITH THE APP WILL BE SUCCESSFULLY, ACCURATELY OR SECURELY
    TRANSMITTED.
    <br/>
    <br/>
    <strong>No Liability</strong>
    <br/>
    TO THE FULLEST EXTENT ALLOWABLE UNDER APPLICABLE LAW, IN NO EVENT SHALL
    SPECTRUM OR ITS AGENTS OR ANYONE INVOLVED IN CREATING OR PROVIDING THIS APP
    OR CONTENT (A) BE LIABLE WITH RESPECT TO USE OF THE APP; AND/OR (B) BE
    LIABLE FOR ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL,
    PUNITIVE, EXEMPLARY OR OTHER DAMAGES, INCLUDING, WITHOUT LIMITATION,
    DAMAGES FOR LOSS OF GOODWILL, LOST PROFITS, LOSS, THEFT OR CORRUPTION OF
    USER INFORMATION, THE INABILITY TO USE THE APP, OR DEVICE FAILURE OR
    MALFUNCTION. YOUR SOLE REMEDY IS TO CEASE USE OF THE APP. SPECTRUM, ITS
    AGENTS AND ANYONE INVOLVED IN CREATING OR PROVIDING THIS APP OR CONTENT
    SHALL NOT BE LIABLE EVEN IF ANY OF THEM OR ANY AUTHORIZED REPRESENTATIVE OF
    THEM HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, INCLUDING WITHOUT
    LIMITATION DAMAGES OR INJURY CAUSED BY ERROR, OMISSION, INTERRUPTION,
    DEFECT, FAILURE OF PERFORMANCE, UNAUTHORIZED USE, DELAY IN OPERATION OR
    TRANSMISSION, LINE FAILURE, COMPUTER VIRUS, WORM, TROJAN HORSE OR OTHER
    HARM.
    <br/>
    <br/>
    In the event that applicable law does not allow the exclusion of certain
    warranties or the limitation or exclusion of liability for incidental,
    consequential or other damages, in no event shall Spectrum, its agents or
    anyone involved in creating or providing this App or Content be liable for
    damages, losses, and/or causes of action exceeding the amount, if any, paid
    by you for use of the App or $100, whichever is less.
    <br/>
    <br/>
    <strong>Intellectual Property</strong>
    <br/>
    The Spectrum name, logo and other marks graphics and logos used in
    connection with the App are trademarks or registered trademarks of Spectrum
    (collectively “Spectrum Marks”). Other trademarks, service marks, graphics
    and logos used in connection with the App are the trademarks of their
    respective owners (collectively “Third-Party Marks”). The Spectrum Marks
    and Third-Party Marks may not be copied, imitated or used, in whole or in
    part, without the prior written permission of Spectrum or the applicable
    trademark holder. The App and the Content are protected by copyright,
    trademark, patent, trade secret, international treaties, laws and other
    proprietary rights, and also may have security components that protect
    digital information only as authorized by Spectrum or the owner of the
    Content.
</p>
<p>
    <strong>Choice of Law; Jurisdiction for Disputes</strong>
    <br/>
    These Terms are governed by the laws of the State of Wisconsin, without
    regard to conflict of laws rules. You consent to the exclusive jurisdiction
    of the state or federal courts in Madison, Wisconsin for purposes of any
    legal action arising out of or related to the use of the App or these Terms
    to the extent. Further details on disputes are set forth below in the
    Spectrum Online <u><strong>Terms of Service</strong></u>.
    <br/>
    <br/>
    <strong>Severability</strong>
    <br/>
    If any of these Terms shall be deemed invalid, void, or for any reason
    unenforceable, those terms shall be deemed severable and shall not affect
    the validity and enforceability of any remaining terms. Failure of Spectrum
    to exercise or enforce any right or provision of this Agreement shall not
    constitute a waiver of such right or provision in that or any other
    instance.
    <br/>
    <br/>
</p>
<p>
    <strong>Contact Us</strong>
    <br/>
    If you have any questions regarding our privacy practices, you can email us
    at
</p>
<p>
    Spectrum Brands, Inc.
    <br/>
    Attention: Legal Department
    <br/>
    3001 Deming Way
    <br/>
    Middleton, WI 53562-143
</p>`;

export default EULA_eng;
