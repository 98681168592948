<h2 class="hidden-xs text-center title"> {{ 'PROFILE.DELETE_ACCOUNT.TITLE' | translate }}</h2>
<div class="sp-body clearfix">
  <h2 class="visible-xs text-center mobile-title"> {{ 'PROFILE.DELETE_ACCOUNT.TITLE' | translate }}</h2>
  <form class="form-signin col-md-offset-3 col-md-6 col-sm-8 col-sm-offset-2" method="POST" action="#" role="form">
    <p class="subtext">{{ 'PROFILE.DELETE_ACCOUNT.WARNING_MESSAGE.PRE_MESSAGE' | translate }}</p>

    <div class="disable-link">{{ 'PROFILE.DELETE_ACCOUNT.WARNING_MESSAGE.DISABLE_PRE' | translate }}
      <a class="sp-hyperlink" [routerLink]="['/profile/toggleEnableDisableAccount']">{{ 'PROFILE.DELETE_ACCOUNT.WARNING_MESSAGE.DISABLE_LINK' | translate }}</a>
    </div>
    <div class="form-group col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2">
      <button (click)="showConfirmationDialog()"id="submit" type="submit" class="btn btn-block sp-form-button sp-red-button">
          {{'PROFILE.DELETE_ACCOUNT.DELETE_BUTTON' | translate}}
      </button>
    </div>
  </form>
</div>


