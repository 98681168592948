<div class="col-xs-12 col-sm-12 col-md-11 col-md-offset-1 col-lg-8 col-lg-offset-2 account-info-main-div">

  <div *ngIf="isShowingTermsOfService===true" class="show-terms-of-service">
    <h3 class="registration-new-account-title"> {{ 'REGISTRATION.TERMS.TITLE' | translate }} </h3>
    <p class="terms-of-service-p">{{ 'REGISTRATION.TERMS.MESSAGE' | translate }}</p>
    <br />
    <div class="terms-of-service-text">
      <iframe [src]="url" width="100%" height="375"
        type="application/pdf"></iframe>
    </div>

    <form [formGroup]="signup">
      <br />
      <mat-checkbox tabIndex="20" #confirmed aria-label="checkbox-for-captcha" input (change)='toggleEula($event); captchaRef.execute()'
        formControlName="eulaCheck" class="example-margin">
      </mat-checkbox>
      <span style="margin-left: 5px;bottom: 2px;">
        {{'REGISTRATION.COLLECT_INFO.LABEL_TERMS_OF_SERVICE_TEXT' | translate }} <a (click)='showDialog()' tabIndex="21"
          class="sp-checkbox-a"> {{'REGISTRATION.COLLECT_INFO.LABEL_TERMS_OF_SERVICE_LINK' | translate }} </a>
      </span>

    </form>
    <div class="row" style="padding-top: 10px;">
      <div class="col-lg-6 col-md-6 col-sm-12 btn-padding-left">

        <button type="button" (click)='onCollectInfo()' class="btn btn-block sp-btn-dark use-your-email margin-top-5px"
          [disabled]="!signup.valid && !confirmed.checked">
          {{ 'REGISTRATION.COLLECT_INFO.BUTTON_SUBMIT_FORM' | translate }}
        </button>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 btn-padding-right">

        <button (click)='backHandler()' class="btn btn-block use-your-gmail margin-top-5px">
          {{ 'REGISTRATION.TERMS.BACK_BUTTON' | translate }}
        </button>
      </div>
    </div>
  </div>

  <br />
  <div [ngClass]="{'show-terms-of-service-hidden': isShowingTermsOfService}">
    <div *ngIf="errorMessage!=null" class="alert alert-danger alert-dismissible fade in">
      <a class="close" aria-label="close" (click)="errorMessage = null">&times;</a>
      {{ errorMessage }}
    </div>
    <ng-container *ngIf="provider === federatedProvider && userAlreadyExists">
      <br />
      <div class="alert alert-danger alert-dismissible fade in">
        <a class="close" aria-label="close" (click)="userAlreadyExists = false">&times;</a>
        {{'REGISTRATION.EMAIL_VERIFICATION.ERROR.ALREADY_EXIST' | translate}}
      </div>
      <br />
    </ng-container>
    <h3 class="registration-new-account-title"> {{ 'REGISTRATION.TITLE' | translate }} </h3>
    <!-- <hr class="sp-red-border"> -->
    <form class="sp-reg-form" method="POST" action="#" role="form" [formGroup]="signup" (ngSubmit)="onCollectInfo()">


      <div class="form-group">
        <div class="form-group has-feedback">
          <div
            [ngClass]="{'has-error' : signup.get('firstName').invalid && signup.get('firstName').dirty, 'has-success' : signup.get('firstName').valid}">
            <!-- <input matInput id="firstName" type="text" maxlength="30" formControlName="firstName"
              [placeholder]="'REGISTRATION.COLLECT_INFO.LABEL_FIRST_NAME' | translate"> -->
            <mat-form-field class="full-width" appearance="fill">
              <mat-label>{{'REGISTRATION.COLLECT_INFO.LABEL_FIRST_NAME' | translate}}</mat-label>
              <input #nameFocus tabIndex="11" maxlength="30" matInput id="firstName" formControlName="firstName">
            </mat-form-field>
            <p *ngIf="signup.get('firstName').invalid && signup.get('firstName').dirty" class="help-block">{{
              'VALIDATION.FIRSTNAME' | translate}}</p>
          </div>
        </div>
        <div class="form-group has-feedback">
          <div
            [ngClass]="{'has-error' : signup.get('lastName').invalid && signup.get('lastName').dirty, 'has-success' : signup.get('lastName').valid}">
            <mat-form-field class="full-width" appearance="fill">
              <mat-label>{{'REGISTRATION.COLLECT_INFO.LABEL_LAST_NAME' | translate}}</mat-label>
              <input tabIndex="12" maxlength="30" matInput id="lastName" formControlName="lastName">
            </mat-form-field>
            <p *ngIf="signup.get('lastName').invalid && signup.get('lastName').dirty" class="help-block">{{
              'VALIDATION.LASTNAME' | translate}}</p>
          </div>
        </div>
        <ng-container *ngIf="provider !== federatedProvider">
          <div class="form-group has-feedback">
            <div
              [ngClass]="{'has-error' : userAlreadyExists || (signup.get('email').invalid && signup.get('email').dirty), 'has-success' : !userAlreadyExists && signup.get('email').valid}">
              <mat-form-field class="full-width" appearance="fill">
                <mat-label>{{'REGISTRATION.COLLECT_INFO.LABEL_EMAIL' | translate}}</mat-label>
                <input tabIndex="13" matInput type="email" maxlength="100" id="emailVerify" formControlName="email">
              </mat-form-field>
              <p *ngIf="!userAlreadyExists && signup.get('email').invalid && signup.get('email').dirty"
                class="help-block">{{
                'VALIDATION.EMAIL' | translate}}</p>
              <p *ngIf="userAlreadyExists" style="color: #a94442" class="help-block">
                {{'REGISTRATION.EMAIL_VERIFICATION.ERROR.ALREADY_EXIST' | translate}}</p>
            </div>
          </div>
        </ng-container>
        <div>
          <phone-field [formname]="signup" [selectedCountryCode]="selectedCountryCode" (selectedCountryEvent)="selectedCountry($event)"></phone-field>
        </div>
        <ng-container *ngIf="provider !== federatedProvider">
          <div class="form-group has-feedback">
            <div
              [ngClass]="{'has-error' : signup.get('password').invalid && signup.get('password').dirty, 'has-success' : signup.get('password').valid}">
              <mat-form-field class="full-width" appearance="fill">
                <mat-label>{{ 'REGISTRATION.SUMMARY_PASSWORD.LABEL_PASSWORD' | translate }}</mat-label>
                <input tabIndex="16" matInput type="password" id="password" formControlName="password"
                  [type]="hidePass ? 'password' : 'text'">

                  <mat-icon tabIndex="17" class="cursor-pointer eye-focus" (click)="togglePasswordField('password')" (keydown.enter)="togglePasswordField('password')" matSuffix>{{hidePass ?
                    'visibility' : 'visibility_off'}}</mat-icon>
              </mat-form-field>
              <div style="margin-top: -8px;" *ngIf="signup.get('password').value !== null" class="password-steps">

                <p class="password-errors"
                  [ngStyle]="{'color':signup.get('password').value != null && (signup.get('password').value).length > 7 && signup.get('password').value != null && (signup.get('password').value).length < 21  ? '#41b705' : 'black'}">
                  {{ 'VALIDATION.PASSWORD_STEP_1' | translate }} </p>
                <p class="password-errors"
                  [ngStyle]="{'color':signup.get('password').value != null &&  (signup.get('password').value).match(upperCase) ? '#41b705' : 'black'}">
                  {{ 'VALIDATION.PASSWORD_STEP_2' | translate }}</p>
                <p class="password-errors"
                  [ngStyle]="{'color':signup.get('password').value != null &&  (signup.get('password').value).match(lowerCase) ? '#41b705' : 'black'}">
                  {{ 'VALIDATION.PASSWORD_STEP_3' | translate }}</p>
                <p class="password-errors"
                  [ngStyle]="{'color':signup.get('password').value != null && (signup.get('password').value).match(number)  ? '#41b705' : 'black'}">
                  {{ 'VALIDATION.PASSWORD_STEP_4' | translate }}</p>
                  <p class="password-errors"
                  [ngStyle]="{'color':signup.get('password').value != null && (signup.get('password').value).match(specialCharacter)  ? '#41b705' : 'black'}">
                  {{ 'VALIDATION.PASSWORD_STEP_5' | translate }}</p>
                <p style="font-size: 11px;"
                  [ngStyle]="{'color':signup.get('password').value != null && (signup.get('password').value).match(space)  ? 'black' : '#41b705'}">
                  {{ 'VALIDATION.PASSWORD_STEP_6' | translate }}</p>


              </div>
            </div>
          </div>
          <div class="form-group has-feedback">
            <div
              [ngClass]="{'has-error' : signup.get('repeatPassword').value !== signup.get('password').value , 'has-success' : signup.get('repeatPassword').value === signup.get('password').value}">
              <mat-form-field class="full-width" appearance="fill">
                <mat-label>{{ 'REGISTRATION.SUMMARY_PASSWORD.LABEL_REPEAT_PASSWORD' | translate }}</mat-label>
                <input tabIndex="18" (keyup)="onEnterPassword($event)" #password matInput type="password"
                  [type]="hideRepeatPass ? 'password' : 'text'" id="repeatPassword" formControlName="repeatPassword"
                  [(ngModel)]="repeatPassword" ngModelOptions="{standalone: true}">
                <mat-icon tabIndex="19" class="cursor-pointer eye-focus" (click)="togglePasswordField('repeatPassword')" (keydown.enter)="togglePasswordField('repeatPassword')" matSuffix>
                  {{hideRepeatPass ?
                  'visibility' : 'visibility_off'}}</mat-icon>
              </mat-form-field>
              <p style="margin: 0px;"
                *ngIf="signup.get('repeatPassword').value != '' && signup.get('repeatPassword').value !== signup.get('password').value"
                class="help-block">{{ 'VALIDATION.RETYPE_MATCH' | translate}}</p>
            </div>
          </div>

        </ng-container>
     
        <section class="example-section">
          <mat-checkbox tabIndex="20" aria-label="checkbox-for-captcha" input (change)='toggleEula($event); captchaRef.execute()'
            formControlName="eulaCheck" class="example-margin">
          </mat-checkbox>
          <span style="margin-left: 5px;bottom: 2px;">
            {{'REGISTRATION.COLLECT_INFO.LABEL_TERMS_OF_SERVICE_TEXT' | translate }} 
            <a [href]="url"
            target="_blank" tabIndex="21" class="sp-checkbox-a">{{ 'REGISTRATION.COLLECT_INFO.LABEL_TERMS_OF_SERVICE_LINK' | translate}}</a>
          </span>
        </section>

        <re-captcha #captchaRef class="g-recaptcha" (resolved)="resolved($event)" size="invisible" [siteKey]='siteKey'
          ng-style="{'transform':'scale(0.77)','-webkit-transform':'scale(0.77)','transform-origin':'0 0','-webkit-transform-origin':'0 0'}">
        </re-captcha>


        <ng-container *ngIf="provider !== federatedProvider">
          <div class="row submit-button">
            <div class="col-lg-5 no-padding mb-25px">
              <button tabIndex="22"  id="submitInfo" type="submit" class="btn btn-block sp-btn-dark h-45"
                [disabled]="!signup.valid || (signup.get('password').value !== signup.get('repeatPassword').value)">
                {{ 'REGISTRATION.COLLECT_INFO.BUTTON_SUBMIT_FORM' | translate }}
              </button>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="provider === federatedProvider">
          <div class="row submit-button">
            <div class="col-lg-5 no-padding mb-25px">
              <button tabIndex="22"  id="submitInfo" type="submit" class="btn btn-block sp-btn-dark h-45" [disabled]="!signup.valid">
                {{ 'REGISTRATION.COLLECT_INFO.BUTTON_SUBMIT_FORM' | translate }}
              </button>
            </div>
          </div>
          <div class="hidden-xs hidden-sm">
            <div style="padding-bottom: 38%;"></div>
          </div>
        </ng-container>
      </div>
    </form>
  </div>
  <!-- <div id='pdf-div' class='pdf-view'></div> -->
</div>