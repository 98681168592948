<div class="{{modalCss}}">
<div class="modal-header">
  <button type="button" class="close close-btn" (click)="closeDialogue(lastTab)"><mat-icon>clear</mat-icon></button>
 </div>
 <div class="modal-body">
  <div class="options-modal" tabindex="-1" #filterModal>
    <div class="col-md-12 col-sm-12 col-xs-12 view-shared-div">
      <div class="col-xs-12">
        <div class="row">
          <div class="col-md-12  main-head">
            <div class="options-modal" *ngIf="currentTab==0">
              <h3 class="text-center header-modal">{{ 'HOME.HISTORY.FILTERS.FILTERS_BY_TEXT' | translate }}</h3>
              <div class="form-group has-feedback">
                <ng-container>
                  <a  tabindex="0" role="button" (keydown.enter)="resetFilterAndCloseDialog()" (click)="resetFilterAndCloseDialog()" class="reset-btn-link sp-hyperlink">{{
                    'HOME.HISTORY.FILTERS.RESET_TEXT' | translate }}</a>
                </ng-container>
                <div class="container filter-main"> 
                  <div tabindex="0" role="button" (keydown.enter)="changeTab(2, false)" class="filter-main-row filter-main-row-top" (click)="changeTab(2, false)">
                    <div style="background-color:white ;">
                      <img class="row-icon" src="../../../../assets/images/home/ico-fingerprint.png">
                      <b>{{ 'SHARE_HOME.TITLE.ROLE' | translate }}</b>
                    </div>
                    <div>
                      <p class="filter-text"> <span *ngFor="let s of selectedAccessLevels">{{s[langCode]}}&nbsp;</span>
                      </p>
                      <img src="../../../../assets/images/home/ico-chevron-forward-outline.png">
                    </div>
                  </div>
                  <div class="filter-main-row" tabindex="0" role="button" (keydown.enter)="changeTab(3, false)"  (click)="changeTab(3, false)">
                    <div>
                      <img class="row-icon" src="../../../../assets/images/home/ico-checkmark.png">
                      <b>{{ 'SHARE_HOME.TITLE.STATUS' | translate }}</b>
                    </div>
                    <div>
                      <p class="filter-text"> <span *ngFor="let s of selectedStatuses">{{s[langCode]}}&nbsp;</span> </p>
                      <img src="../../../../assets/images/home/ico-chevron-forward-outline.png">
                    </div>
                  </div>
                  <div class="filter-main-row filter-main-row-bottom" tabindex="0" role="button" (keydown.enter)="changeTab(4, false)" (click)="changeTab(4, false)">
                    <div>
                      <img class="row-icon" src="../../../../assets/images/home/ico-share-user-access.png">
                      <b>{{ 'SHARE_HOME.TITLE.SHARED_BY' | translate }}</b>
                    </div>
                    <div>
                      <p class="filter-text">{{selectedSharedBy[0]}}</p>
                      <img src="../../../../assets/images/home/ico-chevron-forward-outline.png">
                    </div>
                  </div>
                  <button tabindex="0" (click)="closeDialog()"
                    [disabled]="selectedAccessLevels.length === 0 && selectedStatuses.length === 0 && selectedSharedBy.length === 0"
                    class="options-modal-btn btn-block btn-filled btn-filter-box">
                    <b>{{ 'HOME.HISTORY.FILTERS.SEE_RESULTS_TEXT' | translate }}</b>
                  </button>
                </div>
              </div>
            </div>
            <div class="options-modal" *ngIf="currentTab==1">
              <img class="back-btn" tabindex="0" (keydown.enter)="changeTab(0, false, true, 1)" (click)="changeTab(0, false, true, 1)"
                src="../../../../assets/images/home/ico-chevron-back-outline.png">
              <h3 class="text-center header-modal">{{ 'SHARE_HOME.TITLE.USER' | translate }}</h3>
              <div class="form-group has-feedback">
                <div class="input-icons">
                  <i class="fa fa-search icon"></i>
                  <input class="input-field" type="text"
                    placeholder="{{ 'HOME.HISTORY.FILTERS.SEARCH_FOR_KEYWORDS_PLACEHOLDER' | translate }}"
                    (keyup)="addUserNameFilter($event)">
                </div>
              </div>
            </div>
            <div class="options-modal" *ngIf="currentTab==2">
              <img class="back-btn" tabindex="0" (keydown.enter)="changeTab(0 , false, true, 2)" (click)="changeTab(0 , false, true, 2)"
                src="../../../../assets/images/home/ico-chevron-back-outline.png">
              <h3 class="text-center header-modal">{{ 'SHARE_HOME.TITLE.ROLE' | translate }}</h3>
              <div class="form-group has-feedback">
                <div style="overflow:auto; height: 75px;">
                  <div *ngFor="let accessLevel of accessLevels;">
                    <div class="checkbox-container">
                      <div class="panel-bottom">
                        <div class="div-80"> <label class="checkbox-label">{{accessLevel[langCode]}}</label> </div>
                        <div class="div-20">
                          <div class="container-checkbox">
                            <div class="round" tabindex="0" (keydown.enter)="checkbox.click()">
                              <input type="checkbox" #checkbox value="{{accessLevel[defaultLangCode]}}"
                                id="{{accessLevel[defaultLangCode]}}" (change)="addAccessLevelFilter(accessLevel)"
                                [checked]="isAccessLevelChecked(accessLevel)" />
                              <label for="{{accessLevel[defaultLangCode]}}"></label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <button tabindex="0" (click)="changeTab(0, true, false, 2)" [disabled]="selectedAccessLevels.length === 0"
                    class="options-modal-btn btn-block btn-filled-date-picker btn-filter-box date-picker-btn">
                    <b>{{ 'HOME.HISTORY.FILTERS.APPLY_TEXT' | translate }}</b>
                  </button>
                </div>
              </div>
            </div>
            <div class="options-modal" *ngIf="currentTab==3">
              <img class="back-btn" tabindex="0" (keydown.enter)="changeTab(0, false, true, 3)" (click)="changeTab(0, false, true, 3)"
                src="../../../../assets/images/home/ico-chevron-back-outline.png">
              <h3 class="text-center header-modal">{{ 'SHARE_HOME.TITLE.STATUS' | translate }}</h3>
              <div class="form-group has-feedback">
                <div id="" style="overflow:auto; height: 100px;">
                  <div *ngFor="let status of statuses;">
                    <div class="checkbox-container">
                      <div class="panel-bottom">
                        <div class="div-80"> <label class="checkbox-label">{{status[langCode]}}</label> </div>
                        <div class="div-20">
                          <div class="container-checkbox">
                            <div class="round" tabindex="0" (keydown.enter)="checkbox1.click()">
                              <input type="checkbox" #checkbox1 value="{{status[defaultLangCode]}}"
                                id="{{status[defaultLangCode]}}" (change)="addStatusFilter(status)"
                                [checked]="isStatusChecked(status)" />
                              <label for="{{status[defaultLangCode]}}"></label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <button tabindex="0" (click)="changeTab(0, true, false, 3)" [disabled]="selectedStatuses.length === 0"
                    class="options-modal-btn btn-block btn-filled-date-picker btn-filter-box date-picker-btn">
                    <b>{{ 'HOME.HISTORY.FILTERS.APPLY_TEXT' | translate }}</b>
                  </button>
                </div>
              </div>
            </div>
            <div class="options-modal" *ngIf="currentTab==4">
              <img class="back-btn" tabindex="0" (keydown.enter)="changeTab(0, false, true, 4)" (click)="changeTab(0, false, true, 4)"
                src="../../../../assets/images/home/ico-chevron-back-outline.png">
              <h3 class="text-center header-modal">{{ 'SHARE_HOME.TITLE.SHARED_BY' | translate }}</h3>
              <div class="form-group has-feedback">
                <div class="input-icons">
                  <i class="fa fa-search icon"></i>
                  <input class="input-field" type="text"
                    placeholder="{{ 'HOME.HISTORY.FILTERS.SEARCH_FOR_KEYWORDS_PLACEHOLDER' | translate }}"
                    (keyup)="addSharedByFilter($event)" [(ngModel)]="selectedSharedBy[0]">
                </div>
                <div>
                  <button tabindex="0" (click)="changeTab(0, true, false, 4)" [disabled]="selectedSharedBy.length === 0"
                    class="options-modal-btn btn-block btn-filled-date-picker btn-filter-box date-picker-btn">
                    <b>{{ 'HOME.HISTORY.FILTERS.APPLY_TEXT' | translate }}</b>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
 </div>
</div> 