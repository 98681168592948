import { Directive, Input, ElementRef, HostListener, OnInit } from '@angular/core';
import { TabService } from './tab.service';
import { BehaviorSubject } from 'rxjs';

@Directive({
  selector: '[tabIndex]'
})
export class TabDirective implements OnInit {
  private _index: number;

  get index() {
    return this._index;
  }
  @Input('tabIndex')
  set index(i: any) {
    this._index = parseInt(i);
  }

  @HostListener('keydown', ['$event'])
  onInput(e: any) {
    if (e.which === 2000) {
      e.preventDefault();
      this.tabService.selectedInput.next(this.index + 1);
    }
  }
  constructor(private el: ElementRef, private tabService: TabService) {}

  ngOnInit(){
    this.tabService.selectedInput.subscribe((i) => {
      if (i === this.index) {
        this.el.nativeElement.focus()
      }
    })
  }
}