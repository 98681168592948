{{setTitle()}}
<div class="col-xs-12 sm-no-padding">
  <div class="col-xs-12 sm-no-padding">
    <div class="center-row">
      <!-- <div class="col-md-6 hidden-sm hidden-xs sp_login_left">
        <img src="../../../assets/images/login/bg7.jpg" height="100%" width="100%" class="cover">
      </div> -->
      <div class="col-md-12 sp_login_right">
        <div class="sp_grey_bg">
          <div class="col-md-6 col-md-offset-3 sp-middle-element">
            <div class="sp-middle-element">
              <div class="col-md-8">
                <div *ngIf="errorMessage!=null">
                    <br>
                    <div *ngIf="errorMessage!=null" class="alert alert-danger alert-dismissible fade in">
                      <a class="close" aria-label="close" (click)="errorMessage = null">&times;</a>
                      {{ errorMessage }}
                    </div>
                    <br>
                </div>
                <div class="brand-heading col-xs-12">
                  <h3>{{ 'WHITELIST.TITLE' | translate }}</h3>
                </div>
                <div class="brand-sub-heading col-xs-12">
                  <h5>{{ 'WHITELIST.SUBTITLE' | translate }}</h5>
                </div>
                <hr class="hr-brand">
                <br>
                <form class="col-md-10 col-md-offset-1 col-lg-8 col-lg-offset-2 col-sm-6 col-sm-offset-3 col-xs-12"
                  method="POST" [formGroup]="whiteList" (ngSubmit)="onSubmit()">
                  <div class="form-group has-feedback">
                    <div [ngClass]="{'has-error' : whiteList.controls['email'].invalid && whiteList.controls['email'].touched , 'has-success' : whiteList.controls['email'].valid && whiteList.controls['email'].touched}">
                      <input id="loginEmail" type="email" maxlength="100" class="form-control sp-form-input"
                        formControlName="email" [placeholder]="'WHITELIST.EMAIL' | translate">
                      <p *ngIf="whiteList.controls['email'].invalid && whiteList.controls['email'].touched" class="help-block">{{
                        'VALIDATION.EMAIL' | translate}}</p>
                    </div>
                  </div>
                  <div class="form-group has-feedback">
                    <div [ngClass]="{'has-error' : whiteList.get('code').invalid && whiteList.get('code').dirty , 'has-success' : whiteList.controls['code'].valid && whiteList.controls['code'].touched}">
                      <input id="loginCode" type="text" maxlength="100" class="form-control sp-form-input"
                        formControlName="code" [placeholder]="'WHITELIST.CODE' | translate">
                      <p *ngIf="whiteList.get('code').invalid && whiteList.get('code').dirty" class="help-block">{{
                        'VALIDATION.CODE' | translate}}</p>
                    </div>
                  </div>
                  <button id="loginSubmit" type="submit" class="sp-form-button btn btn-block" [disabled]="!whiteList.valid">
                    {{ 'WHITELIST.BUTTON' | translate }}
                  </button>
                </form>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

    <!-- <div class="row text-center brand-footer">
      <div class="sp-middle-element">
        <p>{{ 'WHITELIST.FOOTER_CONTENT' | translate }} </p>
      </div>
    </div> -->

  </div>
</div>
