<div class="verify-continue" style="padding-top: 25px;">
   <div class="row">
    <div *ngIf="errorMessage!=null" class="alert alert-danger alert-dismissible fade in">
      <a class="close" aria-label="close" (click)="errorMessage = null">&times;</a>
      {{ errorMessage }}
    </div>
   <p  class="verify-continue-title-1" style="margin-bottom: 0;"> {{ 'REGISTRATION.EMAIL_VERIFICATION.LOGIN_TITLE' | translate }} </p>
  <div class="verify-tick-congrats">
    <!-- <img src="../../../../assets/images/tick-icon.png" /> -->
      <p class="verify-tick-congrats-p"> <ng-container *ngIf="brandName === 'weiser'">  {{ 'REGISTRATION.CONGRATS_MESSAGE_WEISER' | translate }} </ng-container> <ng-container *ngIf="brandName !== 'weiser'">  {{ 'REGISTRATION.CONGRATS_MESSAGE' | translate }} </ng-container>  </p>
      <div class="verify-tick-img-login"></div>
      <p style="margin: 0 auto" class="verify-tick-p hidden-lg hidden-md">{{ 'REGISTRATION.EMAIL_VERIFICATION.COMPLETED' | translate }} </p>
  </div>
  <!-- <br /> -->
  <!-- <p class="verify-tick-login-mobile-only">{{ 'REGISTRATION.EMAIL_VERIFICATION.COMPLETED' | translate }} </p> -->
  <br />
  <div class="row">
    <div class="col-lg-7 no-padding mb-10px">
      <button (click)="onLogin()" class="btn btn-block sp-btn-dark h-45" [disabled]="false">
          {{ 'REGISTRATION.LOGIN' | translate }}
      </button>
    </div>
  </div>
   </div>
  </div>
  