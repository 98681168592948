import { HttpClient, HttpHeaderResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { LoaderService } from '../loader.service';
import { Constants } from '../utils/Constants';
import { ResponseHandler } from './response-handler';

@Injectable({
  providedIn: 'root',
})
export class ApiRequestService {
  BASE_URL = environment.baseUrl;
  constructor(
    private http: HttpClient,
    private responseHandler: ResponseHandler,
    private loaderService: LoaderService
  ) { }

  callApi(method: string, uri: string, body = {}, globalLoader = true, pathParams = {}, optionalHeaders = {}) {
    if (globalLoader) {
      this.loaderService.fireLoaderValue(true);
    }

    

    if (pathParams) {
      for (var key in pathParams) {
        if (pathParams.hasOwnProperty(key)) {
          uri = uri.replace("{" + key + "}", pathParams[key])
        }
      }
    }
    let request;

    switch (method.toLocaleLowerCase()) {
      case Constants.API_METHODS.GET:
        request = this.http.get(`${this.BASE_URL}${uri}`, optionalHeaders);
        break;
      case Constants.API_METHODS.POST:
        request = this.http.post(`${this.BASE_URL}${uri}`, body, optionalHeaders);
        break;
      case Constants.API_METHODS.PATCH:
        request = this.http.patch(`${this.BASE_URL}${uri}`, body);
        break;
      case Constants.API_METHODS.PUT:
        request = this.http.put(`${this.BASE_URL}${uri}`, body);
        break;
      case Constants.API_METHODS.DELETE:
        request = this.http.delete(`${this.BASE_URL}${uri}`, { body });
        break;
      case Constants.API_METHODS.PRESIGNED:
        request = this.http.post(`${uri}`, body );
        break;
      default:
        break;
    }

    return request.pipe(
      map((response) => {
        this.loaderService.isLoading.next(false);
        return response;
      }),
      catchError(err => {
        this.loaderService.isLoading.next(false);
        return throwError(err);
      })
    );
  }
}
