<div class="col-md-10 col-md-offset-1 col-lg-8 col-lg-offset-2 col-sm-6 col-sm-offset-3 col-xs-12">
  <h3 class="text-center"> {{ 'FORGOT_PASSWORD.ENTER_EMAIL.TITLE' | translate }} </h3>
  <hr class="sp-red-border">
  <form class="form-signin" method="POST" action="#" role="form" [formGroup]="enterEmailForm" (ngSubmit)="onConfirm()">
    <div class="form-group has-feedback">
      <div [ngClass]="{'has-error' : this.enterEmailForm.invalid && this.enterEmailForm.dirty, 'has-success' : this.enterEmailForm.valid}">
        <input id="emailVerify" type="email" maxlength="50" class="sp-form-input form-control" formControlName="email" [placeholder]="'FORGOT_PASSWORD.ENTER_EMAIL.LABEL_EMAIL' | translate">
        <p *ngIf="this.enterEmailForm.invalid && this.enterEmailForm.dirty" class="help-block">{{ 'VALIDATION.EMAIL' | translate}}</p>
      </div>
    </div>
    <div class="form-group">
      <button id="submitEmail" type="submit" class="sp-form-button btn btn-block" [disabled]="!enterEmailForm.valid">
        {{ 'FORGOT_PASSWORD.ENTER_EMAIL.BUTTON_CONFIRM_EMAIL' | translate }}
      </button>
    </div>
  </form>
</div>
