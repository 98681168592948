<!-- 
Modification for: HALOC-1905
By: Arif
Date: Feb 4th, 2022
Description: With current implementation percentage is not realtime updated with lock and it needs structure modification at API and 
Lock firmware side. So based on discussion for now we'll hide percentage 
Only hidden the percentage value so that existing HTML design should remain the same and UI will not be disturbed
-->

<div *ngIf="homeLocks == null" class="lock-loader">
  <!--  <app-loader [interalLoad]="true"></app-loader> -->
  <div class="loader-body sp-middle-element">
    <div class="col-sm-12">
      <div class="loader center-block"></div>
    </div>
  </div>
</div>

<div *ngIf="homeLocks != null && (viewType == null || viewType == 'List')">
  <ng-container *ngIf="homeLocks.length <= 0">
    <div class="row">
      <div class="col-lg-10 col-lg-offset-1 col-sm-12">
        <div class="shared-div">
          <div class="row">
            <div class="col-sm-12">
              <p>{{ ('LOCKS.NO_LOCKS' | translate).replace("{home}",this.homeName)}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <div class="col-lg-10 col-lg-offset-1 col-sm-12" *ngIf="homeLocks.length > 0">
    <div class="row hidden-xs sp-middle-element"
      [ngClass]="{'without-warning' : ((lock.lockBattery <= batteryLevel && this.checkUpdateLastStatus(lock.lastUpdateStatus)) || (lock.lockState && lock.lockState === this.lockState && this.checkUpdateLastStatus(lock.lastUpdateStatus))) || (lock.bleAdvertisement && lock.bleAdvertisement === bleAdvertisement) || !checkUpdateLastStatus(lock.lastUpdateStatus)}"
      *ngFor="let lock of homeLocks">
      <div class="thumbnail sp-lock-value">
        <div class="col-md-1 col-sm-1 pull-left">
          <div>
            <img src="../../../../assets/images/Locked.png" alt="Locked"
              *ngIf="lock.lockState && lock.lockState === 'locked' && lock.lockBattery != undefined && checkUpdateLastStatus(lock.lastUpdateStatus)"
              class="lock lock-list">
            <img src="../../../../assets/images/Unlocked.png" alt="Unlocked"
              *ngIf="lock.lockState && lock.lockState ==='unlocked' && lock.lockBattery != undefined && checkUpdateLastStatus(lock.lastUpdateStatus)"
              class="lock lock-list">
            <img src="../../../../assets/images/Alert.png" alt="Jammed"
              *ngIf=" lock.lockState && lock.lockState === 'jammed' && checkUpdateLastStatus(lock.lastUpdateStatus)"
              class="lock lock-list">
            <img src="../../../../assets/images/No-Connection.png" alt="No-Connection"
              *ngIf=" (lock.bleAdvertisement && lock.bleAdvertisement === '1') || !checkUpdateLastStatus(lock.lastUpdateStatus)"
              class="lock lock-list">
          </div>
        </div>
        <div class="col-md-5 col-sm-6">
          <div class="sp-lock-name">
            <div class="row">
              {{lock.lockName}}
            </div>
            <div class="row">
              <div class="sp-lock-type pull-left">
                {{('LOCKS.DETAIL.KEY.DOOR_STATUS' | translate)}}: <span
                  *ngIf="!checkUpdateLastStatus(lock.lastUpdateStatus)" class="sp-lock-State">{{('LOCKS.NO_CONNECTION' |
                  translate)}}</span>
                <span *ngIf="lock.lockState && checkUpdateLastStatus(lock.lastUpdateStatus)"
                  class="sp-lock-State">{{convertFirstLetter(lock.lockState)}}</span>
                <span *ngIf="!lock.lockState && checkUpdateLastStatus(lock.lastUpdateStatus)"
                  class="sp-lock-State">N/A</span>
              </div>
              <div class="sp-lock-type-list pull-right">
                {{('LOCKS.DETAIL.KEY.LAST_ACTIVITY' | translate)}}: <span
                  *ngIf="lock.bleAdvertisement != '1' && checkUpdateLastStatus(lock.lastUpdateStatus)">{{convertDateFormat(lock.lastUpdateStatus)}}</span>
                <span *ngIf="lock.bleAdvertisement === '1' || !checkUpdateLastStatus(lock.lastUpdateStatus)"
                  class="sp-lock-no-connection">N/A</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-sm-2 sp-lock-battery"
          *ngIf="lock.bleAdvertisement && lock.bleAdvertisement != '1' && checkUpdateLastStatus(lock.lastUpdateStatus)">
          <div class="row">
            <div class="col-md-12 col-sm-12 pull-right">
              <div class="col-md-2 col-sm-6 sp-lock-type sp-lock-last-activity" *ngIf="lock.lockBattery < 100">
                {{ ('LOCKS.DETAIL.BATTERY_PERCENTAGE' | translate).replace("{batteryPercentage}",
                  lock.lockBattery)}}
              </div>
              <div class="col-md-2 col-sm-6 sp-lock-type sp-lock-last-activity-full" *ngIf="lock.lockBattery === 100">
                {{ ('LOCKS.DETAIL.BATTERY_PERCENTAGE' | translate).replace("{batteryPercentage}",
                lock.lockBattery)}}
              </div>
              <div class="col-md-6 col-sm-6 sp-battery-img">
                <app-battery [batteryStatus] = 'lock.batteryStatus' ></app-battery>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-sm-3 pull-right">
          <div class="col-md-12 col-sm-12 text-right">
            <img src="./../../../../assets/images/home/lock-history.png" *ngIf="member && lock.bleAdvertisement != '1'"
            alt="history.png" class="history click click-history  lock-history-list" (keydown.enter)="lockHistoryRef.click()" #lockHistoryRef
            [routerLink]="['/lock/history', lock.deviceID]">
          
              <img (keydown.enter)="lockSettingRef.click()" #lockSettingRef src="../../../../assets/images/home/lock-settings.png" *ngIf="member"
              [routerLink]="['/lock/details', lock.deviceID]" alt="lock-settings.png"
              class="lock-settings click ">

            <img src="../../../../assets/images/home/lock-history.png" *ngIf="!member && lock.bleAdvertisement != '1'"
              alt="history.png" class="history lock-history-list custom-disabled">

              <img src="../../../../assets/images/home/lock-settings.png" *ngIf="!member" alt="lock-settings.png"
              class="lock-settings custom-disabled">
          
          </div>
        </div>
        <div
          *ngIf="(lock.lockBattery != undefined && lock.lockBattery <= batteryLevel && checkUpdateLastStatus(lock.lastUpdateStatus)) || (lock.lockState && lock.lockState === 'jammed' && checkUpdateLastStatus(lock.lastUpdateStatus))"
          class="caption sp-lock-list-alert sp-lock-list-sm-alert">
          <div class="col-md-1 col-sm-2 pull-left">
            <img src="../../../../assets/images/home/alert-icon.png" alt="alert-icon.png">
          </div>
          <div class="col-md-11 col-sm-10 pull-right">
            <p *ngIf="lock.lockBattery != undefined && lock.lockBattery <= batteryLevel && (lock.lockState && lock.lockState != 'jammed')"
              class="alert-text alert-text-list"> {{ ('LOCKS.DETAIL.ALERT' | translate).replace("{batteryPercentage}",
              lock.lockBattery)}}</p>
            <p *ngIf="lock.lockState && lock.lockState === 'jammed'" class="alert-text alert-text-list">
              {{'LOCKS.DETAIL.JAMMED_ALERT' | translate}}</p>
          </div>
        </div>
        <div
          *ngIf="(lock.bleAdvertisement && lock.bleAdvertisement === '1') || !checkUpdateLastStatus(lock.lastUpdateStatus)"
          class="caption sp-lock-list-alert sp-lock-list-sm-alert sp-lock-list-ble-alert">
          <div class="col-md-1 col-sm-2 pull-left">
            <img src="../../../../assets/images/home/alert-icon.png" alt="alert-icon.png">

          </div>
          <div class="col-md-11 col-sm-10 pull-right">
            <p *ngIf=" lock.bleAdvertisement && lock.bleAdvertisement === '1' " class="alert-text alert-text-list">
              {{'LOCKS.DETAIL.BLE_ALERT' | translate}}</p>
            <p *ngIf=" !checkUpdateLastStatus(lock.lastUpdateStatus) " class="alert-text alert-text-list">
              {{'LOCKS.DETAIL.NO_CONNECTION' | translate}}</p>
          </div>
        </div>
      </div>
    </div>


    <div class="col-xs-12 col-sm-5 col-md-4 visible-xs" *ngFor="let lock of homeLocks">
      <div class="thumbnail sp-lock-list-sm">
        <div class="col-xs-2 pull-left">
          <div>
            <img src="../../../../assets/images/Locked.png" alt="Locked"
              *ngIf="lock.lockState && lock.lockState === 'locked' && lock.lockBattery != undefined && checkUpdateLastStatus(lock.lastUpdateStatus)"
              class="lock lock-list-sm ">
            <img src="../../../../assets/images/Unlocked.png" alt="Unlocked"
              *ngIf="lock.lockState && lock.lockState ==='unlocked' && lock.lockBattery != undefined && checkUpdateLastStatus(lock.lastUpdateStatus)"
              class="lock lock-list-sm ">
            <img src="../../../../assets/images/Alert.png" alt="Jammed"
              *ngIf=" lock.lockState && lock.lockState === 'jammed' && checkUpdateLastStatus(lock.lastUpdateStatus)"
              class="lock lock-list-sm">
            <img src="../../../../assets/images/No-Connection.png" alt="No-Connection"
              *ngIf=" (lock.bleAdvertisement && lock.bleAdvertisement === '1') || !checkUpdateLastStatus(lock.lastUpdateStatus)"
              class="lock lock-list-sm">
          </div>
        </div>
        <div class="col-xs-8">
          <div class="sp-lock-name-list-sm">
            <div class="row">
              {{lock.lockName}}
            </div>
            <div class="row sp-lock-type-grid">
              {{('LOCKS.DETAIL.KEY.DOOR_STATUS' | translate)}}: <span
                *ngIf="!checkUpdateLastStatus(lock.lastUpdateStatus)" class="sp-lock-State">{{('LOCKS.NO_CONNECTION' |
                translate)}}</span>
              <span *ngIf="lock.lockState && checkUpdateLastStatus(lock.lastUpdateStatus)"
                class="sp-lock-State">{{convertFirstLetter(lock.lockState)}}</span>
              <span *ngIf="!lock.lockState && checkUpdateLastStatus(lock.lastUpdateStatus)"
                class="sp-lock-State">N/A</span>
            </div>
          </div>
          <div class="sp-lock-last-activity-list-sm">
            <div class="row sp-lock-type-list-sm">
              {{('LOCKS.DETAIL.KEY.LAST_ACTIVITY' | translate)}}: <span
                *ngIf="lock.bleAdvertisement != '1' && checkUpdateLastStatus(lock.lastUpdateStatus)">{{convertDateFormat(lock.lastUpdateStatus)}}</span>
              <span *ngIf="lock.bleAdvertisement === '1' || !checkUpdateLastStatus(lock.lastUpdateStatus)">N/A</span>
            </div>
          </div>
        </div>
        <div class="col-xs-2 pull-right">
          <div class="row">
            <div class="col-xs-12 pull-right pos-bottom">
              <img src="../../../../assets/images/home/lock-settings.png" *ngIf="member"
                [routerLink]="['/lock/details', lock.deviceID]" alt="lock-settings.png"
                class="lock-settings lock-settings-list-sm pull-right click">
              <img src="../../../../assets/images/home/lock-settings.png" *ngIf="!member" alt="lock-settings.png"
                class="lock-settings lock-settings-list-sm pull-right custom-disabled">
              <img src="./../../../../assets/images/home/lock-history.png"
                *ngIf="member && lock.bleAdvertisement != '1'" alt="history.png"
                class="lock-settings lock-history-sm pull-left click" [routerLink]="['/lock/history', lock.deviceID]">
              <img src="../../../../assets/images/home/lock-history.png" *ngIf="!member && lock.bleAdvertisement != '1'"
                alt="history.png" class="lock-settings lock-history-sm pull-left custom-disabled">
            </div>
          </div>
          <div class="row"
            *ngIf="lock.bleAdvertisement && lock.bleAdvertisement != '1' && checkUpdateLastStatus(lock.lastUpdateStatus)">
            <div class="col-xs-12 list-view pull-right">
              <div class="sp-lock-battery-list-sm" *ngIf="lock.lockBattery < 100">
                {{ ('LOCKS.DETAIL.BATTERY_PERCENTAGE' | translate).replace("{batteryPercentage}",
                  lock.lockBattery)}}
              </div>
              <div class="sp-lock-battery-list-sm-full" *ngIf="lock.lockBattery === 100">
                {{ ('LOCKS.DETAIL.BATTERY_PERCENTAGE' | translate).replace("{batteryPercentage}",
                lock.lockBattery)}}
              </div>
               <div class="sp-lock-battery-img-grid">
                <app-battery [batteryStatus] = 'lock.batteryStatus' ></app-battery>
              </div>
            </div>
          </div>
        </div>
        <div
          *ngIf="(lock.lockBattery != undefined && lock.lockBattery <= batteryLevel && checkUpdateLastStatus(lock.lastUpdateStatus)) || (lock.lockState && lock.lockState === 'jammed' && checkUpdateLastStatus(lock.lastUpdateStatus))"
          class="caption sp-lock-list-sm-alert">
          <div class="col-sm-2 col-xs-2 pull-left">
            <img src="../../../../assets/images/home/alert-icon.png" alt="alert-icon.png" class="alert-icon">

          </div>
          <div class="col-sm-10 col-xs-10 pull-right">
            <p *ngIf="lock.lockBattery != undefined && lock.lockBattery <= batteryLevel && (lock.lockState && lock.lockState != 'jammed')"
              class="alert-text alert-text-list-sm"> {{ ('LOCKS.DETAIL.ALERT' |
              translate).replace("{batteryPercentage}", lock.lockBattery)}}</p>
            <p *ngIf="lock.lockState && lock.lockState === 'jammed'" class="alert-text alert-text-list-sm">
              {{'LOCKS.DETAIL.JAMMED_ALERT' | translate}}</p>
          </div>
        </div>
        <div
          *ngIf="(lock.bleAdvertisement && lock.bleAdvertisement === '1') || !checkUpdateLastStatus(lock.lastUpdateStatus)"
          class="caption sp-lock-list-sm-alert sp-lock-list-ble-alert">
          <div class="col-sm-2 col-xs-2 pull-left">
            <img src="../../../../assets/images/home/alert-icon.png" alt="alert-icon.png" class="alert-icon">

          </div>
          <div class="col-sm-10 col-xs-10 pull-right">
            <p *ngIf=" lock.bleAdvertisement && lock.bleAdvertisement === '1' " class="alert-text alert-text-list-sm">
              {{'LOCKS.DETAIL.BLE_ALERT' | translate}}</p>
            <p *ngIf=" !checkUpdateLastStatus(lock.lastUpdateStatus) " class="alert-text alert-text-list-sm">
              {{'LOCKS.DETAIL.NO_CONNECTION' | translate}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="homeLocks != null && viewType != null && viewType == 'Grid'">
  <ng-container *ngIf="homeLocks.length <= 0">
    <div class="row">
      <div class="col-lg-10 col-lg-offset-1 col-sm-12">
        <div class="shared-div">
          <div class="row">
            <div class="col-sm-12">
              <p>{{ ('LOCKS.NO_LOCKS' | translate).replace("{home}",this.homeName)}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <div class="col-lg-10 col-lg-offset-1 col-sm-12 no-padding" *ngIf="homeLocks.length > 0">
    <div class="row sp-home-grid-row">
      <div class="col-xs-12 col-sm-6 col-md-4" *ngFor="let lock of homeLocks">
        <div class="thumbnail sp-lock-grid">
          <div class="col-xs-9 col-md-10 col-sm-10 pull-left base">
            <div>
              <img src="../../../../assets/images/Locked.png" alt="Locked"
                *ngIf="lock.lockState && lock.lockState === 'locked' && lock.lockBattery != undefined && checkUpdateLastStatus(lock.lastUpdateStatus)"
                class="lock lock-grid">
              <img src="../../../../assets/images/Unlocked.png" alt="Unlocked"
                *ngIf="lock.lockState && lock.lockState ==='unlocked' && lock.lockBattery != undefined && checkUpdateLastStatus(lock.lastUpdateStatus)"
                class="lock lock-grid">
              <img src="../../../../assets/images/Alert.png" alt="Jammed"
                *ngIf="lock.lockState && lock.lockState === 'jammed' && checkUpdateLastStatus(lock.lastUpdateStatus)"
                class="lock lock-grid">
              <img src="../../../../assets/images/No-Connection.png" alt="No-Connection"
                *ngIf=" (lock.bleAdvertisement && lock.bleAdvertisement === '1') || !checkUpdateLastStatus(lock.lastUpdateStatus)"
                class="lock lock-grid">
              <img src="./../../../../assets/images/home/lock-history.png"
                *ngIf="member && lock.bleAdvertisement != '1'" tabindex="0" #historyFilter (keydown.enter)="historyFilter.click()" alt="history.png"
                class="history click click-history pull-right lock-history history-icon" 
                [routerLink]="['/lock/history', lock.deviceID]">
              <img src="../../../../assets/images/home/lock-history.png" *ngIf="!member && lock.bleAdvertisement != '1'"
                alt="history.png" class="history click-history pull-right lock-history custom-disabled history-icon">
            </div>
            <div class="sp-lock-name-grid">
              <div class="row goth-heading ellipsis">
                {{lock.lockName}}
              </div>
              <div class="row sp-lock-type-grid">
                {{('LOCKS.DETAIL.KEY.DOOR_STATUS' | translate)}}: <span
                  *ngIf="!checkUpdateLastStatus(lock.lastUpdateStatus)" class="sp-lock-State">{{('LOCKS.NO_CONNECTION' |
                  translate)}}</span>
                <span *ngIf="lock.lockState && checkUpdateLastStatus(lock.lastUpdateStatus)"
                  class="sp-lock-State">{{convertFirstLetter(lock.lockState)}}</span>
                <span *ngIf="!lock.lockState && checkUpdateLastStatus(lock.lastUpdateStatus)"
                  class="sp-lock-State">N/A</span>
              </div>
            </div>
            <div class="sp-lock-last-activity-grid">
              <div class="row sp-lock-type-grid">
                {{('LOCKS.DETAIL.KEY.LAST_ACTIVITY' | translate)}}: <span
                  *ngIf="lock.bleAdvertisement != '1' && checkUpdateLastStatus(lock.lastUpdateStatus)">{{convertDateFormat(lock.lastUpdateStatus)}}</span>
                <span *ngIf="lock.bleAdvertisement === '1' || !checkUpdateLastStatus(lock.lastUpdateStatus)">N/A</span>
              </div>
            </div>
          </div>
          <div class="col-xs-3 col-md-2 col-sm-2 pull-right detail">
            <div class="row">
              <div class="col-md-6 pull-right detail-main">
                <img src="../../../../assets/images/home/lock-settings.png" #lockSettingFilter (keydown.enter)="lockSettingFilter.click()" *ngIf="member"
                  [routerLink]="['/lock/details', lock.deviceID]" alt="lock-settings.png"
                  class="lock-settings lock-setting-grid click">
                <img src="../../../../assets/images/home/lock-settings.png" *ngIf="!member" alt="lock-settings.png"
                  class="lock-settings lock-setting-grid click custom-disabled">
              </div>
            </div>
            <div class="row"
              *ngIf="lock.bleAdvertisement && lock.bleAdvertisement != '1' && checkUpdateLastStatus(lock.lastUpdateStatus)">
              <div class="col-md-6 grid-view pull-right">
                <div class="sp-lock-battery-grid" *ngIf="lock.lockBattery < 100">
                  {{ ('LOCKS.DETAIL.BATTERY_PERCENTAGE' | translate).replace("{batteryPercentage}",
                  lock.lockBattery)}}
                </div>
                <div class="sp-lock-battery-grid-full" *ngIf="lock.lockBattery === 100">
                  {{ ('LOCKS.DETAIL.BATTERY_PERCENTAGE' | translate).replace("{batteryPercentage}",
                lock.lockBattery)}}
                </div>
                <div class="sp-lock-battery-img-grid">
                  <app-battery [batteryStatus] = 'lock.batteryStatus' ></app-battery>
                </div>
              </div>
            </div>
          </div>
          <div
            *ngIf="(lock.lockBattery != undefined && lock.lockBattery <= batteryLevel && checkUpdateLastStatus(lock.lastUpdateStatus)) || (lock.lockState && lock.lockState === 'jammed' && checkUpdateLastStatus(lock.lastUpdateStatus))"
            class="caption sp-lock-grid-alert">
            <div class="col-sm-2 col-xs-2 pull-left">
              <img src="../../../../assets/images/home/alert-icon.png" alt="alert-icon.png" class="alert-icon">
            </div>
            <div class="col-sm-10 col-xs-10 pull-right">
              <p *ngIf="lock.lockBattery != undefined && lock.lockBattery <= batteryLevel && (lock.lockState && lock.lockState != 'jammed')"
                class="alert-text"> {{ ('LOCKS.DETAIL.ALERT' | translate).replace("{batteryPercentage}",
                lock.lockBattery)}}</p>
              <p *ngIf="lock.lockState && lock.lockState === 'jammed'" class="alert-text"> {{'LOCKS.DETAIL.JAMMED_ALERT'
                | translate}}</p>
            </div>
          </div>
          <div
            *ngIf="(lock.bleAdvertisement && lock.bleAdvertisement === '1') || !checkUpdateLastStatus(lock.lastUpdateStatus)"
            class="caption sp-lock-grid-alert sp-ble-alert">
            <div class="col-sm-2 col-xs-2 pull-left">
              <img src="../../../../assets/images/home/alert-icon.png" alt="alert-icon.png" class="alert-icon">
            </div>
            <div class="col-sm-10 col-xs-10 pull-right">
              <p *ngIf=" lock.bleAdvertisement && lock.bleAdvertisement === '1' " class="alert-text">
                {{'LOCKS.DETAIL.BLE_ALERT' | translate}}</p>
              <p *ngIf=" !checkUpdateLastStatus(lock.lastUpdateStatus) " class="alert-text">
                {{'LOCKS.DETAIL.NO_CONNECTION' | translate}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>