import {
  LOGOUT,
  UPDATE_USER_OBJECT,
  UPDATE_REGISTRAION_USER,
  RESET_REGISTRATION,
  UPDATE_VERIFICATION,
  UPDATE_REG_STEP,
  UPDATE_TWO_STEP,
  UPDATE_PROFILE_DATA,
  CHANGE_LANGUAGE,
  UPDATE_FORGOT_PASSWORD_FLOW,
  UPDATE_CORRELAION_ID,
  UPDATE_HOMES,
  UPDATE_USER_BRAND,
  UPDATE_LOCKS,
  ADD_ERROR_MESSAGE,
  LAST_ACTIVITY_TIME,
  ADD_SUCCESS_MESSAGE,
  WHITE_LIST_CHECKED,
  USER_VIEW_TYPE,
  USER_MODAL_VIEW_TYPE,
  USER_SELECTED_HOME
} from "./actions";

import { IForgotPassword, IUser } from "./types";
import { v4 as uuid } from "uuid";
import { loadState } from "./session-store";
import { IHomeObject } from "../../home.service";


export interface IAppState {
  user: Object;
  userObject: IUser;
  verify: Object;
  regStep: number;
  updateProfileData: Object;
  preferredLanguage: string;
  forgotPassword: IForgotPassword;
  correlation_ID: string;
  homes: IHomeObject[];
  locks: {};
  userBrand: string;
  errorMessage: string;
  successMessage: string;
  time: number;
  whiteListChecked: boolean;
  userView: string;
  userModalView: string;
  userSelectedHome:  string;
}

export const INITIAL_STATE: IAppState = {
  correlation_ID: uuid(),
  user: {},
  userObject: null,
  verify: null,
  updateProfileData: null,
  regStep: 0,
  homes: null,
  locks: {},
  preferredLanguage: localStorage.getItem('state') ? JSON.parse(localStorage.getItem('state') || '').preferredLanguage : 'English',
  forgotPassword: {
    email: null,
    step: 1,
    verificationType: null,
    phoneNumber: null,
    verificationCode: null,
    secretQuestions: null,
    secretAnswer: null,
    phoneActive: true
  },
  userBrand:  localStorage.getItem("userBrand") || '',
  errorMessage: '',
  successMessage: '',
  time: 0,
  whiteListChecked: false,
  userView: 'Grid',
  userModalView: 'Grid',
  userSelectedHome: 'All'
};

export function rootReducer(state: IAppState, action: any): IAppState {
  let persistedState = loadState();
  if (persistedState) {
   /*  if (!Utility.compareJson(persistedState, state)) {
      state = Object.assign({}, state, persistedState);
    } */
  }
  switch (action.type) {
    case LOGOUT: {
      const newState = INITIAL_STATE;
      newState.user = state.user;
      newState.preferredLanguage = state.preferredLanguage;
      newState.regStep = state.regStep;
      return Object.assign({}, state, newState);
    }
    case UPDATE_USER_OBJECT:
      return Object.assign({}, state, {
        userObject: action.userObject
      });
    case UPDATE_REGISTRAION_USER:
      return Object.assign({}, state, {
        user: action.user,
        // regStep: state.regStep + 1
      });
    case RESET_REGISTRATION:
      return Object.assign({}, state, {
        regStep: 0,
        user: {}
      });
    case UPDATE_VERIFICATION:
      return Object.assign({}, state, {
        verify: action.object
      });
    case UPDATE_REG_STEP:
      return Object.assign({}, state, {
        regStep: state.regStep + 1
      });
    case UPDATE_TWO_STEP:
      return Object.assign({}, state, {
        regStep: state.regStep + 2
      });
    case UPDATE_PROFILE_DATA:
      return Object.assign({}, state, {
        updateProfileData: action.object
      });
    case CHANGE_LANGUAGE:
      return Object.assign({}, state, {
        preferredLanguage: action.preferredLanguage
      });
    case UPDATE_FORGOT_PASSWORD_FLOW:
      return Object.assign({}, state, {
        forgotPassword: action.forgotPassword
      });
    case UPDATE_CORRELAION_ID:
      return Object.assign({}, state, { correlation_ID: uuid() });
    case LAST_ACTIVITY_TIME:
      return Object.assign({}, state, { time: action.time });
    case UPDATE_HOMES:
      return Object.assign({}, state, {
        homes: action.homes
      });
    case UPDATE_USER_BRAND:
      if (action.brand && (action.brand.toLowerCase() === "weiser" || action.brand.toLowerCase() === "kwikset")) {
        let brand = action.brand.toLowerCase();
        localStorage.setItem("userBrand", brand);
        document
          .getElementById("brand")
          .setAttribute("href", "./assets/css/" + brand + ".css");
        return Object.assign({}, state, {
          userBrand: brand
        });
      }
      break;
    case UPDATE_LOCKS:
      let homeId = action.homeID;
      let allLocks = state.locks;
      allLocks[homeId] = action.locks;
      return Object.assign({}, state, {
        locks: allLocks
      });
    case ADD_ERROR_MESSAGE:
      return Object.assign({}, state, {
        errorMessage: action.msg
      });
    case ADD_SUCCESS_MESSAGE:
      return Object.assign({}, state, {
        successMessage: action.msg
      });
    case WHITE_LIST_CHECKED:
      return Object.assign({}, state, {
        whiteListChecked: action.check
      });
    case USER_VIEW_TYPE:
      return Object.assign({}, state, {
        userView: action.userView
      });
    case USER_MODAL_VIEW_TYPE:
      return Object.assign({}, state, {
        userModalView: action.userModalView
      });
    case USER_SELECTED_HOME:
      return Object.assign({}, state, {
        userSelectedHome: action.userSelectedHome
      });  
  }
  return state;
}
