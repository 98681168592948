// import { ApiClient } from "./utils/api-client/api-client";
import { NgRedux } from "@angular-redux/store";
import { IAppState } from "./utils/state-management/store";
import { Injectable } from "@angular/core";
import apiConfig from "./api-client/api-config";
import { WHITE_LIST_CHECKED } from "./utils/state-management/actions";
import { ApiRequestService } from "./api-client/api-requests.service";
import { map } from "rxjs";
import { Constants } from "./utils/Constants";
import { ResponseHandler } from "./api-client/response-handler";
import { ResponseHandlerService } from "./utils/response-handler.service";
import { callbackify } from "util";

@Injectable()
export class WhiteListService {
    constructor(
        private apiRequestService: ApiRequestService,
        private responseHandler: ResponseHandlerService,
        private ngRedux: NgRedux<IAppState>,
    ) { }

    checkEmail(email: string, code: string, callBack) {

        return this.apiRequestService.callApi(Constants.API_METHODS.POST, apiConfig.checkWhiteList.uri,  { email, code }).subscribe({
            next: (res) => {
                this.ngRedux.dispatch({
                    type: WHITE_LIST_CHECKED,
                    check: true
                });
                callBack.onSuccess();
          },
          error: (err) => {
            this.ngRedux.dispatch({
                type: WHITE_LIST_CHECKED,
                check: false
            });
            callBack.onFailure();
          }
          }  
        );

      /*   this.apiClient.makeCall(null, 'post', apiConfig.checkWhiteList, { email: email, code: code }, (err, res) => {
            if (res && res.success) {
                this.ngRedux.dispatch({
                    type: WHITE_LIST_CHECKED,
                    check: true
                });
                callBack.onSuccess();
            } else {
                this.ngRedux.dispatch({
                    type: WHITE_LIST_CHECKED,
                    check: false
                });
                callBack.onFailure();
            }
        }); */

    }
    getWhiteListUsersRequired(callback: any) {
        /*  this.apiClient.makeCall(null, 'get', apiConfig.checkWhiteListRequired, {}, (err, res) => {
             if (res && res.success) {
                 callBack.onWhiteListRequired(res.data[0] === "true" ? true : false);
             } else {
                 callBack.onWhiteListRequired(false);
             }
         }); */

         this.apiRequestService.callApi(Constants.API_METHODS.GET, apiConfig.checkWhiteListRequired.uri, {}).subscribe({
            next: (res)=>{
                if(res){
                    callback.onWhiteListRequired(res.data[0] === "true" ? true : false);
                }
            },
            error: (err) => {
                this.responseHandler.parseErrorResponseV2(err);
                callback.onWhiteListRequired(false);
            }
         })


       
    }
}
