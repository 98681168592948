const EULA_fr = `<p>
<strong>
    Contrat de d’utilisateur final
    <br/>
</strong>
<br/>
<br/>
</p>
<p>
<a name="_GoBack"></a>
<em>Dernière mise à jour : 1</em>
<sup><em>er</em></sup>
<em> juillet 2016 (ver. 1.0 Application)</em>
<br/>
<br/>
<strong>
    <br/>
    CONDITIONS D’UTILISATION
</strong>
<br/>
<br/>
EN UTILISANT CETTE APPLICATION MOBILE SPECTRUM (« L’APPLICATION »), VOUS
ACCEPTEZ LES PRÉSENTES CONDITIONS D’UTILISATION (« CONDITIONS »). SI VOUS
N’ACCEPTEZ PAS LES PRÉSENTES CONDITIONS, VOUS DEVEZ IMMÉDIATEMENT
DÉSINSTALLER CETTE APPLICATION ET NE PLUS L’UTILISER. LES PRÉSENTES
CONDITIONS FORMENT UN CONTRAT AYANT FORCE OBLIGATOIRE ENTRE VOUS ET
SPECTRUM BRAND HOLDINGS, INC. (« SPECTRUM »).
<br/>
<br/>
<strong>Limite d’âge</strong>
<br/>
Seules les personnes ayant treize (13) ans et plus peuvent utiliser
l’Application. Les utilisateurs ayant entre 13 et 18 ans doivent obtenir
l’approbation de leurs parents pour utiliser l’Application et accepter les
présentes Conditions.
<br/>
<br/>
<strong>
    Conditions et politiques supplémentaires; changements/mises à jour
</strong>
<br/>
Les présentes Conditions intègrent et complètent les conditions générales
d’Apple, Inc. (« Apple ») (disponibles sur le site    <u>http://www.apple.com/legal/itunes/us/terms.html#service</u>), y compris,
mais sans toutefois s’y limiter, le contrat d’utilisateur final des
applications sous licence qui en fait partie (« Conditions Apple »). En
outre, les présentes Conditions intègrent et complètent également les
politiques et conditions suivantes de Spectrum qui sont reproduites à la
fin des présentes Conditions. En cas de conflit, les présentes Conditions
prévaudront, mais uniquement dans la mesure où elles sont applicables à
l’utilisation de l’Application.
<em>
    <strong>
        Nous pouvons à tout moment mettre à jour les présentes Conditions
        ou les
    </strong>
</em>
<em><u><strong>Conditions d’utilisation en ligne</strong></u></em>
<em><strong> et/ou la </strong></em>
<em><u><strong>Politique de confidentialité</strong></u></em>
<em>
    <strong>
        les accompagnant, pour autant que, en cas de mise à jour
        quelconque, nous notifions les utilisateurs du changement et leur
        demandions de confirmer qu’ils acceptent la mise à jour. Si vous
        n’acceptez pas la mise à jour, vous cesserez d’avoir accès à
        l’Application et vous ne serez pas légalement tenu de vous
        conformer à la mise à jour.
    </strong>
</em>
</p>
<ul>
<li>
    <p>
        <a href="#Terms">
            <u>Conditions d’utilisation en ligne Spectrum</u>
        </a>
    </p>
</li>
<li>
    <p>
        <a href="#Privacy"><u>Politique de confidentialité Spectrum</u></a>
    </p>
</li>
</ul>
<p>
<strong>Licence d’utilisateur final</strong>
<br/>
Sous réserve des présentes Conditions, Spectrum vous octroie une licence
nominative, non exclusive, incessible, limitée et révocable pour utiliser
l’Application à titre personnel uniquement sur un iPhone, un iPad ou un
iPod Touch Apple (« Appareil iOS ») dont vous êtes propriétaire ou dont
vous avez le contrôle, comme l’autorisent les Règles d’utilisation
contenues dans les Conditions Apple et conformément aux présentes
Conditions (« Licence d’utilisateur »). Toute utilisation différente de
l’Application, y compris, mais sans toutefois s’y limiter, la revente, le
transfert, la modification ou la distribution de l’Application ou de
textes, photos, musique, codes à barres, vidéo, données, hyperliens,
captures d’écran et de tout autre contenu associé à l’Application («
Contenu »), est interdite. Les présentes Conditions régissent également
toutes les mises à jour de cette Application ainsi que tout ce qui la
complète ou la remplace, à moins que des conditions distinctes accompagnent
lesdites mises à jour de l’Application ou tout ce qui la complète ou la
remplace, auquel cas les conditions distinctes s’appliqueront. La licence
est valide tant qu’elle n’a pas été résiliée par vous-même ou par Spectrum,
qu’il y ait ou non préavis écrit. Vos droits découlant des présentes seront
automatiquement abrogés, sans préavis de Spectrum, si vous manquez de vous
conformer à l’une quelconque des Conditions ou des conditions de la Licence
d’utilisateur. Dès la résiliation de la Licence d’utilisateur, vous devez
cesser toutes les utilisations de l’Application et détruire toutes les
copies, complètes ou partielles, de l’Application.
</p>
<p>
<strong>Informations utilisateur</strong>
<br/>
Ni le téléchargement de l’Application ni son utilisation ne nécessite la
création d’un compte ou la soumission d’informations nominatives
quelconques. L’Application ne nécessite pas non plus d’informations
nominatives ou leur recueil. Toutefois, vous pouvez choisir d’activer la
fonction « Carnet d’adresses » afin de faciliter le partage des dispositifs
de contrôle de blocage avec les autres personnes qui sont dans votre carnet
d’adresses. Le choix d’utiliser cette fonction est volontaire. Si vous
activez cette fonction, l’Application aura accès aux informations du carnet
d’adresses de votre Appareil iOS pour activer l’envoi ou le partage
d’informations avec les personnes de votre choix. Ces informations seront
utilisées pour faciliter le partage des dispositifs de contrôle de blocage
que vous choisissez d’activer et non dans un autre but quelconque.
</p>
<p>
<br/>
En utilisant l’Application, vous consentez à ce que les informations soient
transmises à Spectrum, y compris à ses mandataires et à ses tiers associés,
et vous acceptez que Spectrum, y compris ses mandataires et tiers associés,
reçoive, stocke, traite, transmette et utilise toutes les informations que
vous soumettez ou que vous transmettez pour la fonctionnalité de
l’Application et aux fins d’application divulguées dans notre    <u>Politique de confidentialité</u>, sous réserve des limites d’utilisation
des informations de carnet d’adresses énumérées ci-dessus.
</p>
<p>
Vous acceptez également que Spectrum, ses prestataires de services et/ou
d’autres entités impliquées dans la création ou dans la fourniture de
l’Application puissent recueillir et utiliser des données techniques et des
informations associées, y compris, mais sans toutefois s’y limiter, des
informations techniques sur votre appareil, votre système et votre logiciel
d’application ainsi que sur vos périphériques, qui sont recueillies
périodiquement pour vous faciliter (le cas échéant) la mise à disposition
des mises à jour de logiciels, de l’assistance technique et d’autres
services liés à l’Application. Spectrum, ses prestataires de services et/ou
d’autres entités impliquées dans la création ou la fourniture de
l’Application peuvent utiliser ces informations, dans la mesure où elles
sont sous une forme qui ne permet pas de vous identifier nominativement,
afin de vous fournir des services ou des technologies.
<br/>
<br/>
<strong>Votre responsabilité envers votre appareil mobile</strong>
</p>
<p>
Vous êtes seul responsable de (a) la confidentialité et de la sécurité des
informations utilisateur envoyées depuis votre Appareil iOS ou stockées
dedans par l’Application.(b) prendre les mesures de précaution pour
protéger les informations utilisateur stockées dans votre Appareil iOS, y
compris, mais sans toutefois s’y limiter, la protection par mot de passe de
l’Appareil iOS et l’utilisation de la fonction d’effacement à distance
d’Apple, et enfin de (c) toutes les activités entreprises au moyen de votre
appareil, que vous les ayez ou non autorisées. Parmi celles-ci figurent les
utilisations non autorisées de l’Application, de quelque nature qu’elles
soient, avec accès à votre appareil. Vous acceptez de notifier
immédiatement à Spectrum toutes les actions soupçonnées être non autorisées
qui sont associées à l’Application ou toute autre atteinte à la sécurité.
Spectrum ne saurait être tenue responsable des pertes quelconques découlant
d’une perte ou d’un vol de données ou de matériel dus à des transactions
non autorisées ou frauduleuses associées à votre appareil.
</p>
<p>
<strong>Utilisations interdites</strong>
<br/>
L’utilisation de l’Application se limite à la fonctionnalité recherchée.
L’Application ne saurait être utilisée si, d’une façon ou d’une autre, elle
:
<br/>
<br/>
</p>
<ul>
<li>
    <p>
        harcèle, maltraite, traque, menace, diffame ou, d’une manière ou
        d’une autre, prote atteinte ou enfreint les droits de toute autre
        partie (y compris, mais sans toutefois s’y limiter, les droits de
        publicité ou d’autres droits patrimoniaux);
    </p>
</li>
<li>
    <p>
        est illégale, frauduleuse ou fallacieuse;
    </p>
</li>
<li>
    <p>
        utilise une technologie ou d’autres moyens pour accéder à du
        contenu non autorisé ou à des espaces non publics, y compris, mais
        sans toutefois s’y limiter, des « robots », des « collecteurs » ou
        des « robots d’indexation »;
    </p>
</li>
<li>
    <p>
        tente (a) d’introduire des virus ou d’autres codes informatiques,
        fichiers ou programmes dangereux qui interrompent, détruisent ou
        limitent la fonctionnalité d’un logiciel, matériel informatique ou
        de télécommunication, (b) d’endommager, désactiver, surcharger ou
        déstabiliser les serveurs ou les réseaux Spectrum, ou enfin (c)
        d’avoir un accès non autorisé à un réseau d’ordinateurs ou à des
        comptes utilisateurs Spectrum;
    </p>
</li>
<li>
    <p>
        encourage des comportements constituant une infraction criminelle
        ou donnant lieu à une responsabilité civile;
    </p>
</li>
<li>
    <p>
        contrevient aux présentes Conditions de quelque manière que ce
        soit;
    </p>
</li>
<li>
    <p>
        ne se conforme pas aux conditions générales de tiers applicables ou
        à d’autres politiques de tiers (collectivement, « Utilisation
        acceptable »).
    </p>
</li>
</ul>
<p>
Spectrum se réserve le droit, à sa discrétion absolue, de résilier toute
Licence d’utilisateur, de retirer du contenu, d’aider à l’application de la
loi lors de poursuites pénales ou d’intenter une action ou procédure civile
ou criminelle eu égard au contenu ou à l’utilisation de l’Application, si
Spectrum est raisonnablement convaincue qu’il s’agit ou qu’il pourrait
s’agir d’une infraction aux présentes Conditions. Tout retard pris pour
entreprendre lesdites actions ou toute inactivité à cet égard ne constitue
pas une renonciation aux droits de faire appliquer légalement les présentes
Conditions.
<br/>
<br/>
</p>
<p>
<strong>Indemnisation</strong>
<br/>
Vous acceptez d’indemniser et de dégager Spectrum, y compris ses
mandataires, sociétés affiliées, employés, sous-traitants, administrateurs
et dirigeants ainsi que toute personne impliquée dans la création ou la
fourniture de l’Application, de toutes les demandes d’indemnisation ou
droits d’action, responsabilités, dommages-intérêts, coûts, amendes,
pénalités et frais (y compris les honoraires d’avocat) découlant ou liés à
l’utilisation ou à la mauvaise utilisation de l’Application, à une
infraction aux présentes Conditions ou à des violations de droits
quelconques d’un tiers ou à une allégation quelconque de celles-ci.
Spectrum se réserve le droit d’assurer la défense et le contrôle exclusifs
dans toute affaire pouvant aboutir d’une manière ou d’une autre à une
indemnisation de votre part, auquel cas vous coopéreriez en engageant tous
les moyens de défense disponibles.
</p>
<p>
<br/>
<br/>
<strong>Absence de garantie</strong>
<br/>
SPECTRUM FOURNIT L’APPLICATION ET LE CONTENU « EN L’ÉTAT » ET « TEL QUE
DISPONIBLE » SANS AUCUNE GARANTIE OU DÉCLARATION, EXPRESSE OU IMPLICITE.
VOUS UTILISEZ L’APPLICATION À VOS PROPRES RISQUES. DANS LES PLEINES LIMITES
AUTORISÉES AU TITRE DU DROIT EN VIGUEUR, SPECTRUM S’EXONÈRE DE TOUTES LES
GARANTIES, QU’ELLES SOIENT EXPRESSES OU IMPLICITES, Y COMPRIS TOUTES LES
GARANTIES QUE L’APPLICATION EST DE QUALITÉ MARCHANDE, FIABLE, PRÉCISE ET DE
CONVENANCE À DES FINS OU DES BESOINS PARTICULIERS, EXEMPTE DE CONTREFAÇON
OU DE VICES OU CAPABLE DE FONCTIONNER DE MANIÈRE ININTERROMPUE, OU QUE
L’UTILISATION DE L’APPLICATION PAR L’UTILISATEUR EST EN CONFORMITÉ AVEC LES
LOIS, OU QUE LES INFORMATIONS TRANSMISES DANS LE CADRE DE L’APPLICATION LE
SERONT AVEC SUCCÈS, PRÉCISION OU EN SÉCURITÉ.
<br/>
<br/>
<strong>Absence de responsabilité</strong>
<br/>
DANS LES PLEINES LIMITES AUTORISÉES AU TITRE DU DROIT EN VIGUEUR ET EN
AUCUNE CIRCONSTANCE, SPECTRUM OU SES MANDATAIRES OU QUICONQUE IMPLIQUÉ DANS
LA CRÉATION OU LA FOURNITURE DE LA PRÉSENTE APPLICATION OU DU CONTENU NE
SAURAIENT ÊTRE TENUS (A) RESPONSABLES EU ÉGARD À L’UTILISATION DE
L’APPLICATION ET/OU (B) RESPONSABLES DE TOUS LES DOMMAGES DIRECTS,
INDIRECTS, SPÉCIAUX, ACCESSOIRES, CONSÉCUTIFS, PUNITIFS, EXEMPLAIRES OU
AUTRES, Y COMPRIS, MAIS SANS TOUTEFOIS S’Y LIMITER, LES DOMMAGES POUR
DÉFECTION DE CLIENTS, MANQUE À GAGNER, PERTE, VOL OU CORRUPTION
D’INFORMATIONS UTILISATEUR, L’INCAPACITÉ À UTILISER L’APPLICATION OU UNE
PANNE OU UNE DÉFAILLANCE DE L’APPAREIL. VOTRE SEUL RECOURS CONSISTE À
CESSER D’UTILISER L’APPLICATION. SPECTRUM, SES MANDATAIRES ET QUICONQUE
IMPLIQUÉ DANS LA CRÉATION OU LA FOURNITURE DE LA PRÉSENTE APPLICATION OU DU
CONTENU NE SAURAIENT ÊTRE TENUS RESPONSABLES, MÊME SI L’UN QUELCONQUE
D’ENTRE EUX OU UN REPRÉSENTANT AUTORISÉ QUELCONQUE DE CEUX-CI A ÉTÉ AVISÉ
DE LA POSSIBILITÉ DESDITS DOMMAGES, Y COMPRIS, MAIS SANS TOUTEFOIS S’Y
LIMITER LES DOMMAGES MATÉRIELS OU CORPORELS DUS À UNE ERREUR, UNE OMISSION,
UNE INTERRUPTION, UN VICE, UNE INEXÉCUTION, UNE UTILISATION NON AUTORISÉE,
UN RETARD DANS LE FONCTIONNEMENT OU DANS LA TRANSMISSION, UNE PANNE
D’INTERNET, UN VIRUS INFORMATIQUE, UN VER, UN CHEVAL DE TROIE OU TOUT AUTRE
DOMMAGE.
<br/>
<br/>
Dans le cas où cette loi en vigueur n’autorise pas l’exclusion de certaines
garanties ou la limitation ou l’exclusion de responsabilité pour les
dommages accessoires, consécutifs ou autres, en aucune circonstance,
Spectrum, ses mandataires ou quiconque impliqué dans la création ou la
fourniture de la présente Application ou du contenu ne sauraient être tenus
responsables de dommages, pertes et/ou droits d’action supérieurs au
montant payé, le cas échéant, par vous pour l’utilisation de l’Application
ou à 100 $, le montant le plus faible prévalant.
<br/>
<br/>
<strong>Propriété intellectuelle</strong>
<br/>
Le nom, le logo et les autres graphismes de marques Spectrum ainsi que les
logos utilisés dans le cadre de l’Application sont des marques de commerce
ou des marques déposées de Spectrum (collectivement, « Marques de Spectrum
»). D’autres marques de commerce, marques de service, graphismes et logos
utilisés dans le cadre de l’Application sont des marques de commerce
appartenant à leurs propriétaires respectifs (collectivement, « Marques de
tiers »). Les Marques de Spectrum et les Marques de tiers ne peuvent ni
copiées, ni imitées, ni utilisées, en tout ou partie, sans l’autorisation
écrite préalable de Spectrum ou du propriétaire de la marque de commerce
concernée. L’Application et le contenu sont protégés par le droit d’auteur,
le droit des marques de commerce, le droit des brevets, le secret
commercial, les traités internationaux, les lois et d’autres droits
patrimoniaux et ils peuvent également avoir des composants sécuritaires qui
protègent les informations numériques uniquement sur autorisation de
Spectrum ou du propriétaire du contenu.
</p>
<p>
<strong>
    Choix de la loi applicable et de la juridiction compétente en cas de
    litige
</strong>
<br/>
Les présentes Conditions sont régies par les lois de l’État du Wisconsin,
sans tenir compte des règles de droit international privé. Vous consentez à
ce que les tribunaux d’état ou fédéraux à Madison, Wisconsin soient les
seuls compétents dans le cas où une action en justice quelconque
découlerait de l’utilisation de l’Application ou des présentes Conditions
ou y serait associée, si besoin est. De plus amples détails sur les litiges
sont énoncés ci-dessous dans les    <u><strong>Conditions d’utilisation</strong></u> en ligne Spectrum.
<br/>
<br/>
<strong>Dissociabilité</strong>
<br/>
Si l’une quelconque des présentes Conditions est réputée invalide, nulle et
non avenue ou, pour quelque raison que ce soit, inexécutable, cette
condition sera réputée dissociable et ne saurait affecter la validité et le
caractère exécutoire de toutes les conditions restantes. L’incapacité de
Spectrum d’exercer ou d’appliquer un droit ou une clause quelconque du
présent Contrat ne saurait constituer une renonciation audit droit ou à
ladite clause, dans ce cas ou dans tout autre cas.
<br/>
</p>
<p>
<strong>Nous joindre</strong>
<br/>
Si vous avez la moindre question concernant nos pratiques en matière de
protection de la vie privée, vous pouvez nous envoyer un courriel à
l’adresse
</p>
<p>
Spectrum Brands, Inc.
<br/>
Attention: Legal Department
<br/>
3001 Deming Way
<br/>
Middleton, WI 53562-1431
</p>`;

export default EULA_fr;
