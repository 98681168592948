import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { UserManagement } from "../../services/utils/state-management/action-classes";
import { UserLoginService } from "../../services/user-login.service";
import { LoggedInCallback } from "../../services/utils/cognito.service";
import { Router } from "@angular/router";
import { Utility } from "../../services/utils/utility";

@Component({
  selector: "consumer-portal",
  templateUrl: "./secureHome.html",
  styleUrls: ["./secureHome.css"]
})
export class SecureHomeComponent implements OnInit, LoggedInCallback {
  firstName;
  lastName;

  constructor(
    private userManagement: UserManagement,
    private translate: TranslateService,
    public userService: UserLoginService,
    public router: Router
  ) {}

  ngOnInit() {
    localStorage.removeItem('PhoneVerified');
    
    this.userService.isAuthenticated(this);
    Utility.focusLogo();
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (!isLoggedIn) {
      console.log('Go to Home');
      this.router.navigate(["/home"]);
    } else {

      let user = this.userManagement.getUser();
     /*  let preferredLang = user.preferredLanguage;

      if (preferredLang && preferredLang != null) {
        this.translate.use(preferredLang);
      } */

      this.firstName = user.firstName;
      this.lastName = user.lastName;
    }
  }

  viewSharedUsers() {
    this.router.navigate(["/viewSharedUsers/affanhome"]);
  }
}
