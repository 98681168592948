import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CognitoUtil } from '../services/utils/cognito.service';
import { Logger } from '../services/utils/logger';
import { LoggingLevel } from '../services/utils/logging-level';
import { Router } from '@angular/router';
import { Constants } from '../services/utils/Constants';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    s3ExplicitUrlsWithoutToken = Constants.S3_EXPLICIT_URLS_WITHOUT_TOKEN;

    constructor(private cognitoUtil: CognitoUtil, private logger: Logger, private router: Router) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        // Authentication From Session Storage
        if (this.cognitoUtil.getCurrentUser() != null) {
            this.cognitoUtil.getCurrentUser().getSession((err, session) => {

                if (err) {
                    this.logger.log(
                        LoggingLevel.ERROR,
                        "CognitoUtil: Can't set the credentials: ",
                        JSON.stringify(err)
                    );
                    return next.handle(request);
                }
                const { STARTS: starts , ENDS: ends } = this.s3ExplicitUrlsWithoutToken;
                if (session && !(request.url.startsWith(starts) && request.url.endsWith(ends))) {
                    request = request.clone({
                        setHeaders: {
                            authorization: `Bearer ${session.getIdToken().getJwtToken()}`,
                        }
                    });
                    return next.handle(request);
                } else {


                    return next.handle(request);
                }
            });
        }
        return next.handle(request);
    }


}