import { Component, Input, OnInit } from '@angular/core';
import { NgRedux, select } from "@angular-redux/store";
import { IAppState } from 'src/app/services/utils/state-management/store';

@Component({
  selector: 'disabled-phone',
  templateUrl: './disabled-phone.component.html',
  styleUrls: ['./disabled-phone.component.css']
})
export class DisabledPhoneComponent implements OnInit {

  userObject: any;
  @Input() disabledText;
  @Input() link;

  constructor(private ngRedux: NgRedux<IAppState>) { }

  ngOnInit() {
    this.ngRedux.select('userObject').subscribe((value) => {
      this.userObject = value;
    });
  }

}
