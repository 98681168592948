import { Component, OnInit, Input, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Config } from '../../../services/utils/Config';
import { environment } from '../../../../environments/environment';
import {
  RegistrationCallback,
  UserRegistrationService,
} from '../../../services/user-registration.service';
import { Logger } from '../../../services/utils/logger';
import { LoggingLevel } from '../../../services/utils/logging-level';
import { VerificationCallback } from '../../../services/utils/verification.service';
import { Constants } from '../../../services/utils/Constants';
import { ResponseHandlerService } from '../../../services/utils/response-handler.service';
import { Utility } from '../../../services/utils/utility';
import { Ng2TelInput } from 'ng2-tel-input';
import { FederatedManagementService } from '../../../services/federated-management.service';
import { UserManagement } from '../../../services/utils/state-management/action-classes';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { UserProfileService } from 'src/app/services/user-profile.service';

@Component({
  selector: 'app-account-info',
  templateUrl: './account-info.component.html',
  styleUrls: [
    './account-info.component.css'
  ],
})
export class AccountInfoComponent
  implements OnInit, RegistrationCallback, VerificationCallback, AfterViewInit {
  @Input() parent;
  @ViewChild('nameFocus') nameFocus: ElementRef

  signup: FormGroup;
  KWIKSET = 'kwikset';
  errorMessage: string;
  phoneError: boolean = true;
  emailError: boolean = true;
  phoneCodeError: string;
  emailCodeError: string;
  siteKey: string;
  maxlength: any;
  configObject: any;
  dialCode: string = '+1';
  selectedCountryCode: any = 'US';
  repeatPassword = '';
  isShowingTermsOfService: boolean = false;
  brand;
  upperCase = /[A-Z]/g;
  lowerCase = /[a-z]/g;
  number = /[0-9]/g;
  specialCharacter = /[#?!@$%^&*-]/g;
  space = /[\s]/g;
  // invalidSpecialCharacters = /[+\\={}()/><,._]/g;
  iti: any;
  password: string = '';

  hidePass: boolean = true;
  hideRepeatPass: boolean = true;
  captchaResponse: string = Config.DefaultCaptcha;
  provider: string;
  userAlreadyExists: boolean = false;
  url: SafeResourceUrl;
  disableCaptcha: boolean = false;
  federatedProvider = Config.PROVIDERS.FEDERATED;

  constructor(
    public formBuilder: FormBuilder,
    public translate: TranslateService,
    public registrationService: UserRegistrationService,
    public logger: Logger,
    private userManagementService: UserManagement,
    public googleservice: FederatedManagementService,
    public userProfileService: UserProfileService,
    public responseHandler: ResponseHandlerService,
    private sanitizer: DomSanitizer
  ) {
    this.brand = localStorage.getItem('userBrand');
    this.selectedCountryCode = Config[this.brand.toUpperCase()].defaultCountryCode;
  }

  /**
  * This method initializes the component after the view has been fully initialized
  */
  ngAfterViewInit() {
    this.nameFocus.nativeElement.focus()
  }

  ngOnInit() {
    /**
     * Modifications by Zeeshan
     */
    this.url =
      this.brand === this.KWIKSET
        ? this.sanitizer.bypassSecurityTrustResourceUrl(Config.KWIKSET.ToS)
        : this.sanitizer.bypassSecurityTrustResourceUrl(Config.WEISER.ToS);
    this.configObject = JSON.parse(localStorage.getItem('config'));
    // HALOC-3774 adding this check to disable captcha for automation scripts for QB
    this.disableCaptcha = this.configObject && this.configObject.Feature.DisableCaptcha;
    this.maxlength = Config.PHONEFORMAT[this.selectedCountryCode].FORMATTEDLENGTH;;
    this.siteKey = this.disableCaptcha ? null : environment.invisibleCaptcha;
    this.signup = this.formBuilder.group({
      firstName: [
        null,
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(30),
          this.noWhitespaceValidator,
        ],
      ],
      lastName: [
        null,
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(30),
          this.noWhitespaceValidator,
        ],
      ],
      email: [
        null,
        [
          Validators.required,
          Validators.minLength(10),
          Validators.pattern('^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$'),
        ],
      ],
      password: [
        null,
        [
          Validators.required,
          Validators.pattern(Config.PASSWORD_POLICY_PATTERN)
        ],
      ],
      repeatPassword: [
        null,
        [
          Validators.required,
          Validators.pattern(Config.PASSWORD_POLICY_PATTERN)
        ],
      ],
      phone: [
        '',
        Validators.compose([
          Validators.required,
          Validators.maxLength(this.maxlength),
        ]),
      ],
      // phone: [null, [Validators.required] ],
      eulaCheck: [null, [Validators.requiredTrue]],
      recaptchaReactive: new FormControl(null, this.disableCaptcha === true ? null : Validators.requiredTrue),
    });

    // If Signup from google provider
    this.provider = localStorage.getItem('PROVIDER') || '';
    console.log(this.provider);
    if (this.provider === this.federatedProvider) {
      this.signup.removeControl('email');
      this.signup.removeControl('password');
      this.signup.removeControl('repeatPassword');
    }
  }

  noWhitespaceValidator(control: FormControl) {
    const value = new String(control.value);
    const valueLength = value.length;
    const trimLenght = value.trim().length;
    var isValid;
    var isWhitespace = (control.value || '').trim().length === 0;
    var isEqualChar = valueLength === trimLenght ? true : false;
    if (trimLenght >= 2) {
      isValid = !isWhitespace;
    } else {
      isValid = !isWhitespace && isEqualChar;
    }
    return isValid ? null : { whitespace: true };
  }

  onEnterPassword(e) {
    this.repeatPassword = e.target.value;
  }

  public telInputObject(obj: Ng2TelInput) {
    this.iti = obj;
  }

  public selectedCountry(event) {
    this.dialCode = event.dialCode;
    this.maxlength = event.phoneMaxLength;
  }

  passwordPolicyPattern(str: string, type: any, regex) {
    if (type == 'upperCase') {
      return regex.test(str);
    } else if (type == 'number') {
      return regex.test(str);
    } else if (type == 'specialChar') {
      return regex.test(str);
    }
  }

  onCollectInfo() {
    sessionStorage.setItem('captcha', this.captchaResponse);

    this.parent.changed = true;

    if (this.provider === this.federatedProvider) {
      // On Google case, we are checking that email already exist or not.
      const userGoogleEmail = sessionStorage.getItem('GoogleEmail');
      this.googleservice.checkIfUserExist((err, res) => {
        if (res && res[0]['code'] === Constants.ERROR_CODES.RESOURCE_NOT_FOUND) {
          this.registrationService.updateAccountInfoGoogle(
            this.signup.get('firstName').value,
            this.signup.get('lastName').value,
            this.dialCode + this.signup.get('phone').value,
            this.signup.get('eulaCheck').value,
            this
          );
          // Direct goes to phone verification and codeverifcation api calls there.
          this.registrationService.rollOverRegStep();
          return;
        } else {
          this.userAlreadyExists = true;
          this.userManagementService.removeUser();
          this.googleservice.removeGoogleTokensSessionStorage(userGoogleEmail);
        }
      });
    } else {
      this.registrationService.updateAccountInfo(
        this.signup.get('firstName').value,
        this.signup.get('lastName').value,
        this.signup.get('email').value,
        this.dialCode + this.signup.get('phone').value,
        this.signup.get('password').value,
        this.signup.get('eulaCheck').value,
        this
      );
      this.registrationService.sendVerificationCode(
        'email',
        this.signup.get('email').value,
        this
      );
    }
    // Now request the server to send a verification code to email
    Utility.focusLogo();
  }

  isNotNullOrEmpty(value) {
    if (value == '' || value == null) {
      return true;
    } else {
      return false;
    }
  }

  onSuccessSendCode(result: any) {
    console.log('This is call to OnSuccessSendCode callback');
    this[`${result.medium}Error`] = false;
    if (this.provider !== this.federatedProvider) {
      if (!this.emailError) {
        this.errorMessage = null;
        this.registrationService.rollOverRegStep();
      }
    }
  }

  onFailure(errorObj: any) {
    this.userAlreadyExists = false;
    console.log('onFailure of send verfictaion');
    this[`${errorObj.medium}Error`] = true;

    if (errorObj.data[0].code === Constants.ERROR_CODES.ALREADY_EXIST) {
      this.userAlreadyExists = true;
      this.signup.get('email').setErrors({ notUnique: true });
    }
    else if (errorObj.data[0].code === Constants.ERROR_CODES.CAPTCHA_VERIFICATION_FAILED) {
      this.signup.controls['eulaCheck'].setValue(false);
      document.querySelector(
        '.example-margin .mat-checkbox-layout .mat-checkbox-inner-container .mat-checkbox-frame'
      )['style'].borderColor = 'red';
    }

    if (!this.userAlreadyExists) {
      this.errorMessage = this.responseHandler.getErrorMessageViaCode(
        errorObj.data,
        'REGISTRATION.EMAIL_VERIFICATION.ERROR',
      );
      if (this.errorMessage.slice(-1) !== '.') {
        this.errorMessage += '.';
      }
    }
  }

  onSuccess(result: any) {
    // this.registrationService.updateEmail();
    console.log(result);
  }

  registrationCallback(message: string, result: any): void {
    this.logger.log(LoggingLevel.DEBUG, 'registration user', result);
  }

  resolved(captchaResponse: string) {
    this.captchaResponse = captchaResponse;
    if (captchaResponse == null) {
      return;
    }
    this.signup.controls['recaptchaReactive'].setValue(true);
  }

  showDialog() {
    this.isShowingTermsOfService = true;
  }
  toggleEula(x) {
    this.signup.controls['eulaCheck'].setValue(x.checked);
    document.querySelector(
      '.example-margin .mat-checkbox-layout .mat-checkbox-inner-container .mat-checkbox-frame'
    )['style'].borderColor = 'black';
    this.errorMessage = null;
  }
  backHandler() {
    this.isShowingTermsOfService = false;
  }
  repeatPasswordError(type: string) {
    if (type === 'missMatch') {
      if (Config.PASSWORD_POLICY_PATTERN.test(this.repeatPassword)) {
        return this.signup.get('password').value != this.repeatPassword;
      }
    } else if (type === 'pattern') {
      return (
        !Config.PASSWORD_POLICY_PATTERN.test(this.repeatPassword) &&
        this.repeatPassword != ''
      );
    }
    return null;
  }

  togglePasswordField(fieldName) {
    if (fieldName === 'password') {
      this.hidePass = !this.hidePass;
    } else if (fieldName === 'repeatPassword') {
      this.hideRepeatPass = !this.hideRepeatPass;
    }
  }
}
