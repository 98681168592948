<div *ngIf="successMessage!=null" class="alert alert-danger alert-dismissible fade in">
  <a class="close" aria-label="close" (click)="successMessage = null">&times;</a>
  {{ successMessage }}
</div>
<div *ngIf="!phoneEntered">
  <h3 class="phone-heading" style="font-weight: 800;"> {{ 'REGISTRATION.PHONE_VERIFICATION.ADD_PHONE' | translate }}
  </h3>
</div>
<div *ngIf="addPhoneUnAuth && !phoneEntered">
  <p class="phone-invalid-meesage"> {{ 'REGISTRATION.PHONE_VERIFICATION.PHONE_INVALID_MESSAGE' | translate }}
  </p>
  <br>
</div>
<div *ngIf="!addPhoneUnAuth && !phoneEntered">
  <p class="phone-invalid-meesage"> {{ 'REGISTRATION.PHONE_VERIFICATION.PHONE_UPDATE_MESSAGE_ONE' | translate }} {{ 'REGISTRATION.PHONE_VERIFICATION.PHONE_UPDATE_MESSAGE_TWO' | translate }}
  </p>
  <br>
</div>
<form *ngIf="!phoneEntered" method="POST" action="#" role="form" [formGroup]="phoneValidation"
  (ngSubmit)="onVerify(this.dialCode)">

    <div>
      <phone-field [formname]="phoneValidation" [selectedCountryCode]="countryCode" (selectedCountryEvent)="selectedCountry($event)" ></phone-field>
    </div>
    <div style="padding: 0px;" class="col-md-12 form-group">
      <button tabindex="16" *ngIf="!phoneEntered" id="submitPhone" type="submit" class="btn btn-block sp-btn-dark h-45"
        [disabled]="!validPhoneNumber || !phoneValidation.valid">
        {{ 'REGISTRATION.PHONE_VERIFICATION.CONTINUE' | translate }}
      </button>
      <button tabindex="16" *ngIf="phoneEntered" id="submitPhone" type="submit" class="btn btn-block sp-btn-dark h-45"
        [disabled]="!validPhoneNumber">
        {{ 'REGISTRATION.PHONE_VERIFICATION.BUTTON_RESEND_CODE' | translate }}
      </button>
    </div>
    <div *ngIf="addPhoneUnAuth && !phoneEntered" style="padding: 0px;" class="col-md-12 form-group">
      <div class="sp-hyperlink-div text-center">
        {{ 'REGISTRATION.PHONE_VERIFICATION.PRE' | translate }}
        <a tabIndex="40" (keydown.enter)="onLogin()" (click)="onLogin()" class="sp-hyperlink">{{ 'REGISTRATION.PHONE_VERIFICATION.CLICK' | translate }}</a>
      </div>
    </div>
</form>
<form *ngIf="phoneEntered" class="sp-verf-code" method="POST" action="#" role="form" [formGroup]="codeValidation"
  (ngSubmit)="onSubmitCode()">
  <div>
    <h3 class="phone-heading" style="font-weight: 800;"> {{ 'REGISTRATION.PHONE_VERIFICATION.TITLE' | translate }}
    </h3>
    <div [hidden]="isLoaderActive">
      <p class="invalid-text-p">{{ ('REGISTRATION.PHONE_VERIFICATION.SUB_TITLE' | translate)}}
        <b>{{phoneValidation.get('phone').value.slice(-2)}}</b>.</p>


      <div class="form-group has-feedback">
        <div
          [ngClass]="{'has-error' : this.codeValidation.invalid && this.codeValidation.dirty, 'has-success' : this.codeValidation.valid}">
          <div class="ngx-otp">
            <ng-otp-input #ngOtpInput (onInputChange)="handleFillEvent($event)" 
                      [config]="ngconfig" id="ng-otp-input" *ngIf="showOtpComponent"></ng-otp-input>
          </div>
          <p *ngIf="errorMessage" class="invalid-otp">{{ errorMessage }}
          </p>
          <p style="margin-top:15px" *ngIf="this.codeValidation.invalid && this.codeValidation.dirty" class="help-block">{{
            'VALIDATION.CODE' | translate}}</p>
        </div>
      </div>

      <div class="form-group">
        <p class="resend-code-p">{{ 'REGISTRATION.PHONE_VERIFICATION.RE_SEND_CODE' | translate }} <span tabIndex="0"
            class="sp-hyperlink cursor-pointer" (keydown.enter)="resendCode(this.dialCode)" (click)="resendCode(this.dialCode)"> {{'LOGIN.VERIFICATION.LINK_RESEND_CODE' | translate }}.</span></p>
      </div>
    </div>
    <ng-container *ngIf="isLoaderActive">
      <p class="invalid-text-p">{{('REGISTRATION.PHONE_VERIFICATION.WAIT_MESSAGE' | translate )}}</p>
      <app-inner-loader></app-inner-loader>
    </ng-container>

  </div>
</form>