import { NgRedux } from '@angular-redux/store';
import { Component, OnInit, NgZone } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import apiConfig from './services/api-client/api-config';
import { ApiRequestService } from './services/api-client/api-requests.service';
import { DataShareService } from './services/data-share.service';
import { UserLoginService } from './services/user-login.service';
import { LoggedInCallback } from './services/utils/cognito.service';
import { Config } from './services/utils/Config';
import { Constants } from './services/utils/Constants';
import { Logger } from './services/utils/logger';
import { LoggingLevel } from './services/utils/logging-level';
import { UserManagement } from './services/utils/state-management/action-classes';
import { LOGOUT, UPDATE_USER_BRAND } from './services/utils/state-management/actions';
import { IAppState } from './services/utils/state-management/store';
import { WhiteListService } from './services/white-list.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { VersionInfoComponent } from './public/version-info/version-info.component';
import { WebAccessibiltyComponent } from './public/webaccessibilty/web-accessibilty.component';
import { CommonService } from './services/common.service';
import { delay, share, tap } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, LoggedInCallback {

  title = 'consumer-portal';

  userObject: any;
  whiteListChecked: boolean;
  userBrand: string;
  unwantedPage: boolean;
  whiteListedUsersOnly: boolean;
  pathName = "";
  isVerified = true;
  currentYear = new Date().getFullYear();
  isDisabled: boolean = false;
  logoutCalled: boolean = false;
  configuration: any;


  constructor(private translate: TranslateService,
    private logger: Logger,
    private dataservice: DataShareService,
    private whiteListService: WhiteListService,
    private ngRedux: NgRedux<IAppState>,
    private activatedRoute: ActivatedRoute,
    private apiRequestService: ApiRequestService,
    private userManagement: UserManagement,
    private modalService: BsModalService,
    public userService: UserLoginService,
    private commonService: CommonService,
    private ngZone: NgZone,
    public router: Router) {

    this.router.events.subscribe((route: any) => {
      if (route.navigationTrigger === 'popstate' && route.url == '/home') {
        let email = localStorage.getItem(`CognitoIdentityServiceProvider.${environment.clientId}.LastAuthUser`);
        localStorage.removeItem(`CognitoIdentityServiceProvider.${environment.clientId}.${email}.idToken`);
      } else if (route.url == '/scanweburl' || route.url == '/scanweburl/index.html') {
        window.location.href = `${window.location.origin}/scanweburl/index.html`;
      }
    });

    this.whiteListService.getWhiteListUsersRequired(this);
    this.logger.log(LoggingLevel.INFO, "AppComponent: ", "constructor");
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        let url = event.urlAfterRedirects;
        if (url.toString().indexOf("404") > 0) {
          this.unwantedPage = true;
        }
      }
    });

    this.setupLanguage();
    let b = localStorage.getItem("userBrand");
    if (b == 'kwikset' || b == 'weiser') {
      this.ngRedux.dispatch({
        type: UPDATE_USER_BRAND,
        brand: b
      });
    } else {
      this.activatedRoute.queryParams.subscribe(
        params => {
          let brand = params["brand"]
          if (brand) {
            this.ngRedux.dispatch({
              type: UPDATE_USER_BRAND,
              brand: brand.trim().toLowerCase()
            });
          }
          // this.utility.removeLoader();
        });
    }

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const cookiesEvent = this.commonService.cookiesEventTrigger;
        if (!cookiesEvent || cookiesEvent === 'false') {
          this.commonService.cookiesEventTrigger = 'false';
          this.commonService.clearCookies();
        }

      }
    });
  }

  ngOnInit(): void {


    this.fetchConfiguration();

    this.ngRedux.select('userObject').subscribe((value) => {
      this.userObject = value;
    });

    this.ngRedux.select('userBrand').subscribe((value: any) => {
      this.userBrand = value;

    });

    this.ngRedux.select('whiteListChecked').subscribe((value: any) => {
      this.whiteListChecked = value;

    });

    this.dataservice.currentValue.subscribe((value) => {
      this.logoutCalled = value.logoutCalled;
    })

    this.router.events.subscribe((route: any) => {
      if (route.url && (route.url.includes('/login') || route.url.includes('/register'))) {
        this.isVerified = false;
      } else if (route.url) {
        this.isVerified = true;
      }
    });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.pathName = event.urlAfterRedirects;
      }
    });

    const time = this.userService.getLastActivityTime();
    if (time && environment.totalDelayTimeOut < Date.now() - time) {
      this.userService.logout();
      this.isLoggedIn(null, false);
      return;
    }

    this.logger.log(
      LoggingLevel.INFO,
      "AppComponent: ",
      "Checking if the user is already authenticated"
    );
    this.userService.isAuthenticated(this);

    if(!this.commonService.cookiesEventTrigger){
      document.getElementById("oneTrust").setAttribute("data-domain-script", environment.dataDomainScript);
      document.getElementById("oneTrust").setAttribute('src', 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js');
    }

  }

  setupLanguage() {
    this.logger.log(LoggingLevel.INFO, "AppComponent: ", "setupLanguage");
    const languages = this.configuration ? this.configuration.Feature.Languages : Config.TRANSLATION_LANGS;
    this.translate.addLangs(languages);
    this.translate.setDefaultLang(this.ngRedux.getState().preferredLanguage || languages[0]);

    let browserLang = this.translate.getBrowserLang() || '';
    this.translate.use(
      browserLang.match(/English|French|Spanish/) ? browserLang : this.ngRedux.getState().preferredLanguage
    );

  }

  showWebAccessibilty() {
    this.modalService.show(WebAccessibiltyComponent, { backdrop: Constants.MODAL_CONFIG.backdrop, class: 'web-access' });
  }

  showVersionInfo() {
    this.modalService.show(VersionInfoComponent, { backdrop: Constants.MODAL_CONFIG.backdrop, class: 'version-info' });
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    this.logger.log(LoggingLevel.INFO, "AppComponent: the user is authenticated:", isLoggedIn.toString());
    if (!isLoggedIn) {
      this.ngRedux.dispatch({ type: LOGOUT });
    }
    else {
      this.fetchUserDetails();
    }
  }

  homePage() {
    this.router.navigate(['/home']);
  }

  fetchUserDetails() {

    this.apiRequestService.callApi(Constants.API_METHODS.GET, apiConfig.me.uri).subscribe((res: any) => {
      if (res && res.data && res.data.length > 0) {
        this.logger.log(LoggingLevel.INFO, "AppComponent: Updating User Info", res.data[0]);
        this.userManagement.parseUserObjectAndDispatch(res.data[0]);
      }
    });
  }
  /*
  * this function is used to fetch configuration from backend.
  */

  fetchConfiguration() {
    this.apiRequestService.callApi(Constants.API_METHODS.GET, apiConfig.getConfiguration.uri).subscribe({
      next: (res: any) => {
        if (res && res.data && res.data.length > 0) {
          this.logger.log(LoggingLevel.INFO, "AppComponent: Updating Configuration", res.data[0]);
          this.configuration = res.data[0].configuration;
          localStorage.setItem('config', JSON.stringify(this.configuration));
          // language config is being fetched from get configuration request above
          if (this.configuration.hasOwnProperty('Feature')) {
            this.setupLanguage();
          }
        }
      }
    });
  }

  redirectHome() {
    if (this.isDisabled === false) {
      this.isDisabled = true;
      const currentState = JSON.parse(localStorage.getItem('state'));
      if (currentState && Object.keys(currentState).length !== 0 && currentState.userObject && currentState.userObject.phoneActive && this.isVerified) {
        this.router.navigate(['/securehome']).then(() => {
          location.reload();
        });
      }
      else {
        this.router.navigate(['/home/login']).then(() => {
          location.reload();
        });
      }
    }
  }

  onWhiteListRequired(check: boolean) {
    var whiteListed = sessionStorage.getItem('whiteListed');
    if (!whiteListed) {
      this.whiteListedUsersOnly = check
    }
    else {
      this.whiteListedUsersOnly = false;
    }
  }
}
