import { ComponentCanDeactivate } from "./ComponentCanDeactivate";
import { Injectable } from "@angular/core";
import { CanDeactivate } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<ComponentCanDeactivate> {
    constructor(
        public translate: TranslateService
    ) { }
    toastMsg: string = "";
    canDeactivate(component: ComponentCanDeactivate): boolean {
        
        // added component check because it was giving error in a case where component was null
        if (component && !component.canDeactivate()) {
            this.toastMsg = this.translate.instant('TOASTER.MESSAGE');
            if (confirm(this.toastMsg)) {
                return true;
            } else {
                return false;
            }
        }
        return true;
    }
}
