import { Pipe, PipeTransform } from '@angular/core';
import { AsYouType } from 'libphonenumber-js';

@Pipe({ name: 'phoneNumberFormat', pure: false })
export class PhoneNumberFormatPipe implements PipeTransform {
  constructor() {}
  transform(value: any, countryCode): string {
    let asYouType = new AsYouType(countryCode);
    asYouType.reset();
    return asYouType.input(value);
  }
}
