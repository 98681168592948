import { AfterViewInit, Component, ElementRef, EventEmitter, ViewChild, ViewEncapsulation } from '@angular/core';
// import { DialogComponent, DialogService } from "ng6-bootstrap-modal";
import { Router } from "@angular/router";
import { TResetModal } from '../../../services/utils/state-management/types';
import { RESET_MODAL_SOFT, RESET_MODAL_HARD } from '../../../services/utils/state-management/actions';
import { TranslateService } from '@ngx-translate/core';
import { Config } from '../../../services/utils/Config';
import { LanguageChangeService } from '../../../services/language-change.service';
import { BsModalRef } from 'ngx-bootstrap/modal';

export interface ViewSharedUserOptionsModal {
  filters: Object
}
@Component({
  selector: 'app-view-shared-user-filter-modal',
  templateUrl: './view-shared-user-filter-modal.component.html',
  styleUrls: ['./view-shared-user-filter-modal.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ViewSharedUserFilterModalComponent implements ViewSharedUserOptionsModal, AfterViewInit {
  currentTab = 0;
  selectedUserName = [];
  selectedAccessLevels: any = [];
  filters;
  accessLevels = Config.ACCESS_LEVELS;
  statuses = Config.USER_STATUSES;
  selectedStatuses = [];
  selectedSharedBy = [];
  modalCss = 'view-shared';
  filterTypes = {
    USERNAME: 'username',
    ACCESS_LEVEL: 'accesslevel',
    SHARED_BY: 'sharedby',
    STATUS: 'status'
  }
  errorMsg = "Enter or choose any value before applying the filter"
  errorDisplay = "";
  private resetType: TResetModal = RESET_MODAL_SOFT;
  lastTab: number;
  tabs = {
    ACCESS_LEVELS: 2,
    STATUS: 3,
    SHARED_BY: 4
  }
  result: {};
  //Selected Language
  langCode: string;
  defaultLangCode: string;
  public event: EventEmitter<any> = new EventEmitter();
  public eventClose: EventEmitter<any> = new EventEmitter();
  holdSelectedAccessLevels = [];
  holdSelectedStatuses = [];
  holdSelectedSharedBy = [];

  @ViewChild('filterModal') filterModal: ElementRef;

  constructor(public router: Router,
    private translate: TranslateService,
    private langService: LanguageChangeService,
    public bsModalRef: BsModalRef) {
  }

  /**
   * @description prevent to focus background input and focus to modal input
   * @returns void 
   */
  focusInput(): void {
    this.filterModal.nativeElement.focus();
  }

  /**
   * @description this is angular hook responisble when page fully load 
   * @returns void
   */
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.focusInput();
    }, 0);
  }

  ngOnInit() {

    this.langCode = this.langService.getCurrentLanguage();
    this.defaultLangCode = this.langService.getDefaultLanguage();

    this.selectedAccessLevels = this.filters.selectedAccessLevels ? this.filters.selectedAccessLevels : [];
    this.selectedSharedBy = this.filters.selectedSharedBy ? this.filters.selectedSharedBy : [];
    this.selectedStatuses = this.filters.selectedStatuses ? this.filters.selectedStatuses : [];

    if (this.selectedAccessLevels.length || this.selectedSharedBy.length || this.selectedStatuses.length) {
      this.resetType = RESET_MODAL_HARD;
    }

  }

  private preSelectedValue(IsbackButton: boolean, activeTabId: number) {
    switch (activeTabId) {
      case 2:
        if (IsbackButton)
          this.selectedAccessLevels = [...this.holdSelectedAccessLevels];
        else
          this.holdSelectedAccessLevels = [...this.selectedAccessLevels];
        break;
      case 3:
        if (IsbackButton)
          this.selectedStatuses = [...this.holdSelectedStatuses];
        else
          this.holdSelectedStatuses = [...this.selectedStatuses];
        break;
      case 4:
        if (IsbackButton)
          this.selectedSharedBy = [...this.holdSelectedSharedBy];
        else
          this.holdSelectedSharedBy = [...this.selectedSharedBy];
        break;

    }
  }
  changeTab(tabId, filterApplied, IsbackButton = false, activeTabId = 1) {
    this.errorDisplay = "";
    this.preSelectedValue(IsbackButton, tabId); // initial state hold of lock, date picker, usertype, activities
    if (tabId === this.tabs.ACCESS_LEVELS) {
      this.lastTab = this.tabs.ACCESS_LEVELS;
      this.modalCss = 'view-shared-input';
    } else if (tabId === this.tabs.STATUS) {
      this.lastTab = this.tabs.STATUS;
      this.modalCss = 'view-shared-list';
    } else if (tabId === this.tabs.SHARED_BY) {
      this.lastTab = this.tabs.SHARED_BY;
      this.modalCss = 'view-shared-input';
    } else {
      if (!filterApplied) {
        this.emptyFilters();
      }
      this.modalCss = 'view-shared';  // hold value for back button click resign to values
      this.preSelectedValue(IsbackButton, activeTabId);
    }
    this.currentTab = tabId
  }


  emptyFilters() {
    if (this.lastTab === this.tabs.ACCESS_LEVELS) {
      this.selectedAccessLevels = [];
    } else if (this.lastTab === this.tabs.STATUS) {
      this.selectedStatuses = [];
    } else if (this.lastTab === this.tabs.SHARED_BY) {
      this.selectedSharedBy = [];
    }
  }

  addUserNameFilter(event) {
    this.errorDisplay = "";
    if (event.target.value) {
      this.selectedUserName = [event.target.value];
    } else {
      this.selectedUserName = [];
    }
  }

  addSharedByFilter(event) {
    this.errorDisplay = "";
    if (event.target.value) {
      this.selectedSharedBy = [event.target.value];
    } else {
      this.selectedSharedBy = [];
    }
  }

  addAccessLevelFilter(accessLevel) {
    this.errorDisplay = "";
    let index = this.selectedAccessLevels.findIndex(name => accessLevel[this.defaultLangCode] === name[this.defaultLangCode]);
    if (index > -1) {
      this.selectedAccessLevels.splice(index, 1);
    } else {
      this.selectedAccessLevels.push(accessLevel);
    }
  }

  addStatusFilter(status) {
    this.errorDisplay = "";
    let index = this.selectedStatuses.findIndex(name => status[this.defaultLangCode] === name[this.defaultLangCode]);
    if (index > -1) {
      this.selectedStatuses.splice(index, 1);
    } else {
      this.selectedStatuses.push(status);
    }
  }

  closeDialogue(selecteTab) {

    if (selecteTab === this.tabs.ACCESS_LEVELS || selecteTab === this.tabs.STATUS || selecteTab === this.tabs.SHARED_BY) {
      this.preSelectedValue(true, selecteTab);
    }
    let setFilter = false;
    if (this.selectedUserName.length || this.selectedStatuses.length || this.selectedAccessLevels.length || this.selectedSharedBy.length) {
      setFilter = true;
    }
    this.result = { setFilter, selectedUserName: this.selectedUserName, selectedStatuses: this.selectedStatuses, selectedAccessLevels: this.selectedAccessLevels, selectedSharedBy: this.selectedSharedBy }
    this.eventClose.emit(this.result);
    this.bsModalRef.hide();
  }

  closeDialog() {
    let setFilter = false;
    if (this.selectedUserName.length || this.selectedStatuses.length || this.selectedAccessLevels.length || this.selectedSharedBy.length) {
      setFilter = true;
    }
    this.result = { setFilter, selectedUserName: this.selectedUserName, selectedStatuses: this.selectedStatuses, selectedAccessLevels: this.selectedAccessLevels, selectedSharedBy: this.selectedSharedBy }
    this.event.emit(this.result);
    this.bsModalRef.hide();
  }

  resetFilterAndCloseDialog() {
    this.result = { setFilter: false };
    this.event.emit(this.result);
    this.bsModalRef.hide();
    // this.dialogService.removeAll();
  }

  checkFilterApplied(type) {
    if (type === this.filterTypes.USERNAME && this.selectedUserName.length) {
      return true;
    } else if (type === this.filterTypes.ACCESS_LEVEL && this.selectedAccessLevels.length) {
      return true;
    } else if (type === this.filterTypes.STATUS && this.selectedStatuses.length) {
      return true;
    } else if (type === this.filterTypes.SHARED_BY && this.selectedSharedBy.length) {
      return true;
    }
    this.errorDisplay = "error-msg-display";
    return false;
  }

  isAccessLevelChecked(accessLevel) {
    let isChecked = this.selectedAccessLevels.find(ele => ele[this.defaultLangCode] === accessLevel[this.defaultLangCode]);
    return isChecked;
  }

  isStatusChecked(status) {
    let isChecked = this.selectedStatuses.find(ele => ele[this.defaultLangCode] === status[this.defaultLangCode]);
    return isChecked;
  }

}
