export default {
    MfaCode: {
        uri: '/users/verificationcodes',
        needAuthentication: false
    },
    signUp: {
        uri: '/users',
        needAuthentication: false
    },
    signUpFederated:{
        uri:'/federatedusers',
        needAuthentication: true
    },
    me: {
        uri: '/users/me?getProfilePicUrl=true',
        needAuthentication: true
    },
    toggleEnableDisableAccount: {
        uri: '/users/me/status',
        needAuthentication: true
    },
    deleteAccount: {
        uri: '/users/me',
        needAuthentication: true
    },
    updateUser: {
        uri: '/users/me',
        needAuthentication: true
    },
    forgotPassword: {
        uri: '/users/password/request',
        needAuthentication: false
    },
    forgotPasswordGetSecurityQuestions: {
        uri: '/users/password/questions/request',
        needAuthentication: false
    },
    forgotPasswordVerifySecurityQuestion: {
        uri: '/users/password/questions/confirm',
        needAuthentication: false
    },
    confirmForgotPassword: {
        uri: '/users/password/confirm',
        needAuthentication: false
    },
    getHome: {
        uri: '/users/me/homes?top={pageSize}',
        needAuthentication: true
    },
    getSharedUsers: {
        uri: '/homes/{id}/sharedusers',
        needAuthentication: true
    },
    getSharedUsersWithParemeter: {
        uri: '/homes/{id}/sharedusers?filter={filter}&orderby={orderBy}&getSharedUserProfilePic=true',
        needAuthentication: true
    },
    toggleSharedUserStatus: {
        uri: '/homes/{id}/sharedusers/{email}',
        needAuthentication: true
    },
    deleteSharedUserStatus: {
        uri: '/homes/{id}/sharedusers/{email}',
        needAuthentication: true
    },
    getLocksByHomeId: {
        uri: '/homes/{id}/devices',
        needAuthentication: true
    },
    getLocksDetails: {
        uri: '/devices/{id}?getDevicePicUrl=true',
        needAuthentication: true
    },
    getLockHistory: {
      uri: '/devices_v3/{id}/history_v3?top={pageSize}&orderby={orderBy}%20{sortOrder}',
      needAuthentication: true
    },
    getLockHistoryv4: {
      uri: '/devices_v4/{id}/history_v4?top={pageSize}&orderby={orderBy}%20{sortOrder}',
      needAuthentication: true
    },
    getLockHistoryWithParams: {
      uri: '/devices_v3/{id}/history_v3?filter={filter}&orderby={orderBy}%20{sortOrder}&startkey={pageNo}&top={pageSize}&todate={toDate}&fromdate={fromDate}',
      needAuthentication: true
    },
    getLockHistoryv4WithParams: {
      uri: '/devices_v4/{id}/history_v4?filter={filter}&orderby={orderBy}%20{sortOrder}&startkey={pageNo}&top={pageSize}&todate={toDate}&fromdate={fromDate}',
      needAuthentication: true
    },
    getHomeHistory: {
      uri: '/homes/{id}/history_v3?top={pageSize}&orderby={orderBy}%20{sortOrder}',
      needAuthentication: true
    },
    getHomeHistoryWithParams: {
      uri: '/homes/{id}/history_v3?filter={filter}&orderby={orderBy}%20{sortOrder}&startkey={pageNo}&top={pageSize}&todate={toDate}&fromdate={fromDate}',
      needAuthentication: true
    },
    checkWhiteList: {
        uri: '/jumppage/verify',
        needAuthentication: false
    },
    getConfiguration: {
        uri: '/configuration',
        needAuthentication: false
    },
    checkWhiteListRequired: {
        uri: '/jumppage/required',
        needAuthentication: false
    },
    getSecreteQuestion: {
        uri: '/users/me/questions',
        needAuthentication: true
    },
    changePassword: {
        uri: '/users/me/password',
        needAuthentication: true
    },
    getCountryCode: {
        uri: '/country/codes',
        needAuthentication: false
    },
    getPresignedURL: {
        uri: '/presignedurl',
        needAuthentication: true
    }
};
