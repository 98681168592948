import { ApiRequestService } from './api-client/api-requests.service';
import { IAppState } from './utils/state-management/store';
import { NgRedux } from '@angular-redux/store';
import apiConfig from './api-client/api-config';
import { UPDATE_LOCKS } from './utils/state-management/actions';
import { Injectable } from '@angular/core';
import { Config } from './utils/Config';
import { HomeService } from './home.service';
import { LanguageChangeService } from './language-change.service';
import { Constants } from './utils/Constants';
import { Utility } from './utils/utility';

export interface LockCallback {
  callback(lock: ILockObject[]);
}

export interface LockDetailCallback {
  callback(lock: ILockDetails, error);
}

export interface LockHistoryCallback {
  callback(lock: ILockHistory[], issue: ILockHistory[], totalRecord: number);
}

export interface ILockHistory {
  eventLocaleName: string;
  eventType: string;
  eventTypeLocale: string;
  event: string;
  eventDescription: string;
  user: string;
  time: Date;
  lockOffsetInMinutes?: number;
  lockName: string;
}

export interface ILockObject {
  lockState: string;
  lockName: string;
  firmwareVersion: string;
  lockBattery: number;
  deviceID: string;
  modelNumber: string;
  batteryStatus: string;
  isBLE: boolean;
  bleAdvertisement: string;
  lastUpdateStatus: number;
  hardwareVariant: string;
  activationdate: number;
}

export interface ILockDetails {
  batteryStatus: string;
  batteryStatusLocale: string;
  doorStatus: string;
  lockTamperState: string;
  modelNumber: string;
  sku: string;
  serialNumber: string;
  firmwareBundleVersion: string;
  productFamily: string;
  productFamilyLocale: string;
  doorStatusLocale: string;
  isBLE: boolean;
  deviceImage: string;
  hardwareVariant: string;
  lastUpdateStatus: string;
  lockState?: string;
}

@Injectable()
export class LockService {

  activation_date = "activationdate";

  constructor(
    private ngRedux: NgRedux<IAppState>,
    public apiService: ApiRequestService,
    private homeService: HomeService,
    private langService: LanguageChangeService,
    private utility: Utility
  ) {}

  getLocksByHomeId(homeId: string, callback: LockCallback) {
    let locks = this.ngRedux.getState().locks;
    if (locks && locks[homeId] != null && locks[homeId].length > 0) {
      callback.callback(locks[homeId]);
    }

    let pathParams = {
      id: homeId,
    };

    this.apiService
      .callApi(
        Constants.API_METHODS.GET,
        apiConfig.getLocksByHomeId.uri,
        null,
        false,
        pathParams
      )
      .subscribe({
        next: (res) => {
          if (res && res.data && res.data.length >= 0) {
            const lock: ILockObject[] = [];
            res.data.forEach((element) => {
              const h: ILockObject = {
                lockState: element['lockstatus']
                  ? element['lockstatus'].toLowerCase()
                  : '',
                lockName: element['devicename'],
                firmwareVersion: element['firmwareversion'],
                lockBattery: element['batterypercentage'],
                deviceID: element['deviceid'],
                modelNumber: element['modelnumber'],
                batteryStatus: element['batterystatus'],
                isBLE:
                  element['bleadvertisement'] === '1' ||
                  element['modelnumber'].indexOf('BLE') >= 0
                    ? true
                    : false,
                bleAdvertisement: element['bleadvertisement'],
                lastUpdateStatus: element['lastupdatestatus'],
                hardwareVariant: element['hardwarevariant'],
                activationdate: element['activationdate'],
              };
              lock.push(h);
              this.utility.sort(lock,this.activation_date,"date");
            });
            this.ngRedux.dispatch({
              type: UPDATE_LOCKS,
              homeID: homeId,
              locks: lock,
            });
            callback.callback(lock);
            return;
          }
          callback.callback(null);
        },
        error: (err) => {
          callback.callback(null);
        },
      });
  }

  getLockDetails(lockId: string, callback: LockDetailCallback) {
    let pathParams = {
      id: lockId,
    };
    this.apiService
      .callApi(
        Constants.API_METHODS.GET,
        apiConfig.getLocksDetails.uri,
        null,
        true,
        pathParams
      )
      .subscribe({
        next: (res) => {
          if (res && res.data && res.data.length > 0) {
            let batteryStatusLangObj = Config.BATTERY_STATUS_LIST.find(
              (obj) =>
                obj[this.langService.getDefaultLanguage()] ===
                res.data[0]['batterystatus']
            );
            let productFamilyLangObj = Config.PRODUCTS_LIST.find(
              (obj) =>
                obj[this.langService.getDefaultLanguage()] ===
                res.data[0]['productfamily']
            );
            let doorStatusLangObj = Config.HISTORY_CHECKLISTS.find(
              (obj) =>
                obj[this.langService.getDefaultLanguage()] ===
                res.data[0]['doorstatus']
            );
            const lock: ILockDetails = {
              batteryStatus: res.data[0]['batterystatus'],
              batteryStatusLocale: batteryStatusLangObj
                ? batteryStatusLangObj[this.langService.getCurrentLanguage()]
                : res.data[0]['batterystatus'],
              doorStatus: res.data[0]['doorstatus'],
              doorStatusLocale: doorStatusLangObj
                ? doorStatusLangObj[this.langService.getCurrentLanguage()]
                : res.data[0]['doorstatus'],
              lockTamperState:
                res.data[0]['locktamperstate'] === ''
                  ? 'InActive'
                  : res.data[0]['locktamperstate'],
              modelNumber: res.data[0]['modelnumber'],
              sku: res.data[0]['sku'],
              serialNumber: res.data[0]['serialnumber'],
              firmwareBundleVersion: res.data[0]['firmwarebundleversion'],
              productFamily: res.data[0]['productfamily'],
              productFamilyLocale: productFamilyLangObj
                ? productFamilyLangObj[this.langService.getCurrentLanguage()]
                : res.data[0]['productfamily'],
              isBLE:
                res.data[0]['bleadvertisement'] === '1' ||
                res.data[0]['modelnumber'].indexOf('BLE') >= 0
                  ? true
                  : false,
              deviceImage: res.data[0]['presignedurl'],
              hardwareVariant: res.data[0]['hardwarevariant'],
              lastUpdateStatus: res.data[0]['lastupdatestatus'],
            };

            const d = res.data[0] || {};
            this.homeService.updateHomeName(d.homename, null, lockId);
            callback.callback(lock, null);
            return;
          }
          this.homeService.updateHomeName(null, null, lockId);
          callback.callback(null, res.type);
        },
      });
  }

  getLockName(lockId: string) {
    const locks = this.ngRedux.getState().locks;
    for (const item in locks) {
      if (locks.hasOwnProperty(item)) {
        for (const lock of locks[item]) {
          if (lock['deviceID'] === lockId) {
            return lock['lockName'];
          }
        }
      }
    }
  }

  getHomeName(lockId: string) {
    const locks = this.ngRedux.getState().locks;
    for (const item in locks) {
      if (locks.hasOwnProperty(item)) {
        for (const lock of locks[item]) {
          if (lock['deviceID'] === lockId) {
            const homes = this.ngRedux.getState().homes;
            for (const h of homes) {
              if (h.homeId === item) {
                return h.homeName;
              }
            }
          }
        }
      }
    }
  }

  isBLELock(lockId: string) {
    const locks = this.ngRedux.getState().locks;
    for (const item in locks) {
      if (locks.hasOwnProperty(item)) {
        for (const lock of locks[item]) {
          if (lock.deviceID === lockId) {
            return lock.isBLE;
          }
        }
      }
    }
    return null;
  }

  getLockHistory(lockId: string, callback: LockHistoryCallback) {
    const pathParams = {
      id: lockId,
      pageSize: Config.HISTORY_ON_ONE_PAGE,
      orderBy: '',
      sortOrder: 'desc',
    };

    const UNKNOWN_SOURCE = 'Unknown Source';
    const STATUS_UPDATE = 'Status Update';

    this.apiService
      .callApi(
        Constants.API_METHODS.GET,
        apiConfig.getLockHistoryv4.uri,
        null,
        true,
        pathParams
      )
      .subscribe({
        next: (res) => {
          if (res && res.data && res.data.length > 0) {
            const locks: ILockHistory[] = [];
            const issues: ILockHistory[] = [];
            const events = res.data;
            const issuesHistory = res.issues ? res.issues : [];

            events.forEach((element) => {
              let languageMappingObj = Config.HISTORY_CHECKLISTS.find(
                (ele) =>
                  ele[this.langService.getDefaultLanguage()] === element.event
              );
              let languageMappingSourceList = Config.SOURCES_CHECKLISTS.find(
                (ele) =>
                  ele[this.langService.getDefaultLanguage()] ===
                  element.eventtype
              );
              const eventTypeLocale = languageMappingSourceList
                ? languageMappingSourceList[
                    this.langService.getCurrentLanguage()
                  ]
                : element.eventtype;
              const lock: ILockHistory = {
                eventLocaleName: languageMappingObj
                  ? languageMappingObj[this.langService.getCurrentLanguage()]
                  : element.event,
                event: element['event'] ? element['event'] : '',
                eventTypeLocale: eventTypeLocale,
                eventDescription: element['eventtype']
                  ? element['eventtype'] === UNKNOWN_SOURCE
                    ? STATUS_UPDATE
                    : eventTypeLocale
                  : '',
                user: element['user'] ? element['user'] : '',
                time: new Date(element['timestamp'] * 1000),
                lockName: element['devicename'] ? element['devicename'] : '',
                eventType: element['eventtype']
                  ? element['eventtype'] === UNKNOWN_SOURCE
                    ? STATUS_UPDATE
                    : eventTypeLocale
                  : '',
              };
              locks.push(lock);
            });

            issuesHistory.forEach((element) => {
              let languageMappingObj = Config.HISTORY_CHECKLISTS.find(
                (ele) =>
                  ele[this.langService.getDefaultLanguage()] === element.event
              );
              let languageMappingSourceList = Config.SOURCES_CHECKLISTS.find(
                (ele) =>
                  ele[this.langService.getDefaultLanguage()] ===
                  element.eventtype
              );
              const eventTypeLocale = languageMappingSourceList
                ? languageMappingSourceList[
                    this.langService.getCurrentLanguage()
                  ]
                : element.eventtype;
              const lock: ILockHistory = {
                eventLocaleName: languageMappingObj
                  ? languageMappingObj[this.langService.getCurrentLanguage()]
                  : element.event,
                event: element['event'] ? element['event'] : '',
                eventTypeLocale: eventTypeLocale,
                eventDescription: element['eventtype']
                  ? element['eventtype'] === UNKNOWN_SOURCE
                    ? STATUS_UPDATE
                    : eventTypeLocale
                  : '',
                user: element['user'] ? element['user'] : '',
                time: new Date(element['timestamp'] * 1000),
                lockName: element['devicename'] ? element['devicename'] : '',
                eventType: element['eventtype']
                  ? element['eventtype'] === UNKNOWN_SOURCE
                    ? STATUS_UPDATE
                    : eventTypeLocale
                  : '',
              };
              issues.push(lock);
            });
            this.homeService.updateHomeName(res.data[0].homename, null, lockId);
            callback.callback(locks, issues, res.total);
            return;
          }
          this.homeService.updateHomeName(null, null, pathParams.id);
          callback.callback([], [], 0);
        },
      });
  }

  getLockHistoryWithParams(pathParams: any, callback: LockHistoryCallback) {
    this.apiService
      .callApi(
        Constants.API_METHODS.GET,
        apiConfig.getLockHistoryv4WithParams.uri,
        null,
        true,
        pathParams
      )
      .subscribe({
        next: (res) => {
          if (
            (res && res.data && res.data.length > 0) ||
            (res.issues && res.issues.length > 0)
          ) {
            const locks: ILockHistory[] = [];
            const issues: ILockHistory[] = [];
            const events = res.data ? res.data : [];
            const issuesHistory = res.issues ? res.issues : [];
            const UNKNOWN_SOURCE = 'Unknown Source';
            const STATUS_UPDATE =
              Config.STATUS_UPDATE[this.langService.getCurrentLanguage()];
            const localOffsetInSeconds = new Date().getTimezoneOffset() * 60;
            events.forEach((element) => {
              let languageMappingObj = Config.HISTORY_CHECKLISTS.find(
                (ele) =>
                  ele[this.langService.getDefaultLanguage()] === element.event
              );
              let languageMappingSourceList = Config.SOURCES_CHECKLISTS.find(
                (ele) =>
                  ele[this.langService.getDefaultLanguage()] ===
                  element.eventtype
              );
              const eventTypeLocale = languageMappingSourceList
                ? languageMappingSourceList[
                    this.langService.getCurrentLanguage()
                  ]
                : element.eventtype;
              const lock: ILockHistory = {
                eventLocaleName: languageMappingObj
                  ? languageMappingObj[this.langService.getCurrentLanguage()]
                  : element.event,
                event: element['event'] ? element['event'] : '',
                eventTypeLocale: eventTypeLocale,
                eventDescription: element['eventtype']
                  ? element['eventtype'] === UNKNOWN_SOURCE
                    ? STATUS_UPDATE
                    : eventTypeLocale
                  : '',
                user: element['user']
                  ? element['user']
                  : element['eventtype']
                  ? element['eventtype'] === UNKNOWN_SOURCE
                    ? STATUS_UPDATE
                    : eventTypeLocale
                  : '',
                time: new Date(
                  (element['timestamp'] + localOffsetInSeconds) * 1000
                ),
                lockOffsetInMinutes: this.homeService.getLockOffsetInMinutes(
                  element['timezone']
                ),
                lockName: element['devicename'] ? element['devicename'] : '',
                eventType: element['eventtype']
                  ? element['eventtype'] === UNKNOWN_SOURCE
                    ? STATUS_UPDATE
                    : eventTypeLocale
                  : '',
              };
              locks.push(lock);
            });

            issuesHistory.forEach((element) => {
              let languageMappingObj = Config.HISTORY_CHECKLISTS.find(
                (ele) =>
                  ele[this.langService.getDefaultLanguage()] === element.event
              );
              let languageMappingSourceList = Config.SOURCES_CHECKLISTS.find(
                (ele) =>
                  ele[this.langService.getDefaultLanguage()] ===
                  element.eventtype
              );
              const eventTypeLocale = languageMappingSourceList
                ? languageMappingSourceList[
                    this.langService.getCurrentLanguage()
                  ]
                : element.eventtype;
              const lock: ILockHistory = {
                eventLocaleName: languageMappingObj
                  ? languageMappingObj[this.langService.getCurrentLanguage()]
                  : element.event,
                event: element['event'] ? element['event'] : '',
                eventTypeLocale: eventTypeLocale,
                eventDescription: element['eventtype']
                  ? element['eventtype'] === UNKNOWN_SOURCE
                    ? STATUS_UPDATE
                    : eventTypeLocale
                  : '',
                user: element['user']
                  ? element['user']
                  : element['eventtype']
                  ? element['eventtype'] === UNKNOWN_SOURCE
                    ? STATUS_UPDATE
                    : eventTypeLocale
                  : '',
                time: new Date(
                  (element['timestamp'] + localOffsetInSeconds) * 1000
                ),
                lockOffsetInMinutes: this.homeService.getLockOffsetInMinutes(
                  element['timezone']
                ),
                lockName: element['devicename'] ? element['devicename'] : '',
                eventType: element['eventtype']
                  ? element['eventtype'] === UNKNOWN_SOURCE
                    ? STATUS_UPDATE
                    : eventTypeLocale
                  : '',
              };
              issues.push(lock);
            });
            callback.callback(locks, issues, res.total);
            return;
          }
          callback.callback([], [], 0);
        },
      });
  }

  getLocksCounter() {
    const locks = this.ngRedux.getState().locks;
    let keys = Object.keys(locks);
    let allLocks = keys.reduce(
      (acc, obj) => {
        acc.counter = acc.counter + locks[obj].length;
        return acc;
      },
      {
        counter: 0,
      }
    );
    return `${allLocks.counter}`;
  }
}
