import { NgRedux } from '@angular-redux/store';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
/* import { DialogService } from 'ng6-bootstrap-modal'; */
import { SecurityQuestionsBaseComponent } from '../../../../public/base-components/security-questions-base/security-questions-base.component';
import { UserProfileService } from '../../../../services/user-profile.service';
import { Callback } from '../../../../services/utils/cognito.service';
import { ResponseHandlerService } from '../../../../services/utils/response-handler.service';
import { IAppState } from '../../../../services/utils/state-management/store';
import { Utility } from '../../../../services/utils/utility';
import { LanguageChangeService } from '../../../../services/language-change.service';
import { Config } from 'src/app/services/utils/Config';

@Component({
  selector: 'app-change-security-questions',
  templateUrl: './change-security-questions.html',
  styleUrls: ['../../../../public/base-components/security-questions-base/security-questions-base.component.css', './change-security-questions.css']
})
export class ChangeSecurityQuestionsComponent extends SecurityQuestionsBaseComponent implements Callback, OnInit {
  override errorMessage: string;
  override securityQuestions: FormGroup;
  successMessage: string;

  override QuestionList = [];
  genericMessage = null;

  constructor(
    public override formBuilder: FormBuilder,
    public override translate: TranslateService,
    public override ngRedux: NgRedux<IAppState>,
    public userProfileService: UserProfileService,
    public responseHandler: ResponseHandlerService,
    public router: Router,
    /* private dialogService: DialogService, */
    private langService: LanguageChangeService,
    private utility: Utility,
  ) {
    super(formBuilder, translate, ngRedux);
  }

  override ngOnInit() {
    super.ngOnInit();
    this.userProfileService.getSecurityQuestions(this);
  }

  onSubmit() {
    let questionLangObj1  = Config.SECURITY_QUESTIONS.find(ele => ele[this.langService.getCurrentLanguage()] === this.question1);
    const Question1 = questionLangObj1  ? questionLangObj1 [this.langService.getDefaultLanguage()] : this.question1;
    let questionlangObj2  = Config.SECURITY_QUESTIONS.find(ele => ele[this.langService.getCurrentLanguage()] === this.question2);
    const Question2 = questionlangObj2  ? questionlangObj2 [this.langService.getDefaultLanguage()] : this.question2;
    let questionLangObj3  = Config.SECURITY_QUESTIONS.find(ele => ele[this.langService.getCurrentLanguage()] === this.question3);
    const Question3 = questionLangObj3  ? questionLangObj3 [this.langService.getDefaultLanguage()] : this.question3;
    this.userProfileService.updateSecurityQuestions(
      Question1.trim(),
      this.securityQuestions.get('answer1').value.trim(),
      Question2.trim(),
      this.securityQuestions.get('answer2').value.trim(),
      Question3.trim(),
      this.securityQuestions.get('answer3').value.trim(),
      this
    );
  }

  callback() {
    this.successMessage = this.translate.instant("PROFILE.CHANGE_SECURITY_QUESTIONS.POPUP.SUCCESS_MESSAGE");
    this.utility.addSuccessMessage(this.successMessage);
    this.router.navigate(['/securehome']);
  }

  callbackWithParam(messages: any) {
    messages = this.responseHandler.getErrorMessage('REGISTRATION.EMAIL_VERIFICATION.ERROR', messages);
    let errMessg = '';
    this.errorMessage = '';
    for (errMessg of messages) {
      this.errorMessage += errMessg + ', ';
    }
    this.errorMessage = this.errorMessage.substring(0, this.errorMessage.length - 2);
    if (this.errorMessage.slice(-1) !== ".") {
      this.errorMessage += ".";
    }
  }

  getSubtitle() {
    return this.translate.instant('PROFILE.EDIT.CHANGE_SECURITY_SUB_TITLE');
  }

  questionsAndAnswers(obj) {
    let languageMappingObj = Config.SECURITY_QUESTIONS.find(ele => ele[this.langService.getDefaultLanguage()] === obj.secretQuestion1);
    const SQ1 = languageMappingObj ? languageMappingObj[this.langService.getCurrentLanguage()] : obj.secretQuestion1;
    this.securityQuestions.get('question1').setValue(SQ1);
    this.securityQuestions.get('answer1').setValue(obj.secretAnswer1);


    let languageMappingObj1 = Config.SECURITY_QUESTIONS.find(ele => ele[this.langService.getDefaultLanguage()] === obj.secretQuestion2);
    const SQ2 = languageMappingObj1 ? languageMappingObj1[this.langService.getCurrentLanguage()] : obj.secretQuestion2;
    this.securityQuestions.get('question2').setValue(SQ2);
    this.securityQuestions.get('answer2').setValue(obj.secretAnswer2);


    let languageMappingObj2 = Config.SECURITY_QUESTIONS.find(ele => ele[this.langService.getDefaultLanguage()] === obj.secretQuestion3);
    const SQ3 = languageMappingObj2 ? languageMappingObj2[this.langService.getCurrentLanguage()] : obj.secretQuestion3;
    this.securityQuestions.get('question3').setValue(SQ3);
    this.securityQuestions.get('answer3').setValue(obj.secretAnswer3);

    this.securityQuestions.get('question1').markAsDirty();
    this.securityQuestions.get('answer1').markAsDirty();
    this.securityQuestions.get('question2').markAsDirty();
    this.securityQuestions.get('answer2').markAsDirty();
    this.securityQuestions.get('question3').markAsDirty();
    this.securityQuestions.get('answer3').markAsDirty();
  }

  clearField(value) {
    this.securityQuestions.get(value).setValue('');
  }

}
