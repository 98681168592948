const terms_fr = `<p align="CENTER">
<a name="Terms"></a>
<strong>CONDITIONS D’UTILISATION</strong>
</p>
<h2>
SPECTRUM BRANDS HOLDINGS, INC. CONDITIONS D’UTILISATION
</h2>
<p>
Dernière mise à jour le 21 octobre 2013
</p>
<p>
Les sites Web Spectrum Brands Holdings, Inc. sont exploités par Spectrum
Brands Holdings, Inc., ses sociétés affiliées ou ses filiales
(collectivement, les « Sites » et collectivement, « Spectrum » ou « nous
»). Les présentes conditions d’utilisation et toutes les conditions
supplémentaires que nous publions périodiquement sur le Site (« Conditions
d’utilisation ») énoncent les conditions générales au titre desquelles vous
pouvez entrer dans le Site et l’utiliser ainsi que tous les services
offerts sur le Site qui peuvent inclure, mais sans toutefois s’y limiter,
ce qui suit : la possibilité d’acheter des produits dans un magasin en
ligne ou la possibilité de créer un profil d’utilisateur et de soumette du
contenu généré par l’utilisateur au Site (collectivement, les « Services
»). Il peut être nécessaire de s’inscrire pour utiliser les Services ou
certaines fonctions du Site. Dans le cadre du processus d’inscription, il
se peut que vous deviez sélectionner un mot de passe et un nom
d’utilisateur sous forme d’une adresse courriel que vous fournissez et d’un
nom de connexion (« Nom d’utilisateur »). Veuillez lire attentivement les
Conditions d’utilisation. En visitant le Site ou en l’utilisant et/ou en
vous inscrivant sur le Site, vous acceptez les Conditions d’utilisation. Si
vous n’acceptez pas les Conditions d’utilisation (sans modification), vous
n’êtes pas autorisé à consulter le Site ou à l’utiliser.
</p>
<p>
Les présentes Conditions d’utilisation énoncent les conditions ayant force
obligatoire eu égard à votre utilisation du Site. Spectrum se réserve le
droit de modifier ou de changer à tout moment les Conditions d’utilisation,
sans être tenue de vous envoyer un préavis. Lesdits changements ou
modifications devront être effectifs dès leur publication sur le Site. Si
Spectrum révise les Conditions d’utilisation, elle révisera également la
date de la « dernière mise à jour » au sommet de la présente page. La
version courante des Conditions d’utilisation peut être consultée en
cliquant sur le lien hypertexte « CONDITIONS D’UTILISATION » situé en bas
des pages Web de Spectrum. En visitant le Site et/ou en l’utilisant de
façon continue une fois que Spectrum a publié des Conditions d’utilisation
révisées, vous signifiez que vous acceptez toutes lesdites Conditions
d’utilisation révisées.
</p>
<p>
<strong>
    NONOBSTANT TOUT CE QUI EST CONTENU DANS LES CONDITIONS D’UTILISATION OU
    SUR LE SITE QUI POURRAIT LAISSER SUPPOSER LE CONTRAIRE, LE SITE EST
    FOURNI SANS GARANTIES ET SOUS RÉSERVE DES LIMITATIONS DE LA
    RESPONSABILITÉ DE SPECTRUM ÉNONCÉES DANS LES PRÉSENTES CONDITIONS
    D’UTILISATION.
</strong>
</p>
<p>
<strong>1. Utilisation et restrictions générales</strong>
</p>
<p>
<strong>1.1. Utilisation du Site.</strong>
Sous condition et sous réserve de votre stricte adhésion aux Conditions
d’utilisation, Spectrum vous octroie une licence nominative, non exclusive,
incessible et limitée pour entrer dans le Site et l’utiliser afin de
consulter des informations, utiliser les Services et à d’autres fins
expressément autorisées par Spectrum, par écrit sur le Site.
</p>
<p>
Vous acceptez de ne pas visiter le Site et de ne pas l’utiliser à des fins
qui sont interdites par les conditions d’utilisation ou qui sont, d’une
manière ou d’une autre, illégales. Vous acceptez également de vous
conformer à toutes les lois des États-Unis en vigueur, à tous les statuts,
à toutes les ordonnances et réglementations, à tous les contrats
applicables ainsi qu’à toutes les licences valides, eu égard à votre
utilisation du Site. Spectrum peut, à sa discrétion absolue, résilier ou
suspendre votre visite dans le Site et/ou votre utilisation du Site, ou une
partie quelconque de celle-ci, à tout moment, sans ou avec préavis et pour
quelque raison que ce soit (ou sans aucun motif) et vous acceptez que (a)
si votre autorisation de visiter le Site est résiliée, vous ne visiterez
pas le Site après ou vous n’essayerez pas de le visiter directement ou
indirectement, et enfin (b) si votre autorisation de visiter le Site est
suspendue, vous ne visiterez pas le Site après ou vous n’essayerez pas de
le visiter directement ou indirectement tant que votre suspension ne sera
pas supprimée et que Spectrum vous en ait averti expressément.
</p>
<p>
<strong>1.2. Informations fournies.</strong>
Si vous nous fournissez des informations, quelles qu’elles soient, vous
acceptez de fournir uniquement des informations véridiques, exactes,
actuelles et complètes. L’utilisation du présent Site est régie par notre
Politique de confidentialité que nous vous invitons à examiner en cliquant
sur le lien hypertexte situé en bas de page de ce site Web. L’utilisation
par Spectrum d’informations personnelles pouvant être soumis à ce Site par
des enfants est régie par la Politique de confidentialité.
</p>
<p>
<strong>1.3. Utilisation du contenu.</strong>
Vous pouvez imprimer ou copier toutes les informations (y compris, mais
sans toutefois s’y limiter, les annonces publicitaires de tiers)
s’affichant ou transmises sur le Site (collectivement, le « Contenu ») et
auxquelles vous êtes autorisé à accéder, uniquement pour vous informer et à
des fins personnelles, non commerciales, pour autant que vous (a) ne
supprimiez aucun titre, aucune marque de commerce, mention de droit
d’auteur et/ou aucun avis de droits assujettis à des réserves compris dans
ledit Contenu, et enfin (b) vous conformiez strictement aux clauses des
Conditions d’utilisation, y compris, mais sans toutefois s’y limiter, la
Section 1.4 ci-dessous.
</p>
<p>
<strong>1.4. Limitations.</strong>
Sauf mention contraire dans la Section 1.3 ci-dessus, vous ne pouvez pas
(et vous acceptez de ne pas le pouvoir) reproduire, altérer, modifier,
créer des œuvres dérivées ou présenter publiquement du Contenu, quel qu’il
soit, sans avoir reçu au préalable l’autorisation expresse écrite de
Spectrum. Vous acceptez également, sans avoir obtenu au préalable
l’autorisation expresse de Spectrum, de ne pas (a) utiliser l’une
quelconque de ses marques de commerce sous forme de balises Méta sur
d’autres sites Web, (b) utiliser le Site d’une manière ou d’une autre qui
est illégale ou qui affecte le fonctionnement du Site, sa disponibilité ou
son utilisation par d’autres personnes, et enfin de ne pas (c) afficher une
partie quelconque du Site en images fixes (ou tout Contenu par
l’intermédiaire de liens en ligne). Vous acceptez également de ne pas
décompiler, désosser ou démonter tout logiciel ou tout autre produit ou
processus accessible par le Site et de ne pas insérer de codes ou de
produits ou de ne pas manipuler le Site d’une façon quelconque qui affecte
ce que peut en tirer l’utilisateur, y compris, mais sans toutefois s’y
limiter, le fait d’entreprendre des actions qui imposent ou peuvent imposer
au Site une charge irréaliste ou exagérément forte. Vous acceptez également
de ne pas vous livrer au forage de données et de ne pas utiliser de robots
d’indexation, de robots, de robots d’annulation de message, de collecteurs,
de chevaux de Troie ou de méthodes de recueil ou d’extraction de données
dans le cadre de votre utilisation du Site, sauf les moteurs de recherche
ordinaires utilisés conformément aux instructions automatisées conçues pour
les moteurs de recherche et offertes sur le Site.
</p>
<p>
<strong>2.0 Généralités.</strong>
</p>
<p>
<strong>2.1 Compte et mot de passe.</strong>
</p>
<p>
Si nous vous fournissons un compte pour que vous puissiez utiliser les
Services (un « Compte »), nous vous fournissons également un nom
d’utilisateur et un mot de passe initial pour ledit Compte (chaque
utilisateur du Service à qui un Compte a été fourni sera désigné dans les
présentes comme étant un « Utilisateur inscrit »). Les parties du Site
offertes au public et aux Utilisateurs inscrits peuvent varier. Si vous ne
devenez pas Utilisateur inscrit, il se peut que vous ne puissiez pas
accéder à toutes les fonctions ou utiliser tous les Services sur le Site.
Vous ne pouvez pas autoriser un tiers quelconque à entrer sur le Site en
votre nom en utilisant votre Compte. En conséquence, vous acceptez de
protéger votre nom d’utilisateur et votre mot de passe en maintenant entre
autres l’aspect confidentiel de votre nom d’utilisateur et de votre mot de
passe. Si, nonobstant l’obligation qui précède, vous autorisez un tiers à
utiliser votre nom d’utilisateur et votre mot de passe pour accéder à votre
Compte, vous serez responsable de toute l’utilisation du Site par ce tiers
qui utilise votre Compte. Vous acceptez (a) de notifier immédiatement à
Spectrum toute utilisation non autorisée de votre mot de passe ou toute
atteinte à la sécurité, et enfin (b) de vous assurer que vous avez quitté
votre Compte à la fin de chaque session.
</p>
<p>
<strong>2.2 Soumissions et contenu généré par l’utilisateur.</strong>
</p>
<p>
<strong>2.1.</strong>
Le Site peut contenir des babillards, des groupes de clavardage, des forums
et d’autres domaines interactifs permettant aux utilisateurs d’exprimer
leurs opinions et de publier des informations et/ou d’autres documents
(collectivement, le « Contenu généré par l’utilisateur »). Nous ne
contrôlons pas tout le Contenu généré par l’utilisateur qui est publié ou
transmis par les utilisateurs et les fournisseurs d’informations tiers.
Vous pouvez avoir l’occasion de répondre à des enquêtes, des évaluations et
des questions publiés sur le Site par un sous-traitant de Spectrum. Si vous
choisissez de répondre, vous répondrez en réalité à l’enquête, à
l’évaluation ou aux questions sur le site du sous-traitant et vous devrez
vous conformer aux conditions générales d’utilisation du sous-traitant
quand vous soumettrez vos réponses. Vous pouvez accéder aux conditions
d’utilisation du sous-traitant en cliquant sur le lien indiqué sur ce site
Web.
</p>
<p>
<strong>2.2.</strong>
Toutes les opinions, tous les conseils, les déclarations, les services,
toutes les offres, les autres informations ou tout le contenu exprimés ou
offerts par des tiers, y compris de fournisseurs d’informations et des
utilisateurs, sont ceux du tiers et non ceux de Spectrum. Spectrum ne fait
valoir et n’atteste ni l’exactitude ni la fiabilité de tout Contenu généré
par l’utilisateur qui est affiché, chargé, ou distribué sur le Site par un
tiers. Vous reconnaissez que votre confiance à l’égard de tout Contenu
généré par l’utilisateur est à vos seuls risques.
</p>
<p>
<strong>2.3.</strong>
Spectrum peut, à sa discrétion absolue, changer et/ou supprimer tout
Contenu généré par l’utilisateur que vous soumettez sur le Site. Sans
limiter la portée générale de ce qui précède, Spectrum peut changer ou
supprimer tout Contenu généré par l’utilisateur que vous soumettez sur le
Site et que Spectrum, à sa discrétion absolue, estime être injurieux,
diffamatoire, obscène, illégal ou, d’une manière ou d’une autre,
inacceptable et vous renoncez à tous les droits moraux que vous pouvez
avoir eu égard aux changements du Contenu généré par l’utilisateur. Il ne
nous incombe pas de tenir à jour le Contenu généré par l’utilisateur que
vous avez publié et nous pouvons, à notre discrétion absolue et à tout
moment, le supprimer ou le détruire, sans aucune responsabilité ou
obligation envers vous.
</p>
<p>
<strong>2.4.</strong>
Vous être seul responsable de tout le Contenu généré par l’utilisateur que
vous soumettez sur le Site. Nous ne saurions être tenus responsables de
l’utilisation ou de la mauvaise utilisation d’informations ou de données
quelconques, y compris les informations personnelles, qui sont comprises
dans le Contenu généré par l’utilisateur que vous publiez.
</p>
<p>
<strong>2.5.</strong>
En soumettant sur le Site du Contenu généré par l’utilisateur, par les
présentes, vous accordez à Spectrum, à ses sociétés affiliées et aux
entités associées, dans le monde entier, le droit et la licence libres de
redevance, absolus, perpétuels, irrévocables, non exclusifs et entièrement
transmissibles, cessibles et pouvant donner lieu à l’octroi d’une
sous-licence d’utiliser, de copier, d’exploiter, de modifier, d’archiver,
de stocker, de reproduire, d’adapter, de publier, de traduire, d’en créer
des œuvres dérivées, de distribuer, d’exécuter et de présenter la totalité
dudit Contenu généré par l’utilisateur, sous toute forme, dans tous les
médias ou logiciels, en faisant appel à toute technologie, de quelque
nature qu’elle soit, existante ou développée dans le futur. Vous accordez
également à Spectrum, à ses sociétés affiliées et aux entités associées, à
ses concessionnaires de licence et à ses ayants droit le droit d’utiliser
votre nom et toutes autres informations à votre sujet que vous fournissez
dans le cadre de ladite utilisation du Contenu généré par l’utilisateur,
quelle qu’elle soit. Vous comprenez et vous acceptez que les visiteurs du
Site puissent utiliser votre Contenu généré par l’utilisateur, conformément
aux présentes Conditions d’utilisation. Le Contenu généré par l’utilisateur
que vous soumettez sur le Site peut être utilisé conformément aux présentes
Conditions d’utilisation sans compensation ou reconnaissance.
</p>
<p>
<strong>3. Limitations du Contenu généré par l’utilisateur</strong>
</p>
<p>
<strong>3.1.</strong>
Vous acceptez de ne pas publier de Contenu généré par l’utilisateur qui :
<br/>
i) contient des propos, des épithètes vulgaires, grossières, injurieuses ou
haineuses, des invectives, du texte ou des illustrations de mauvais goût,
des attaques incendiaires de nature raciale ou religieuse ou des
expressions de sectarisme, de racisme, de discrimination ou de haine ou qui
<br/>
ii) est diffamatoire, menaçant, désobligeant, incendiaire, faux, trompeur,
fallacieux, frauduleux, inexact, injuste, qui contient de grossières
exagérations ou des revendications non fondées, qui est déraisonnablement
destructeur ou offensants pour n’importe quelle personne ou communauté, qui
contient une déclaration quelconque pouvant donner matière à des
poursuites, qui tend à tromper toute autre personne, entreprise ou entité
ou à se refléter injustement sur elles, ou qui
<br/>
iii) viole ou enfreint un droit quelconque de Spectrum ou d’un tiers
quelconque, y compris, mais sans toutefois s’y limiter toute protection de
la vie privée, marque de commerce, tout droit d’auteur ou autre droit de la
propriété intellectuelle ou qui, d’une manière ou d’une autre cause
préjudice à un tiers quelconque, ou qui
<br/>
iv) fait preuve de discrimination en se fondant sur la race, la religion,
l’origine nationale, le sexe, l’âge, la situation de famille, l’orientation
sexuelle ou une infirmité, qui fait référence de quelque manière que ce
soit à ce qui est interdit par la loi, ou qui
<br/>
v) enfreint une loi, une règle, un règlement ou une ordonnance au niveau
municipal, provincial ou fédéral, qui essaye d’encourager à s’y soustraire
ou à les enfreindre, ou qui
<br/>
vi) interfère injustement avec l’utilisation ininterrompue du Site par un
tiers quelconque et le plaisir qu’il peut en tirer, ou qui
<br/>
vii) fait la publicité ou la promotion de n’importe quel bien ou service ou
qui en fait l’objet d’offres commerciales, sauf dans les domaines
spécifiquement prévus pour cela, ou qui
<br/>
viii) contient des documents protégés par le droit d’auteur ou exclusifs,
de quelque nature que ce soit, sur le Site sans l’autorisation expresse du
propriétaire de ces documents, ou qui
<br/>
ix) comprend ou transmet des virus ou des fichiers dangereux, perturbateurs
ou destructifs,
<br/>
x) perturbe, interfère avec ou, d’une manière ou d’une autre, nuit à ou
compromet la sécurité du Site ou de tous les Services, des ressources du
système, des comptes, des mots de passe, des serveurs ou des réseaux
connectés au Site ou aux sites affiliés ou liés ou qui sont accessibles par
ces mêmes sites, ou qui
<br/>
xi) « insulte » une personne ou une entité quelconque (p. ex., l’envoi de
messages répétés liés à un autre utilisateur et/ou la publication de
commentaires désobligeants ou offensants sur une autre personne) ou répète
une publication antérieure du même message sous plusieurs fils ou thèmes de
discussion, ou qui
<br/>
xii) d’une manière ou d’une autre, enfreint les présentes Conditions
d’utilisation.
</p>
<p>
<strong>3.2.</strong>
Tout Contenu généré par l’utilisateur publié par vous-même ou par d’autres
personnes sera réputé n’être ni confidentiel ni secret. Vous comprenez que
les informations personnelles et autres (p. ex., nom d’utilisateur, adresse
courriel, numéro de téléphone) que vous publiez sur notre Site sont
généralement accessibles à d’autres personnes qui peuvent les recueillir ou
les utiliser, ce qui peut donner lieu à des messages non sollicités ou à
des contacts d’autres personnes. Pour protéger votre sécurité, veuillez
utiliser votre bon sens quand vous publiez des informations. Nous
décourageons particulièrement la divulgation de numéros de téléphone
personnels et d’adresses ou d’autres informations qui peuvent être utilisés
pour vous identifier ou vous localiser.
</p>
<p>
<strong>4. Création de liens et transactions de tiers.</strong>
</p>
<p>
<strong>4.1. Liens vers des sites externes.</strong>
Spectrum peut fournir des hyperliens vers d’autres sites Web et des
ressources Internet exploitées par des parties autres que Spectrum.
Spectrum n’a aucun contrôle sur ce type de sites et de ressources, sur
leurs politiques de confidentialité ou sur leurs conditions d’utilisation
et les politiques et conditions de Spectrum ne les régissent plus. Ces
hyperliens vous sont fournis uniquement à titre de référence. L’inclusion
d’hyperliens vers ce type de sites n’implique aucun parrainage, aucune
affiliation, ni aucune promotion des documents de ces sites ou de leurs
opérateurs.
</p>
<p>
<strong>4.2. Création de liens vers le Site. </strong>
Sous réserve de clauses supplémentaires ajoutées à la présente Section 4,
Spectrum accepte volontiers les liens vers le Site créés à partir d’autres
sites Web. Toutefois, si Spectrum demande que vous ne créiez pas de lien
vers le Site ou une partie quelconque du Site, vous acceptez de ne pas
créer, directement ou indirectement, de lien vers le Site ou vers toute
partie du Site spécifiée dans notre demande, à tout instant une fois que la
demande a été faite.
</p>
<p>
<strong>5. Propriété intellectuelle</strong>
</p>
<p>
<strong>5.1 Généralités.</strong>
À l’exception du Contenu qui est dans le domaine public, le Site et tout le
Contenu d’origine, ainsi que la sélection et la mise en page du Contenu,
sont la propriété de Spectrum ou de ses fournisseurs (à qui une licence a
pu avoir été accordée) et sont protégés par les lois sur le droit d’auteur,
l’habillage commercial, les marques de commerce, la concurrence déloyale
et/ou d’autres lois et ils ne peuvent être ni utilisés, ni copiés, ni
imités, en tout ou partie, sauf mention contraire expressément spécifiée
dans les présentes. Sauf mention contraire expressément spécifiée dans les
Conditions d’utilisation, tous les droits sur le Site et le Contenu sont
expressément réservés par Spectrum.
</p>
<p>
<strong>5.2 Marques de commerce.</strong>
UltraPro, Roughneck, Remington, Black and Decker, Rayovac, certaines autres
marques, marques de commerce, marques de service et certains autres logos
sont des marques de Spectrum (les marques de Spectrum ne s’affichent pas
toutes sur ce Site) et, sauf mention contraire expressément spécifiée dans
la Section 1.3 ci-dessus, elles ne peuvent être ni copiées, ni imitées, ni
utilisées, en tout ou partie, sans l’autorisation écrite préalable de
Spectrum. En outre, tous les en-têtes de page, les graphismes
personnalisés, les icônes-boutons et le script sont des marques de service,
des marques de commerce et/ou de l’habillage commercial de Spectrum ou de
ses concessionnaires de licence et ils ne peuvent être ni copiés, ni
imités, ni utilisés, en tout ou partie, sans l’autorisation écrite
préalable de Spectrum.
</p>
<p>
<strong>
    6. Politique, avis et procédures concernant les atteintes présumées au
    droit d’auteur.
</strong>
</p>
<p>
Spectrum respecte les droits de propriété intellectuelle d’autrui. Si vous
êtes convaincu que votre œuvre a été copiée et si elle est accessible sur
le Site de quelque façon que ce soit qui constitue une atteinte au droit
d’auteur, vous pouvez notifier notre agent comme indiqué dans la présente
Section 6. Conformément au Titre 17, Section 512(c)(2) du Code des
États-Unis, les notifications pour atteinte présumée au droit d’auteur
doivent être directement envoyées à :
</p>
<table width="307" border="0" cellpadding="1" cellspacing="0">
<colgroup>
    <col width="97"/>
    <col width="206"/>
</colgroup>
<tbody>
    <tr>
        <td width="97" valign="TOP">
            <p>
                <strong>Par courrier :</strong>
            </p>
        </td>
        <td width="206">
            <p>
                Spectrum Brands Holdings, Inc.
                <br/>
                Legal/IP Division
                <br/>
                Copyright Agent
                <br/>
                3001 Deming Way
                <br/>
                Middleton, WI 53562-1431, États-Unis
            </p>
        </td>
    </tr>
    <tr>
        <td width="97" valign="TOP">
            <p>
                <strong>Par télécopieur :</strong>
            </p>
        </td>
        <td width="206">
            <p>
                (608) 278-6643
            </p>
        </td>
    </tr>
    <tr>
        <td width="97" valign="TOP">
            <p>
                <strong>Par courriel :</strong>
            </p>
        </td>
        <td width="206">
            <p>
                <a
                    href="mailto:copyright.noi@spectrumbrands.com?subject=Notice%20of%20Infringement"
                >
                    <u>copyright.noi@spectrumbrands.com</u>
                </a>
            </p>
        </td>
    </tr>
</tbody>
</table>
<p>
<strong>REMARQUE :</strong>
les informations précédentes de la présente Section 6 sont fournies
exclusivement pour notifier Spectrum que votre œuvre protégée par un droit
d’auteur peut avoir fait l’objet d’une atteinte au droit d’auteur. Toutes
les autres demandes, par exemple les questions et les requêtes liés à des
produits ou à des services, ou les questions sur la protection de la vie
privée <u><strong>ne recevront aucune</strong></u> réponse par ce
processus.
</p>
<p>
<strong>7. Indemnisation</strong>
. Par les présentes, vous acceptez d’indemniser et de dégager Spectrum et
ses sociétés affiliées, dirigeants, membres, administrateurs, employés,
actionnaires, prestataires de service, fournisseurs, sous-traitants et
concessionnaires de licence (collectivement les « Parties indemnisées ») de
toute responsabilité et de tous les coûts, de quelque nature qu’ils soient,
y compris, mais sans toutefois s’y limiter, les honoraires raisonnables
d’avocats, à la charge des Parties indemnisées et associés à une demande
d’indemnisation découlant de (a) votre entrée dans le Site et/ou de votre
utilisation de celui-ci (y compris, mais sans toutefois s’y limiter, toute
Service), et/ou enfin de (b) toute infraction ou infraction présumée à
l’une quelconque des Conditions d’utilisation que vous auriez commise.
</p>
<p>
<strong>8. Clause de non-responsabilité des garanties.</strong>
Vous reconnaissez et acceptez expressément que votre utilisation du Site (y
compris, mais sans toutefois s’y limiter, d’un Service ou d’un Contenu
quelconque) est à vos seuls risques. Dans les pleines limites autorisées
par le droit en vigueur, le Site (y compris, mais sans toutefois s’y
limiter, les Services et le Contenu) vous est fourni    <strong>« EN L’ÉTAT » et « TEL QUE DISPONIBLE »</strong>. Dans les pleines
limites autorisées par le droit en vigueur, Spectrum s’exonère de toutes
les déclarations ou garanties ayant rapport au Site, aux Services et au
Contenu, y compris, mais sans toutefois s’y limiter, les déclarations ou
les garanties que (a) le Site, les Services ou le Contenu répondent à vos
exigences, (b) l’exploitation du Site ou des Services sera ininterrompue ou
sans erreur, ou que (c) tous les défauts du Site, des Services ou du
Contenu seront corrigés.
<strong>
    DANS LES PLEINES LIMITES AUTORISÉES PAR LE DROIT EN VIGUEUR, ET PAR LES
    PRÉSENTES, SPECTRUM S’EXONÈRE DE TOUTES LES GARANTIES ET/OU
    DÉCLARATIONS, DE QUELQUE NATURE QU’ELLES SOIENT ET QU’ELLES SOIENT
    EXPRESSES, IMPLICITES, VERBALES OU ÉCRITES, Y COMPRIS, MAIS SANS
    TOUTEFOIS S’Y LIMITER, LES GARANTIES IMPLICITES DE QUALITÉ MARCHANDE,
    DE DILIGENCE RAISONNABLE ET/OU DE CONVENANCE À DES FINS PARTICULIÈRES,
    DE QUELQUE NATURE QU’ELLES SOIENT (QUE SPECTRUM AIT OU NON CONNAISSANCE
    OU RAISON DE SAVOIR, AIT OU NON ÉTÉ INFORMÉE OU, D’UNE MANIÈRE OU D’UNE
    AUTRE, SOIT OU NON CONSCIENTE DE FACTO DE L’UNE QUELCONQUE DESDITES
    FINS PARTICULIÈRES), DANS CHAQUE CAS, EU ÉGARD AU SITE, AUX SERVICES ET
    AU CONTENU. DANS LES PLEINES LIMITES AUTORISÉES PAR LE DROIT EN
    VIGUEUR, SPECTRUM S’EXONÈRE ÉGALEMENT DES GARANTIES ET/OU DES
    DÉCLARATIONS, DE QUELQUE NATURE QU’ILS SOIENT, DE JOUISSANCE PAISIBLE,
    DE TITRE DE POSSESSION, D’ABSENCE DE CONTREFAÇON, EU ÉGARD AU SITE, AUX
    SERVICES ET AU CONTENU. CERTAINS TERRITOIRES N’AUTORISENT PAS
    L’EXCLUSION DES GARANTIES IMPLICITES OU LES LIMITATIONS SUR LES DROITS
    APPLICABLES ACCORDÉS PAR LA LOI, PAR CONSÉQUENT, L’EXCLUSION OU LES
    LIMITATIONS CI-DESSUS POURRAIENT NE PAS S’APPLIQUER À VOUS.
</strong>
</p>
<p>
<strong>9. Limitation de responsabilité.</strong>
EN AUCUNE CIRCONSTANCE, SPECTRUM, SES CONCESSIONNAIRES DE LICENCE OU LES
PARTIES INDEMNISÉES NE SAURAIENT ÊTRE TENUS RESPONSABLES DE DOMMAGES
CORPORELS OU DE TOUS LES DOMMAGES INDIRECTS, SPÉCIAUX OU CONSÉCUTIFS, DE
QUELQUE NATURE QU’ILS SOIENT, Y COMPRIS, MAIS SANS TOUTEFOIS S’Y LIMITER,
LES DOMMAGES POUR MANQUE À GAGNER, PERTE DE DONNÉES, PERTE D’EXPLOITATION,
COÛTS D’APPROVISIONNEMENT DE PRODUITS OU DE SERVICES DE REMPLACEMENT OU
TOUT AUTRE DOMMAGE COMMERCIAL OU TOUTE AUTRE PERTE COMMERCIALE, DÉCOULANT
DES CONDITIONS D’UTILISATION, DU SITE, DES SERVICES OU DU CONTENU OU, D’UNE
MANIÈRE OU D’UNE AUTRE, ASSOCIÉS À CEUX-CI, QUE CE SOIT OU NON POUR UNE
VIOLATION DE CONTRAT, DÉLICTUELLEMENT OU, D’UNE MANIÈRE OU D’UNE AUTRE,
MÊME SI SPECTRUM EST AVISÉE DE LA POSSIBILITÉ DESDITS DOMMAGES. CERTAINS
TERRITOIRES N’AUTORISENT PAS LA LIMITATION DE RESPONSABILITÉ POUR LES
DOMMAGES CORPORELS OU POUR LES DOMMAGES INDIRECTS OU CONSÉCUTIFS, PAR
CONSÉQUENT, LA PRÉSENTE LIMITATION POURRAIT NE PAS S’APPLIQUER À VOUS. En
aucune circonstance, la responsabilité de Spectrum ne saurait être engagée
pour tous les dommages d’un montant supérieur à 10,00 $.
</p>
<p>
<strong>10. Modifications apportées au Site.</strong>
Afin de lever toute ambiguïté, Spectrum peut modifier, suspendre,
discontinuer et/ou restreindre l’utilisation de tout ou partie du Site, y
compris, mais sans toutefois s’y limiter, les Services et le Contenu, à
tout moment et pour quelque raison que ce soit (ou en l’absence de raison)
et sans préavis ni obligation.
</p>
<p>
<strong>11. Droit applicable et tribunaux compétents.</strong>
Le Site est hébergé sur des serveurs situés aux États-Unis et il est
destiné à être visité par des résidents des États-Unis. Bien que les
informations sur ce site Web soient accessibles aux utilisateurs résidant
hors des États-Unis, les informations sur le Site Web concernant les
produits Spectrum sont destinées uniquement aux résidents des États-Unis.
D’autres pays peuvent avoir des lois et des exigences réglementaires qui
diffèrent de celles des États-Unis. Le Site est lié à d’autres sites créés
pour des marques nous appartenant, certaines d’entre elles étant
commercialisées en dehors des États-Unis. Ces sites peuvent présenter des
informations qui sont uniquement appropriées au pays d’origine des marques
ou à la région dans laquelle elles sont commercialisées. Spectrum se
réserve le droit de limiter la mise à disposition de nos produits ou de nos
Services à certaines personnes, certaines régions ou certains territoires
et/ou de limiter les quantités ou des produits ou Services que nous
fournissons. Toute offre pour un produit ou un Service quelconque proposée
sur ce site Web est nulle et non avenue là où elle est interdite.
</p>
<p>
Les Conditions d’utilisation seront réputées être acceptées par vous-même,
à Madison, Wisconsin. Les Conditions d’utilisation et tout ce qui concerne
les Conditions d’utilisation ou qui y est lié devront être régies par les
lois de l’État du Wisconsin et des États-Unis, sans donner effet aux
clauses de celles-ci relatives aux conflits de lois et en excluant toute
application de la Convention des Nations unies sur les contrats de vente
internationale de marchandises, l’application de laquelle étant
expressément exclue. Vous acceptez que les tribunaux fédéraux siégeant à
Madison, Wisconsin aient la compétence exclusive d’attribution et
territoriale, à moins qu’aucune compétence d’attribution fédérale n’existe,
auquel cas vous acceptez que les tribunaux de l’État siégeant à Madison,
Wisconsin aient la compétence exclusive d’attribution et territoriale. Par
les présentes, vous renoncez irrévocablement, dans les pleines limites
autorisées par la loi en vigueur, à toute objection que vous pouvez avoir
maintenant ou plus tard quant au choix d’attribution pour toute procédure
portée devant ledit tribunal quant à toute demande d’indemnisation que
ladite procédure portée devant ledit tribunal a été portée devant un forum    <em>non conveniens</em>. Vous acceptez que toute cause d’action que vous
pouvez souhaiter exercer et découlant des Conditions d’utilisation ou
associée à celles-ci doit commencer au plus tard un (1) an à compter de la
date à laquelle la cause d’action est engagée ou, d’une manière ou d’une
autre, il devra être mis fin à ladite cause d’action.
</p>
<p>
<strong>12. Clauses générales.</strong>
Si une clause quelconque des Conditions d’utilisation est déclarée ou
constatée comme étant illégale, inexécutable ou nulle et non avenue, la
partie non valide ou inexécutable devra être modifiée conformément au droit
en vigueur en reflétant le plus fidèlement possible l’intention d’origine
de la clause applicable et les parties restantes des Conditions
d’utilisation continuent à s’appliquer de plein droit et avec plein effet.
Aucun droit ou recours conféré par les Conditions d’utilisation n’est
exclusif d’aucun autre droit ou recours conféré par les présentes ou en
droit ou équité; par contre, lesdits droits et recours se cumulent avec
tout autre droit et recours et, périodiquement, peuvent être exercés
concurremment ou séparément. Spectrum peut vous donner un préavis eu égard
aux Conditions d’utilisation en envoyant un courriel à votre dernière
adresse de courrier électronique connue, le cas échéant, à votre dernière
adresse postale connue, le cas échéant, ou en publiant un préavis sur le
Site, et ledit préavis sera réputé donné et reçu le jour où il vous a été
envoyé ou le jour où il a été publié sur le Site, le premier de ces deux
jours étant retenu. Une version imprimée des Conditions d’utilisation et de
tous les préavis qui vous ont été donnés sous forme électronique devront
être considérés comme étant admissibles lors d’une action en justice ou
d’une procédure administrative fondée sur les Conditions d’utilisation ou
associée à celle-ci, au même titre que d’autres documents et dossiers
commerciaux ou opérationnels créés à l’origine et tenus à jour sous forme
imprimée et sous réserve des mêmes conditions que ceux-ci. L’impossibilité
pour Spectrum d’insister sur l’exécution à la lettre de votre part de toute
clause des Conditions d’utilisation ou de vous y forcer ne saurait être
interprétée comme une renonciation à une clause ou à un droit quelconque.
Si Spectrum engage des poursuites quelconques contre vous pour faire
appliquer les Conditions d’utilisation ou, d’une manière ou d’une autre,
motivées par votre utilisation du Site et/ou votre entrée dedans, vous
acceptez que, si Spectrum obtient gain de cause au titre desdites
poursuites, Spectrum devra être fondée à recouvrer tous les coûts et frais
encourus au titre desdites poursuites, y compris les honoraires
raisonnables d’avocats. L’utilisation du Site n’est pas autorisée dans tous
les territoires qui ne donnent pas effet à toutes les clauses des
Conditions d’utilisation.
</p>
<p>
<strong>13. Déclarations de nature prospective</strong>
</p>
<p>
Ce site peut contenir des déclarations de nature prospective pouvant
impliquer des risques et des incertitudes. Suite à des changements de
facteurs externes du marché concurrentiel, à des changements non anticipés
dans l’industrie de l’entreprise ou dans l’économie en général, et en
raison également de divers autres facteurs, y compris ceux discutés dans
les présentes et ceux évoqués dans le rapport annuel le plus récent de
Spectrum sur le Formulaire 10-K déposé auprès de la Securities and Exchange
Commission, les résultats réels peuvent différer de ceux dont il a été fait
état dans ces déclarations. Le rendement du cours de l’action indiqué dans
les cours des actions de Bourse du site Web de Spectrum n’est pas
nécessairement indicatif du rendement futur de l’action.
</p>`;

export default terms_fr;
