import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class DeviceDetectorService {

   

    constructor() { }

    isIOSPhone(){
      return ['iPhone'].indexOf(navigator.platform) >= 0;
    }


}
