import { NgRedux, NgReduxModule } from '@angular-redux/store';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { Logger } from './services/utils/logger';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {
  loadState,
  saveState,
} from './services/utils/state-management/session-store';
import {
  IAppState,
  INITIAL_STATE,
  rootReducer,
} from './services/utils/state-management/store';
import { BrandSelectionComponent } from './public/brand-selection/brand-selection.component';
import { HomeComponent } from './public/home/home.component';
import { LandingPageComponent } from './public/home/landing-page/landing-page.component';
import { WhiteListService } from './services/white-list.service';
import { ApiRequestService } from './services/api-client/api-requests.service';
import { DataShareService } from './services/data-share.service';
import { ResponseHandler } from './services/api-client/response-handler';
import { UserManagement } from './services/utils/state-management/action-classes';
import { UserLoginService } from './services/user-login.service';
import { Utility } from './services/utils/utility';
import { CognitoUtil } from './services/utils/cognito.service';
import { VersionInfoComponent } from './public/version-info/version-info.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { setTheme } from 'ngx-bootstrap/utils';
import { WebAccessibiltyComponent } from './public/webaccessibilty/web-accessibilty.component';
import { TimeoutComponent } from './public/timeout/timeout.component';
import { WhiteListComponent } from './helpers/white-list/white-list.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { NgOtpInputModule } from 'ng-otp-input';
import { LoginComponent } from './public/auth/login/login.component';
import { UserRegistrationService } from './services/user-registration.service';
import { VerificationService } from './services/utils/verification.service';
import { ForgotPasswordService } from './services/forgot-password.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatIconModule } from '@angular/material/icon';
import { LoaderService } from './services/loader.service';
import { LoaderComponent } from './public/loader/loader.component';
import { AccountInfoComponent } from './public/register/account-info/account-info.component';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { RecaptchaModule } from 'ng-recaptcha';
import { InnerLoaderComponent } from './public/inner-loader/inner-loader.component';
import { CanDeactivateGuard } from './guards/deactivate/CanDeactivateGuard';
import { HomeGuard } from './guards/home.guard';
import { ResponseHandlerService } from './services/utils/response-handler.service';
import { UserProfileService } from './services/user-profile.service';
import { RegisterComponent } from './public/register/registration.component';
import { PhoneFieldComponent } from './helpers/phone-field/phone-field.component';
import { PhoneNumberFormatPipe } from './services/utils/phoneNumberFormat.pipe';
import { VerificationContinueComponent } from './public/base-components/verification-continue-base/verification-continue-base.component';
import { SecurityQuestionsComponent } from './public/register/security-questions/security-questions.component';
import { DeviceDetectorService } from './services/device-detector.service';
import { TokenInterceptor } from './interceptors/token-interceptor.service';
import { EmailVerificationComponent } from './public/register/email-verification/email-verification.component';
import { ConfirmationScreenComponent } from './public/register/confirmation-screen/confirmation-screen.component';
import { SecureHomeComponent } from './secure/landing/securehome.component';
import { LanguageChangeService } from './services/language-change.service';
import { NavBarComponent } from './secure/nav-bar/nav-bar.component';
import { ForgotPasswordComponent } from './public/auth/forgot-password/forgot-password.component';
import { EnterVerificationCodeComponent } from './public/auth/forgot-password/enter-verification-code/enter-verification-code.component';
import { EnterSecretQuestionsComponent } from './public/auth/forgot-password/enter-secret-questions/enter-secret-questions.component';
import { SelectVerificationTypeComponent } from './public/auth/forgot-password/select-verification-type/select-verification-type.component';
import { EnterEmailComponent } from './public/auth/forgot-password/enter-email/enter-email.component';
import { EditPhoneVerificationComponent } from './secure/profile/edit-profile/edit-phone/edit-phone-verification.component';
import { ChangePasswordService } from './services/change-password.service';
import { CommonService } from './services/common.service';
import { HomeBaseComponent } from './secure/home/home-base/home-base.component';
import { HomeService } from './services/home.service';
import { ShareHomeService } from './services/share-home.service';
import { HomeLocksComponent } from './secure/locks/home-locks/home-locks.component';
import { LockService } from './services/lock.service';
import { HomeSelectionModalComponent } from './secure/home/home-selection-modal/home-selection-modal.component';
import { HomeHistoryComponent } from './secure/home/home-history/home-history.component';
import { LockHistoryComponent } from './secure/locks/lock-history/lock-history.component';
import { LockBaseComponent } from './secure/locks/lock-base/lock-base.component';
import { LockDetailsComponent } from './secure/locks/lock-details/lock-details.component';
import { DisabledPhoneComponent } from './helpers/disabled-phone/disabled-phone.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_LOCALE_PROVIDER } from '@danielmoncada/angular-datetime-picker';
import { LoginGuard } from './guards/login.guard';
import { BatteryComponent } from './helpers/battery/battery.component';
import { LockHistoryFilterModalComponent } from './secure/locks/lock-history-filter-modal/lock-history-filter-modal.component';
import { HomeHistoryFilterModalComponent } from './secure/home/home-history-filter-modal/home-history-filter-modal.component';
import { ViewSharedUsersComponent } from './secure/home/view-shared-users/view-shared-users.component';
import { ProfileComponent } from './secure/profile/profile.component';
import { EditProfileComponent } from './secure/profile/edit-profile/edit-profile.component';
import { ChangeSecurityQuestionsComponent } from './secure/profile/edit-profile/change-security-questions/change-security-questions.component';
import { ToggleEnableDisableAccountComponent } from './secure/profile/toggle-enable-disable-account/toggle-enable-disable-account.component';
import { DeleteAccountComponent } from './secure/profile/delete-account/delete-account.component';
import { ChangePasswordComponent } from './public/auth/change-password/change-password.component';
import { ImageCropDialogComponent } from './secure/profile/image-crop-dialog/image-crop-dialog.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { VerificationCodeModalComponent } from './public/auth/verification-code-modal/verification-code-modal.component';
import { DeleteVerificationModalComponent } from './secure/profile/delete-verification-modal/delete-verification-modal.component';
import { PhoneNumberPipe } from './services/utils/phone-number.pipe';
import { ViewSharedUserFilterModalComponent } from './secure/home/view-shared-user-filter-modal/view-shared-user-filter-modal.component';
import { LanguageComponent } from './public/language/language.component';
import { SignupOptionsModalComponent } from './public/register/signup-options-modal/signup-options-modal.component';
import { RedirectFederationAuthComponent } from './public/auth/redirect-federation-auth/redirect-federation-auth.component';
import { Config } from './services/utils/Config';
import { ViewSharedUserConfirmModalComponent } from './secure/home/view-shared-user-confirm-modal/view-shared-user-confirm-modal.component';
import { ConfirmComponent } from './helpers/modal/confirm.component';
import { AcceptRejectHomeModalComponent } from './secure/home/accept-reject-home-modal/accept-reject-home-modal.component';
import { TabDirective } from './tab.directive';
import { ScanWebUrlComponent } from './public/scanweburl/scan-web-url.component';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  declarations: [
    AppComponent,
    BrandSelectionComponent,
    HomeComponent,
    ScanWebUrlComponent,
    LandingPageComponent,
    VersionInfoComponent,
    WebAccessibiltyComponent,
    TimeoutComponent,
    WhiteListComponent,
    LoginComponent,
    LoaderComponent,
    AccountInfoComponent,
    InnerLoaderComponent,
    HomeComponent,
    LandingPageComponent,
    RegisterComponent,
    PhoneFieldComponent,
    PhoneNumberFormatPipe,
    SecurityQuestionsComponent,
    VerificationContinueComponent,
    EmailVerificationComponent,
    ConfirmationScreenComponent,
    SecureHomeComponent,
    NavBarComponent,
    ForgotPasswordComponent,
    EnterVerificationCodeComponent,
    EnterSecretQuestionsComponent,
    SelectVerificationTypeComponent,
    EnterEmailComponent,
    EditPhoneVerificationComponent,
    HomeBaseComponent,
    HomeLocksComponent,
    HomeSelectionModalComponent,
    HomeHistoryComponent,
    LockHistoryComponent,
    LockBaseComponent,
    LockDetailsComponent,
    DisabledPhoneComponent,
    BatteryComponent,
    LockHistoryFilterModalComponent,
    HomeHistoryFilterModalComponent,
    ViewSharedUsersComponent,
    ProfileComponent,
    EditProfileComponent,
    EditPhoneVerificationComponent,
    ChangeSecurityQuestionsComponent,
    ToggleEnableDisableAccountComponent,
    DeleteAccountComponent,
    ChangePasswordComponent,
    ImageCropDialogComponent,
    VerificationCodeModalComponent,
    DeleteVerificationModalComponent,
    ViewSharedUserFilterModalComponent,
    ViewSharedUserConfirmModalComponent,
    LanguageComponent,
    SignupOptionsModalComponent,
    RedirectFederationAuthComponent,
    ConfirmComponent,
    AcceptRejectHomeModalComponent,
    TabDirective
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    NgReduxModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatIconModule,
    MatSelectModule,
    NgOtpInputModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    LoggerModule.forRoot({
      serverLoggingUrl: '/api/logs',
      level: NgxLoggerLevel.DEBUG,
      serverLogLevel: NgxLoggerLevel.ERROR,
    }),
    Ng2TelInputModule,
    RecaptchaModule,
    InfiniteScrollModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    ImageCropperModule
  ],
  providers: [
    Logger,
    WhiteListService,
    ApiRequestService,
    DataShareService,
    ResponseHandler,
    UserManagement,
    UserLoginService,
    Utility,
    CognitoUtil,
    BsModalService,
    UserRegistrationService,
    VerificationService,
    ForgotPasswordService,
    LoaderService,
    CanDeactivateGuard,
    HomeGuard,
    LoginGuard,
    ResponseHandlerService,
    UserProfileService,
    DeviceDetectorService,
    LanguageChangeService,
    ChangePasswordService,
    CommonService,
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: OWL_DATE_TIME_LOCALE_PROVIDER, useValue: Config.LANGUAGES.ENGLISH },
    HomeService,
    ShareHomeService,
    LockService,
    PhoneNumberFormatPipe,
    PhoneNumberPipe
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(ngRedux: NgRedux<IAppState>) {
    let persistedState = loadState();
    if (!persistedState) {
      persistedState = INITIAL_STATE;
    }
    ngRedux.configureStore(rootReducer, persistedState);
    ngRedux.subscribe(() => {
      saveState(ngRedux.getState());
    });

    setTheme('bs3');
  }
}
