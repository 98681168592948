import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { IForgotPassword } from "../../../../services/utils/state-management/types";
import { ForgotPasswordService } from "../../../../services/forgot-password.service";

@Component({
  selector: "app-enter-email",
  templateUrl: "./enter-email.component.html",
  styleUrls: ["./enter-email.component.css"]
})
export class EnterEmailComponent implements OnInit {
  enterEmailForm: FormGroup;
  @Output() onError = new EventEmitter<any>();
  @Input() parent;


  constructor(
    public formBuilder: FormBuilder,
    public translate: TranslateService,
    private forgotPasswordService: ForgotPasswordService
  ) {}

  ngOnInit() {
    this.enterEmailForm = this.formBuilder.group({
      email: [null, [Validators.required, Validators.pattern("^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$")]]
    });
  }

  onConfirm() {
    this.parent.changed = true;
    this.forgotPasswordService.getSecurityQuestions(this.enterEmailForm.get("email").value.toLowerCase(), this);
  }

  onFailure() {
    this.onError.emit(this.translate.get('FORGOT_PASSWORD.ENTER_EMAIL.ERROR')['value']);
  }
}
