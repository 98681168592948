import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ForgotPasswordService, SendVerificationCodeCallback } from '../../../../services/forgot-password.service';
import { ResponseHandlerService } from '../../../../services/utils/response-handler.service';

@Component({
  selector: 'app-select-verification-type',
  templateUrl: './select-verification-type.component.html',
  styleUrls: ['./select-verification-type.component.css']
})
export class SelectVerificationTypeComponent implements OnInit, SendVerificationCodeCallback {
  @Output() onError = new EventEmitter<any>();

  selectVerificationCodeType: FormGroup;
  verificationType: string;
  choice = null;
  mediumType = 'phone';

  constructor(
    public formBuilder: FormBuilder,
    public translate: TranslateService,
    public responseHandler: ResponseHandlerService,
    public forgotPasswordService: ForgotPasswordService
  ) { }


  ngOnInit() {
    this.selectVerificationCodeType = this.formBuilder.group({
      choice: [null, null]
    });

    this.verificationType = this.mediumType;
    this.onSelectVerificationCodeType();
  }

  onSelectionChange(choice) {
    this.verificationType = choice;
  }

  onSelectVerificationCodeType() {
    this.forgotPasswordService.callApiToSendCode(this.verificationType, this);
  }

  sendVerifiactionCodeFailure() {
    this.onError.emit(this.translate.get('FORGOT_PASSWORD.SELECT_VERIFICATION_TYPE.ERROR.COMMON')['value']);
  }

  sendVerifiactionCodeErrorResponse(res) {
    res = this.responseHandler.getErrorMessage('FORGOT_PASSWORD.SELECT_VERIFICATION_TYPE.ERROR', res);
    let errMessg = '';
    let errorMessage = '';
    for (errMessg of res) {
      errorMessage += errMessg + ', ';
    }
    errorMessage = errorMessage.substring(0, errorMessage.length - 2);
    if (errorMessage.slice(-1) !== ".") {
      errorMessage += ".";
    }
    this.onError.emit(errorMessage);
  }

  sendVerifiactionCodeSuccess() {
    this.onError.emit(null);
  }
}
