<div class="col-md-12 col-sm-12 col-xs-12 breadcrumbs no-padding hidden-xs hidden-sm" style="margin-left: 15px;">
  <a class="home-logo-back" tabindex="8" [routerLink]="'/securehome'">
    <img src="./../../../../assets/images/home/default-home.png" alt="home.png">
  </a>
<i style="color: #7F7F7F; margin-left: 10px; margin-right: 10px;" class="fa fa-chevron-right"></i> 
<span tabindex="9" class="account-setting" *ngIf=!phone (keydown.enter)="accountSetNoPhoneRef.click()" #accountSetNoPhoneRef (click)="goToAccountSetting()"  style="font-family: 'Gotham-Bold'; cursor: pointer;">  {{ 'EDIT_PROFILE.ACCOUNT_SETTINGS' | translate }} </span>
<span tabindex="9" class="account-setting" *ngIf=phone (keydown.enter)="accountSetIsPhoneRef.click()" #accountSetIsPhoneRef (click)="goToAccountSetting()"  style="color: #7F7F7F; font-family: 'Gotham-Bold'; cursor: pointer;"> {{ 'EDIT_PROFILE.ACCOUNT_SETTINGS' | translate }}</span>
<i *ngIf=phone style="margin-left: 10px; margin-right: 10px; color: #7F7F7F;" class="fa fa-chevron-right"></i> <span *ngIf=phone style="font-family: 'Gotham-Bold';">{{ 'EDIT_PROFILE.EDIT_PHONE_LABEL' | translate }}</span>
<br/><br/>
</div>
<div class="row sm-no-margin hidden-md hidden-lg">
  <div class="col-xs-12 sp-back"> 
    <a (keydown.enter)="linkEditBtn.click()" #linkEditBtn  [routerLink]="'/securehome'">  <i class="fa fa-chevron-left"></i> {{ 'PROFILE.BACK' | translate }}</a>
  </div>
</div>
<div class="sp-body clearfix">

  <ng-container *ngIf="isImageType || isImageSize">
    <div class="alert alert-danger alert-dismissible fade in">
      <a class="close" aria-label="close" (click)="isImageType = false; isImageSize = false;">&times;</a>
      {{ 'VALIDATION.IMAGETYPE' | translate }}
      </div>
  </ng-container>
  <div *ngIf="successMessage!=null && errorMessage == null"
    class="alert alert-danger alert-info alert-dismissible fade in">
    <a class="close" aria-label="close" (click)="successMessage = null">&times;</a>
    {{ successMessage }}
  </div>
  <div class="row mt-warning">
    <div class="col-lg-11 col-xs-12">
      <disabled-phone [link]="false" [disabledText]="'HOME.ACCOUNT_DISABLED.PRE_PROFILE'"></disabled-phone>
    </div>
  </div>
  <div *ngIf="!phone">
  <div class="row">
    <div class="col-md-12 col-sm-12 col-xs-12">
      <div class="col-sm-12 col-md-7 col-lg-6 no-padding">
        <div class="row sp-profile visible-xs visible-sm">

          <div class="thumbnail-xs-without col-xs-5 sp-img-div-xs pull-left" *ngIf="!imageSrc">
            <div class="sp-circle-img-xs sp-middle-element click">
              <div class="img-div">
              <label class="choose-label">
                <input type="file" size="60" (change)="fileInput($event)" accept=".jpeg,.png,.jpg" style="display: none;">
                </label>
              </div>
            </div>
          </div>

          <div class="thumbnail-xs col-xs-5 sp-img-div-xs pull-left" *ngIf="imageSrc">
            <div class="parent">
              <input class="overlay-xs" type="file" (change)="fileInput($event)" accept=".jpeg,.png,.jpg">
              <img alt="profile-picture"  [src]="imageSrc" class="upload-img-xs">
            </div>
          </div>

          <div class="col-xs-3 sp-vertical-divider" ></div>
          <div class="col-md-6 sp-account-header pull-left">
            <h2 >{{ 'EDIT_PROFILE.ACCOUNT_SETTINGS' | translate }}</h2>
          </div>
        </div>
        <!-- <div class="row visible-xs" *ngIf="isImageType">
          <p class="image-validation">{{
          'VALIDATION.IMAGETYPE' | translate}}</p>
        </div> -->
      
        <h2 class="hidden-xs hidden-sm">{{ 'EDIT_PROFILE.ACCOUNT_SETTINGS' | translate }}</h2>
        <form class="sp-reg-form" method="POST" action="#" role="form" [formGroup]="userValidation">

          <div class="form-group">
           
            <div class="form-group has-feedback">
              <div
                [ngClass]="{'has-error' : userValidation.get('firstName').invalid && userValidation.get('firstName').dirty, 'has-success' : userValidation.get('firstName').valid}">
                <!-- <input matInput id="firstName" type="text" maxlength="30" formControlName="firstName"
                [placeholder]="'REGISTRATION.COLLECT_INFO.LABEL_FIRST_NAME' | translate"> -->
                <mat-form-field class="full-width" appearance="fill">
                  <mat-label>{{'REGISTRATION.COLLECT_INFO.LABEL_FIRST_NAME' | translate}}</mat-label>
                  <input tabIndex="11" maxlength="30" matInput id="firstName" formControlName="firstName">
                </mat-form-field>
                <p *ngIf="userValidation.get('firstName').invalid && userValidation.get('firstName').dirty" class="help-block">{{
                  'VALIDATION.FIRSTNAME' | translate}}</p>
              </div>
            </div>
            <div class="form-group has-feedback">
              <div
                [ngClass]="{'has-error' : userValidation.get('lastName').invalid && userValidation.get('lastName').dirty, 'has-success' : userValidation.get('lastName').valid}">
                <mat-form-field class="full-width" appearance="fill">
                  <mat-label>{{'REGISTRATION.COLLECT_INFO.LABEL_LAST_NAME' | translate}}</mat-label>
                  <input tabIndex="12" maxlength="30" matInput id="lastName" formControlName="lastName">
                </mat-form-field>
                <p *ngIf="userValidation.get('lastName').invalid && userValidation.get('lastName').dirty"
                  class="help-block">{{
                  'VALIDATION.LASTNAME' | translate}}</p>
              </div>
            </div>
            <ng-container
                *ngIf="userObject && (userObject.provider === undefined || userObject.provider === 'email') ">
              <div class="form-group has-feedback">
                <div
                  [ngClass]="{'has-error' : userValidation.get('email').invalid && userValidation.get('email').dirty, 'has-success' : userValidation.get('email').valid}">
                  <mat-form-field class="full-width" appearance="fill">
                    <mat-label>{{'REGISTRATION.COLLECT_INFO.LABEL_EMAIL' | translate}}</mat-label>
                    <input tabIndex="13" maxlength="100" matInput id="email" formControlName="email" [readonly]="true" >
                  </mat-form-field>
                </div>
              </div>
            </ng-container>
            <div>
              <phone-field [formname]="userValidation" [selectedCountryCode]="selectedCountryCode" (selectedCountryEvent)="selectedCountry($event)" ></phone-field>
            </div>
            <ng-container *ngIf="userObject && (userObject.provider === undefined || userObject.provider === 'email') ">
              <div style="padding: 10px 0;" class="row">
                <div class="col-md-6 col-xs-6 text-left">
                  {{ 'EDIT_PROFILE.PASSWORD' | translate }}
                </div>
                <div tabIndex="33" (keydown.enter)="this.router.navigate(['/profile/changePassword'])" (click)="this.router.navigate(['/profile/changePassword'])" class="col-md-6 col-xs-6 text-right update-link">
                  {{ 'EDIT_PROFILE.UPD_PASSWORD' | translate }}
                </div>
              </div>
              <!-- If Security Question is Enabled this option will be shown -->
              <ng-container *ngIf="configuration.Feature.SecurityQuestions">
                <div style="padding: 10px 0;" class="row">
                  <div class="col-md-6 col-xs-6 text-left">
                    {{ 'EDIT_PROFILE.SEC_QUESTIONS' | translate }}
                  </div>
                  <div tabIndex="34" (keydown.enter)="this.router.navigate(['/profile/changeSecurityQuestions'])" (click)="this.router.navigate(['/profile/changeSecurityQuestions'])" class="col-md-6 col-xs-6 text-right update-link">
                    {{ 'EDIT_PROFILE.UPD_SEC_QUESTIONS' | translate }}
                  </div>
                </div>
              </ng-container>
              <!-- If Security Question is Disabled 2-Step Verification will be shown -->
              <ng-container *ngIf="!configuration.Feature.SecurityQuestions">
                <div style="padding: 10px 0 0 0;" class="row">
                  <div class="col-md-6 col-xs-10 text-left">
                    {{ 'EDIT_PROFILE.2Step_Verification' | translate }}
                  </div>
                  <div class="col-md-6 col-xs-2 text-right">
                    <mat-slide-toggle tabIndex="35" aria-label="mfa-toggle" (change)="onToggle($event)" id="mfaEnabled"
                      formControlName="mfaEnabled">
                    </mat-slide-toggle>
                  </div>
                </div>
              </ng-container>
            </ng-container>
            
           
            <div style="padding: 10px 0 2px 0;" class="row">
              <div class="col-md-6 col-xs-10 text-left">
                {{ 'EDIT_PROFILE.Dis_Phone_Access' | translate }}
              </div>
              <div class="col-md-6 col-xs-2 text-right">
                <mat-slide-toggle tabIndex="35" aria-label="phone-access-toggle"formControlName="accountEnabled">
                </mat-slide-toggle>
              </div>
            </div>

            <div class="row">
              <div class="col-xs-12 col-sm-6 col-md-6 no-padding">
                <button tabIndex="36" id="saveButton" type="submit" (click)="onSave()" class="btn btn-block sp-btn-dark h-45" [disabled]="(userValidation.get('firstName').invalid && userValidation.get('firstName').dirty) || (userValidation.get('lastName').invalid && userValidation.get('lastName').dirty) || ( userValidation.get('phone').invalid && userValidation.get('phone').dirty)">
                  {{ 'PROFILE.EDIT.BUTTON_CONFIRM' | translate }}
                </button>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-6 sp-hyperlink-div sp-middle-element no-padding top-margin-link">
                <a tabIndex="37" (keydown.enter)="confirmDeleteAccountVerification()" (click)="confirmDeleteAccountVerification()" class="sp-hyperlink sp-middle-element"> <img style="width: 23px;" src="./../../../../assets/images/delete-button-red.png" alt="Delete Button"> &nbsp; {{ 'PROFILE.DELETE_ACCOUNT.DELETE_ACCOUNT' |
                  translate }}
                </a>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="col-sm-12 col-md-5 col-lg-6 no-padding">
        <div>
          <div>
            <div>
              
              <div class="thumbnail sp-img-div hidden-xs hidden-sm file-upload" *ngIf="!imageSrc">
                <div class="sp-circle-img sp-middle-element click">
                  <div class="img-div">
                  <label class = "choose-label">
                    <input title="" type="file" size="60" (change)="fileInput($event);" accept=".jpeg,.png,.jpg" style="display: none;">    
                  </label>
                  </div>
                </div>
              </div>

              <div class="thumbnail sp-img-div hidden-xs hidden-sm" *ngIf="imageSrc" [ngClass]="{'no-border' : imageSrc}">
                <div class="parent image-container">
                  <label class="hidden-label">
                  <input tabIndex="38" name="file-input" title="" class="overlay file-upload file-uploader" type="file" (change)="fileInput($event);" accept=".jpeg,.png,.jpg">
                  <img alt="profile-picture"  [src]="imageSrc" class="upload-img file-uploader" >
                </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
  <div *ngIf=phone>
    <div class="form-signin col-md-8 col-lg-6 col-lg-offset-0 col-md-offset-0 col-sm-12">
      <router-outlet></router-outlet>
    </div>
  </div>

</div>