import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { Router } from "@angular/router";
import { UserLoginService } from "../../../services/user-login.service";
import { Utility, spaceCheck } from "../../../services/utils/utility";
import { environment } from "../../../../environments/environment";
import { ChangePasswordService, ChangePasswordCallback } from "src/app/services/change-password.service";
import { Config } from "src/app/services/utils/Config";

@Component({
  selector: "app-change-password",
  templateUrl: "./change-password.component.html",
  styleUrls: ["./change-password.component.css"]
})
export class ChangePasswordComponent implements OnInit, ChangePasswordCallback {
  changePasswordForm: FormGroup;
  errorMessage: string;
  spaceCheck = spaceCheck;
  limitExceed: boolean = false;
  passwordError = {
    divClass: '',
    req: false
  };
  hidePass: boolean = true;
  hideNewPass: boolean = true;
  hideNewConfirmPass: boolean = true;
  upperCase = /[A-Z]/g;
  lowerCase = /[a-z]/g;
  number = /[0-9]/g;
  specialCharacter = /[#?!@$%^&*-]/g;
  space = /[\s]/g;
  genericMessage = null;
  changed = false;
  passwordChanged: boolean = false;

  constructor(
    public formBuilder: FormBuilder,
    public translate: TranslateService,
    public changePasswordService: ChangePasswordService,
    private router: Router,
    private userService: UserLoginService
  ) { }

  ngOnInit() {
    Utility.focusLogo();

    this.changePasswordForm = this.formBuilder.group({
      existingPassword: [
        null,
        [
          Validators.required,
          Validators.pattern(Config.PASSWORD_POLICY_PATTERN)
        ]
      ],
      newPassword: [
        null,
        [
          Validators.required,
          Validators.pattern(Config.PASSWORD_POLICY_PATTERN)
        ]
      ],
      confirmNewPassword: [
        null,
        [
          Validators.required,
          Validators.pattern(Config.PASSWORD_POLICY_PATTERN)
        ]
      ]
    });
  }

  canDeactivate() {
    // HALOC-3017-
    // logout case, the form change was not being detected and was logging out the user, 
    // Now, we check if the user is not present in localstorage, then we simply logout the user and show no Prompt Box
    let email = localStorage.getItem(`CognitoIdentityServiceProvider.${environment.clientId}.LastAuthUser`);

    if (!email || this.changePasswordForm.pristine || this.passwordChanged) {
      return !this.changed;
    } else {
      return this.changed;
    }
  }

  onChanges($event) {
    // if ($event == null || $event.target.id === 'loginEmail') {
    if ($event == null || $event.target.id === 'loginPassword') {
      // if (!this.login.controls.loginPassword.valid) {
      this.passwordError.divClass = 'has-error';
      this.passwordError.req = true;
    } else {
      this.passwordError.divClass = 'has-success';
      this.passwordError.req = false;
    }
    // }
  }

  onSubmitPassword() {
    this.errorMessage = null;
    const existingPassword = this.changePasswordForm.get("existingPassword")
      .value;
    const newPassword = this.changePasswordForm.get("newPassword").value;
    const confirmNewPassword = this.changePasswordForm.get("confirmNewPassword")
      .value;

    if (existingPassword === newPassword) {
      this.errorMessage = this.translate.instant(
        "CHANGE_PASSWORD.ERROR.SAME_NEW_PASSWORD"
      );
    } else {
      this.passwordChanged = true;
      this.changePasswordService.changePassword(
        existingPassword,
        newPassword,
        confirmNewPassword,
        this
      );
    }
  }

  togglePasswordField(fieldType) {
    if (fieldType === 'password') {
      this.hidePass = !this.hidePass;
    }
    else if (fieldType === 'newPassword') {
      this.hideNewPass = !this.hideNewPass;
    }
    else if (fieldType === 'confirmNewPassword') {
      this.hideNewConfirmPass = !this.hideNewConfirmPass;
    }

  }

  onFailure(error: any) {
    if (error[0].code === "CURRENT_PASSWORD_MISMATCH" || error[0].code == 'BAD_ARGUMENT' || error[0].code == 'SHORT_LENGTH' || error[0].code == 'MALFORMED_VALUE' || error[0].code == 'EXCEED_LENGTH') {
      this.errorMessage = this.translate.instant(
        "CHANGE_PASSWORD.ERROR.INCORRECT_PASSWORD"
      );
    } 
    else if (error[0].code == 'PASSWORD_SAME_PREVIOUS') {
      this.errorMessage = this.translate.instant(
        "CHANGE_PASSWORD.ERROR.SAME_NEW_PASSWORD"
      );
    } else {
      this.genericMessage = this.translate.instant("CHANGE_PASSWORD.ERROR.COMMON");
    }
  }

  onInput(){
    this.errorMessage = '';
  }

  onSuccess() {
    this.router.navigate(["/home"]);
  }

  onPasswordMissMatch() {
    this.errorMessage = this.translate.instant(
      "CHANGE_PASSWORD.ERROR.PASSWORD_MIS_MATCH"
    );
  }

  hasError(property: string) {
    let controls = this.changePasswordForm.controls;
    if (controls[property].dirty) {
      if (controls[property].invalid) {
        return true;
      }
      if (property === "newPassword") {
        if (controls['newPassword'].value === controls['existingPassword'].value) {
          return true;
        } else if (controls['newPassword'].value !== controls['existingPassword'].value) {
          return false;
        }
      }
      if (property === "confirmNewPassword") {
        if (controls['newPassword'].value === controls['confirmNewPassword'].value) {
          return false;
        } else if (controls['newPassword'].value !== controls['confirmNewPassword'].value) {
          return true;
        }
      }
      return false;
    }
    return false;
  }
}
