import { Component, OnInit } from "@angular/core";
import { Location } from "@angular/common";
import { goBack } from "../../../services/utils/utility";

@Component({
  selector: "app-lock-base",
  templateUrl: "./lock-base.component.html",
  styleUrls: ["./lock-base.component.css"]
})
export class LockBaseComponent implements OnInit {
  constructor(private location: Location) {}
  goBack = goBack;

  ngOnInit() {}
}
