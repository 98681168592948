import { Injectable } from "@angular/core";
import { CognitoUserPool } from "amazon-cognito-identity-js";
import * as AWS from "aws-sdk/global";
import * as awsservice from "aws-sdk/lib/service";
import * as CognitoIdentity from "aws-sdk/clients/cognitoidentity";
import { environment } from "../../../environments/environment";
import { Logger } from "./logger";
import { LoggingLevel } from "./logging-level";

export interface LoginCognitoCallback {
  cognitoCallback(message: string, result: any): void;

  handleMFAStep(
    challengeName: string,
    callback: (err: any, res: any) => any
  ): void;
}

export interface LoggedInCallback {
  isLoggedIn(message: string, loggedIn: boolean): void;
}

export interface Callback {
  callback(): void;

  callbackWithParam(result: any): void;
}

export interface CallMeCallback {
  isSuccess(status: boolean): void;
}

@Injectable()
export class CognitoUtil {
  constructor(private logger: Logger) {}
  public static _REGION = environment.region;

  public static _USER_POOL_ID = environment.userPoolId;
  public static _CLIENT_ID = environment.clientId;

  public static _POOL_DATA: any = {
    UserPoolId: CognitoUtil._USER_POOL_ID,
    ClientId: CognitoUtil._CLIENT_ID
  };

  getUserPool() {
    return new CognitoUserPool(CognitoUtil._POOL_DATA);
  }

  getCurrentUser() {
    return this.getUserPool().getCurrentUser();
  }

  getIdToken(callback: Callback): void {
    if (callback == null) {
      throw "CognitoUtil: callback in getIdToken is null...returning";
    }
    if (this.getCurrentUser() != null) {
      this.getCurrentUser().getSession((err: any, session: any) => {
        if (err) {
          this.logger.log(
            LoggingLevel.ERROR,
            "CognitoUtil: Can't set the credentials: ",
            JSON.stringify(err)
          );
          callback.callbackWithParam(null);
        } else {
          if (session.isValid()) {
            callback.callbackWithParam(session.getIdToken().getJwtToken());
          } else {
            this.logger.log(
              LoggingLevel.INFO,
              "CognitoUtil: ",
              "Got the id token, but the session isn't valid"
            );
          }
        }
      });
    } else {
      callback.callbackWithParam(null);
    }
  }

  getRefreshToken(callback: Callback): void {
    if (callback == null) {
      throw "CognitoUtil: callback in getRefreshToken is null...returning";
    }
    if (this.getCurrentUser() != null) {
      this.getCurrentUser().getSession((err: any, session: any) => {
        if (err) {
          this.logger.log(
            LoggingLevel.ERROR,
            "CognitoUtil: Can't set the credentials: ",
            JSON.stringify(err)
          );
          callback.callbackWithParam(null);
        } else {
          if (session.isValid()) {
            callback.callbackWithParam(session.getRefreshToken());
          }
        }
      });
    } else {
      callback.callbackWithParam(null);
    }
  }

  refresh(callback: any): void {
    let user = this.getCurrentUser();
    console.log('User in cognito', user);
    user.getSession((err: any, session: any) => {
      if (err) {
        console.log('Error in getting session', err);
        this.logger.log(
          LoggingLevel.ERROR,
          "CognitoUtil: Can't set the credentials: ",
          JSON.stringify(err)
        );
      } else {
        user.refreshSession(session.getRefreshToken(), callback);
      }
    });
  }
}
