export class Constants {

    public static API_METHODS = {
        GET: 'get',
        POST: 'post',
        PUT: 'put',
        DELETE: 'delete',
        PATCH: 'patch',
        PRESIGNED: 'presigned' 
    }

    public static ERROR_CODES = {
        "FAILED": "FAILED",
        "BAD_ARGUMENT": "BAD_ARGUMENT",
        "PHONE_NOT_VERIFIED": "PHONE_NOT_VERIFIED",
        "NULL_VALUE": "NULL_VALUE",
        "EXCEED_LENGTH": "EXCEED_LENGTH",
        "SHORT_LENGTH": "SHORT_LENGTH",
        "MALFORMED_VALUE": "MALFORMED_VALUE",
        "ALREADY_EXIST": "ALREADY_EXIST",
        "CODE_NOT_FOUND": "CODE_NOT_FOUND",
        "CODE_EXPIRED": "CODE_EXPIRED",
        "CODE_OLDER": "100CODE_OLDER9",
        "CODE_VERIFIED_SUCCESS": "CODE_VERIFIED_SUCCESS",
        "RESOURCE_NOT_FOUND": "RESOURCE_NOT_FOUND",
        "LIMIT_EXCEED": "LIMIT_EXCEED",
        "VALUE_MISS_MATCH": "VALUE_MISS_MATCH",
        "CODE_VERIFY_LIMIT_EXCEED": "CODE_VERIFY_LIMIT_EXCEED",
        "INVALID_SESSION": "Invalid session for the user",
        "HOME_NOT_FOUND": "HOME_NOT_FOUND",
        "USER_NOT_AUTHORIZED": "USER_NOT_AUTHORIZED",
        "CAPTCHA_VERIFICATION_FAILED": "CAPTCHA_VERIFICATION_FAILED"
    }

    public static MODAL_CONFIG = {
        backdrop: false,
        ignoreBackdropClick: true,
        keyboard: false
    }

    public static S3_EXPLICIT_URLS_WITHOUT_TOKEN = {
        "STARTS": "https://s3.amazonaws.com/",
        "ENDS": "-profile-pic-user"
    }

}