<h2 class="hidden-xs text-center title"> {{ 'PROFILE.TOGGLE_ENABLE_DISABLE_ACCOUNT.TITLE' | translate }}</h2>
<div class="sp-body clearfix">
  <h2 class="visible-xs text-center mobile-title"> {{ 'PROFILE.TOGGLE_ENABLE_DISABLE_ACCOUNT.TITLE' | translate }}</h2>

  <div *ngIf="errorMessage!=null" class="alert alert-danger alert-dismissible fade in">
    <a class="close" aria-label="close" (click)="errorMessage = null">&times;</a>
    {{ errorMessage }}
  </div>
  <div *ngIf="successMessage!=null && errorMessage == null" class="alert alert-danger alert-info alert-dismissible fade in">
    <a class="close" aria-label="close" (click)="successMessage = null">&times;</a>
    {{ successMessage }}
  </div>

  <form class="form-signin col-md-offset-3 col-md-6 col-sm-8 col-sm-offset-2" method="POST" action="#" role="form">
    <p class="subtext" *ngIf="this.accountEnabled">{{ 'PROFILE.TOGGLE_ENABLE_DISABLE_ACCOUNT.INSTRUCTIONS_ENABLE' | translate }}</p>
    <p class="subtext" *ngIf="!this.accountEnabled">{{ 'PROFILE.TOGGLE_ENABLE_DISABLE_ACCOUNT.INSTRUCTIONS_DISABLE' | translate }}</p>

    <div class="form-group col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2">
      <button *ngIf="this.accountEnabled" (click)="onSubmit()" id="submit" type="submit" class="btn btn-block sp-form-button sp-red-button">
        {{ 'PROFILE.TOGGLE_ENABLE_DISABLE_ACCOUNT.BUTTON_DISABLE' | translate }}
      </button>
      <button *ngIf="!this.accountEnabled" (click)="onSubmit()" id="submit" type="submit" class="btn btn-block sp-form-button sp-green-button">
        {{ 'PROFILE.TOGGLE_ENABLE_DISABLE_ACCOUNT.BUTTON_ENABLE' | translate }}
      </button>
    </div>
  </form>
</div>
