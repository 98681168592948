import { Injectable } from "@angular/core";
import { Router, CanActivate } from "@angular/router";
import { UserLoginService } from "../services/user-login.service";

@Injectable()
export class LoginGuard implements CanActivate {
  constructor(public userService: UserLoginService, protected router: Router) {}

  canActivate() {
    this.userService.canActivate(retVal => {
      if (retVal) {
        return true;
      }
      this.router.navigate(["/home/login"]);
      return false;
    });
    return true;
  }
}
