<ng-container *ngIf=" userObject && userObject.phoneActive && !(userObject.accountEnabled)">
  <div class="alert disable-box">
    <div class="alert-div"> <!-- Double [routerlink] used so removed it-->
      <div class="warning-icon">
        <img width="25" src="assets/images/history/svg/Very Low Battery.svg" />
      </div>
      <div class="warning-text">
        {{ disabledText | translate}}
        <a *ngIf="link" class="anchor-link" [routerLink]="['/profile/edit']">{{ 'HOME.ACCOUNT_DISABLED.CLICK' |
          translate}}
        </a>
      </div>
    </div>
  </div>
</ng-container>