import { Injectable } from "@angular/core";
import { LoginCognitoCallback, CognitoUtil, LoggedInCallback } from "./utils/cognito.service";
import { AuthenticationDetails, CognitoUser, CognitoUserSession } from "amazon-cognito-identity-js";
import { UPDATE_USER_BRAND, LAST_ACTIVITY_TIME } from "./utils/state-management/actions";
import { NgRedux, select } from '@angular-redux/store';
import { IAppState } from './utils/state-management/store';
import { LOGOUT } from './utils/state-management/actions';
import { UserManagement } from "./utils/state-management/action-classes";
import { Utility } from "./utils/utility";
import { LoaderService } from "./loader.service";


@Injectable()
export class UserLoginService {
    @select() user: any;
    private date: any;

    private onLoginSuccess = (callback: LoginCognitoCallback, session: CognitoUserSession) => {
        callback.cognitoCallback(null, session);

    }

    private onLoginError = (callback: LoginCognitoCallback, err: any) => {
        callback.cognitoCallback(err.message, null);
        this.loaderService.isLoading.next(false);
    }

    constructor(public cognitoUtil: CognitoUtil, private ngRedux: NgRedux<IAppState>,
        private userManagement: UserManagement, private utility: Utility, public loaderService: LoaderService) {
    }

    authenticate(username: string, password: string, callback: LoginCognitoCallback) {
        this.date = Date.now();
        this.loaderService.isLoading.next(true);

        let authenticationData = {
            Username: username,
            Password: password,
        };

        let authenticationDetails = new AuthenticationDetails(authenticationData);

        let userData = {
            Username: username,
            Pool: this.cognitoUtil.getUserPool()
        };

        let cognitoUser = new CognitoUser(userData);
        let context = this;
        cognitoUser.setAuthenticationFlowType('CUSTOM_AUTH');
        cognitoUser.authenticateUser(authenticationDetails, {
            newPasswordRequired: (userAttributes, requiredAttributes) => {
                callback.cognitoCallback(`User needs to set password.`, null);
                context.loaderService.isLoading.next(false);
            },
            onSuccess: result => this.onLoginSuccess(callback, result),
            onFailure: err => this.onLoginError(callback, err),
            customChallenge: function (challengeParameters) {
                context.loaderService.isLoading.next(false);
                var current = this;
                callback.handleMFAStep(challengeParameters, (err, res) => {
                                      if (res.answerType === 'generateCode') {
                        context.loaderService.isLoading.next(true);
                        var answer = "answerType:generateCode,medium:" + res.codeType + ",codeType:login"

                        cognitoUser.sendCustomChallengeAnswer(answer, current);
                    } else if (res.answerType === 'regenerateCode') {
                        context.loaderService.isLoading.next(true);
                        var answer = "answerType:regenerateCode,medium:" + res.codeType + ",codeType:login"

                        cognitoUser.sendCustomChallengeAnswer(answer, current);
                    } else if (res.answerType === 'verifyCode') {
                        var answer = "answerType:verifyCode,medium:" + res.codeType + ",codeType:login,code:" + res.code
                        cognitoUser.sendCustomChallengeAnswer(answer, current);
                    }

                });

            }
        });
    }

    forgotPassword(username: string, callback: LoginCognitoCallback) {
        let userData = {
            Username: username,
            Pool: this.cognitoUtil.getUserPool()
        };

        let cognitoUser = new CognitoUser(userData);

        cognitoUser.forgotPassword({
            onSuccess: function () {

            },
            onFailure: function (err) {
                callback.cognitoCallback(err.message, null);
            },
            inputVerificationCode() {
                callback.cognitoCallback(null, null);
            }
        });
    }

    confirmNewPassword(email: string, verificationCode: string, password: string, callback: LoginCognitoCallback) {
        let userData = {
            Username: email,
            Pool: this.cognitoUtil.getUserPool()
        };

        let cognitoUser = new CognitoUser(userData);

        cognitoUser.confirmPassword(verificationCode, password, {
            onSuccess: function () {
                callback.cognitoCallback(null, null);
            },
            onFailure: function (err) {
                callback.cognitoCallback(err.message, null);
            }
        });
    }

    logout() { 
        try {
            if (this.cognitoUtil.getCurrentUser()) {
                this.cognitoUtil.getCurrentUser().signOut();
            }
            this.ngRedux.dispatch({ type: LOGOUT });
            let brand = localStorage.getItem("userBrand");
            let configObject = JSON.parse(localStorage.getItem('config'));
            const cookiesStatus = localStorage.getItem("cookiesEventTrigger");
            localStorage.clear();
            localStorage.setItem('cookiesEventTrigger', cookiesStatus);
            localStorage.setItem('config', JSON.stringify(configObject));
            this.ngRedux.dispatch({
                type: UPDATE_USER_BRAND,
                brand: brand
            });
        } catch (err) { } 
    }

    globalLogout(callback: any) {
        this.date = Date.now(); 
        this.loaderService.isLoading.next(true);

        try {
            let cognitoUser = this.cognitoUtil.getCurrentUser();
            cognitoUser.getSession((err: any, session: any) => {
                if (session.isValid()) {
                    cognitoUser.globalSignOut(callback);
                }
                this.ngRedux.dispatch({ type: LOGOUT });
                let brand = localStorage.getItem("userBrand");
                let configObject = JSON.parse(localStorage.getItem('config'));
                localStorage.clear();
                localStorage.setItem('config', JSON.stringify(configObject));

                this.ngRedux.dispatch({
                    type: UPDATE_USER_BRAND,
                    brand: brand
                });
            });
        } catch (err) {
            this.utility.removeLoader(this.date);
            this.loaderService.isLoading.next(false);
        }
        this.utility.removeLoader(this.date);
        this.loaderService.isLoading.next(false);

    }

    isAuthenticated(callback: LoggedInCallback) {

        if (callback == null)
            throw ("UserLoginService: Callback in isAuthenticated() cannot be null");
        if (this.cognitoUtil.getCurrentUser()) {
            this.cognitoUtil.getCurrentUser().getSession((err: any, session: any) => {
                if (session && session.isValid()) {
                    callback.isLoggedIn(err, true);
                } else {
                    console.log('Here False');
                    callback.isLoggedIn(err, false);
                }
            });
        } else {
            callback.isLoggedIn(null, false)
        }

    }

    canActivate(callback: any) {
        if (this.cognitoUtil.getCurrentUser()) {
            this.cognitoUtil.getCurrentUser().getSession((err: any, session: any) => {
                if (localStorage.getItem('isSignUp')) {
                    return callback(true);
                } else {
                    if ((session.isValid() && this.ngRedux.getState().userObject.phoneActive)) {
                        return callback(true);
                    } else {
                        return callback(false);
                    }
                }
            });
        } else {
            return callback(false);
        }
    }

    getLastActivityTime() {
        return this.ngRedux.getState().time;
    }

    UpadteLastActivity() {
        this.ngRedux.dispatch({ type: LAST_ACTIVITY_TIME, time: Date.now() });
    }
}
