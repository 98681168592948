import { Component, ViewEncapsulation } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Config } from 'src/app/services/utils/Config';


@Component({
  selector: 'app-web-accessibilty',
  templateUrl: './web-accessibilty.component.html',
  styleUrls: ['./web-accessibilty.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class WebAccessibiltyComponent {
  title: string;
  email = Config.LINKS.EMAIL;

  constructor(public bsModalRef: BsModalRef) {
   }
}
