import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class DataShareService {

    private valueSoruce = new BehaviorSubject<any>('');
    currentValue = this.valueSoruce.asObservable();

    constructor() { }

    fireValue(value: any) {
    this.valueSoruce.next(value);
  }
}
