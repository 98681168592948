  <div class="{{modalCss}}">
    <div class="modal-header">
      <button type="button" class="close close-btn" (click)="closeDialogue(lastTab)"><mat-icon>clear</mat-icon></button>
    </div>
    <div class="modal-body" >
      <div class="options-modal" tabindex="-1" #filterModal>
        <div class="col-md-12 col-sm-12 col-xs-12 main-div">
          <div class="col-xs-12">
            <div class="row">
              <div class="col-md-12 main-head">
                <div class="options-modal" *ngIf="currentTab==0">
                  <h3 class="text-center header-modal">{{ 'HOME.HISTORY.FILTERS.FILTERS_BY_TEXT' | translate }}</h3>
                  <div class="form-group has-feedback">
                    <ng-container>
                      <a tabindex="0" (click)="resetFilterAndCloseDialog()" class="reset-btn-link sp-hyperlink">{{ 'HOME.HISTORY.FILTERS.RESET_TEXT' | translate }}</a>
                    </ng-container>
                    <div class="container filter-main">
                      <div tabindex="0" role="button" (keydown.enter)="changeTab(1, false)" class="filter-main-row filter-main-row-top" (click)="changeTab(1, false)">
                        <div>
                          <img style="margin-left:2px" class="row-icon"
                            src="../../../../assets/images/home/ico-lock.png">
                          <b style="margin-left:4px">{{ 'HOME.HISTORY.FILTERS.LOCKS_TEXT' | translate }}</b>
                        </div>
                        <div>
                          <p class="filter-text"> {{selectedLocks[0]}} </p>
                          <img src="../../../../assets/images/home/ico-chevron-forward-outline.png">
                        </div>
                      </div>
                      <div tabindex="0" role="button" class="filter-main-row" (keydown.enter)="changeTab(2, false)" (click)="changeTab(2, false)">
                        <div>
                          <img class="row-icon"
                            src="../../../../assets/images/home/ico-date-and-time-schedule-date-range.png">
                          <b style="margin-left:2px">{{ 'HOME.HISTORY.FILTERS.DATE_RANGE_TEXT' | translate }}</b>
                        </div>
                        <div>
                          <p class="filter-text">
                            <ng-container *ngIf="selectedMoments[0] && selectedMoments[1]">
                              {{selectedMoments[0] | date:'dd/MM/yyyy' }} - {{selectedMoments[1] |
                              date:'dd/MM/yyyy' }}
                            </ng-container>
                          </p>
                          <img src="../../../../assets/images/home/ico-chevron-forward-outline.png">
                        </div>
                      </div>
                      <div tabindex="0" role="button" class="filter-main-row" (keydown.enter)="changeTab(3, false)" (click)="changeTab(3, false)">
                        <div>
                          <img class="row-icon" src="../../../../assets/images/home/ico-user.png">
                          <b style="margin-left:2px">{{ 'HOME.HISTORY.FILTERS.USER_TYPE_TEXT' | translate }}</b>
                        </div>
                        <div>
                          <p class="filter-text"> {{selectedSources[0]}} </p>
                          <img src="../../../../assets/images/home/ico-chevron-forward-outline.png">
                        </div>
                      </div>
                      <div tabindex="0" role="button" class="filter-main-row filter-main-row-bottom" (keydown.enter)="changeTab(4, false)" (click)="changeTab(4, false)">
                        <div>
                          <img class="row-icon" src="../../../../assets/images/home/ico-activity.png">
                          <b style="margin-left:2px">{{ 'HOME.HISTORY.FILTERS.ACTIVITY_TEXT' | translate }}</b>
                        </div>
                        <div>
                          <p class="filter-text"> <span *ngFor="let s of selectedActivities">{{s[langCode]}}&nbsp;</span> </p>
                          <img src="../../../../assets/images/home/ico-chevron-forward-outline.png">
                        </div>
                      </div>
                      <button (click)="closeDialog()"  tabindex="0"
                        [disabled]="selectedSources.length === 0 && selectedLocks.length === 0 && selectedActivities.length === 0 && !selectedMoments[0] &&  !selectedMoments[1]"
                        class="options-modal-btn btn-block btn-filled btn-filter-box">
                        <b>{{ 'HOME.HISTORY.FILTERS.SEE_RESULTS_TEXT' | translate }}</b>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="options-modal" *ngIf="currentTab==1">
                  <img tabindex="0" (keydown.enter)="changeTab(0, false, true, 1)" class="back-btn" (click)="changeTab(0, false, true, 1)"
                    src="../../../../assets/images/home/ico-chevron-back-outline.png">
                  <h3 class="text-center header-modal">{{ 'HOME.HISTORY.FILTERS.LOCKS_TEXT' | translate }}</h3>
                  <div class="form-group has-feedback">
                    <div class="input-icons">
                      <i class="fa fa-search icon"></i>
                      <input class="input-field" type="text" placeholder="{{ 'HOME.HISTORY.FILTERS.SEARCH_FOR_KEYWORDS_PLACEHOLDER' | translate }}"
                        (keyup)="addLockFilter($event)" [(ngModel)]="selectedLocks[0]">
                    </div>
                    <div>
                      <button  (click)="changeTab(0, true, false, 1)" [disabled]="selectedLocks.length === 0"
                        class="options-modal-btn btn-block btn-filled-date-picker btn-filter-box date-picker-btn">
                        <b>{{ 'HOME.HISTORY.FILTERS.APPLY_TEXT' | translate }}</b>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="options-modal" *ngIf="currentTab==2">
                  <img tabindex="0" (keydown.enter)="changeTab(0, false, true, 2)" class="back-btn"
                  (click)="changeTab(0, false, true, 2)"
                    src="../../../../assets/images/home/ico-chevron-back-outline.png">
                  <h3 class="text-center header-modal">{{ 'HOME.HISTORY.FILTERS.SELECT_DATE_RANGE_TEXT' | translate }}</h3>
                  <div class="form-group has-feedback date-picker-main-container">
                    <div class="date-picker-container">
                      <owl-date-time-inline [max]="toDate" [min]="minDate"
                        [(ngModel)]="selectedMoments" [selectMode]="'range'"></owl-date-time-inline>

                    </div>
                    <div>
                      <button tabindex="0" (click)="changeTab(0, true, false, 2)" [disabled]="!selectedMoments[0] || !selectedMoments[1]"
                        class="options-modal-btn btn-block btn-filled-date-picker btn-filter-box date-picker-btn">
                        <b>{{ 'HOME.HISTORY.FILTERS.APPLY_TEXT' | translate }}</b>
                      </button>
                    </div>


                  </div>
                </div>
                <div class="options-modal" *ngIf="currentTab==3">
                  <img tabindex="0" (keydown.enter)="changeTab(0, false, true, 3)" class="back-btn" (click)="changeTab(0, false, true, 3)"
                    src="../../../../assets/images/home/ico-chevron-back-outline.png">
                  <h3 class="text-center header-modal">{{ 'HOME.HISTORY.FILTERS.USER_TYPE_TEXT' | translate }}</h3>
                  <div class="form-group has-feedback">
                    <div class="input-icons">
                      <i class="fa fa-search icon"></i>
                      <input class="input-field" type="text" placeholder="{{ 'HOME.HISTORY.FILTERS.SEARCH_FOR_KEYWORDS_PLACEHOLDER' | translate }}"
                        (keyup)="addSourceFilter($event)" [(ngModel)]="selectedSources[0]">
                    </div>
                    <div>
                      <button tabindex="0"
                      (click)="changeTab(0, true, false, 3)"
                        [disabled]="selectedSources.length === 0 || selectedSources[0].trim().length === 0"
                        class="options-modal-btn btn-block btn-filled-date-picker btn-filter-box date-picker-btn">
                        <b>{{ 'HOME.HISTORY.FILTERS.APPLY_TEXT' | translate }}</b>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="options-modal" *ngIf="currentTab==4">
                  <img tabindex="0" (keydown.enter)="changeTab(0, false, true, 4)" class="back-btn" (click)="changeTab(0, false, true, 4)"
                    src="../../../../assets/images/home/ico-chevron-back-outline.png">
                  <h3 class="text-center header-modal">{{ 'HOME.HISTORY.FILTERS.ACTIVITY_TEXT' | translate }}</h3>
                  <div class="form-group has-feedback">
                    <div id="" class="checkbox-container-main">
                      <div *ngFor="let activity of activities;">
                        <div class="checkbox-container">
                          <div class="panel-bottom">
                            <div class="div-80"> <label class="checkbox-label">{{activity[langCode]}}</label> </div>
                            <div class="div-20">
                              <div class="container-checkbox">
                                <div class="round" tabindex="0" (keydown.enter)="checkbox.click()">
                                  <input type="checkbox" #checkbox value="{{activity[defaultLangCode]}}" id="{{activity[defaultLangCode]}}"
                                    (change)="addActivityFilter(activity)"
                                    [checked]="isActivityChecked(activity)"
                                    />
                                  <label for="{{activity[defaultLangCode]}}"></label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <button tabindex="0" (click)="changeTab(0, true, false, 4)" [disabled]="selectedActivities.length === 0"
                        class="options-modal-btn btn-block btn-filled-date-picker btn-filter-box date-picker-btn">
                        <b>{{ 'HOME.HISTORY.FILTERS.APPLY_TEXT' | translate }}</b>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>