import { Injectable } from '@angular/core';
import apiConfig from './api-client/api-config';
import { ApiRequestService } from './api-client/api-requests.service';
import { Constants } from './utils/Constants';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class CommonService {
  constructor(
    private apiClient: ApiRequestService,
    private cookieService: CookieService
    ) { }

  getPresignedUrl(callback) {
    this.apiClient.callApi(Constants.API_METHODS.POST, apiConfig.getPresignedURL.uri, { "uploadtype": "user" }).subscribe((res) => {
      let { data: preSignedData = [] } = res;
      let [data] = preSignedData;
      callback.preSignedCallBack(data);
    })

  }

  
  /**
   * @description It will clear all cookies related onetrust
   * @returns void
   */
  clearCookies() {
    this.cookieService.delete('OptanonConsent', '/');
    this.cookieService.delete('OptanonAlertBoxClosed', '/');
  }

  /**
   * @description get lattest value of Cookies 
   * @returns boolean true | false
   */
  get cookiesEventTrigger() {
    return localStorage.getItem('cookiesEventTrigger');
  }

  /**
   * @description set lattest value of Cookies 
   * @returns boolean true | false
   */
  set cookiesEventTrigger(status: string) {
    localStorage.setItem('cookiesEventTrigger', status);
  }
}
