import { NgRedux } from '@angular-redux/store';
import { Injectable } from '@angular/core';
// import { ApiClient } from './utils/api-client/api-client';
import { Logger } from './utils/logger';
import { LoggingLevel } from './utils/logging-level';
import {
  UPDATE_FORGOT_PASSWORD_FLOW,
  UPDATE_VERIFICATION,
} from './utils/state-management/actions';
import { IAppState } from './utils/state-management/store';
import { IForgotPassword } from './utils/state-management/types';
import { TranslateService } from '@ngx-translate/core';
import { ADD_SUCCESS_MESSAGE } from './utils/state-management/actions';
import apiConfig from './api-client/api-config';
import { Constants } from './utils/Constants';
import { ApiRequestService } from './api-client/api-requests.service';
import { debug } from 'console';
import { ResponseHandlerService } from './utils/response-handler.service';

export interface SendVerificationCodeCallback {
  sendVerifiactionCodeFailure(): void;
  sendVerifiactionCodeErrorResponse(res: any): void;
  sendVerifiactionCodeSuccess(): void;
}

export interface ConfirmPasswordCallback {
  onSuccess(): void;
  onFailure(): void;
  onFailureResponse(any: any): void;
  passwordMissMatch(): void;
}

@Injectable()
export class ForgotPasswordService {
  isStep1 = 1;
  isStep3 = 3;
  config: any;
  securityQuestionsFlag: any;

  constructor(
    private ngRedux: NgRedux<IAppState>,
    public apiService: ApiRequestService,
    private logger: Logger,
    private translate: TranslateService,
    private responseHandlerService: ResponseHandlerService
  ) {}

  getForgotPasswordObject(): IForgotPassword {
    return this.ngRedux.getState().forgotPassword;
  }

  updateForgotPasswordObject(forgotPassword: IForgotPassword) {
    this.ngRedux.dispatch({
      type: UPDATE_FORGOT_PASSWORD_FLOW,
      forgotPassword: forgotPassword,
    });
  }

  getSecurityQuestions(email: string, callback: any) {
    const forgotPassword = this.ngRedux.getState().forgotPassword;
    let body = {
      email: email,
    };
    this.apiService
      .callApi(
        Constants.API_METHODS.POST,
        apiConfig.forgotPasswordGetSecurityQuestions.uri,
        body
      )
      .subscribe({
        next: (res) => {
          if (res && res.data && res.data.length > 0) {
            const data = res.data[0];
            forgotPassword.secretQuestions = data.secretquestions;
            forgotPassword.email = email;
            console.log(forgotPassword);
            if (forgotPassword.step == this.isStep1) {
              forgotPassword.step = this.isStep3;
            } else {
              forgotPassword.step++;
            }
            this.updateForgotPasswordObject(forgotPassword);
          } else {
            callback.onFailure();
          }
        },
        error: (err) => {
          this.logger.log(
            LoggingLevel.DEBUG,
            'Forgot Password Service',
            'Sending verification code failure: ' + err
          );
          callback.onFailure();
        },
      });
    /*  this.apiService.makeCall(null, 'post', apiConfig.forgotPasswordGetSecurityQuestions, { email: email }, (err, res) => {
      if (err) {
        this.logger.log(LoggingLevel.DEBUG, 'Forgot Password Service', 'Sending verification code failure: ' + err);
        callback.onFailure();
      } else {
        if (res && res.success && res.data && res.data.length > 0) {
          const data = res.data[0];
          forgotPassword.secretQuestions = data.secretquestions;
          forgotPassword.email = email;
          if (forgotPassword.step == this.isStep1) {
            forgotPassword.step = this.isStep3;
          } else {
            forgotPassword.step++;
          }
          this.updateForgotPasswordObject(forgotPassword);
        } else {
          callback.onFailure();
        }
      }
    }); */
  }

  verifySecurityQuestion(question: string, ans: string, callback: any) {
    const forgotPassword = this.ngRedux.getState().forgotPassword;
    const body = {
      email: forgotPassword.email,
      secretquestion: question.trim(),
      secretanswer: ans.trim(),
    };

    this.apiService
      .callApi(
        Constants.API_METHODS.POST,
        apiConfig.forgotPasswordVerifySecurityQuestion.uri,
        body
      )
      .subscribe({
        next: (res) => {
          if (res) {
            this.updateForgotPasswordObject(forgotPassword);
            callback.onSuccess(res);
          } else {
            callback.onFailure(res);
          }
        },
        error: (err) => {
          this.responseHandlerService.parseErrorResponseV2(err);
          callback.onFailure(err.error);
        },
      });
    // TODO remove comments
    /* this.apiService.makeCall(null, 'POST', apiConfig.forgotPasswordVerifySecurityQuestion, body, (err, res) => {
      if (err) {
        callback.onFailure();
      } else {
        if (res && res.success) {
          // forgotPassword.step++;
          this.updateForgotPasswordObject(forgotPassword);
          callback.onSuccess(res);
        } else {
          callback.onFailure(res);
        }
      }
    }); */
  }

  confirmPassword(code: string, pass: string, callback: any) {
    // callback.onSuccess();
    const forgotPassword = this.ngRedux.getState().forgotPassword;

    const body = {
      email: forgotPassword.email,
      code: code,
      password: pass,
    };
    this.apiService
      .callApi(
        Constants.API_METHODS.POST,
        apiConfig.confirmForgotPassword.uri,
        body
      )
      .subscribe({
        next: (res) => {
          /* if (res && res.success) { */
            res.type = 'confirmPassword';
            this.ngRedux.dispatch({
              type: ADD_SUCCESS_MESSAGE,
              msg: this.translate.instant('FORGOT_PASSWORD.ENTER_PASSWORD.PASSWORD_CHANGE_SUCCESS_MESSAGE')
            })
            callback.onSuccess(res);
          

          /*   this.ngRedux.dispatch({
              type: ADD_SUCCESS_MESSAGE,
              msg: this.translate.get(
                'FORGOT_PASSWORD.ENTER_PASSWORD.PASSWORD_CHANGE_SUCCESS_MESSAGE'
              )['value'],
            });
            callback.onSuccess(res); */
          /* } else {
            callback.onFailureResponse(res);
          } */
        },
        error: (err) => {
          this.logger.log(
            LoggingLevel.DEBUG,
            'Forgot password service - Call to update password',
            err
          );
          callback.onFailure();
        },
      });
    /*  this.apiService.makeCall(null, 'post', apiConfig.confirmForgotPassword, body, (err, res) => {
      if (err) {
        this.logger.log(LoggingLevel.DEBUG, 'Forgot password service - Call to update password', err);
        callback.onFailure();
      } else {
        if (res && res.success) {
          res.type = 'confirmPassword'
          this.ngRedux.dispatch({
            type: ADD_SUCCESS_MESSAGE,
            msg: this.translate.get("FORGOT_PASSWORD.ENTER_PASSWORD.PASSWORD_CHANGE_SUCCESS_MESSAGE")["value"]
          });
          callback.onSuccess(res);
        } else {
          callback.onFailureResponse(res);
        }
      }
    }); */
  }

  callApiToResend(
    verificationType: any,
    callback: SendVerificationCodeCallback
  ) {
    const forgotPassword = this.getForgotPasswordObject();

    const body = {
      email: forgotPassword.email,
      verificationtype: verificationType,
    };
    this.apiService
      .callApi(
        Constants.API_METHODS.POST,
        apiConfig.forgotPassword.uri,
        body
      )
      .subscribe({
        next: (res) => {
          /* if (res && res.success) { */
            if (verificationType === 'phone') {
              const data = res.data[0];
              forgotPassword.phoneNumber = data.phonenumber || '';

              let verifyObject = this.ngRedux.getState().verify;
              verifyObject = {};
              verifyObject[verificationType] = {};
              verifyObject[verificationType].address = forgotPassword.email;
              verifyObject[verificationType].verify = false;
              this.ngRedux.dispatch({
                type: UPDATE_VERIFICATION,
                object: verifyObject,
              });
            } else if (verificationType === 'email') {
              let verifyObject = this.ngRedux.getState().verify;
              verifyObject = {};
              verifyObject[verificationType] = {};
              verifyObject[verificationType].address = forgotPassword.email;
              verifyObject[verificationType].verify = false;
              this.ngRedux.dispatch({
                type: UPDATE_VERIFICATION,
                object: verifyObject,
              });
            }
            this.updateForgotPasswordObject(forgotPassword);
            callback.sendVerifiactionCodeSuccess();
         /*  } else {
            callback.sendVerifiactionCodeErrorResponse(res);
          } */
        },
        error: (err) => {
          this.logger.log(
            LoggingLevel.DEBUG,
            'Forgot Password Service',
            'Sending verification code failure: ' + err
          );
          callback.sendVerifiactionCodeFailure();
        },
      });
    /*  this.apiService.makeCall(null, 'post', apiConfig.forgotPassword, body, (err, res) => {
      if (err) {
        this.logger.log(LoggingLevel.DEBUG, 'Forgot Password Service', 'Sending verification code failure: ' + err);
        callback.sendVerifiactionCodeFailure();
      } else {
        if (res && res.success) {
          if (verificationType === 'phone') {
            
            const data = res.data[0];
            forgotPassword.phoneNumber = data.phonenumber || '';

            let verifyObject = this.ngRedux.getState().verify;
              verifyObject = {};
              verifyObject[verificationType] = {};
              verifyObject[verificationType].address = forgotPassword.email;
              verifyObject[verificationType].verify = false;
              this.ngRedux.dispatch({
                type: UPDATE_VERIFICATION,
                object: verifyObject
              });

          } else if (verificationType === 'email') {

              let verifyObject = this.ngRedux.getState().verify;
              verifyObject = {};
              verifyObject[verificationType] = {};
              verifyObject[verificationType].address = forgotPassword.email;
              verifyObject[verificationType].verify = false;
              this.ngRedux.dispatch({
                type: UPDATE_VERIFICATION,
                object: verifyObject
              });
          }
          this.updateForgotPasswordObject(forgotPassword);
          callback.sendVerifiactionCodeSuccess();
        } else {
          callback.sendVerifiactionCodeErrorResponse(res);
        }
      }
    }); */
  }

  callApiToSendCode(
    verificationType: string,
    callback: SendVerificationCodeCallback
  ) {
    const forgotPassword = this.getForgotPasswordObject();
    forgotPassword.verificationType = verificationType;

    this.config = JSON.parse(localStorage.getItem('config'));
    this.securityQuestionsFlag = this.config.Feature.SecurityQuestions;

    const body = {
      email: forgotPassword.email,
      verificationtype: verificationType,
    };

    this.apiService
      .callApi(
        Constants.API_METHODS.POST,
        apiConfig.forgotPassword.uri,
        body
      )
      .subscribe({
        next: (res) => {
          if (res) {
            let verifyObject = this.ngRedux.getState().verify;
            verifyObject = {};
            verifyObject[verificationType] = {};
            verifyObject[verificationType].address = forgotPassword.email;
            verifyObject[verificationType].verify = false;

            if (forgotPassword.verificationType === 'phone') {
              const data = res.data[0];
              forgotPassword.phoneNumber = data.phonenumber;
              forgotPassword.phoneActive =
                data && data.code === Constants.ERROR_CODES.PHONE_NOT_VERIFIED
                  ? false
                  : true;
              // forgotPassword.phoneActive ? forgotPassword.step++ : ''
              forgotPassword.step = !this.securityQuestionsFlag
                ? forgotPassword.step + 2
                : forgotPassword.step + 1;
            }

            this.ngRedux.dispatch({
              type: UPDATE_VERIFICATION,
              object: verifyObject,
            });
            this.updateForgotPasswordObject(forgotPassword);
            callback.sendVerifiactionCodeSuccess();
          } else if (
            res.data[0] &&
            res.data[0].code === Constants.ERROR_CODES.PHONE_NOT_VERIFIED
          ) {
            const data = res.data[0];

            forgotPassword.phoneActive =
              data && data.code === Constants.ERROR_CODES.PHONE_NOT_VERIFIED
                ? false
                : true;
            forgotPassword.step++;

            this.updateForgotPasswordObject(forgotPassword);
            callback.sendVerifiactionCodeSuccess();
          } else {
            callback.sendVerifiactionCodeErrorResponse(res);
          }
        },
        error: (err) => {
          this.logger.log(
            LoggingLevel.DEBUG,
            'Forgot Password Service',
            'Sending verification code failure: ' + err
          );
          callback.sendVerifiactionCodeFailure();
        },
      });
    /*  this.apiService.makeCall(null, 'post', apiConfig.forgotPassword, body, (err, res) => {
      console.log('res', res);
      if (err) {
        this.logger.log(LoggingLevel.DEBUG, 'Forgot Password Service', 'Sending verification code failure: ' + err);
        callback.sendVerifiactionCodeFailure();
      } else {
        if (res && res.success) {
          let verifyObject = this.ngRedux.getState().verify;
          verifyObject = {};
          verifyObject[verificationType] = {};
          verifyObject[verificationType].address = forgotPassword.email;
          verifyObject[verificationType].verify = false;

          if (forgotPassword.verificationType === 'phone') {
            const data = res.data[0];
            forgotPassword.phoneNumber = data.phonenumber;
            forgotPassword.phoneActive = data && data.code === Constants.ERROR_CODES.PHONE_NOT_VERIFIED ? false : true
            // forgotPassword.phoneActive ? forgotPassword.step++ : ''
            forgotPassword.step = !this.securityQuestionsFlag ? forgotPassword.step+2 : forgotPassword.step+1
          }

          this.ngRedux.dispatch({
            type: UPDATE_VERIFICATION,
            object: verifyObject
          });
          this.updateForgotPasswordObject(forgotPassword);
          callback.sendVerifiactionCodeSuccess();
        } else if (res.data[0] && res.data[0].code === Constants.ERROR_CODES.PHONE_NOT_VERIFIED) {

          const data = res.data[0];
          
          forgotPassword.phoneActive = data && data.code === Constants.ERROR_CODES.PHONE_NOT_VERIFIED ? false : true
          forgotPassword.step++;
          
          this.updateForgotPasswordObject(forgotPassword);
          callback.sendVerifiactionCodeSuccess();
          
        } else {
          callback.sendVerifiactionCodeErrorResponse(res);
        }
      }
    }); */
  }
}
