import { Component, EventEmitter, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from "@angular/router";
import { VerificationCallback, VerificationService } from '../../../services/utils/verification.service';
import { TranslateService } from "@ngx-translate/core";
import { Utility } from '../../../services/utils/utility';
import { ToggleEnableDisableAndDeleteAccountCallback, UserProfileService } from '../../../services/user-profile.service';
import { Config } from 'src/app/services/utils/Config';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { LoaderService } from 'src/app/services/loader.service';

export interface VerificationCodeModal {
  medium: string;
  codeType: string;
  email: string;
  phoneNumber: string;
  initialState: boolean;
  errorMsg: string;
  successMsg: string;
  code: string;
  continueDisabled: boolean;
  isPhone: boolean;
  isEmail: boolean;
}
@Component({
  selector: 'app-verification-code-modal',
  templateUrl: './verification-code-modal.component.html',
  styleUrls: ['./verification-code-modal.component.css', '../../../../assets/css/ng-otp.css'],
  encapsulation: ViewEncapsulation.None
})
export class VerificationCodeModalComponent implements ToggleEnableDisableAndDeleteAccountCallback, VerificationCodeModal, VerificationCallback {
  
  medium: string;
  codeType: string;
  email: string;
  phoneNumber: string;
  address: string;
  initialState: boolean
  errorMsg: string;
  successMsg: string;
  code: string;
  continueDisabled: boolean;
  @ViewChild('ngxotp') ngxOtp;
  phoneLastDigits: string;
  securityCodePaste: string;
  securityCodeEnter = [];
  securityCode: string;
  securityCondition: boolean = false;
  isPhone: boolean;
  isEmail: boolean;

  //ng-otp-input
  showOtpComponent = true;
  ngconfig = Utility.getNgxOtpConfigObject();

  isLoading = true;
  toggle = false;

  outputEvent: EventEmitter<any> = new EventEmitter(); 

  constructor(
    public router: Router,
    public verificationService: VerificationService,
    public userProfileService: UserProfileService,
    public translate: TranslateService,
    public utility: Utility,
    public bsModalRef: BsModalRef, 
    private loaderService: LoaderService
  ) { }


  ngOnInit() {
    setTimeout(() => { this.setCode(this.code) }, 100);
    this.setAddressBasedMedium();
    if (this.initialState)
      this.sendCode();
  }

  setAddressBasedMedium() {
    if (this.medium === Config.MEDIUM_TYPES.PHONE) {
      this.address = this.phoneNumber;
    } else if (this.medium === Config.MEDIUM_TYPES.EMAIL) {
      this.address = this.email;
    }
  }

  handleFillEvent(value: string): void {
    this.securityCodePaste = value;
    if (this.securityCodePaste.length === 6 && !this.securityCondition) {
      this.securityCode = this.securityCodePaste;
      this.verifyCode(value);
    }
  }

  resetOTP() {
    this.securityCodePaste = null;
  }


  setMediumAsEmail() {
    this.toggle = true;
    this.isPhone = false;
    this.isEmail = true;
    this.medium = Config.MEDIUM_TYPES.EMAIL;
    this.setAddressBasedMedium();
    this.sendCode();
  }

  setMediumAsPhone() {
    this.toggle = true;
    this.isEmail = false;
    this.isPhone = true;
    this.medium = Config.MEDIUM_TYPES.PHONE;
    this.setAddressBasedMedium();
    this.sendCode();
  }

  reSendCode() {
    this.resetOTP();
    this.errorMsg = '';
    if (this.isPhone) {
      this.medium = Config.MEDIUM_TYPES.PHONE;
    } else if (this.isEmail) {
      this.medium = Config.MEDIUM_TYPES.EMAIL;
    }
    this.setAddressBasedMedium();
    this.sendCode();
  }

  verifyCode(code) {
    this.isLoading = true;
    let params = { medium: this.medium, code };
    this.userProfileService.deleteUserAccount(params, this)
  }

  // callback function for DeleteUser
  onSuccess() {
    this.outputEvent.emit({delete : true});
    this.bsModalRef.hide();
    this.loaderService.fireLoaderValue(false);
    this.router.navigate(["/home/login"]);
  }
  // callback function for DeleteUser
  onFailure() {
    this.isLoading = false;
    let errorMsg = '';
    errorMsg = this.translate.instant('HOME.ERROR.INCORRECT_CODE');
    this.errorMsg = errorMsg;
    this.resetOTP();
  }

  sendCode() {
    this.resetOTP();
    this.isLoading = true;
    this.verificationService.sendCodeSilent(this.medium, this.address, this.codeType, this);
  }

  onSuccessSendCode(result: any) {
    if (this.initialState) {
      this.initialState = false;
    } else if (this.toggle) {
      this.errorMsg = this.translate.instant('HOME.ERROR.CODE_SEND');
    }
    else {
      this.errorMsg = this.translate.instant('HOME.ERROR.CODE_RESEND');
    }
    this.toggle = false;
    this.isLoading = false;
  }

  setCode(val) {
    this.phoneLastDigits = `${this.phoneNumber.substr(this.phoneNumber.length - 2)}`
    val = val ? `${val}` : '';
    for (var i = 0; i < val.length; i++) {
      this.ngxOtp.handleKeyup(`${val[i]}`, i);
    }
  }

  close() {
    /* this.result = { delete : false };
    this.dialogService.removeAll(); */
    this.outputEvent.emit({delete : false});
    this.bsModalRef.hide();
  }
}