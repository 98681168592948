import { NgRedux } from '@angular-redux/store';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { IAppState } from '../../../services/utils/state-management/store';

@Component({
  template: '',
  encapsulation: ViewEncapsulation.None
})
export abstract class SecurityQuestionsBaseComponent implements OnInit {
  errorMessage: string;
  securityQuestions: FormGroup;

  QuestionList = [];

  question1 = '';
  question2 = '';
  question3 = '';

  constructor(
    public formBuilder: FormBuilder,
    public translate: TranslateService,
    public ngRedux: NgRedux<IAppState>
  ) {}

  ngOnInit() {
    this.securityQuestions = this.formBuilder.group({
      question1: [null, [Validators.required]],
      answer1: [null, [Validators.required, Validators.minLength(3)]],
      question2: [null, [Validators.required]],
      answer2: [null, [Validators.required, Validators.minLength(3)]],
      question3: [null, [Validators.required]],
      answer3: [null, [Validators.required, Validators.minLength(3)]],
    });

    this.translate
      .get('REGISTRATION.SECURITY_QUESTIONS.QUESTIONS')
      .subscribe((data) => {
        this.QuestionList = data;
      });
  }

  /**
  Purpose of this method is to filter the security questions
  */
  getQuestionList(currentIndex) {
    const collection = this.QuestionList.filter((x) => {
      const secQuestionCount = 3;

      // calculate index of each question based on total security questions.

      const qIndex1 = ((currentIndex + 1) % secQuestionCount) + 1;
      const qIndex2 = ((currentIndex + 2) % secQuestionCount) + 1;

      const question1 = this.securityQuestions.get('question' + qIndex1);
      const question2 = this.securityQuestions.get('question' + qIndex2);

      const value1 = question1 ? x !== question1.value : true;
      const value2 = question2 ? x !== question2.value : true;

      return value1 && value2;
    });

    return collection;
  }

  checkError(number: number) {
    let controls = this.securityQuestions.controls;
    let question = this.securityQuestions.controls['question' + number];
    let ans = this.securityQuestions.controls['answer' + number];
    if (question.dirty && ans.dirty) {
      // value exists in both of the controls[question and answer]  .. now should check with rest of the controls
      if (question.valid && ans.valid && ans.value.trim().length >= 3) {
        // both have valid content
        if (this.checkSameAnswer(number)) {
          // ans is not equal to others
          return true;
        }
        return false;
      }
      return true;
    }
    return false;
  }

  checkSuccess(number: number) {
    let question = this.securityQuestions.controls['question' + number];
    let ans = this.securityQuestions.controls['answer' + number];
    return question.dirty && ans.dirty && !this.checkError(number);
  }

  checkSameAnswer(number: number) {
    let controls = this.securityQuestions.controls;
    let ans = this.securityQuestions.controls['answer' + number];
    let temp;
    if (ans.valid) {
      // && (ans.value.trim() === controls["answer" + (((number) % 3) + 1)].value.trim() || ans.value.trim() === controls["answer" + (((number + 1) % 3) + 1)].value.trim())) {
      if (
        (temp = controls['answer' + ((number % 3) + 1)].value) != null &&
        ans.value.trim().toLowerCase() === temp.trim().toLowerCase()
      ) {
        return true;
      }
      if (
        (temp = controls['answer' + (((number + 1) % 3) + 1)].value) != null &&
        ans.value.trim().toLowerCase() === temp.trim().toLowerCase()
      ) {
        return true;
      }
      return false;
    }
    return false;
  }

  abstract onSubmit();
  abstract getSubtitle();

  onChangeQuestion(number: number) {
    this.securityQuestions.get(`answer${number}`).setValue('');
  }
}
