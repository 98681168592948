import { NgRedux } from '@angular-redux/store';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { ApiRequestService } from './api-client/api-requests.service';
import apiConfig from './api-client/api-config';
import {
  RESET_REGISTRATION,
  UPDATE_REGISTRAION_USER,
  UPDATE_VERIFICATION,
  UPDATE_REG_STEP,
  UPDATE_TWO_STEP,
} from './utils/state-management/actions';
import { IAppState } from './utils/state-management/store';
import { getLanguageCode } from './utils/utility';
import {
  VerificationCallback,
  VerificationService,
} from './utils/verification.service';
import { Constants } from './utils/Constants';

export interface RegistrationCallback {
  registrationCallback(message: string, result: any): void;
}

export class RegistrationUser {
  firstName: string;
  lastName: string;
  eula: boolean;
  email: string;
  phone: string;
  password: string;
}

@Injectable()
export class UserRegistrationService {
  constructor(
    private apiRequest: ApiRequestService,
    private ngRedux: NgRedux<IAppState>,
    private verificationService: VerificationService
  ) {}

  register(password: string, callback: RegistrationCallback): void {
    let body: any;
    let tempUser: any = this.ngRedux.getState().user;
    let language = this.ngRedux.getState().preferredLanguage;
    let config = JSON.parse(localStorage.getItem('config'));

    tempUser['password'] = password;

    body = {
      email: tempUser['email']
        ? tempUser['email'].toLowerCase()
        : tempUser['email'],
      phonenumber: tempUser['phone'],
      firstname: tempUser['firstName'].trim(),
      lastname: tempUser['lastName'].trim(),
      password: tempUser['password'],
      preferredlanguage: getLanguageCode(language),
      brandname: this.ngRedux.getState().userBrand,
    };

    let securityQuestionParams = {
      secretquestion1: tempUser['question1'] && tempUser['question1'].trim(),
      secretanswer1: tempUser['answer1'] && tempUser['answer1'].trim(),
      secretquestion2: tempUser['question2'] && tempUser['question2'].trim(),
      secretanswer2: tempUser['answer2'] && tempUser['answer2'].trim(),
      secretquestion3: tempUser['question3'] && tempUser['question3'].trim(),
      secretanswer3: tempUser['answer3'] && tempUser['answer3'].trim(),
    };
    // setting securityQuestions params on the basis of configuration
    body = config.Feature.SecurityQuestions
      ? { ...body, ...securityQuestionParams }
      : body;

    /* this.apiClient.makeCall(null, 'post', apiConfig.signUp, body, (err, res) => {
      if (err) {
        callback.registrationCallback(err, null);
      } else {
        var result = res.data[0];
        localStorage.setItem(`CognitoIdentityServiceProvider.${environment.clientId}.LastAuthUser`, body.email);
        localStorage.setItem(`CognitoIdentityServiceProvider.${environment.clientId}.${body.email}.clockDrift`, '0');
        localStorage.setItem(`CognitoIdentityServiceProvider.${environment.clientId}.${body.email}.accessToken`, result.accesstoken);
        localStorage.setItem(`CognitoIdentityServiceProvider.${environment.clientId}.${body.email}.refreshToken`, result.refreshtoken);
        localStorage.setItem(`CognitoIdentityServiceProvider.${environment.clientId}.${body.email}.idToken`, result.idtoken);
        localStorage.setItem(`isSignUp`, 'true');
        callback.registrationCallback(null, res);
      }
    }); */

    this.apiRequest
      .callApi(Constants.API_METHODS.POST, apiConfig.signUp.uri, body, true)
      .subscribe({
        next: (res) => {
          let result = res.data[0];
          localStorage.setItem(
            `CognitoIdentityServiceProvider.${environment.clientId}.LastAuthUser`,
            body.email
          );
          localStorage.setItem(
            `CognitoIdentityServiceProvider.${environment.clientId}.${body.email}.clockDrift`,
            '0'
          );
          localStorage.setItem(
            `CognitoIdentityServiceProvider.${environment.clientId}.${body.email}.accessToken`,
            result.accesstoken
          );
          localStorage.setItem(
            `CognitoIdentityServiceProvider.${environment.clientId}.${body.email}.refreshToken`,
            result.refreshtoken
          );
          localStorage.setItem(
            `CognitoIdentityServiceProvider.${environment.clientId}.${body.email}.idToken`,
            result.idtoken
          );
          localStorage.setItem(`isSignUp`, 'true');
          callback.registrationCallback(null, res);
        },
        error: (err) => {
          callback.registrationCallback(err, null);
        },
      });
  }

  updateAccountInfo(
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
    password: string,
    eula: boolean,
    callback: RegistrationCallback
  ) {
    let tempUser = new RegistrationUser();
    tempUser.firstName = firstName.trim();
    tempUser.lastName = lastName.trim();
    tempUser.email = email.trim();
    tempUser.phone = phone.trim();
    tempUser.password = password;
    tempUser.eula = eula;
    this.ngRedux.dispatch({
      type: UPDATE_REGISTRAION_USER,
      user: tempUser,
    });
    callback.registrationCallback(null, 'Account info updated successfully');
  }

  updateAccountInfoGoogle(
    firstName: string,
    lastName: string,
    phone: string,
    eula: boolean,
    callback: RegistrationCallback
  ) {
    let tempUser = new RegistrationUser();
    tempUser.firstName = firstName.trim();
    tempUser.lastName = lastName.trim();
    tempUser.phone = phone.trim();
    tempUser.eula = eula;
    this.ngRedux.dispatch({
      type: UPDATE_REGISTRAION_USER,
      user: tempUser,
    });
    callback.registrationCallback(null, 'Account info updated successfully');
  }

  /**
   * By Zeeshan 28 Sept 2021
   * Method for disptaching redux action for increment the stepper for registration regStep
   */
  rollOverRegStep() {
    this.ngRedux.dispatch({
      type: UPDATE_REG_STEP,
      object: null,
    });
  }
  rollOverTwoStep() {
    this.ngRedux.dispatch({
      type: UPDATE_TWO_STEP,
      object: null,
    });
  }

  updateEmail() {
    const tempUser: any = this.ngRedux.getState().user;
    const verifyObj: any = this.ngRedux.getState().verify;
    tempUser['email'] = verifyObj['email'].address.trim();
    this.ngRedux.dispatch({
      type: UPDATE_REGISTRAION_USER,
      user: tempUser,
    });
  }

  updatePhone() {
    const tempUser: any = this.ngRedux.getState().user;
    const verifyObj: any = this.ngRedux.getState().verify;
    tempUser['phone'] = verifyObj['phone'].address.trim();
    this.ngRedux.dispatch({
      type: UPDATE_REGISTRAION_USER,
      user: tempUser,
    });
  }

  confirmRegistration(
    username: string,
    confirmationCode: string,
    callback: any
  ): void {}

  resendCode(username: string, callback: any): void {
    if (username) {
      callback.cognitoCallback('error', null);
    } else {
      callback.cognitoCallback(null, 'success');
    }
  }

  sendVerificationCode(
    medium: string,
    address: string,
    callback: VerificationCallback
  ): void {
    this.verificationService.sendCodeWithCaptcha(
      medium,
      address,
      'registration',
      sessionStorage.getItem('captcha'),
      callback
    );
  }

  updateSecurityQuestions(
    question1: string,
    answer1: string,
    question2: string,
    answer2: string,
    question3: string,
    answer3: string
  ) {
    let tempUser: any = this.ngRedux.getState().user;
    tempUser['question1'] = question1.trim();
    tempUser['answer1'] = answer1.trim();
    tempUser['question2'] = question2.trim();
    tempUser['answer2'] = answer2.trim();
    tempUser['question3'] = question3.trim();
    tempUser['answer3'] = answer3.trim();
    this.ngRedux.dispatch({
      type: UPDATE_REGISTRAION_USER,
      user: tempUser,
    });
  }

  checkVerificationCode(
    medium: string,
    code: string,
    callback: VerificationCallback,
    isGlobalLoader: any
  ): void {
    this.verificationService.checkCode(
      medium,
      code,
      'registration',
      callback,
      isGlobalLoader
    );
  }

  resetSteps() {
    this.ngRedux.dispatch({ type: RESET_REGISTRATION });
  }
}
