import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import {
  LockService,
  LockDetailCallback,
  ILockDetails
} from "../../../services/lock.service";
import { ActivatedRoute } from "@angular/router";
import { Utility } from "../../../services/utils/utility";
import { Constants } from "src/app/services/utils/Constants";
import { Config } from "src/app/services/utils/Config";

@Component({
  selector: "app-lock-details",
  templateUrl: "./lock-details.component.html",
  styleUrls: ["./lock-details.component.css"]
})
export class LockDetailsComponent implements OnInit, LockDetailCallback {
  details: ILockDetails;
  private lockID;
  errorMessage: string;
  name: string;
  ble: boolean;

  constructor(
    private translate: TranslateService,
    private activatedRoute: ActivatedRoute,
    private lockService: LockService
  ) {}

  ngOnInit() {
    Utility.focusLogo();
    this.activatedRoute.params.subscribe(
      params => (this.lockID = params["lockID"])
    );
    this.lockService.getLockDetails(this.lockID, this);
  }

  callback(lock: ILockDetails, error) {
    if (error === Constants.ERROR_CODES.HOME_NOT_FOUND || error === Constants.ERROR_CODES.USER_NOT_AUTHORIZED) {
      this.errorMessage = this.translate.instant("LOCKS.DETAIL.INVALID_ACCESS");
      return;
    }
    if (lock === null) {
      this.errorMessage = this.translate.instant("LOCKS.DETAIL.ERROR");
      return;
    }

    if (lock.isBLE) {
      this.ble = true;
      this.details = null;
      return;
    }
    this.ble = false;
    this.details = lock;
  }

  getHomeName() {
    let title: string = this.translate.instant("LOCKS.DETAIL.HOME_TITLE");
    if (title) {
      title = title.replace(
        "{home}",
        this.lockService.getHomeName(this.lockID)
      );
      return title;
    }
  }

  getLockName() {
    let title: string = this.translate.instant("LOCKS.DETAIL.LOCK_TITLE");
    this.name = this.lockService.getLockName(this.lockID);
    if (title) {
      title = title.replace("{lock}", this.name);
      return title;
    }
  }

  checkUpdateLastStatus(lastUpdateStatus) {
    let currentDate = Math.floor(Date.now() / 1000);
    let lockStatus = currentDate - lastUpdateStatus;
    return lockStatus > Config.UPDATE_STATUS_SECONDS ? false : true; 
  }
}
