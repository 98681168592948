const privacy_eng = `<p align="CENTER">
    <strong>SPECTRUM BRANDS HOLDINGS, INC. PRIVACY POLICY</strong>
</p>
<p>
    This Privacy Policy (the “Policy”) is effective as of October 1, 2015.
</p>
<p>
    This Policy describes Spectrum Brands Holdings, Inc.’s and its affiliated
    companies and subsidiaries (collectively “Spectrum”) practices with respect
    to information it collects and receives from users of this Website (the
    “Site”), including the types of information collected, how it is used, and
    with whom it is shared.
</p>
<p>
    This Policy applies only to information we collect from this Site and does
    not apply to any other business activity of Spectrum. By accessing and
    using our Site, you consent to the collection, maintenance and use of this
    information by us. If you do not agree to the terms of this policy and do
    not consent to the collection, use and maintenance of information as
    described in this Policy, you should leave the Site immediately.
</p>
<p>
    We reserve the right to revise this Policy at any time. When we do, we will
    give you notice of the revised Privacy Policy by posting the revised
    Privacy Policy on the Site and by revising the “effective date” at the top
    of the Policy. You are responsible for regularly reviewing the current
    policy.
</p>
<p>
    <strong>PRIVACY OF CHILDREN</strong>
</p>
<p>
    The Site is a general audience website. We do not intentionally collect
    Personally Identifiable Information (as defined below) from users of this
    Site who are under the age of thirteen (13). If we become aware that a user
    of our Site is under the age of 13 and has provided information to us
    (e.g., placed an order, entered a sweepstakes, contest or other promotion,
    etc.) using our Site, we will remove that child’s personal information from
    our files. We may use the information to provide notices to the parents of
    users under the age of 13 where that may be necessary.
</p>
<p>
    <strong>WHAT INFORMATION DO WE COLLECT AND HOW DO WE USE IT?</strong>
</p>
<p>
    We collect and store “Personally Identifiable Information” and “Automatic
    Information.”
</p>
<p>
    <strong>Personal Information.</strong>
    “Personally Identifiable Information” is information that tells us who you
    are, such as your first and last name, your postal address and zip code,
    your e-mail address, your telephone number, your hobbies and interests,
    and/or information regarding your pets and purchases.
</p>
<p>
    We may collect information from you if you register with our site or sign
    up for our newsletter, purchase products on our site, enter a sweepstakes
    or contest, submit a rebate or coupon request, or otherwise voluntarily
    provide the information to us (e.g., via our “Contact Us” webpage or in
    connection with ratings, reviews, requests for advice, suggestions,
    testimonials or any user generated content, such as photographs and/or
    videos).
</p>
<p>
    You can choose not to provide Personally Identifiable Information to us.
    However, if you do not provide such information, you will be unable to
    enter a sweepstakes or contest, and we may not be able to respond to your
    “Contact Us” inquiry, send our newsletter to you, and/or process your
    rebate or coupon request.
</p>
<p>
    We may use the Personally Identifiable Information we collect to process a
    rebate, coupon or other request that has been placed by you, notify you
    regarding sweepstakes and contests, send our newsletter to you, and/or for
    marketing Spectrum’s products and services (or those of affiliated
    companies or marketing partners) via email, postal mail and otherwise.
</p>
<p>
    <strong>Automatic Information.</strong>
    “Automatic Information” is information automatically collected by our
    webserver (which may be hosted by a third party), or third party services
    (such as Google Analytics), that your web browser makes available whenever
    you visit our Site.
</p>
<p>
    Automatic Information is not generally associated with Personally
    Identifiable Information, but if it is, the Automatic Information will be
    treated like Personally Identifiable Information.
</p>
<p>
    We receive and store certain types of information whenever you interact
    with us. This includes HTML “Cookies.” A “Cookie” is a small amount of data
    that is sent to your browser from a webserver and stored on your computer’s
    hard drive. Cookies provide a means for websites that you visit to keep
    track of your online patterns and preferences. Among other things, Cookies
    help us identify returning users, and they help us customize our services
    to a user’s needs. By understanding which areas of the Site a user visits,
    Cookies allow us to present information, products and specials that may be
    of interest.
</p>
<p>
    Spectrum may also use “Flash Cookies,” which track a person’s Internet use
    across websites and are not deleted or controlled by users in the same
    manner as standard HTML Cookies.
</p>
<p>
    In addition to Cookies, for each visitor to the Site, our webserver
    automatically recognizes and/or collects (where possible) certain
    information, such as your domain name, IP address, type of computer, type
    of web browser, screen size and characteristics (e.g., color, resolution),
    and operating system, and the city, state and/or country in which you are
    located. An “IP Address” is a number assigned to your computer when you
    connect to the Internet. We may also collect information regarding your
    Internet browsing history (e.g., the site you visited before accessing our
    Site, the search engine and search terms that you used, and information
    about your previous visits to our Site), the length of your visit, the
    pages you viewed, your Internet service provider, the language settings on
    your computer, and certain software (e.g., Java support).
</p>
<p>
    We may use such information for purposes of system administration, to
    improve the design and content of the Site, and/or to enable us to
    personalize your Internet experience. We may also use such information in
    the aggregate to analyze Site usage and/or to assess the traffic to the
    Site.
</p>
<p>
    This site may have Google Analytics enabled. To find out how Google
    Analytics collects and processes data, read “How Google uses data when you
    use our partners' sites or apps” (located at
    www.google.com/policies/privacy/partners/).
</p>
<p>
    <strong>HOW DO WE SHARE THE INFORMATION WE COLLECT?</strong>
</p>
<p>
    We may transfer and otherwise disclose your Personally Identifiable
    Information and Automatic Information to third parties that are either
    subject to this Policy or follow practices at least as protective as those
    described in this Policy.
</p>
<p>
    Without limiting the generality of the foregoing, we may transfer or
    otherwise disclose such information to third parties (a) for their
    marketing purposes, (b) that perform services on behalf of Spectrum, (c) in
    connection with the sale or purchase of subsidiaries or business units or
    the sale of Spectrum’s assets, (d) to meet any applicable law, regulation,
    legal process or enforceable governmental request, (e) to protect and
    defend the rights or property of Spectrum or its affiliated companies, (f)
    to identify, contact and/or bring legal action against someone who may have
    breached our Terms of Service, and/or (g) to act to protect the interests of
    our Site, users or others as we deem necessary.
</p>
<p>
    <strong>IS THE INFORMATION COLLECTED SECURE?</strong>
</p>
<p>
    Information transmitted on the Internet and/or stored on systems attached
    to the Internet is not 100% secure. As a result, we do not ensure, warrant
    or guarantee the security or integrity of such information. We will not be
    responsible for disclosure of any information due to errors in transmission
    or the unauthorized acts of third parties.
</p>
<p>
    <strong>CAN I ACCESS THE INFORMATION COLLECTED?</strong>
</p>
<p>
    If you would like to review the Personally Identifiable Information that we
    have collected about you online, please send your name, street address,
    telephone number and e-mail address to us by regular mail to the following
    address:
</p>
<p>
    Spectrum Brands, Inc.
    <br/>
    Attention: Legal Department
    <br/>
    3001 Deming Way
    <br/>
    Middleton, WI 53562-1431
</p>
<p>
    We will send you the Personally Identifiable Information that we have
    collected about you online. If you would like to correct any factual
    errors, please note such correction and send them to us at the same address
    listed above so that we may make the corrections that you have requested.
</p>
<p>
    <strong>YOUR CALIFORNIA PRIVACY RIGHTS</strong>
</p>
<p>
    California Civil Code Section 1798.83 permits users of our Site that are
    California residents to request a notice identifying the categories of
    personal information we share with third parties for their direct marketing
    purposes. To make such a request, please submit a written request,
    specifying that you would like a “California Privacy Notice,” to the
    following address:
</p>
<p>
    Spectrum Brands, Inc.
    <br/>
    Attention: Legal Department
    <br/>
    3001 Deming Way
    <br/>
    Middleton, WI 53562-1431
</p>
<p>
    <strong>California Do Not Track Disclosure.</strong>
    Spectrum does not track users’ behavior on our Site or across websites for
    the purpose of serving targeted ads and therefore our Sites do not respond
    to browser Do Not Track (DNT) signals. Third parties that have content
    embedded on Spectrum’s sites may set cookies on a user’s browser and/or
    obtain information about the fact that a web browser visited a specific
    Spectrum website from a certain IP address. Third parties cannot collect
    any other personally identifiable information from Spectrum’s websites
    unless you provide it to them directly.
</p>
<p>
    <strong>DO I HAVE ANY CHOICES REGARDING THE INFORMATION COLLECTED?</strong>
</p>
<p>
    You can choose not to provide personal information.
</p>
<p>
    <strong>CHANGES TO THIS PRIVACY POLICY</strong>
</p>
<p>
    We reserve the right to revise this Policy at any time. When we do, we will
    also revise the “effective date” at the top of this Policy. You are
    responsible for regularly reviewing the current policy. The most current
    version of the Privacy Policy can be reviewed by clicking on the “Privacy”
    or “Privacy Policy” hypertext located at the bottom of our webpages. Your
    continued use of the Site after we post any revisions constitutes your
    agreement to any such revisions. If any such revisions are unacceptable to
    you, do not access the Site and do not submit any information to us.
</p>
<p>
    <strong>Links to Other Websites.</strong>
    While you are using this Site, you may be linked or directed to other third
    party sites outside of our Site that are beyond our control. Each of these
    third party sites may have a privacy policy that differs from ours. For
    example, you might click on a link or banner advertisement that takes you
    off of our Site. These links and banners may take you to sites of
    advertisers, sponsors and co-branding partners. It is your responsibility
    to review the privacy policies of these sites. We are not responsible for
    any actions or policies of such third parties.
</p>
<p>
    <strong>CONTACT INFORMATION</strong>
</p>
<p>
    We welcome your comments regarding this Policy. If you have questions or
    concerns regarding this Policy, please contact us by U.S. mail at:
</p>
<p>
    Spectrum Brands, Inc.
    <br/>
    Attention: Legal Department
    <br/>
    3001 Deming Way
    <br/>
    Middleton, WI 53562-1431
</p>`;



export default privacy_eng;
