import { NgRedux } from '@angular-redux/store';
import { Injectable } from '@angular/core';
import { ResponseHandlerService } from './utils/response-handler.service';
import { CHANGE_LANGUAGE, UPDATE_USER_OBJECT } from './utils/state-management/actions';
import { IAppState } from './utils/state-management/store';
import { getLanguageCode } from './utils/utility';
import { TranslateService } from '@ngx-translate/core';
import { ApiRequestService } from './api-client/api-requests.service';
import { Config } from './utils/Config';
import { Constants } from './utils/Constants';
import apiConfig from './api-client/api-config';


export interface LanguageChangeCallback {
  onSuccessSendCode(result: any): void;
  onFailure(result: any): void;
}


@Injectable()
export class LanguageChangeService {

  constructor(
    private apiClient: ApiRequestService,
    private ngRedux: NgRedux<IAppState>,
    private responseHandler: ResponseHandlerService,
    private translate: TranslateService
    ) {}

  updateUserLanguage(isLoggedIn : boolean ,lang: string, callback: LanguageChangeCallback ): void {
 
    let language = getLanguageCode(lang);
    

    if (isLoggedIn) {
      let tempUser = this.ngRedux.getState().user;
      const body = {
        email: tempUser['Email'],
        preferredlanguage: language
      };
      this.ngRedux.dispatch({
        type: CHANGE_LANGUAGE,
        preferredLanguage: lang
      });

      this.apiClient.callApi(Constants.API_METHODS.PATCH, apiConfig.updateUser.uri, body).subscribe((res) => {
        if(res){

          // update preferredLanguage in userObject in redux
          let user=this.ngRedux.getState().userObject;
          user.preferredLanguage = lang;
          this.ngRedux.dispatch({
            type: UPDATE_USER_OBJECT,
            userObject: user
          });

          callback.onSuccessSendCode({})
        }


      }, (err) => {
        err = this.responseHandler.parseErrorResponseV2(err);
        callback.onFailure(err);
      })
      

    
    } else {
      this.ngRedux.dispatch({
        type: CHANGE_LANGUAGE,
        preferredLanguage: lang
      });

      callback.onSuccessSendCode({})
    }
    
  }

  getCurrentLanguage(){
    // let user = this.ngRedux.getState().userObject;
    // const currentLang = user.preferredLanguage;
    const currentLang = this.translate.currentLang;
    const selectedLangCode = Config.LANGUAGES[currentLang.toUpperCase()];
    return selectedLangCode;
  }

  getDefaultLanguage(){

    const defaultLang = Config.TRANSLATION_LANGS[0];
    const defaultLangCode = Config.LANGUAGES[defaultLang.toUpperCase()];
    return defaultLangCode;
  }
}
