import { Component, OnInit, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ForgotPasswordService, SendVerificationCodeCallback } from '../../../../services/forgot-password.service';
import { ResponseHandlerService } from '../../../../services/utils/response-handler.service';
import { IForgotPassword } from '../../../../services/utils/state-management/types';
import { spaceCheck, Utility } from '../../../../services/utils/utility';
import { Router } from '@angular/router';
import { VerificationService } from '../../../../services/utils/verification.service';
import { Config } from 'src/app/services/utils/Config';


@Component({
  selector: 'app-enter-verification-code',
  templateUrl: './enter-verification-code.component.html',
  styleUrls: ['./enter-verification-code.component.css', '../../../../../assets/css/ng-otp.css']
})
export class EnterVerificationCodeComponent implements OnInit, SendVerificationCodeCallback {
  @Output() onError = new EventEmitter<any>();
  @Input() parent;
  @ViewChild('ngxotp') ngxOtp;

  enterVerificationCodeAndPassword: FormGroup;
  forgotPasswordVerify: FormGroup;
  verificationCode: string;
  forgotPassword: IForgotPassword;
  successMessage: string;
  spaceCheck = spaceCheck;
  otp = '';

  //ng-otp-input
  showOtpComponent = true;
  ngconfig = Utility.getNgxOtpConfigObject();

  securityCodePaste: string;
  securityCodeEnter = [];
  securityCode: string;
  securityCondition: boolean = false;
  passwordView: boolean = false;
  choice = 'phone';
  hidePass: boolean = true;
  hideRepeatPass: boolean = true;
  isVerified: boolean = false;
  otpError: boolean = false;
  errorMessage = "";
  resendAttempt: number;
  upperCase = /[A-Z]/g;
  lowerCase = /[a-z]/g;
  number = /[0-9]/g;
  specialCharacter = /[#?!@$%^&*-]/g;
  space = /[\s]/g;
  password = 'password';
  confirmPassword = 'confirmPassword';
  isLoaderActive: boolean = false;
  forgotMobileLastTwoCharacters: string;
  phoneDisableCall = false;

  constructor(
    public formBuilder: FormBuilder,
    public translate: TranslateService,
    public responseHandler: ResponseHandlerService,
    public forgotPasswordService: ForgotPasswordService,
    public verificationService: VerificationService,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.enterVerificationCodeAndPassword = this.formBuilder.group({
      verificationCode: [null, [Validators.required]],
      password: [null, [Validators.required,Validators.pattern(Config.PASSWORD_POLICY_PATTERN)]],
      confirmPassword: [null,Validators.required]
    });

    this.forgotPassword = this.forgotPasswordService.getForgotPasswordObject();
    this.forgotMobileLastTwoCharacters = this.forgotPassword.phoneNumber && this.forgotPassword.phoneNumber.slice(-2);
    this.choice = this.forgotPassword.phoneActive ? 'phone' : 'email'
    // Incase of phone disabled flow, send code again to email
    if (!this.forgotPassword.phoneActive && this.forgotPassword.verificationType === 'phone') {
      this.phoneDisableCall = true;
      this.sendForgotPasswordCode();
    }
  }

  sendForgotPasswordCode() {
    this.forgotPasswordService.callApiToSendCode('email', this);
  }

  onSubmitCode() {
    const pass = this.enterVerificationCodeAndPassword.get('password').value;
    const repPass = this.enterVerificationCodeAndPassword.get('confirmPassword').value;
    if (pass !== '' && pass === repPass) {
      this.forgotPasswordService.confirmPassword(this.otp, pass, this);
      this.successMessage = null;
    }
  }

  goToLogin() {
    this.router.navigate(['/home/login']);
  }

  onResendCode(type) {
    this.otpError = false;
    this.resetOTP();
    if (this.choice === type) {
      this.resendAttempt = this.resendAttempt + 1;
      this.choice = type;
      this.forgotPasswordService.callApiToResend(this.choice, this);
    }
    else {
      this.choice = type;
      this.resendAttempt = 0;
      this.forgotPasswordService.callApiToResend(this.choice, this);
    }
    this.phoneDisableCall = false;
  }

  sendVerifiactionCodeFailure() {
    this.onError.emit(this.translate.instant('FORGOT_PASSWORD.SELECT_VERIFICATION_TYPE.RESEND_CODE.SUCCESS'));
  }

  onOtpChange(value) {
    this.otp = value;
  }

  togglePasswordField(fieldName) {
    if (fieldName === this.password) {
      this.hidePass = !this.hidePass;
    } else if (fieldName === this.confirmPassword) {
      this.hideRepeatPass = !this.hideRepeatPass;
    }
  }

  sendVerifiactionCodeErrorResponse(res) {
    res = this.responseHandler.getErrorMessage('FORGOT_PASSWORD.ENTER_VERIFICATION_CODE.ERROR', res);
    let errMessg = '';
    let errorMessage = '';
    for (errMessg of res) {
      errorMessage += errMessg + ', ';
    }
    errorMessage = errorMessage.substring(0, errorMessage.length - 2);
    if (errorMessage.slice(-1) !== ".") {
      errorMessage += ".";
    }
    this.onError.emit({ errorMessage: errorMessage, isShowError: this.phoneDisableCall ? false : true });
  }

  sendVerifiactionCodeSuccess() {
    this.isLoaderActive = false;
    if (this.resendAttempt === 0) {
      this.enterVerificationCodeAndPassword.controls['verificationCode'].reset();
      this.onError.emit({ errorMessage: `${this.translate.instant('HOME.ERROR.CODE_SEND')}`, isShowError: this.phoneDisableCall ? false : true });
    }
    else {
      this.enterVerificationCodeAndPassword.controls['verificationCode'].reset();
      this.onError.emit({ errorMessage: this.translate.instant('FORGOT_PASSWORD.SELECT_VERIFICATION_TYPE.RESEND_CODE.SUCCESS'), isShowError: this.phoneDisableCall ? false : true });
    }

  }

  handleFillEvent(value: string): void {
    this.securityCodePaste = value;
    if (this.securityCodePaste.length === 6 && !this.securityCondition) {
      this.onError.emit({ errorMessage: '', isShowError: false });
      this.securityCode = this.securityCodePaste;
      this.checkVerificationCode();
    }
  }

  resetOTP() {
    this.showOtpComponent = false;
    this.securityCodePaste = null;
    setTimeout(() => {
      this.showOtpComponent = true;
    }, 0);
  }

  checkVerificationCode() {
    this.isLoaderActive = true;
    this.verificationService.checkVerificationCode(this.forgotPassword.email, this.choice, this.securityCode, 'forgotpassword', this);
  }

  onSuccessSendCode(result: any) {
  }

  onSuccess(obj) {
    this.isLoaderActive = false;
    if (obj.type === 'confirmPassword') {
      this.passwordView = false;
      this.isVerified = true;
    } else {
      this.onError.emit({ errorMessage: '', isShowError: false });
      this.passwordView = true;
    }
  }

  onFailureResponse(res) {
    res = this.responseHandler.getErrorMessage(
      "FORGOT_PASSWORD.ENTER_PASSWORD.ERROR",
      res
    );
    let errMessg = "";
    let errorMessage = "";
    for (errMessg of res) {
      errorMessage += errMessg + ", ";
    }
    errorMessage = errorMessage.substring(
      0,
      errorMessage.length - 2
    );
    if (errorMessage.slice(-1) !== ".") {
      errorMessage += ".";
    }


    this.onError.emit(errorMessage);
  }
  onFailure() {
    this.isLoaderActive = false;
    this.otpError = true;
    this.errorMessage = this.translate.instant('HOME.ERROR.INCORRECT_CODE');
    this.onError.emit({ errorMessage: this.errorMessage, isShowError: false });
    this.resetOTP();
  }

  passwordMissMatch() {
    this.onError.emit(this.translate.instant("FORGOT_PASSWORD.ENTER_PASSWORD.ERROR.PASSWORD_MIS_MATCH"));
  }
}