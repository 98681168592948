<div class="hidden-xs hidden-sm col-md-12 col-sm-12 col-xs-12 breadcrumbs no-padding">
  <a tabindex="50" class="home-logo-back" [routerLink]="'/securehome'">
    <img src="./../../../../assets/images/home/ico-default-home-gray.png" alt="home.png">
  </a>
  <i style="color: #7F7F7F; margin-left: 10px; margin-right: 10px;" class="fa fa-chevron-right"></i>
  <span  tabindex="51" class="account-setting" (keydown.enter)="linkEditBtn.click()" #linkEditBtn [routerLink]="'/profile/edit'" style="color: #7F7F7F; font-family: 'Gotham-Bold'; cursor: pointer;">{{ 'EDIT_PROFILE.ACCOUNT_SETTINGS' | translate }}</span>
  <i style="color: #7F7F7F; margin-left: 10px; margin-right: 10px;" class="fa fa-chevron-right"></i>
  <span style="font-family: 'Gotham-Bold';"> <b>{{ 'EDIT_PROFILE.UPD_PASSWORD' | translate }}</b> </span>
</div>
<div class="row sm-no-margin hidden-md hidden-lg">
  <div class="col-xs-12 sp-back">
    <a tabindex="52" (keydown.enter)="linkEditBtn1.click()" #linkEditBtn1 [routerLink]="'/profile/edit'">  <i class="fa fa-chevron-left"></i> {{ 'PROFILE.BACK' | translate }}</a>
  </div>
</div>
<div class="sp-body clearfix padding-none">
  <div class="row mt-warning">
    <div class="col-lg-10 col-xs-12 margin-no-padding">
      <disabled-phone [link]="true" [disabledText]="'HOME.ACCOUNT_DISABLED.PRE'"></disabled-phone>
    </div>
  </div>
  <div class="row">
    <div *ngIf="genericMessage"
    class="alert alert-danger alert-dismissible fade in">
    <a class="close" aria-label="close" (click)="genericMessage = null">&times;</a>
    {{ genericMessage }}
  </div>
  </div>
  <div class="row">
    <div class="col-sm-12 no-padding">
      <h2 class="text-left "> {{ 'CHANGE_PASSWORD.TITLE' | translate }} </h2>
      <p class="text-left">{{'CHANGE_PASSWORD.ENTER_NEW_PASS' | translate}}</p>
    </div>
  </div>

  <br />
  <form class="form-signin col-md-6 col-sm-12 no-padding" method="POST" action="#" role="form"
    [formGroup]="changePasswordForm" (ngSubmit)="onSubmitPassword()">
    <div
      [ngClass]="{'has-error' : hasError('existingPassword') , 'has-success' : this.changePasswordForm.controls['existingPassword'].dirty && !hasError('existingPassword') }">

      <mat-form-field hideRequiredMarker aria-label="old-password" class="full-width" appearance="fill">
        <mat-label>{{ 'CHANGE_PASSWORD.LABEL_EXISTING_PASSWORD' | translate }}</mat-label>
        <input tabIndex="53" matInput type="password" id="existingPassword" (input)="onInput()" formControlName="existingPassword"
          [type]="hidePass ? 'password' : 'text'" maxlength="20" (blur)="onChanges($event)">
        <mat-icon tabIndex="54" class="cursor-pointer eye-focus" (keydown.enter)="togglePasswordField('password')" (click)="togglePasswordField('password')" matSuffix>{{hidePass ?
          'visibility' : 'visibility_off'}}</mat-icon>
      </mat-form-field>
      <p *ngIf="errorMessage" class="help-block">{{ errorMessage }}</p>
      

    </div>

    <div
      [ngClass]="{'has-error' : hasError('newPassword'), 'has-success' : !hasError('newPassword') && this.changePasswordForm.controls['newPassword'].dirty}">

      <mat-form-field hideRequiredMarker aria-label="new-password" class="full-width" appearance="fill">
        <mat-label>{{ 'CHANGE_PASSWORD.LABEL_NEW_PASSWORD' | translate }}</mat-label>
        <input tabIndex="55" matInput type="password" id="loginPassword" formControlName="newPassword"
          [type]="hideNewPass ? 'password' : 'text'" maxlength="20" (blur)="onChanges($event)">
        <mat-icon tabIndex="56" class="cursor-pointer eye-focus" (keydown.enter)="togglePasswordField('newPassword')" (click)="togglePasswordField('newPassword')" matSuffix>{{hideNewPass ?
          'visibility' : 'visibility_off'}}</mat-icon>
      </mat-form-field>
      <p *ngIf="changePasswordForm.controls['newPassword'].dirty && changePasswordForm.value.newPassword === changePasswordForm.value.existingPassword" style="margin-bottom: 6px;" class="help-block">{{ 'CHANGE_PASSWORD.ERROR.SAME_NEW_PASSWORD' | translate }}</p>
      <div style="margin-top: -8px;" *ngIf="changePasswordForm.get('newPassword').value !== null"
        class="password-steps">

        <ng-container *ngIf="changePasswordForm.value.newPassword !== changePasswordForm.value.existingPassword">
          <p class="password-errors"
          [ngStyle]="{'color':changePasswordForm.get('newPassword').value != null && (changePasswordForm.get('newPassword').value).length > 7 && changePasswordForm.get('newPassword').value != null && (changePasswordForm.get('newPassword').value).length < 21  ? '#41b705' : 'black'}">
          {{ 'VALIDATION.PASSWORD_STEP_1' | translate }} </p>
        <p class="password-errors"
          [ngStyle]="{'color':changePasswordForm.get('newPassword').value != null &&  (changePasswordForm.get('newPassword').value).match(upperCase) ? '#41b705' : 'black'}">
          {{ 'VALIDATION.PASSWORD_STEP_2' | translate }}</p>
        <p class="password-errors"
          [ngStyle]="{'color':changePasswordForm.get('newPassword').value != null &&  (changePasswordForm.get('newPassword').value).match(lowerCase) ? '#41b705' : 'black'}">
          {{ 'VALIDATION.PASSWORD_STEP_3' | translate }}</p>
        <p class="password-errors"
          [ngStyle]="{'color':changePasswordForm.get('newPassword').value != null && (changePasswordForm.get('newPassword').value).match(number)  ? '#41b705' : 'black'}">
          {{ 'VALIDATION.PASSWORD_STEP_4' | translate }}</p>
        <p class="password-errors"
          [ngStyle]="{'color':changePasswordForm.get('newPassword').value != null && (changePasswordForm.get('newPassword').value).match(specialCharacter)  ? '#41b705' : 'black'}">
          {{ 'VALIDATION.PASSWORD_STEP_5' | translate }}</p>
        <p style="font-size: 11px;"
          [ngStyle]="{'color':changePasswordForm.get('newPassword').value != null && (changePasswordForm.get('newPassword').value).match(space)  ? 'black' : '#41b705'}">
          {{ 'VALIDATION.PASSWORD_STEP_6' | translate }}</p>

        </ng-container>
      </div>
    </div>

    <div
      [ngClass]="{'has-error' :  hasError('confirmNewPassword') , 'has-success' : this.changePasswordForm.controls['confirmNewPassword'].dirty && !hasError('confirmNewPassword')}">

      <mat-form-field hideRequiredMarker aria-label="retype-new-password" class="full-width" appearance="fill">
        <mat-label>{{ 'CHANGE_PASSWORD.LABEL_CONFIRM_NEW_PASSWORD' | translate }}</mat-label>
        <input tabIndex="57" matInput type="password" id="loginPasswordd"  formControlName="confirmNewPassword"
          [type]="hideNewConfirmPass ? 'password' : 'text'" maxlength="20" (blur)="onChanges($event)">
        <mat-icon tabIndex="58" class="cursor-pointer eye-focus" (keydown.enter)="togglePasswordField('confirmNewPassword')" (click)="togglePasswordField('confirmNewPassword')" matSuffix>
          {{hideNewConfirmPass ?
          'visibility' : 'visibility_off'}}</mat-icon>
      </mat-form-field>
      <p style="margin: 0px;"
        *ngIf="this.changePasswordForm.controls['confirmNewPassword'].dirty && changePasswordForm.get('confirmNewPassword').value != '' && changePasswordForm.get('confirmNewPassword').value !== changePasswordForm.get('newPassword').value"
        class="help-block">{{ 'VALIDATION.RETYPE_MATCH' | translate}}</p>
    </div>

    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 no-padding">
        <h4 class="text-left mb-0">{{ 'CHANGE_PASSWORD.GLOBAL_LOGOUT_INFO' | translate }}</h4>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 no-padding">

        <div class="bulb-icon-div">
          <img alt="bulb-icon" class="bulb-icon-img" src="assets/images/ilightbulb.png" />
        </div>
        
        <div class="info-logout-div">
          <p class="text-left">{{ 'CHANGE_PASSWORD.PASSWORD_LOGOUT_INFO' | translate }}</p>
        </div>
      </div>
    </div>
    <br/>

    <div class="col-lg-6 col-md-6 col-sm-12 no-padding">
      <div class="form-group">
        <button tabIndex="59" id="submitPassword" type="submit" class="btn btn-block sp-btn-dark h-45"
          [disabled]="!changePasswordForm.valid || !changePasswordForm.get('newPassword').valid || changePasswordForm.get('newPassword').value !== changePasswordForm.get('confirmNewPassword').value || changePasswordForm.get('newPassword').value === changePasswordForm.get('existingPassword').value">
          {{ 'CHANGE_PASSWORD.BUTTON_CONFIRM_PASSWORD' | translate }}
        </button>
      </div>
    </div>
  </form>
</div>