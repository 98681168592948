<div class="main">
  <div class="sp-middle-element lock-base">
      <div class="col-xs-12 sm-no-padding">
        <div class="col-xs-12 sm-no-padding">
          <div class="row">
            <div class="col-xs-12 sp-back">
            
            </div>
          </div>
          <div class="row">
            <div class="col-xs-12 sm-no-padding" >
              <div class="col-xs-12 sm-no-padding">
                <div class="col-xs-12 sm-no-padding">
                  <div class="row main-row">
                    <div class="col-xs-12 home-header">
                      <div class="back-div">
                        <p class="back-btn" [routerLink]="'/securehome'">
                          <img alt="back-icon" class="back-icon" [routerLink]="'/securehome'"
                            src="../../../../assets/images/home/ico-chevron-back-outline.png">
                            {{ 'REGISTRATION.TERMS.BACK_BUTTON' | translate }}
                        </p>
                      </div>
                      <div class="row home-header scr-sml home-header-top">
                        <div class="col-md-6 title-header">
                          <div class="breadcrumbs">
                            <a class="home-logo-back" tabindex="1" name="route-back" [routerLink]="'/securehome'">
                              <img alt="home-icon" src="../../../../assets/images/home/default-home.png">
                            </a>
                            <i class="fa fa-chevron-right chevron-right-bd-icon"></i>
                            {{getTitle()}}
                          </div>
                        </div>
                        <div class="col-md-3 col-md-offset-3" (keydown.enter)="opeFiltersModal()" (click)="opeFiltersModal()">
                          <div class="div-filter">
                            <img tabIndex="2" alt="menu-icon" class="img-filter" src="../../../../assets/images/ico-menu.png">
                          </div>
                          
                        </div>
                      </div>

                      <div class="row mt-warning">
                        <div class="col-lg-9 col-lg-offset-1 col-xs-12 margin-padding">
                          <disabled-phone [link]="true" [disabledText]="'HOME.ACCOUNT_DISABLED.PRE'"></disabled-phone>
                        </div>
                      </div>
              
                      <div class="row home-header scr-sml home-header-top">
                        <div class="col-md-12 title-header">
                          <h3> {{getPageTitle()}} </h3>
                        </div>
                      </div>
              
                      <div class="page-label-header-small">
                        <div class="no-padding">
                          <h3> {{getPageTitle()}} </h3>
                        </div>
                        <div class="div-filter" (keydown.enter)="opeFiltersModal()" (click)="opeFiltersModal()">
                          <img tabIndex="3" class="img-filter" src="../../../../assets/images/ico-menu.png">
                        </div>
                      </div>
              
                      <div *ngIf="issueHistory?.length == 0 && eventHistory?.length == 0 && showError" class="row home-no-history">
                        <div class="col-md-11 col-md-offset-1 error-display">
                          <p class="f-14" *ngIf="recordMsg"> {{recordMsg}} </p>
                        </div>
                      </div>
                      <div *ngIf="issueHistory?.length > 0 || eventHistory?.length > 0" class="search-results" data-infinite-scroll [infiniteScrollDistance]="2"
                        [infiniteScrollThrottle]="250" [infiniteScrollUpDistance]="-10" [alwaysCallback]="true" [immediateCheck]="false" (scrolled)="onScroll($event)">
                        <div *ngIf="issueHistory?.length > 0">
                          <div class="row home-header">
                            <div class="col-md-12 title-header">
                              <h4> {{ 'HOME.HISTORY.ISSUES' | translate }} </h4>
                            </div>
                          </div>
                          <div *ngFor="let event of issueHistory;" class="row home-header">
                            <div class="list-row-issue">
                              <div class="list-col-item">
                                <div class="list-row-icon ">
                                  <img alt="not-jammed" class="event-img" [src]="getImageSrc(event.event)" *ngIf="event.event !== 'Jammed'"
                                    width="100%" height="100%">
                                  <img alt="jammed" class="event-img" [src]="getImageSrc(event.event)" *ngIf="event.event === 'Jammed'"
                                    width="100%" height="100%">
                                </div>
                                <div>
                                  <p class="event-title"> <b> {{event.lockName}}: {{event.eventLocaleName}} </b> </p>
                                  <p class="event-by">{{ 'HOME.HISTORY.BY_TEXT' | translate }} <ng-container *ngIf="event.user !== event.eventType && (event.event === 'Unlocked' || event.event === 'Jammed')">{{event?.eventType}} / </ng-container> {{event.user}}</p>
                                </div>
                              </div>
                              <div class="list-col-item flex-end">
                                <div>
                                  <p class="event-title-web date-view"> <b>{{event.day}}</b> </p>
                                  <p class="event-title-mobile"> <b>{{event.dayMobile}}</b> </p>
                                  <p class="event-by">{{event.showTime}}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div *ngFor="let data of eventHistory;">
                          <div *ngIf="data.history?.length">
                            <div class="row home-header">
                              <div class="col-md-12 title-header">
                                <h4>{{data.historyTitle}}</h4>
                              </div>
                            </div>
                            <div *ngFor="let event of data.history;" class="row home-header">
                              <div class="list-row-data" *ngIf="(event.event !== 'Locked' && event.event !== 'Auto Locked') && event.event !== 'Door Open'">
                                <div class="list-col-item">
                                  <div class="list-row-icon ">
                                    <img alt="event-img" class="event-img" [src]="getImageSrc(event.event)" width="100%" height="100%">
                                  </div>
                                  <div>
                                    <p class="event-title"> <b> {{event.lockName}}: {{event.eventLocaleName}} </b> </p>
                                    <p class="event-by">{{ 'HOME.HISTORY.BY_TEXT' | translate }} <ng-container *ngIf="event.user !== event.eventType && (event.event === 'Unlocked' || event.event === 'Jammed')">{{event?.eventType}} / </ng-container> {{event.user}}</p>
                                  </div>
                                </div>
                                <div class="list-col-item flex-end">
                                  <div>
                                    <p class="event-title-web date-view"> <b>{{event.day}}</b> </p>
                                    <p class="event-title-mobile"> <b>{{event.dayMobile}}</b> </p>
                                    <p class="event-by">{{event.showTime}}</p>
                                  </div>
                                </div>
                              </div>
                              <div class="list-row-data list-row-data-locked" *ngIf="(event.event === 'Locked' || event.event === 'Auto Locked') && event.event !== 'Door Open'">
                                <div class="list-col-item">
                                  <div class="list-row-icon ">
                                    <img alt="event-image" class="event-img" [src]="getImageSrc(event.event)" width="100%" height="100%">
                                  </div>
                                  <div>
                                    <p class="event-title"> <b> {{event.lockName}}: {{event.eventLocaleName}} </b> </p>
                                    <p class="event-by">{{ 'HOME.HISTORY.BY_TEXT' | translate }} <ng-container *ngIf="event.user !== event.eventType">{{event.eventType  }} / </ng-container>{{event.user}}</p>
                                  </div>
                                </div>
                                <div class="list-col-item flex-end">
                                  <div>
                                    <p class="event-title-web date-view"> <b>{{event.day}}</b> </p>
                                    <p class="event-title-mobile"> <b>{{event.dayMobile}}</b> </p>
                                    <p class="event-by">{{event.showTime}}</p>
                                  </div>
                                </div>
                              </div>
                              <div class="list-row-data" *ngIf="event.event === 'Door Open'">
                                <div class="list-col-item">
                                  <div class="list-row-icon ">
                                    <img alt="event-image" class="event-img" [src]="getImageSrc(event.event)" width="100%" height="100%">
                                  </div>
                                  <div>
                                    <p class="event-title"> <b> {{event.lockName}}: {{event.eventLocaleName}} </b> </p>
                                    <p class="event-by"> <ng-container *ngIf="event.user !== event.eventType">{{event.eventType  }} / </ng-container>{{event.user}}</p>
                                  </div>
                                </div>
                                <div class="list-col-item flex-end">
                                  <div>
                                    <p class="event-title-web date-view"> <b>{{event.day}}</b> </p>
                                    <p class="event-title-mobile"> <b>{{event.dayMobile}}</b> </p>
                                    <p class="event-by">{{event.showTime}}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>