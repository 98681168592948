import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserManagement } from '../services/utils/state-management/action-classes';



@Injectable()
export class HomeGuard implements CanActivate {

    constructor(
        private router: Router, public userManagementService: UserManagement) {
    }
    canActivate(): Observable<boolean> | Promise<boolean> | boolean {
        let mainRoute = '/securehome';
        if (this.userManagementService.getUser()) {
            this.router.navigate([mainRoute]);
            return false;
        }
        else {
            return true;
        }
    }
}
