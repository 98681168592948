import { Component, EventEmitter, ViewEncapsulation } from '@angular/core';
/* import { DialogComponent, DialogService } from "ng6-bootstrap-modal"; */
import { Router} from "@angular/router";
import { UserManagement } from "../../../services/utils/state-management/action-classes";
import { HomeCallback, HomeService, IHomeObject } from "../../../services/home.service";
import { BsModalRef } from 'ngx-bootstrap/modal';

export interface HomeSelectionModal {}
@Component({
  selector: 'app-home-selection-modal',
  templateUrl: './home-selection-modal.component.html',
  styleUrls: ['./home-selection-modal.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class HomeSelectionModalComponent /* DialogComponent<HomeSelectionModal, Object> */ implements HomeSelectionModal {
  modalViewType;
  homeList: IHomeObject[];
  selectedHome;
  result: {};
  public event: EventEmitter<any> = new EventEmitter();
  constructor(public router: Router, private userManagement: UserManagement, private homeService: HomeService, public bsModalRef: BsModalRef) {
    /* super(dialogService); */
  }

  ngOnInit() {
    this.homeService.getHome(this);
    this.modalViewType = this.userManagement.getUserModalView();
    this.selectedHome = this.userManagement.getUserHome();
  }

  callback(home) {
    this.homeList = home;
  }

  confirm() {
    /* this.result = true;
    this.close(); */
  }

  getHomePic(home){
    let { homePic = null } = home;
    if(homePic){
      return homePic;
    } else {
      return "./../../../../assets/images/home/home-photo-icon-inactive.png";
    }
  }

  changeView(view: string) {
    this.modalViewType = view;
    this.userManagement.storeUserModalViewType(view);
  }

  getHomeSelecion(home: string) {
    this.selectedHome = home;
    console.log(home);
    this.userManagement.storeSelectedHome(home);
    this.closeDialog();
  }

  closeDialog(){
    this.result = this.selectedHome;
    this.event.emit(this.result)
    this.bsModalRef.hide();
  }
}

