<div class="auto-height">
  <div class="col-md-10 col-md-offset-1 col-lg-8 col-lg-offset-2 col-sm-6 col-sm-offset-3 col-xs-12 sp-verf-block">
    <!-- <div *ngIf="errorMessage!=null" class="alert alert-danger alert-dismissible fade in">
      <a class="close" aria-label="close" (click)="errorMessage = null">&times;</a>
      {{ errorMessage }}
    </div> -->
    <div *ngIf="successMessage!=null" class="alert alert-danger alert-dismissible fade in">
      <a class="close" aria-label="close" (click)="successMessage = null">&times;</a>
      {{ successMessage }}
    </div>
    <h3 class="verify-email-title"> {{ (continueType === "email" ? 'REGISTRATION.EMAIL_VERIFICATION.TITLE' :
      'REGISTRATION.PHONE_VERIFICATION.TITLE') | translate }} </h3>
    <!-- <hr class="sp-red-border"> -->
    <ng-container *ngIf="!isLoaderActive">
      <p class="sp-sub-title"> {{ continueType === "email" ? ('REGISTRATION.EMAIL_VERIFICATION.SUB_TITLE' | translate
        ).replace('[EMAIL_ADDRESS]', '') : ('REGISTRATION.PHONE_VERIFICATION.SUB_TITLE' | translate )}}
        <b *ngIf="continueType !== 'email'">{{getLastDigitsOfPhone()}}</b>
        <b class="bold-flex" *ngIf="continueType === 'email'">
          <div class="email-truncated">{{this.email}}</div>@{{this.emailDomain}}.
        </b>
      </p>
    </ng-container>
    <ng-container *ngIf="isLoaderActive">
      <p class="sp-sub-title">{{ continueType === "email" ? ('REGISTRATION.EMAIL_VERIFICATION.WAIT_MESSAGE' | translate)
        : ('REGISTRATION.PHONE_VERIFICATION.WAIT_MESSAGE' | translate ) }}</p>
    </ng-container>
    <br>

    <!-- <form method="POST" action="#" role="form" [formGroup]="emailValidation" (ngSubmit)="onVerify()">
      <div class="form-group has-feedback">
        <div [ngClass]="{'has-error' : this.emailValidation.invalid && this.emailValidation.dirty, 'has-success' : this.emailValidation.valid}">
          <input id="emailVerify" type="email" maxlength="100" class="form-control sp-form-input" formControlName="email" [placeholder]="'REGISTRATION.EMAIL_VERIFICATION.LABEL_EMAIL' | translate">
          <p *ngIf="this.emailValidation.invalid && this.emailValidation.dirty" class="help-block">{{ 'VALIDATION.EMAIL' | translate}}</p>
        </div>
      </div>
      <div class="form-group">
        <button id="submitEmail" *ngIf="!emailEntered" type="submit" class="btn btn-block sp-form-button" [disabled]="!emailValidation.valid">
          {{ 'REGISTRATION.EMAIL_VERIFICATION.BUTTON_SEND_CODE' | translate }}
        </button>
        <button id="submitEmail" *ngIf="emailEntered" type="submit" class="btn btn-block sp-form-button" [disabled]="!emailValidation.valid">
          {{ 'REGISTRATION.EMAIL_VERIFICATION.BUTTON_RESEND_CODE' | translate }}
        </button>
      </div>
    </form> -->

    <form class="sp-verf-code" method="POST" action="#" role="form" [formGroup]="codeValidation"
      (ngSubmit)="onSubmitCode()">
      <div [hidden]="isLoaderActive">
        <div class="form-group has-feedback">
          <div
            [ngClass]="{'has-error' : this.codeValidation.invalid && this.codeValidation.dirty, 'has-success' : this.codeValidation.valid}">

            <div class="ngx-otp">
              <ng-otp-input #ngOtpInput (onInputChange)="handleFillEvent($event)" [config]="ngconfig" id="ng-otp-input"
                *ngIf="showOtpComponent"></ng-otp-input>
            </div>

            <p *ngIf="errorMessage!=null" style="margin-top:15px; color: red" class="help-block">{{ errorMessage }}</p>
          </div>
        </div>

        <p class="resend-code-p">{{ (continueType === "email" ? 'REGISTRATION.EMAIL_VERIFICATION.RE_SEND_CODE' :
          'REGISTRATION.PHONE_VERIFICATION.RE_SEND_CODE') | translate }} <span tabIndex="0" class="sp-hyperlink cursor-pointer" (keydown.enter)="resendCode()"
            (click)="resendCode()"> {{ 'LOGIN.VERIFICATION.LINK_RESEND_CODE' | translate }}.</span></p>
        <div class="form-group">
          <!-- <button  id="submitCode" type="submit" class="btn btn-block btn-dark" [disabled]="this.otp.length < 6">
              {{ 'REGISTRATION.EMAIL_VERIFICATION.BUTTON_VERIFY_CODE' | translate }}
            </button> -->
        </div>
      </div>
      <div *ngIf="isLoaderActive">
        <app-inner-loader></app-inner-loader>
      </div>
    </form>
  </div>
</div>