<div>
  <br>
  <div class="home-body main-container">
    <div class="sp-home-header">
      <div class="col-lg-10 col-lg-offset-1 col-xs-12">

        <div class="padding-tb-icons sp-middle-element middle-element">
          <a href="/securehome" (keydown.enter)="homebtnRef.click()" #homebtnRef>
            <img tabIndex="26"  src="./../../../../assets/images/home/default-home.png" alt="home.png">
          </a>

          <div class="pull-right sp-home-view">
            <img tabIndex="27" *ngIf="viewType === null || viewType === 'Grid'" src="./../../../../assets/images/home/card-view.png"
              alt="card-view.png" class="click" class="icons-new">
            <img tabIndex="27" *ngIf="viewType != 'Grid'" src="./../../../../assets/images/home/card-view-inactive.png"
              alt="card-view.png" (keydown.enter)="changeView('Grid')" (click)="changeView('Grid')" class="click" class="icons-new">
            <img tabIndex="28" *ngIf="viewType != null && viewType === 'List'" src="./../../../../assets/images/home/list-view.png"
              alt="list-view.png" class="click" class="icons-new">
            <img tabIndex="28"  *ngIf="viewType != null && viewType != 'List'"
              src="./../../../../assets/images/home/list-view-inactive.png" alt="list-view.png" (keydown.enter)="changeView('List')"
              (click)="changeView('List')" class="click" class="icons-new">
          </div>
        </div>

      </div>
    </div>

    <div class="row mt-warning">
      <div class="col-lg-10 col-lg-offset-1 col-xs-12">
        <disabled-phone [link]="true" [disabledText]="'HOME.ACCOUNT_DISABLED.PRE'"></disabled-phone>
      </div>
    </div>

    <div *ngIf="successMessage"
      class="alert alert-danger alert-info alert-dismissible fade in col-lg-10 col-lg-offset-1 col-xs-12">
      <a class="close" aria-label="close" (click)="successMessage = null">&times;</a>
      {{ successMessage }}
    </div>

    <div class="sp-home-header" *ngIf="homeList && homeList.length != 0">
      <div class="col-lg-10 col-lg-offset-1 col-xs-12">
        <div class="sp-home-inner-header sp-middle-element padding-tb-icons">
          <a class="sp-home-heading-new goth-heading" (click)="openHomeModal()">
            {{selectedHomeName}}
          </a>
          <img tabIndex="29" src="./../../../../assets/images/home/dropdown-down-arrow.png" (keydown.enter)="openHomeModal()" (click)="openHomeModal()" alt="home.png"
            class="icons-new">
        </div>
      </div>
    </div>
    <div *ngIf="homeList && isShowed">
      <div *ngFor="let home of homeList">
        <div class="sp-home-header">
          <div class="col-lg-10 col-lg-offset-1 col-xs-12">
            <div class="sp-home-inner-header sp-middle-element">
              <div class="sp-middle-element sp-home-heading-div home-width">

                <p class="sp-home-heading goth-heading ellipsis" *ngIf="home.homeState.toLowerCase() !== 'pending'">
                  {{home.homeName}}
                </p>
                <span class="small-name"
                  style="font-size: 13px; text-transform: capitalize; padding-left: 9px; font-family: Gotham-Bold;"
                  *ngIf="home.accessRights == 'owner'">({{ 'SHARE_HOME.TITLE.OWNER' | translate }})</span>
                <span class="small-name"
                  style="font-size: 13px; text-transform: capitalize; padding-left: 9px; font-family: Gotham-Bold;"
                  *ngIf="home.accessRights !== 'owner' && home.homeState.toLowerCase() !== 'pending'"> {{ 'HOME.TITLE' |
                  translate }}
                  {{ 'SHARE_HOME.TITLE.SHARED_BY' | translate }} {{home.sharedBy}} ({{home.accessRights.toLowerCase()
                  === 'admin' ? translate.instant('SHARE_HOME.TITLE.ADMIN') :
                  translate.instant('SHARE_HOME.TITLE.GUEST') }}) </span>
              </div>

              <div *ngIf="home.homeState.toLowerCase() === 'enabled' " class="pull-right sp-home-history">
                <img *ngIf="(home.accessRights.toLowerCase() === isGuest)"
                  src="./../../../../assets/images/home/icon-history.png" tabindex="-1" alt="history.png"
                  class="click custom-disabled icons-new">
                <img
                  *ngIf="(home.accessRights.toLowerCase() === isOwner || home.accessRights.toLowerCase() === isAdmin)"
                  src="./../../../../assets/images/home/icon-history.png" alt="history.png" tabindex="-1" class="click"
                  [routerLink]="['/homeHistory', home.homeId]" class="icons-new">
                <a *ngIf="(home.accessRights.toLowerCase() === isOwner || home.accessRights.toLowerCase() === isAdmin)"
                  [routerLink]="['/homeHistory', home.homeId]" class="sp-home-icons-heading hidden-xs pointer">{{
                  'HOME.HISTORY.NEW_TITLE' | translate }}</a>
                <a *ngIf="!(home.accessRights.toLowerCase() === isOwner || home.accessRights.toLowerCase() === isAdmin)"
                  class="sp-home-icons-heading hidden-xs pointer-disabled">{{ 'HOME.HISTORY.NEW_TITLE' | translate
                  }}</a>
                <img *ngIf="(home.accessRights.toLowerCase() === isGuest)"
                  src="./../../../../assets/images/home/users.png" alt="users.png" tabindex="-1" class="icons-new custom-disabled">
                <img
                  *ngIf="(home.accessRights.toLowerCase() === isOwner || home.accessRights.toLowerCase() === isAdmin)"
                  [routerLink]="['/viewSharedUsers', home.homeId]" src="./../../../../assets/images/home/users.png"
                  alt="users.png" tabindex="-1" class="icons-new">
                <a *ngIf="(home.accessRights.toLowerCase() === isOwner || home.accessRights.toLowerCase() === isAdmin)"
                  [routerLink]="['/viewSharedUsers', home.homeId]" class="sp-home-icons-heading hidden-xs pointer">{{
                  'HOME.USERS' | translate }}</a>
                <a *ngIf="!(home.accessRights.toLowerCase() === isOwner || home.accessRights.toLowerCase() === isAdmin)"
                  class="sp-home-icons-heading hidden-xs pointer-disabled">{{ 'HOME.USERS' | translate }}</a>
              </div>
            </div>
          </div>
        </div>

        <!-- Invited Homes -->
        <ng-container *ngIf="home.homeState.toLowerCase() === 'pending'">
          <div class="sp-home-header margin-top-not">
            <div class="col-lg-10 col-lg-offset-1 col-xs-12">
              <div>
                <a class="sp-home-heading goth-heading">
                  {{home.homeName}}
                </a>
                <span
                  style="font-size: 13px; text-transform: capitalize; padding-left: 9px; margin-top: 6px; font-family: Gotham-Bold;"
                  *ngIf="home.accessRights !== 'owner' && home.homeState.toLowerCase() === 'pending'">Home
                  {{ 'SHARE_HOME.TITLE.SHARED_BY' | translate }} {{home.sharedBy}} ({{home.accessRights.toLowerCase()
                  === 'admin' ? translate.instant('SHARE_HOME.TITLE.ADMIN') :
                  translate.instant('SHARE_HOME.TITLE.GUEST') }}) </span>
                <div class="row invite-home">

                  <div class="col-xs-12 col-sm-11 col-md-11 col-lg-11">
                    {{home.sharedBy}} {{ (('HOME.SHARE.MSG' | translate).replace('[AccessLevel]',
                    home.accessRights.toLowerCase() ===
                    'admin' ? adminText : guestText )).replace('[Home]', home.homeName ) }}

                  </div>
                  <div class="col-xs-12 col-sm-1 col-md-1 col-lg-1">
                    <div>
                      <div *ngIf="home.homeState.toLowerCase() === 'pending'">
                        <a (click)="viewInvitation(home.sharedBy, home.accessRights, home.homeName, home.homeId)"
                          class="underline_text">
                          {{ 'SHARE_HOME.TITLE.VIEW' | translate }} </a>
                      </div>
                    </div>
                  </div>


                </div>
              </div>
            </div>
          </div>
        </ng-container>


        <div class="sp-home-locks">
          <div class="visible-xs">
          </div>
          <div *ngIf="home.homeState.toLowerCase() !== 'pending' ">
            <app-home-locks [homeID]='home.homeId'
              [member]="(home.accessRights.toLowerCase() === 'owner' || home.accessRights.toLowerCase() === 'admin')"
              [viewType]='viewType'></app-home-locks>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="homeList && homeList.length === 0">
      <div class="col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3 text-center"> {{ ('HOME.NO_HOMES.MESSAGE' |
        translate) }}
        <a class="sp-hyperlink" href="{{('LINKS.' + brand.toUpperCase() + '.APPS') | translate }}" target="_blank"> {{
          'HOME.NO_HOMES.LINK_TEXT' | translate }}</a>
      </div>

    </div>

  </div>
  <!-- </div> -->
  <br />
  <br />