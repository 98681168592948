import { Injectable } from '@angular/core';
import { Logger } from './logger';
import { Constants } from './Constants';
import { TranslateService } from '@ngx-translate/core';
import { LoggingLevel } from './logging-level';
import { LoaderService } from '../loader.service';

@Injectable()
export class ResponseHandlerService {
  constructor(private logger: Logger, public translate: TranslateService, private loaderService: LoaderService) {}

  //Parse API response
  parseAPIResponse(res: any) {
    var response = { type: null, success: null, data: null, total: 0 };
    this.logger.log(LoggingLevel.INFO, 'parseAPIResponse', JSON.stringify(res));

    if (res != null && res !== null) {
      if (res._body && res._body != null && res._body !== null) {
        var body = JSON.parse(res._body);
        var resStatus = res.status;
        if (resStatus >= 200 && resStatus < 300) {
          response.success = true;

          if (body.data) {
            response.data = body.data;
          }
          if (body.total) {
            response.total = body.total;
          }
          if (body.issues) {
            response['issues'] = body.issues;
          }
        } else if (resStatus >= 400 && resStatus < 500) {
          response.success = false;
          if (body.error) {
            response.data = this.parseErrorResponse(body.error);
            response.type = body.error.code;
          }
        } else {
          response.success = false;
        }
      } else {
        if (res.status && res.status === 204) {
          //in case of "success" with NO-Content (without body) e.g: DELETE
          response.success = true;
        } else {
          response.success = false;
        }
      }
    } else {
      response.success = false;
    }
    return response;
  }

  parseErrorResponse(errResponse: any) {
    // var errorReponse = { code: '', target: '' }
    var errorResponses = [];
    if (errResponse && errResponse != null && errResponse !== null) {
      if (errResponse.code === Constants.ERROR_CODES.BAD_ARGUMENT) {
        let errorDetails = errResponse.details;
        for (var errorDetail of errorDetails) {
          var errorResponse = {
            code: errorDetail['code'],
            target: errorDetail['target'],
          };
          errorResponses.push(errorResponse);
        }
      } else {
        var errorResponse = {
          code: errResponse.code,
          target: errResponse.target,
        };
        errorResponses.push(errorResponse);
      }
    } else {
      return null;
    }
    return errorResponses;
  }

  parseErrorResponseV2(errResponse: any) {
    // var errorReponse = { code: '', target: '' }
    this.loaderService.isLoading.next(false);
    var errorResponses = [];
    if (errResponse && errResponse.error.error) {
      let errorDetails = errResponse.error.error;
      var errorResponse = {
        code: errorDetails.code,
        target: errorDetails.target,
      };
      errorResponses.push(errorResponse);
    } else {
      return null;
    }
    return errorResponses;
  }

  getErrorMessage(componentMessagePath: string, parsedResponse: any) {
    let msg = "";
    if (parsedResponse && parsedResponse.data) {
      const error = parsedResponse.data;
      let dataElement;

      if (error !== null && error.length > 0) {
        if (error.length > 1) {
          for (dataElement of error) {
            if (dataElement) {
              msg = this.translate.instant(componentMessagePath + '.' + dataElement.code.toUpperCase() + '.' +dataElement.target.toUpperCase());
            }
          }
        } else {
          msg = this.translate.instant(componentMessagePath + '.' + error[0].code.toUpperCase());
        }
      }
    } else {
      msg = this.translate.instant(componentMessagePath + '.COMMON');
    }
    return msg;
  }


  getErrorMessageViaCode(error: any, componentMessagePath: string){
    let msg = "";
    if(error && error.length > 0){
      msg = this.translate.instant(componentMessagePath + '.' + error[0].code.toUpperCase());
    }
    else{
      msg = this.translate.instant(componentMessagePath + '.COMMON');
    }

    return msg;


  }
}
