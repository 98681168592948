    <div class="modal-header">
      <button type="button" class="close close-btn" (click)="bsModalRef.hide()"><mat-icon>clear</mat-icon></button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-sm-12 col-md-12 col-sm-12">
          <h3 class="text-center header-modal">{{ 'HOME.MODAL.TITLE' | translate }}</h3>


        </div>
      </div>
      <div class="row">
        <div class="col-xs-12">
          <div class="pull-right right-margin">
            <img *ngIf="modalViewType === null || modalViewType === 'Grid'"
              src="./../../../../assets/images/home/card-view.png" alt="card-view.png" class="click icons-new">
            <img *ngIf="modalViewType != 'Grid'" src="./../../../../assets/images/home/card-view-inactive.png"
              alt="card-view.png" (click)="changeView('Grid')" class="click icons-new">
            <img *ngIf="modalViewType != null && modalViewType === 'List'"
              src="./../../../../assets/images/home/list-view.png" alt="list-view.png" class="click icons-new">
            <img *ngIf="modalViewType != null && modalViewType != 'List'"
              src="./../../../../assets/images/home/list-view-inactive.png" alt="list-view.png"
              (click)="changeView('List')" class="click icons-new">
          </div>
        </div>
      </div>
      <ng-container *ngIf="this.homeList && (modalViewType === null || modalViewType === 'Grid')">
        <div class="row">
          <div class="col-xs-6 col-sm-3 col-md-3">
            <div class="thumbnail sp-home-grid sp-default-home cursor-pointer" [ngClass]="{'activated-home' : selectedHome === 'All'}" (click)="getHomeSelecion('All')">
              <div class="row">
                <ng-container *ngIf="selectedHome === 'All'">
                  <div class="activated-home-icon">
                    <i class="fa fa-check"></i>
                  </div>
                </ng-container>
                <img src="./../../../../assets/images/home/home-photo-icon-inactive.png" alt="home-photo-icon.png"
                  class="click" class="sp-home-demo-img">
              </div>
              <div class="row sp-home-name">
                {{ 'HOME.MODAL.TAG' | translate }}
              </div>
            </div>
          </div>
          <div class="col-xs-6 col-sm-3 col-md-3" *ngFor="let home of this.homeList">
            <div class="thumbnail sp-home-grid cursor-pointer" [ngClass]="{'activated-home' : selectedHome === home.homeId}" [style.background]="'url(' + getHomePic(home) + ')'"
              (click)="getHomeSelecion(home.homeId)" *ngIf='home.homePic !== undefined'>
              <ng-container *ngIf="selectedHome === home.homeId">
                <div class="activated-home-icon">
                  <i class="fa fa-check"></i>
                </div>
              </ng-container>
              <div class="row sp-home-name sp-home-name-pic">
                {{home.homeName}}
              </div>

            </div>
            <div class="thumbnail sp-home-grid cursor-pointer" [ngClass]="{'activated-home' : selectedHome === home.homeId}" (click)="getHomeSelecion(home.homeId)"
              *ngIf='home.homePic === undefined'>

              <div class="row">
                <ng-container *ngIf="selectedHome === home.homeId">
                  <div class="activated-home-icon">
                    <i class="fa fa-check"></i>
                  </div>
                </ng-container>
                <img [src]=getHomePic(home) alt="home-photo-icon.png" class="click" class="sp-home-demo-img">
              </div>
              <div class="row sp-home-name">
                {{home.homeName}}
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="this.homeList && modalViewType === 'List'">
        <div class="row list-margin-bottom">
          <div class="sp-home-list sp-home-list-space cursor-pointer" (click)="getHomeSelecion('All')">
            <div class="col-xs-2 col-sm-1 col-md-1">
              <img src="./../../../../assets/images/home/home-photo-icon-inactive.png" alt="home-photo-icon.png"
                class="click sp-home-list-img">
            </div>
            <div class="col-xs-8 col-sm-10 col-md-10 sp-home-name-list">
              {{ 'HOME.MODAL.TAG' | translate }}
            </div>
            <div class="col-xs-2 col-sm-1 col-md-1">
              <div class="pull-right">
                <i class="fa fa-chevron-right margin-top-icon"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="row list-margin-bottom" *ngFor="let home of this.homeList">
          <div class="sp-home-list cursor-pointer" (click)="getHomeSelecion(home.homeId)">
            <div class="col-xs-2 col-sm-1 col-md-1">
              <img [src]=getHomePic(home) alt="home-photo-icon.png" class="click sp-home-list-img sp-home-list-no-img" *ngIf="home.homePic === undefined">
              <img [src]=getHomePic(home) alt="home-photo-icon.png" class="click sp-home-list-img sp-home-list-img-pic" *ngIf="home.homePic !== undefined">
            </div>
            <div class="col-xs-8 col-sm-10 col-md-10 sp-home-name-list">
              {{home.homeName}}
            </div>
            <div class="col-xs-2 col-sm-1 col-md-1">
              <div class="pull-right">
                <i class="fa fa-chevron-right margin-top-icon"></i>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

    </div>