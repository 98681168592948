import { NgRedux } from '@angular-redux/store';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
/* import { DialogService } from "ng6-bootstrap-modal"; */
import { ConfirmComponent } from '../../../helpers/modal/confirm.component';
import { UserLoginService } from '../../../services/user-login.service';
import { ToggleEnableDisableAndDeleteAccountCallback, UserProfileService } from '../../../services/user-profile.service';
import { IAppState } from '../../../services/utils/state-management/store';
import { Utility } from '../../../services/utils/utility';

@Component({
  selector: 'app-delete-account',
  templateUrl: './delete-account.component.html',
  styleUrls: ['./delete-account.component.css']
})
export class DeleteAccountComponent implements OnInit, ToggleEnableDisableAndDeleteAccountCallback, OnDestroy {
  disposable;
  constructor(
    public translate: TranslateService,
    public userProfileService: UserProfileService,
    public ngRedux: NgRedux<IAppState>,
    /* private dialogService: DialogService, */
    private router: Router,
    public userService: UserLoginService,
  ) { }

  ngOnInit() {
    Utility.focusLogo();
  }

  ngOnDestroy(): void {
    if(this.disposable){
      this.disposable.unsubscribe();
      this.disposable = null;
      /* this.dialogService.removeAll(); */
    }
  }


  onSuccess() {
    this.router.navigate(["/home"]);
  }

  onFailure() {
    /* this.disposable = this.dialogService.addDialog(ConfirmComponent, {
      title: this.translate.instant('PROFILE.DELETE_ACCOUNT.FAILURE_HEADING'),
      message: this.translate.instant('PROFILE.DELETE_ACCOUNT.ERROR.COMMON'),
      showCloseIcon: true
    }).subscribe((isConfirmed) => {});
    setTimeout(() => {
      if(this.disposable) {
        this.disposable.unsubscribe();
        this.disposable = null;
        this.dialogService.removeAll();
      }
    }, 10000); */
  }

  showConfirmationDialog() {

    this.showPopup(
      this.translate.instant('PROFILE.DELETE_ACCOUNT.WARNING_POP_UP_HEADING'),
      this.translate.instant('PROFILE.DELETE_ACCOUNT.WARNING_POP_UP_MESSAGE'),
      this.translate.instant('PROFILE.DELETE_ACCOUNT.DELETE_BUTTON'),
      this.translate.instant('PROFILE.DELETE_ACCOUNT.CANCEL_BUTTON')
    )
  }

  showPopup(title, message, okButton, canceBUtton) {
   /*  this.disposable = this.dialogService.addDialog(ConfirmComponent, {
      title: title,
      message: message,
      confirmButtonText: okButton,
      cancelButtonText: canceBUtton,
      showCloseIcon: true
    }).subscribe((isConfirmed) => {
      if(isConfirmed == true)
        // this.userProfileService.deleteAccount(this);
      if(this.disposable) {
        this.disposable.unsubscribe();
        this.disposable = null;
        this.dialogService.removeAll();
      }
    }); */
  }
}
