const privacy_fr = `<p align="CENTER">
<strong>
    SPECTRUM BRANDS HOLDINGS, INC. POLITIQUE DE CONFIDENTIALITÉ
</strong>
</p>
<p>
La présente politique de confidentialité (la « Politique ») prend effet à
compter du 1<sup>er</sup> octobre 2015.
</p>
<p>
La présente Politique décrit les pratiques de Spectrum Brands Holdings,
Inc., de ses sociétés affiliées et de ses filiales (collectivement, «
Spectrum ») eu égard aux informations qu’elle recueille et reçoit des
utilisateurs de ce site Web (le « Site »), y compris les types
d’informations recueillies, comment elles sont utilisées et avec qui elles
sont partagées.
</p>
<p>
La présente Politique s’applique uniquement aux informations que nous
recueillons sur ce Site et ne s’applique pas aux autres activités
commerciales de Spectrum. En accédant à notre Site et en l’utilisant, vous
consentez à ce que nous recueillions, mettions à jour et utilisions ces
informations. Si vous n’acceptez pas les conditions de la présente
Politique et si vous ne consentez pas à ce que nous recueillions, tenions à
jour et utilisions les informations comme décrit dans la présente
Politique, vous devez immédiatement quitter le Site.
</p>
<p>
Nous nous réservons le droit de réviser la présente Politique à tout
moment. Lorsque nous le ferons, nous vous donnerons préavis de la Politique
de confidentialité révisée en publiant la Politique de confidentialité
révisée sur le Site et en révisant la « date de prise d’effet » indiquée en
haut de la Politique. Il vous incombe de consulter régulièrement la
Politique courante.
</p>
<p>
<strong>PROTECTION DE LA VIE PRIVÉE DES ENFANTS</strong>
</p>
<p>
Le Site est un site Web grand public. Nous ne recueillons pas
intentionnellement des informations nominatives (comme défini ci-dessous)
provenant d’utilisateurs de ce Site âgés de moins de treize (13) ans. Si
nous avons connaissance qu’un utilisateur de notre Site est âgé de moins de
13 ans et nous a fourni des informations (p. ex., a passé une commande,
s’est inscrit à une loterie publicitaire, à un concours ou à une autre
promotion, etc.) en utilisant notre Site, nous supprimerons de nos fichiers
les informations personnelles de cet enfant. Dès que cela s’avère
nécessaire, nous pouvons utiliser les informations pour envoyer des avis
aux parents d’utilisateurs âgés de moins de 13 ans.
</p>
<p>
<strong>
    QUELLES INFORMATIONS RECUEILLONS-NOUS ET COMMENT LES UTILISONS-NOUS?
</strong>
</p>
<p>
Nous recueillons et nous stockons les « informations nominatives » et les «
informations automatiques ».
</p>
<p>
<strong>Informations personnelles.</strong>
Les « informations nominatives » sont des informations qui nous disent qui
vous êtes, par exemple votre prénom et votre nom de famille, votre adresse
postale et le code postal, votre adresse de courrier électronique, votre
numéro de téléphone, vos passe-temps et centre d’intérêts favoris et/ou des
renseignements concernant vos animaux familiers et vos achats.
</p>
<p>
Nous pouvons recueillir des informations sur vous si vous vous enregistrez
sur notre site ou si vous vous abonnez à notre bulletin d’information, si
vous achetez des produits sur notre site, si vous participez à une loterie
publicitaire ou à un concours, si vous soumettez une demande de remise ou
de coupon ou, d’une manière ou d’une autre, si vous nous fournissez
volontairement des informations (p. ex., en vous rendant sur notre page Web
« Nous joindre » ou en soumettant des évaluations, des commentaires, des
demandes de conseil, des suggestions, des témoignages ou du contenu généré
par l’utilisateur, comme des photos ou des vidéos).
</p>
<p>
Vous pouvez choisir de ne pas nous fournir d’informations nominatives.
Toutefois, si vous ne fournissez pas de telles informations, vous ne
pourrez participer ni à une loterie publicitaire ni à un concours et il se
peut que nous ne puissions pas répondre à votre demande « Nous joindre »,
vous envoyer notre bulletin d’information et/ou traiter votre demande de
remise ou de coupon.
</p>
<p>
Nous pouvons utiliser les informations nominatives que nous recueillons
pour traiter une remise, un coupon ou une autre demande que vous nous avez
soumise, pour vous notifier concernant une loterie publicitaire ou un
concours, pour vous envoyer notre bulletin d’information et/ou pour faire
la publicité et la promotion de produits et de services de Spectrum (ou de
ceux de sociétés affiliées ou de partenaires marketing) par courrier
électronique, courrier postal ou autrement.
</p>
<p>
<strong>Informations automatiques.</strong>
Les « informations automatiques » sont des informations automatiquement
recueillies par notre serveur Web (qui peut être hébergé par un tiers) ou
par des services tiers (par exemple, Google Analytics) que votre navigateur
Web vous offre chaque fois que vous visitez notre Site.
</p>
<p>
Les informations automatiques ne sont généralement pas associées à des
informations nominatives, mais si c’est le cas, les informations
automatiques seront traitées comme des informations nominatives.
</p>
<p>
Nous recevons et nous stockons certains types d’informations à chaque fois
que vous interagissez avec nous. Ceci inclut les « témoins » HTML. Un «
témoin » est une petite quantité de données qui, depuis un serveur Web, est
envoyée à votre navigateur et qui est stockée sur le disque dur de votre
ordinateur. Les témoins fournissent aux sites Web que vous visitez un moyen
de suivre vos habitudes de navigation en ligne et vos préférences. Entre
autres choses, les témoins nous aident à identifier les utilisateurs
retournant sur notre Site et à personnaliser nos services en fonction des
besoins d’un utilisateur. En comprenant mieux les domaines du Site qu’un
utilisateur visite, les témoins nous permettent de présenter des
informations, des produits et des offres spéciales susceptibles de
l’intéresser.
</p>
<p>
Spectrum peut également utiliser des objets locaux partagés (« flash
cookies ») qui suivent l’utilisation de l’Internet par une personne et ses
visites de sites Web; ces témoins ne sont ni supprimés ni contrôlés par les
utilisateurs de la même manière que les témoins HTML.
</p>
<p>
Outre les témoins, pour chaque visiteur du Site, notre serveur Web
reconnaît et/ou recueille automatiquement (le cas échéant) certaines
informations, par exemple le nom de votre domaine, votre adresse IP, le
type de votre ordinateur, le type de votre navigateur Web, la taille de
votre écran et certaines caractéristiques (p. ex., couleur, résolution),
votre système d’exploitation et la ville, la province et/ou le pays où se
trouve votre ordinateur. Une « adresse IP » est un numéro affecté à votre
ordinateur quand vous ouvrez une session sur l’Internet. Nous pouvons
également recueillir des informations concernant l’historique de votre
navigation Internet (p. ex., le site que vous avez visité avant d’accéder à
notre Site, le moteur de recherche et les termes de recherche que vous
utilisez ainsi que des informations sur vos précédentes visites de notre
Site), la durée de votre visite, les pages que vous avez consultées, votre
fournisseur d’accès à l’Internet, le paramétrage des langues sur votre
ordinateur et certains logiciels (p. ex., compatibilité Java).
</p>
<p>
Nous pouvons utiliser de telles informations à des fins d’administration
des systèmes, pour améliorer la conception et le contenu du Site et/ou pour
nous permettre de personnaliser votre navigation Internet. Nous pouvons
également utiliser de telles informations en les agrégeant afin d’analyser
l’utilisation du site et/ou afin d’évaluer le trafic Internet.
</p>
<p>
Le présent site peut avoir activé Google Analytics. Pour savoir comment
Google Analytics recueille et traite les données, lisez « Comment Google
utilise certaines données collectées lorsque vous utilisez des sites ou
applications de nos partenaires » (sur le site
www.google.com/intl/fr/policies/privacy/partners/).
</p>
<p>
<strong>
    COMMENT PARTAGEONS-NOUS LES INFORMATIONS QUE NOUS RECUEILLONS?
</strong>
</p>
<p>
Nous pouvons transférer ou bien divulguer vos informations nominatives et
vos informations automatiques à des tiers qui opèrent conformément à la
présente Politique ou qui suivent des pratiques de protection au moins
aussi strictes que celles décrites dans la présente Politique.
</p>
<p>
Sans limiter la portée générale de ce qui précède, nous pouvons transférer
ou, d’une manière ou d’une autre, divulguer lesdites informations à des
tiers (a) à des fins marketing qui leur sont propres, (b) qui offrent des
services pour le compte de Spectrum, (c) dans le cadre de la vente ou de
l’achat de filiales ou de divisions opérationnelles ou de la vente d’actifs
de Spectrum, (d) afin de se conformer à toute loi, tout règlement ou tout
processus judiciaire applicables ou de répondre à toute demande exécutoire
des autorités, (e) afin de protéger et de défendre les droits ou les biens
de Spectrum ou de ses sociétés affiliées, (f) afin d’identifier, de
contacter et/ou d’intenter une action en justice contre quelqu’un pouvant
avoir enfreint nos Conditions d’utilisation, et/ou enfin (g) afin de
protéger les intérêts de notre Site, des utilisateurs ou de ce que nous
estimons nécessaire.
</p>
<p>
<strong>LES INFORMATIONS RECUEILLIES SONT-ELLES SÉCURISÉES?</strong>
</p>
<p>
Les informations transmises sur l’Internet et/ou stockées sur des systèmes
reliés à l’Internet ne sont pas totalement sécurisées. Il s’ensuit que nous
n’assurons, ne garantissons ou n’émettons aucune garantie eu égard à la
sécurité ou à l’intégrité desdites informations. Nous ne saurons être tenus
responsables de la divulgation d’une information quelconque suite à des
erreurs de transmission ou à des actes non autorisés de tiers.
</p>
<p>
<strong>PUIS-JE ACCÉDER AUX INFORMATIONS RECUEILLIES?</strong>
</p>
<p>
Si vous souhaitez consulter les informations nominatives que nous avons
recueillies en ligne sur vous, veuillez nous envoyer par la poste votre
nom, votre adresse municipale, votre numéro de téléphone et votre adresse
courriel, à l’adresse suivante :
</p>
<p>
Spectrum Brands, Inc.
<br/>
À l’attention de : Legal Department
<br/>
3001 Deming Way
<br/>
Middleton, WI 53562-1431, États-Unis
</p>
<p>
Nous vous enverrons les informations nominatives que nous avons recueillies
en ligne sur vous. Si vous souhaitez corriger des erreurs factuelles,
veuillez noter lesdites corrections et nous les envoyer à la même adresse
indiquée ci-dessus afin que nous puissions faire les corrections que vous
avez demandées.
</p>
<p>
<strong>
    VOS DROITS À LA PROTECTION DES RENSEIGNEMENTS PERSONNELS POUR LA
    CALIFORNIE
</strong>
</p>
<p>
L’article 1798.83 du Code civil de Californie autorise les utilisateurs de
notre Site qui sont résidents de Californie à demander une notification
identifiant les catégories de renseignements personnels que nous partageons
avec des tiers à des fins de marketing direct qui leur sont propres. Pour
faire ce type de demande, veuillez soumettre une requête écrite spécifiant
que vous aimeriez une « notification concernant la protection des
renseignements personnels pour la Californie » et l’envoyer à l’adresse
suivante :
</p>
<p>
Spectrum Brands, Inc.
<br/>
À l’attention de : Legal Department
<br/>
3001 Deming Way
<br/>
Middleton, WI 53562-1431, États-Unis
</p>
<p>
<strong>La Californie ne suit pas les divulgations.</strong>
Spectrum ne suit pas le comportement des utilisateurs sur notre Site ou sur
les sites Web dans le but d’envoyer des messages publicitaires ciblés;
c’est pourquoi nos Sites ne répondent pas aux signaux Do Not Track (DNT)
des navigateurs. Les tiers qui ont du contenu intégré sur des sites de
Spectrum peuvent placer des témoins sur un navigateur de l’utilisateur
et/ou obtenir des informations sur le fait qu’un navigateur Web a visité un
site Web spécifique de Spectrum depuis une certaine adresse IP. Les tiers
ne peuvent recueillir aucune autre information nominative à partir des
sites Web de Spectrum, à moins de le leur fournir directement.
</p>
<p>
<strong>
    AI-JE LE CHOIX EN CE QUI CONCERNE LES INFORMATIONS RECUEILLIES?
</strong>
</p>
<p>
Vous pouvez choisir de ne pas nous fournir d’informations personnelles.
</p>
<p>
<strong>
    CHANGEMENTS APPORTÉS À LA PRÉSENTE POLITIQUE DE CONFIDENTIALITÉ
</strong>
</p>
<p>
Nous nous réservons le droit de réviser la présente Politique à tout
moment. Quand nous la réviserons, nous réviserons également la « date de
prise d’effet » indiqué en haut de la présente Politique. Il vous incombe
de consulter régulièrement la Politique courante. La version la plus
courante de la Politique de confidentialité peut être consultée en cliquant
sur le lien hypertexte « Confidentialité » ou « Politique de
confidentialité » situé en bas de nos pages Web. Votre utilisation continue
du Site une fois que nous avons publié une révision, quelle qu’elle soit,
constitue votre accord pour ladite révision. Si l’une quelconque des
révisions est inacceptable pour vous, n’accédez pas au Site et ne nous
soumettez aucune information.
</p>
<p>
<strong>Liens vers d’autres sites Web. </strong>
Lorsque vous utilisez ce Site, il se peut que vous soyez redirigé par un
lien vers d’autres sites tiers n’appartenant pas à notre Site et
indépendants de notre contrôle. Chacun de ces sites tiers peut avoir une
politique de confidentialité qui diffère de la nôtre. Par exemple, vous
pouvez cliquer sur un lien ou une bannière de publicité qui vous fait
quitter notre Site. Ces liens et ces bannières peuvent vous rediriger vers
des sites d’annonceurs, de commanditaires ou de partenaires associant leurs
marques aux nôtres. Il vous incombe d’examiner la politique de
confidentialité de ces sites. Nous ne sommes pas responsables des actions
ou des politiques de ces tiers.
</p>
<p>
<strong>COORDONNÉES DES PERSONNES-RESSOURCES</strong>
</p>
<p>
Vos commentaires sur la présente Politique sont les bienvenus. Si vous avez
la moindre question ou le moindre problème concernant la présente
Politique, n’hésitez pas à nous écrire :
</p>
<p>
Spectrum Brands, Inc.
<br/>
À l’attention de : Legal Department
<br/>
3001 Deming Way
<br/>
Middleton, WI 53562-1431, États-Unis
</p>`;



export default privacy_fr;
