import { Component, Input, OnInit } from '@angular/core';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements OnInit {

  loading: boolean;
  @Input() interalLoad = false;

  constructor(private loaderService: LoaderService) {}
  ngOnInit() {

    this.loaderService.isLoading.subscribe((value) => {
      /* console.log('Subscribed Value', value); */
      this.loading = value;
    });
  }

}
