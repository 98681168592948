import { LoggingLevel } from "../app/services/utils/logging-level";

export const environment = {

    production: true,

    //AWS Configuration
    region: 'us-east-1',

    //Cognito Configuration
    userPoolId: 'us-east-1_bq1pZLATz',
    clientId: '4au1daq36e4ikimf01e08jfos9',
    tokenKey: 'tokenKey',

    //Language Configuration
    selectedLangKey: 'language',
    defaultLanguage: 'English',

    // values in log array LoggingLevel.FATAL, LoggingLevel.ERROR, LoggingLevel.WARNING, LoggingLevel.INFO, LoggingLevel.DEBUG, LoggingLevel.TRACE
    logs: [],

    //API Configuration
    baseUrl: 'https://iwtmh1daui.execute-api.us-east-1.amazonaws.com/beta_v1',
    captcha: '6Lf-v2QUAAAAAEQBQ_kePb3wjhaezrJdFZoTqg1n',

    invisibleCaptcha: '6Ldci-AcAAAAAFj8k22Py-UbWkrZDMY3T1nxhpdS',

    //OneTrust Cookies data-domain-script
    dataDomainScript:"7a21e3f8-df74-4b34-935f-3f218e4bcff7",
    
    totalDelayTimeOut: 10 * ( 60 * 1000),
    delayWarningTime: 0.5 * ( 60 * 1000),

    codeExpiration: "24 hours",

    app: {
        weiser: "/weiserApp",
        kwikset: "/kwiksetApp"
    },

    fedratedLoginParams: {
        BASEURL: 'https://hhi-beta.auth.us-east-1.amazoncognito.com/',
        redirectUri: 'https://www.hhirasbeta.com/redirect',
        redirectLogoutUri: 'https://www.hhirasbeta.com/home/login',
        responseType: 'code',
        clientId: '5vioe849qhqptsre3ejt01gfi7',
        scope: 'email+openid+phone+profile+aws.cognito.signin.user.admin',
        grantType: 'authorization_code',
        authorization: 'Basic NXZpb2U4NDlxaHFwdHNyZTNlanQwMWdmaTc6YWxpbTNkdGdqaTlxZ3Q2dmcwZjVtNXFyY3NubXBoOWFidWQzczJzYTFwbzcwdGNzb2Vv'
    }

};
