const terms_eng = `<p align="CENTER">
    <strong>TERMS OF SERVICE</strong>
</p>
<h2>
    SPECTRUM BRANDS HOLDINGS, INC. TERMS OF SERVICE
</h2>
<p>
    Last Updated on October 21, 2013
</p>
<p>
    The Spectrum Brands Holdings, Inc. websites are operated by Spectrum Brands
    Holdings, Inc. or its affiliated companies or subsidiaries (collectively
    the "Sites" and collectively "Spectrum" or "us" or "we"). These Spectrum
    Terms of Service and any additional terms or conditions that we post on the
    Site from time-to-time ("Terms of Service") set forth the terms and conditions
    under which you may enter and use the Site and any services available on
    the Site which may include but are not limited to the following: the
    ability to purchase products through an online store or the ability to
    create a user profile and submit user generated content to the Site
    (collectively the "Services"). Registration to use the Services or some
    features of the Site may be necessary. As part of the registration process,
    you may be required to select a password and user name, which shall consist
    of an email address you provide and a screen name ("User ID"). Please read
    the Terms of Service carefully. By entering or using the Site and/or
    registering with the Site, you agree to the Terms of Service. If you do not
    agree to the Terms of Service (without modification), you are not authorized to
    view or use the Site.
</p>
<p>
    These Terms of Service state the legally binding terms with respect to your use
    of the Site. Spectrum reserves the right to modify or change the Terms of
    Use at any time without prior notice to you. Such modification or change
    shall be effective upon posting on the Site. If Spectrum revises the Terms
    of Use, it will also revise the "Last Updated" date at the top of this
    page. The most current version of the Terms of Service can be reviewed by
    clicking on the "TERMS OF SERVICE" hypertext link located at the bottom of
    Spectrum's web pages. Your continued entry to and/or use of the Site after
    Spectrum posts any revised Terms of Service constitutes your agreement to any
    such revised Terms of Service.
</p>
<p>
    <strong>
        NOTWITHSTANDING ANYTHING CONTAINED IN THE TERMS OF SERVICE OR ON THE SITE
        TO THE CONTRARY, THE SITE IS PROVIDED WITHOUT ANY WARRANTIES AND
        SUBJECT TO THE LIMITATIONS ON SPECTRUM'S LIABILITY STATED IN THESE
        TERMS OF SERVICE.
    </strong>
</p>
<p>
    <strong>1. General Use and Restrictions.</strong>
</p>
<p>
    <strong>1.1. Use of Site.</strong>
    Conditioned upon, and subject to, your strict compliance with the Terms of
    Use, Spectrum grants you a personal, non-exclusive, non-transferable,
    limited license to enter and use the Site to view information, use the
    Services and for other purposes expressly permitted by Spectrum in writing
    on the Site.
</p>
<p>
    You agree not to enter or use the Site for any purpose that is prohibited
    by the Terms of Service or is otherwise unlawful. You further agree to comply
    with all applicable U.S. laws, statutes, ordinances, regulations, contracts
    and applicable licenses regarding your use of the Site. Spectrum may, in
    its sole discretion, terminate or suspend your entry to, and/or use of, the
    Site, or any portion thereof, at any time, with or without notice and for
    any reason (or no reason), and you agree that (a) if your authorization to
    enter the Site is terminated, you will not thereafter enter, or attempt to
    enter, the Site, directly or indirectly, and (b) if your authorization to
    enter the Site is suspended, you will not thereafter enter, or attempt to
    enter, the Site, directly or indirectly, until your suspension is removed
    and Spectrum gives you express notice thereof.
</p>
<p>
    <strong>1.2. Information Provided.</strong>
    If you provide any information to us, you agree to provide only true,
    accurate, current and complete information. Use of this Site is governed by
    our Privacy Policy which we encourage you to review by clicking on the
    hypertext link located at the bottom of this website. Spectrum's use of
    personal information that may be submitted by children to this Site is
    governed by the Privacy Policy.
</p>
<p>
    <strong>1.3. Use of Content.</strong>
    You may print or copy any information (including but not limited to third
    party advertisements) displayed or transmitted on the Site (collectively,
    "Content") that you are authorized to access, solely for informational and
    non-commercial, personal use; provided that you (a) do not remove any
    title, trademark, copyright and/or restricted rights notices contained on
    such Content, and (b) strictly comply with the provisions of the Terms of
    Use including, without limitation, Section 1.4 below.
</p>
<p>
    <strong>1.4. Restrictions.</strong>
    Except as provided in Section 1.3 above, you may not (and you agree that
    you will not) reproduce, alter, modify, create derivative works, or
    publicly display any Content without first receiving Spectrum's express
    written permission. You further agree not to, without first obtaining
    Spectrum's express written permission, (a) use any of its trademarks as
    metatags on other web sites, (b) use the Site in any manner that is illegal
    or impairs the operation of the Site or its availability or usage by
    others, and/or (c) display any part of the Site in frames (or any Content
    via in-line links). You further agree not to decompile, reverse engineer or
    disassemble any software or other products or processes accessible through
    the Site, and not to insert any code or product or manipulate the Site in
    any way that affects the user's experience including, without limitation,
    taking any action that imposes, or may impose, an unreasonable or
    disproportionately large load on the Site. You further agree not to use any
    data mining, web crawlers, robots, cancelbots, spiders, Trojan horses, or
    any data gathering or extraction method in connection with your use of the
    Site except for customary search engines used in accordance with automated
    instructions directed to search engines and available on the Site.
</p>
<p>
    <strong>2.0 General.</strong>
</p>
<p>
    <strong>2.1 Account and Password.</strong>
</p>
<p>
    If we issue an account to use the Services to you (an "Account"), we will
    also issue a username and/or initial password for such Account to you (each
    user of the Service that is issued an Account shall be referred to herein
    as a "Registered User"). The portions of the Site available to the general
    public and to Registered Users may vary. You may not be able to access all
    features or use all Services on the Site if you do not become a Registered
    User. You may not authorize any third party to enter and/or use your
    Account on their behalf. Accordingly, you agree to protect your username
    and password by, among other things, keeping your username and password
    confidential. If, notwithstanding the foregoing obligation, you allow
    another party to use your username and/or password to access your Account,
    you will be responsible for all use by the party using your Account. You
    agree to (a) immediately notify Spectrum of any unauthorized use of your
    password or any other breach of security, and (b) ensure that you exit from
    your Account at the end of each session.
</p>
<p>
    <strong>2.2 Submissions and User Generated Content.</strong>
</p>
<p>
    <strong>2.1.</strong>
    The Site may contain bulletin boards, chat groups, forums and other
    interactive areas that allow users to express their opinions and post
    information and/or other materials (collectively, "User Generated
    Content"). We do not monitor all of the User Generated Content posted or
    transmitted by users and third party information providers. You may have
    the opportunity to answer surveys, ratings and questions posted on the Site
    by Spectrum's contractor. If you choose to respond, you will actually
    answer the survey, ratings and questions on the contractor's site and will
    be subject to the contractor's terms and conditions of use when you submit
    your responses. The contractor's terms of service can be accessed by clicking
    the designated link on this website.
</p>
<p>
    <strong>2.2.</strong>
    Any opinions, advice, statements, services, offers, or other information or
    content expressed or made available by third parties, including information
    providers and users, are those of the third party and not of Spectrum.
    Spectrum does not represent or endorse the accuracy or reliability of any
    User Generated Content displayed, uploaded, or distributed on the Site by
    any third party. You acknowledge that any reliance upon any such third
    party User Generated Content is at your sole risk.
</p>
<p>
    <strong>2.3.</strong>
    Spectrum may, in its sole discretion, edit and/or remove any User Generated
    Content you submit to the Site. Without limiting the generality of the
    foregoing, Spectrum may edit or remove any User Generated Content you
    submit to the Site that Spectrum, in its sole discretion, deems abusive,
    defamatory, obscene, unlawful, or otherwise unacceptable, and you waive any
    moral rights you may have with respect to changes in the User Generated
    Content. We are not responsible for maintaining your posted User Generated
    Content and we may, in our sole discretion, delete or destroy it at any
    time with no liability or obligation to you.
</p>
<p>
    <strong>2.4.</strong>
    You are solely responsible for any User Generated Content you submit to the
    Site. We shall not be liable for the use or misuse of any information or
    data, including personal information, that is included in your posted User
    Generated Content.
</p>
<p>
    <strong>2.5.</strong>
    By submitting User Generated Content to the Site, you hereby grant to
    Spectrum, its affiliates and related entities, a royalty-free,
    unrestricted, worldwide, perpetual, irrevocable, non-exclusive and fully
    transferable, assignable and sublicensable right and license to use, copy,
    exploit, modify, archive, store, reproduce, adapt, publish, translate,
    create derivative works from, distribute, perform and display all such User
    Generated Content in any form, media, software or technology of any kind
    now existing or developed in the future. You further grant Spectrum, its
    affiliates, related entities, licensees and assignees the right to use your
    name and any other information about you that you provide in connection
    with any such use of the User Generated Content. You understand and agree
    that visitors to the Site may use your User Generated Content in accordance
    with these Terms of Service. User Generated Content submitted by you to this
    Site can be used according to these Terms of Service without compensation or
    acknowledgement to you.
</p>
<p>
    <strong>3. User Generated Content Limitations.</strong>
</p>
<p>
    <strong>3.1.</strong>
    You agree not to post User Generated Content that:
    <br/>
    i) contains vulgar, profane, abusive or hateful language, epithets or
    slurs, text or illustrations in poor taste, inflammatory attacks of a
    personal, racial or religious nature, or expressions of bigotry, racism,
    discrimination or hate; or
    <br/>
    ii) is defamatory, threatening, disparaging, inflammatory, false,
    misleading, deceptive, fraudulent, inaccurate, unfair, contains gross
    exaggeration or unsubstantiated claims, is unreasonably harmful or
    offensive to any individual or community, contains any actionable
    statement, or tends to mislead or reflect unfairly on any other person,
    business or entity; or
    <br/>
    iii) violates or infringes any right of Spectrum or any third party,
    including without limitation any privacy, trademark, copyright or other
    intellectual property right, or will otherwise cause injury to any third
    party, or
    <br/>
    iv) discriminates on the grounds of race, religion, national origin,
    gender, age, marital status, sexual orientation or disability, or refers to
    such matters in any manner prohibited by law; or
    <br/>
    v) violates any municipal, state or federal law, rule, regulation or
    ordinance, or attempts to encourage evasion or violation thereof; or
    <br/>
    vi) unfairly interferes with any third party's uninterrupted use and
    enjoyment of the Site; or
    <br/>
    vii) advertises, promotes or offers to trade any goods or services, except
    in areas specifically designated for such purpose; or
    <br/>
    viii) contains copyrighted or other proprietary material of any kind on the
    Site without the express permission of the owner of that material; or
    <br/>
    ix) includes or transmits viruses or other harmful, disruptive or
    destructive files; or
    <br/>
    x) disrupts, interferes with, or otherwise harms or violates the security
    of the Site, or any Services, system resources, accounts, passwords,
    servers or networks connected to or accessible through the Site or
    affiliated or linked sites; or
    <br/>
    xi) "flames" any individual or entity (e.g., sends repeated messages
    related to another user and/or makes derogatory or offensive comments about
    another individual), or repeats prior posting of the same message under
    multiple threads or subjects; or
    <br/>
    xii) otherwise violates these Terms of Service.
</p>
<p>
    <strong>3.2.</strong>
    Any User Generated Content posted by you or others will be deemed not to be
    confidential or secret. You understand that personal and other information
    (e.g., username, e-mail address, phone number) that you post on our Site is
    generally accessible to, and may be collected and used by others, and may
    result in unsolicited messages or other contact from others. To protect
    your safety, please use your best judgment when posting information. We
    particularly discourage divulging personal phone numbers and addresses or
    other information that can be used to identify or locate you.
</p>
<p>
    <strong>4. Linking and Third Party Dealings.</strong>
</p>
<p>
    <strong>4.1. Links to External Sites.</strong>
    Spectrum may provide hyperlinks to other web sites and Internet resources
    operated by parties other than Spectrum. Spectrum has no control over such
    sites and resources or their privacy policies or terms of service and
    Spectrum's policies and terms no longer govern. Such hyperlinks are
    provided for your reference only. The inclusion of hyperlinks to such web
    sites does not imply any sponsorship, affiliation or endorsement of the
    material on such web sites or with their operators.
</p>
<p>
    <strong>4.2. Linking to the Site.</strong>
    Subject to the further provisions of this Section 4, Spectrum welcomes
    links to the Site from other web sites. However, if Spectrum demands that
    you not link to the Site, or any portion of the Site, you agree that you
    will not, directly or indirectly, link to the Site or such portion of the
    Site as directed in our demand, at any time after such demand is made.
</p>
<p>
    <strong>5. Intellectual Property.</strong>
</p>
<p>
    <strong>5.1 General.</strong>
    Except for Content that is in the public domain, the Site and all original
    Content, as well as the selection and arrangement of the Content, is owned
    by (or licensed to) Spectrum or its suppliers and is protected by
    copyright, trade dress, trademark, unfair competition, and/or other laws
    and may not be used, copied or imitated in whole or in part except as
    expressly provided herein. Except as otherwise expressly provided in the
    Terms of Service, all rights in and to the Site and Content are expressly
    reserved by Spectrum.
</p>
<p>
    <strong>5.2 Trademarks.</strong>
    UltraPro, Roughneck, Remington, Black and Decker, Rayovac and certain other
    brands, trademarks, service marks and logos are marks of Spectrum (not all
    marks of Spectrum appear on this Site) and except as expressly provided in
    Section 1.3 above, may not be copied, imitated or used, in whole or in
    part, without Spectrum's prior written permission. In addition, all page
    headers, custom graphics, button icons, and scripts are service marks,
    trademarks and/or trade dress of Spectrum or its licensors and may not be
    copied, imitated, or used, in whole or in part, without Spectrum's prior
    written permission.
</p>
<p>
    <strong>
        6. Policy, Notices and Procedures Regarding Claims of Copyright
        Infringement.
    </strong>
</p>
<p>
    Spectrum respects the intellectual property rights of others. If you
    believe that your work has been copied and is accessible on the Site in a
    way that constitutes copyright infringement, you may notify our agent as
    provided in this Section 6. Pursuant to Title 17, United States Code,
    Section 512(c)(2), notification of claimed copyright infringement should be
    directed to:
</p>
<table width="257" border="0" cellpadding="1" cellspacing="0">
    <colgroup>
        <col width="58"/>
        <col width="195"/>
    </colgroup>
    <tbody>
        <tr>
            <td width="58" valign="TOP">
                <p>
                    <strong>By Mail:</strong>
                </p>
            </td>
            <td width="195">
                <p>
                    Spectrum Brands Holdings, Inc.
                    <br/>
                    Legal/IP Division
                    <br/>
                    Copyright Agent
                    <br/>
                    3001 Deming Way
                    <br/>
                    Middleton, WI 53562-1431
                </p>
            </td>
        </tr>
        <tr>
            <td width="58" valign="TOP">
                <p>
                    <strong>By Fax:</strong>
                </p>
            </td>
            <td width="195">
                <p>
                    (608) 278-6643
                </p>
            </td>
        </tr>
        <tr>
            <td width="58" valign="TOP">
                <p>
                    <strong>By Email:</strong>
                </p>
            </td>
            <td width="195">
                <p>
                    <a
                        href="mailto:copyright.noi@spectrumbrands.com?subject=Notice%20of%20Infringement"
                    >
                        copyright.noi@spectrumbrands.com
                    </a>
                </p>
            </td>
        </tr>
    </tbody>
</table>
<p>
    <strong>NOTE:</strong>
    The preceding information in this Section 6 is provided exclusively for
    notifying Spectrum that your copyrighted material may have been infringed.
    All other inquiries, such as product or service related questions and
    requests, or questions on privacy, will <u><strong>not</strong></u> receive
    a response through this process.
</p>
<p>
    <strong>7. Indemnification</strong>
    . You hereby agree to indemnify and hold harmless Spectrum and its
    affiliates, officers, members, directors, employees, shareholders,
    information providers, suppliers, contractors and licensees (collectively,
    "Indemnified Parties") from and against any and all liability and costs,
    including, without limitation, reasonable attorney's fees, incurred by the
    Indemnified Parties in connection with any claim arising out of (a) your
    entry and/or use of the Site (including, without limitation, any Service),
    and/or (b) any breach, or alleged breach, of any of the Terms of Service by
    you.
</p>
<p>
    <strong>8. Disclaimer of Warranties.</strong>
    You expressly acknowledge and agree that your use of the Site (including,
    without limitation, any Service and Content) is at your sole risk. To the
    maximum extent permitted by applicable law, the Site (including, without
limitation, the Services and Content) is provided to you on an    <strong>"AS IS" and "AS AVAILABLE"</strong> basis. To the maximum extent
    permitted by applicable law, Spectrum makes no representations or
    warranties relating to the Site, Services or Content including, without
    limitation, representations or warranties that (a) the Site, Services or
    Content shall meet your requirements, (b) the operation of the Site or
    Services will be uninterrupted or error free, or (c) any defects in the
    Site, Service or Content will be corrected.
    <strong>
        TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, SPECTRUM HEREBY
        DISCLAIMS ANY AND ALL WARRANTIES AND/OR REPRESENTATIONS, WHETHER
        EXPRESS, IMPLIED, ORAL OR WRITTEN INCLUDING, WITHOUT LIMITATION, ANY
        AND ALL IMPLIED WARRANTIES OF MERCHANTABILITY, REASONABLE CARE, AND/OR
        FITNESS FOR A PARTICULAR PURPOSE (WHETHER OR NOT SPECTRUM KNOWS, HAS
        REASON TO KNOW, HAS BEEN ADVISED, OR IS OTHERWISE IN FACT AWARE OF ANY
        SUCH PURPOSE), IN EACH INSTANCE WITH RESPECT TO THE SITE, SERVICE AND
        CONTENT. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, SPECTRUM
        FURTHER DISCLAIMS ANY AND ALL WARRANTIES, AND/OR REPRESENTATIONS OF
        QUIET ENJOYMENT, TITLE AND NONINFRINGEMENT WITH RESPECT TO THE SITE,
        SERVICES AND CONTENT. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF
        IMPLIED WARRANTIES OR LIMITATIONS ON APPLICABLE STATUTORY RIGHTS OF A
        CONSUMER, SO THE ABOVE EXCLUSION AND LIMITATIONS MAY NOT APPLY TO YOU.
    </strong>
</p>
<p>
    <strong>9. Limitation of Liability.</strong>
    IN NO EVENT SHALL SPECTRUM ITS LICENSORS OR THE INDEMNIFIED PARTIES BE
    LIABLE FOR PERSONAL INJURY OR FOR ANY INDIRECT, SPECIAL OR CONSEQUENTIAL
    DAMAGES WHATSOEVER, INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF
    PROFITS, LOSS OF DATA, BUSINESS INTERRUPTION, COSTS OF PROCUREMENT OF
    SUBSTITUTE PRODUCTS OR SERVICES OR ANY OTHER COMMERCIAL DAMAGES OR LOSSES,
    ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE TERMS OF SERVICE, THE SITE,
    SERVICES OR CONTENT, WHETHER FOR BREACH OF CONTRACT, IN TORT OR OTHERWISE,
    EVEN IF SPECTRUM IS ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. SOME
    JURISDICTIONS DO NOT ALLOW THE LIMITATION OF LIABILITY FOR PERSONAL INJURY,
    OR OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THIS LIMITATION MAY NOT APPLY
    TO YOU. In no event shall Spectrum's total liability to you for all damages
    exceed $10.00.
</p>
<p>
    <strong>10. Modifications to the Site.</strong>
    For the avoidance of doubt, Spectrum may modify, suspend, discontinue
    and/or restrict the use of all or any portion of the Site including,
    without limitation, the Services and Content, at any time for any reason
    (or for no reason) and without notice or liability.
</p>
<p>
    <strong>11. Governing Law and Jurisdiction.</strong>
    The Site is hosted on servers located in the United States and is intended
    to be viewed by residents of the United States. Although the information on
    this website is accessible to users outside of the United States, the
    information on the website pertaining to Spectrum products is intended for
    use only be residents of the United States. Other countries may have laws
    and regulatory requirements that differ from those of the United States.
    This Site links to other sites produced by our various brands, some of
    which are outside of the United States. Those sites may have information
    that is appropriate only to that particular originating country or
    geographic region. Spectrum reserves the right to limit provision of our
    products or Services to any person, geographic region or jurisdiction
    and/or to limit the quantities or any products or Services we provide. Any
    offer for any product or Service made on this website is void where
    prohibited.
</p>
<p>
    The Terms of Service shall be deemed to be agreed to by you in Madison,
    Wisconsin. The Terms of Service, and all matters arising out of or relating to
    the Terms of Service, shall be governed by the laws of the State of Wisconsin
    and the United States, without giving effect to the conflict of law
    provisions thereof and excluding any application of the United Nations
    Convention on Contracts for the International Sale of Goods, the
    application of which is expressly excluded. You consent to exclusive
    jurisdiction and venue in the federal courts sitting in Madison, Wisconsin
    unless no federal subject matter jurisdiction exists, in which case you
    consent to exclusive jurisdiction and venue in the state courts sitting in
    Madison, Wisconsin. You hereby irrevocably waive, to the fullest extent
    permitted by applicable law, any objection which you may now or hereafter
    have to the laying of venue of any such proceeding brought in such a court
    and any claim that any such proceeding brought in such a court has been
    brought in an inconvenient forum. You agree that any cause of action that
    you may desire to bring arising out of or related to the Terms of Service must
    commence within one (1) year after the cause of action arises; otherwise,
    such cause of action shall be permanently barred.
</p>
<p>
    <strong>12. General Provisions.</strong>
    If any provision of the Terms of Service is declared or found to be illegal,
    unenforceable or void, then the invalid or unenforceable portion shall be
    modified in accordance with the applicable law as nearly as possible to
    reflect the original intention of the applicable provision, and the
    remaining sections of the Terms of Service shall remain in full force and
    effect. No right or remedy conferred by the Terms of Service is exclusive of
    any other right or remedy conferred herein or by law or in equity; rather,
    all of such rights and remedies are cumulative of every other such right or
    remedy and may be exercised concurrently or separately from time-to-time.
    Spectrum may provide notice to you relating to the Terms of Service by sending
    an e-mail to your last known e-mail address, if any, your last known postal
    address, if any, or posting a notice on the Site, and any such notice shall
    be deemed given and received on the earlier of the day it is sent to you or
    the day it is posted on the Site. A printed version of the Terms of Service and
    of any notices given to you in electronic form shall be admissible in
    judicial or administrative proceedings based upon or relating to the Terms
    of Use to the same extent and subject to the same conditions as other
    business documents and records originally generated and maintained in
    printed form. The failure of Spectrum to insist upon or enforce strict
    performance by you of any provision of the Terms of Service shall not be
    construed as a waiver of any provision or right. If Spectrum brings any
    suit against you to enforce the Terms of Service or otherwise in connection
    with your use and/or enter of the Site, you agree that if Spectrum prevails
    in such suit Spectrum shall be entitled to recover all costs and expenses
    incurred in such suit including reasonable attorneys' fees. Use of the Site
    is unauthorized in any jurisdiction that does not give effect to all
    provisions of the Terms of Service.
</p>
<p>
    <strong>13. Forward-looking Statements.</strong>
</p>
<p>
    This site may contain forward-looking statements which may involve risks
    and uncertainties. Actual results may differ from these statements as a
    result of changes in external competitive market factors, unanticipated
    changes in the company's industry, or economy in general, as well as
    various other factors, including those discussed herein and those set forth
    in Spectrum's most recent Annual Report on Form 10-K filed with the
    Securities and Exchange Commission. The stock price performance shown on
    the stock quotes on Spectrum's website is not necessarily indicative of
    future price performance.
</p>`;

export default terms_eng;
