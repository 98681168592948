
import { NGXLogger } from 'ngx-logger';
import { Injectable } from "@angular/core";
import { NgRedux } from '@angular-redux/store';
import { IAppState } from './state-management/store';
import { environment } from '../../../environments/environment';
import { LoggingLevel } from './logging-level';

@Injectable()
export class Logger {
  constructor(private logger: NGXLogger, private ngRedux: NgRedux<IAppState>) {}

  log(type: LoggingLevel, path: string, message: string) {
      if (environment.logs.indexOf(type) < 0 ) {
        return;
      }

      var date = new Date();
      let logs = JSON.parse(localStorage.getItem("logs") || null);

      if (logs === null) {
          logs = []
      }

      var thisLog = {
          id: this.ngRedux.getState().correlation_ID,
          date: date.toUTCString(),
          type: LoggingLevel[type],
          path: path,
          message: message
      }

      logs.push(thisLog)

      localStorage.setItem('logs', JSON.stringify(logs));

      switch (type) {
          case LoggingLevel.DEBUG:
              this.logger.debug("ID: " + thisLog.id + "%c" + date.toUTCString() + " - " + path + " - " + message);
              break;
          case LoggingLevel.ERROR:
              this.logger.log("ID: " + thisLog.id + "%c" + date.toUTCString() + " - " + path + " - " + message);
              break;
          case LoggingLevel.FATAL:
              this.logger.log("ID: " + thisLog.id + "%c" + date.toUTCString() + " - " + path + " - " + message);
              break;
          case LoggingLevel.INFO:
              this.logger.log("ID: " + thisLog.id + "%c" + date.toUTCString() + " - " + path + " - " + message);
              break;
          case LoggingLevel.TRACE:
              this.logger.log("ID: " + thisLog.id + "%c" + date.toUTCString() + " - " + path + " - " + message);
              break;
          case LoggingLevel.WARNING:
              this.logger.log("ID: " + thisLog.id + "%c" + date.toUTCString() + " - " + path + " - " + message);
              break;

      }

      if (logs.length > 100 || type === LoggingLevel.ERROR) {
          this.saveToServer();
      }

  }

  saveToServer() {
      var allLogs = localStorage.getItem('logs');
      localStorage.removeItem('logs');
  }

}

