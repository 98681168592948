import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgRedux, select } from '@angular-redux/store';
import { Utility } from '../../services/utils/utility';
import { ComponentCanDeactivate } from '../../guards/deactivate/ComponentCanDeactivate';
import { Config } from 'src/app/services/utils/Config';
import { UPDATE_VERIFICATION } from 'src/app/services/utils/state-management/actions';
import { IAppState } from 'src/app/services/utils/state-management/store';

/**
 * This component is responsible for displaying and controlling
 * the registration of the user.
 */
@Component({
  selector: 'consumer-portal-registration',
  templateUrl: './registration.html',
  styleUrls: ['./registration.css'],
})
export class RegisterComponent
  extends ComponentCanDeactivate
  implements OnInit
{
  @select() regStep;
  changed = false;
  email = 'email';
  phone = 'phone';
  login = 'login';
  provider = '';
  state: any;
  providersConfiguration = Config.PROVIDERS;
  constructor(public translate: TranslateService, public ngRedux: NgRedux<IAppState>) {
    super();
    Utility.focusLogo();
  }

  ngOnInit() {
    this.provider = localStorage.getItem('PROVIDER') || '';
    this.state = localStorage.getItem('state');
    // verify state gets lost on refresh, so setting it in localstorage once it gets set
    if (localStorage.getItem('verify')) {
      this.ngRedux.dispatch({
        type: UPDATE_VERIFICATION,
        object: JSON.parse(localStorage.getItem('verify')),
      });
    }
    // adding main class to body to hide overflow
    //  document.body.setAttribute('class', 'main-class')
  }

  canDeactivate() {
    let state = JSON.parse(localStorage.getItem('state'));
    // sets only when phone is verified and continue button is clicked on registration for email and google users
    let phoneVerified = localStorage.getItem('PhoneVerified');
    if (phoneVerified) {
      return true;
    } else if (state.regStep > 0 && state.regStep < 6) {
      return false;
    } else {
      return true;
    }
  }
}
